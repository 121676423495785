import React from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import uuidv1 from "uuid/v1";
import { frontend } from "../../../config";
import BaseSearchResult from "./BaseSearchResult";
import SearchResultItem from "./SearchResultItem";
import Translation from "../../../utils/Translation";

class CompanySearchResult extends BaseSearchResult {
  classes = undefined;

  getMatchText(matches) {
    let matchText = "";
    let divider = "";
    /**
     * 'jobseeker_name' 'education_speciality' 'education_level' 'education_name'
     * 'skill' 'email'
     */
    if (matches.indexOf("jobseeker_name") > -1) {
      matchText +=
        divider +
        Translation.translateField(
          "companyJobseekerNameMatch",
          "Jobseeker Name"
        );
      divider = ", ";
    }
    if (matches.indexOf("education_speciality") > -1) {
      matchText +=
        divider +
        Translation.translateField(
          "companyEducationSpecialityMatch",
          "Education speciality"
        );
      divider = ", ";
    }
    if (matches.indexOf("education_level") > -1) {
      matchText +=
        divider +
        Translation.translateField(
          "jobseekerEducationLevel",
          "Education level"
        );
      divider = ", ";
    }
    if (matches.indexOf("education_name") > -1) {
      matchText +=
        divider +
        Translation.translateField(
          "compnayEducationNameMatch",
          "Education name"
        );
      divider = ", ";
    }
    if (matches.indexOf("skill") > -1) {
      matchText +=
        divider + Translation.translateField("companySkillMatch", "Skill");
      divider = ", ";
    }
    if (matches.indexOf("email") > -1) {
      matchText += divider + Translation.translateField("email", "Email");
      divider = ", ";
    }
    return matchText;
  }

  divider = undefined;
  getdivider() {
    if (this.divider) {
      return this.divider;
    }
    this.divider = <Divider />;
    return null;
  }

  parse(data) {
    this.divider = undefined;
    return (
      <Box component="div" maxWidth="sm" className="align-left">
        {data.map(row => {
          return (
            <Card key={uuidv1()}>
              {this.getdivider()}
              <CardContent classes={{ root: "selectable" }}>
                <Typography color="textSecondary" gutterBottom>
                  <strong>
                    {Translation.translateField(
                      "searchMatchedOn",
                      "Matched on"
                    )}
                    :{" "}
                  </strong>
                  {this.getMatchText(row.matches)}
                </Typography>
                <SearchResultItem
                  title={Translation.translateField(
                    "companyJobseekerNameMatch",
                    "Jobseeker Name"
                  )}
                  url={
                    frontend.DOMAIN +
                    frontend.route.jobseeker.public_profile +
                    "/" +
                    row.jobseeker_id
                  }
                  value={row.jobseeker_name}
                />
                {row.matches.indexOf("education_speciality") > -1 ? (
                  <SearchResultItem
                    title={Translation.translateField(
                      "companyEducationSpecialityMatch",
                      "Education speciality"
                    )}
                    value={row.education_speciality}
                  />
                ) : null}
                {row.matches.indexOf("education_level") > -1 ? (
                  <SearchResultItem
                    title={Translation.translateField(
                      "jobseekerEducationLevel",
                      "Education level"
                    )}
                    value={row.education_level}
                  />
                ) : null}
                {row.matches.indexOf("education_name") > -1 ? (
                  <SearchResultItem
                    title={Translation.translateField(
                      "compnayEducationNameMatch",
                      "Education name"
                    )}
                    value={row.education_name}
                  />
                ) : null}
                {row.matches.indexOf("skill") > -1 ? (
                  <SearchResultItem
                    title={Translation.translateField(
                      "companySkillMatch",
                      "Skill"
                    )}
                    value={row.skill}
                  />
                ) : null}
                {row.matches.indexOf("email") > -1 ? (
                  <SearchResultItem
                    title={Translation.translateField("email", "Email")}
                    value={row.email}
                  />
                ) : null}
              </CardContent>
            </Card>
          );
        })}
      </Box>
    );
  }
}

export default CompanySearchResult;
