import React, { Component } from "react";

import Login from "../../sections/Login";
import Page from "../../Page";
import { setTitle } from "../../../pageOptions";
import manager from "../../../Manager";
import Translation from '../../../utils/Translation';

class LoginPage extends Component {
  static contextType = manager;

  state = {
    waitingForActivation: false,
    triedActivation: false
  };

  componentWillMount() {
    this.context.getAuthManager().pageRestriction({ logged: false });
    const getParams = this.context
      .getUtilManager()
      .processGetParams(this.props.location.search);
    if ("activation_code" in getParams) {
      const activationKey = getParams.activation_code;
      let that = this;
      this.context.getAuthManager().confirmRegistration(this, activationKey, {
        onFinish: {
          callback: result => {
            that.setState({
              waitingForActivation: false,
              triedActivation: true
            });
          }
        }
      });
    }
    setTitle(Translation.translateStaticText("loginPageTitle", "Login"));
  }

  render() {
    if (!this.state.triedActivation && this.state.waitingForActivation)
      return <br />;
    return (
      <Page location={this.props.location}>
        <Login type="jobseeker" />
      </Page>
    );
  }
}

export default LoginPage;
