import React, { Component } from "react";
import AutocompleteSelect from "./AutocompleteSelect";
class FloatingLabelAutocomplete extends Component {
  static defaultProps = {
    value: "",
    disabled: false,
    placeholder: "",
    alwaysShowPlaceholderAsLabel: false,
    placeholderColor: undefined
  };

  state = {
    isFocused: false
  };

  handleFocus = () => {
    this.setState({ isFocused: true });
  };
  handleBlur = () => this.setState({ isFocused: false });

  render() {
    let {
      alwaysShowPlaceholderAsLabel,
      placeholderColor,
      ...props
    } = this.props;
    const { isFocused } = this.state;
    let label = undefined;
    const labelStyle = {
      position: "absolute",
      left: 2,
      top: 2,
      fontSize: 14,
      color: placeholderColor ? placeholderColor : "#8c9298",
      zIndex: 1
    };
    let placeholderHandler = props.placeholder;

    if (!props.value && props.disabled) {
      return null;
    }

    if (
      (isFocused || props.value || alwaysShowPlaceholderAsLabel) &&
      placeholderHandler
    ) {
      label = placeholderHandler;
      if (!props.disabled) {
        label += ":";
      }
      placeholderHandler = undefined;
    }

    return (
      <div style={{ position: "relative" }}>
        <div style={labelStyle}>{label}</div>
        <AutocompleteSelect
          {...props}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          placeholder={placeholderHandler}
          className={`${this.props.className} pad-t-11`}
        />
      </div>
    );
  }
}

export default FloatingLabelAutocomplete;
