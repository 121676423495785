const universities = {
    en: {
        "Plovdiv University “Paisii Hilendarski”":
            "Plovdiv University “Paisii Hilendarski”",
        "“St. Cyril and St. Methodius”": "“St. Cyril and St. Methodius”",
        "University of Rousse “Angel Kantchev”":
            "University of Rousse “Angel Kantchev”",
        "Sofia University “St. Kliment Ohridski”":
            "Sofia University “St. Kliment Ohridski”",
        "New Bulgarian University": "New Bulgarian University",
        "University Veliko Turnovo": "University Veliko Turnovo",
        "International College Albena": "International College Albena",
        "“Konstantin Preslavski” University Shumen":
            "“Konstantin Preslavski” University Shumen",
        "Burgas Free University": "Burgas Free University",
        "South-West University“Neofit Rilski”":
            "South-West University“Neofit Rilski”",
        "Bourgas “Prof. Assen Zlatarov” University":
            "Bourgas “Prof. Assen Zlatarov” University",
        "American University in Bulgaria": "American University in Bulgaria",
        "“D. A. Tsenov” Academy of Economics":
            "“D. A. Tsenov” Academy of Economics",
        "University of Economics Varna": "University of Economics Varna",
        "International Business School": "International Business School",
        "University of National and World Economy":
            "University of National and World Economy",
        "European College of Economics and Management":
            "European College of Economics and Management",
        "Management, Trade and Marketing College Sofia":
            "Management, Trade and Marketing College Sofia",
        "Higher School of Insurance and finances":
            "Higher School of Insurance and finances",
        "Technical University Varna": "Technical University Varna",
        "State University of Library Studies and Information Technologies":
            "State University of Library Studies and Information Technologies",
        "Technical University Gabrovo": "Technical University Gabrovo",
        "University of Architecture, Civil Engineering and Geodesy":
            "University of Architecture, Civil Engineering and Geodesy",
        "Agricultural University Plovdiv": "Agricultural University Plovdiv",
        "“Todor Kableshkov” University of Transport":
            "“Todor Kableshkov” University of Transport",
        "University of Forestry": "University of Forestry",
        "College of Tourism – Blagoevgrad": "College of Tourism – Blagoevgrad",
        "University of Mining and Geology “St. Ivan Rilski”":
            "University of Mining and Geology “St. Ivan Rilski”",
        "Higher School of Civil Engineering (VSU) “Lyuben Karavelov”":
            "Higher School of Civil Engineering (VSU) “Lyuben Karavelov”",
        "Technical University Sofia": "Technical University Sofia",
        "University of Chemical Technology and Metallurgy Sofia":
            "University of Chemical Technology and Metallurgy Sofia",
        "University of Food Technologies": "University of Food Technologies",
        "Agricultural College Plovdiv": "Agricultural College Plovdiv",
        "College of Telecommunications and Post":
            "College of Telecommunications and Post",
        "Higher School – “Telematika”College":
            "Higher School – “Telematika”College",
        "Medical University Varna “Prof. D-r Paraskev Stojanov”":
            "Medical University Varna “Prof. D-r Paraskev Stojanov”",
        "Medical University Pleven": "Medical University Pleven",
        "Medical University Sofia": "Medical University Sofia",
        "Medical University Plovdiv": "Medical University Plovdiv",
        "National Academy of Arts": "National Academy of Arts",
        "Theatre College “Lyuben Groys”": "Theatre College “Lyuben Groys”",
        "National Academy for Theatre and Film Arts":
            "National Academy for Theatre and Film Arts",
        "Academy of Music, Dance and Fine Arts":
            "Academy of Music, Dance and Fine Arts",
        "National Academy of Music “Pantcho Vladigerov”":
            "National Academy of Music “Pantcho Vladigerov”",
        "National Sports Academy “Vassil Levski”":
            "National Sports Academy “Vassil Levski”"
    },
    bg: {
        "Agricultural University Plovdiv": "Аграрен университет",
        "Agricultural College Plovdiv": "Аграрно висше училище",
        "Academy of Music, Dance and Fine Arts":
            "Академия за музикално, танцово и изобразително изкуство",
        "American University in Bulgaria": "Американски университет в България",
        "Burgas Free University": "Бургаски свободен университет",
        "University Veliko Turnovo":
            "Великотърновски университет „Свети свети Кирил и Методий“",
        "Higher School of Civil Engineering (VSU) “Lyuben Karavelov”":
            "Висше строително училище „Любен Каравелов“",
        "“Todor Kableshkov” University of Transport":
            "Висше транспортно училище „Тодор Каблешков“",
        "International College Albena":
            "Висше училище международен колеж Албена",
        "Higher School of Insurance and finances":
            "Висше училище по застраховане и финанси",
        "College of Telecommunications and Post":
            "Висше училище по телекомуникации и пощи",
        "European College of Economics and Management":
            "Европейско висше училище по икономика и мениджмънт",
        "University of Economics Varna": "Икономически университет",
        "Management, Trade and Marketing College Sofia":
            "Колеж по мениджмънт, търговия и маркетинг",
        "College of Tourism – Blagoevgrad": "Колеж по туризъм",
        "University of Forestry": "Лесотехнически университет",
        "Medical University Pleven": "Медицински университет, Плевен",
        "Medical University Plovdiv": "Медицински университет, Пловдив",
        "Medical University Sofia": "Медицински университет, София",
        "Medical University Varna “Prof. D-r Paraskev Stojanov”":
            "Медицински университет „Професор доктор Параскев Стоянов“, Варна",
        "International Business School": "Международно висше бизнес училище",
        "University of Mining and Geology “St. Ivan Rilski”":
            "Минно-геоложки университет „Свети Иван Рилски“",
        "National Academy for Theatre and Film Arts":
            "Национална академия за театрално и филмово изкуство „Кръстьо Сарафов“",
        "National Academy of Music “Pantcho Vladigerov”":
            "Национална музикална академия „Професор Панчо Владигеров“",
        "National Sports Academy “Vassil Levski”":
            "Национална спортна академия „Васил Левски“",
        "“Konstantin Preslavski” University Shumen":
            "Шуменски университет „Епископ Константин Преславски“",
        "National Academy of Arts": "Национална художествена академия",
        "New Bulgarian University": "Нов български университет",
        "Plovdiv University “Paisii Hilendarski”":
            "Пловдивски университет „Паисий Хилендарски“",
        "University of Rousse “Angel Kantchev”":
            "Русенски университет „Ангел Кънчев“",
        "Sofia University “St. Kliment Ohridski”":
            "Софийски университет „Свети Климент Охридски“",
        "“D. A. Tsenov” Academy of Economics":
            "Стопанска академия „Димитър Ценов“",
        "Theatre College “Lyuben Groys”": "Театрален колеж „Любен Гройс“",
        "Technical University Varna": "Технически университет, Варна",
        "Technical University Gabrovo": "Технически университет, Габрово",
        "Technical University Sofia": "Технически университет, София",
        "Bourgas “Prof. Assen Zlatarov” University":
            "Университет „Проф. д-р Асен Златаров“",
        "University of National and World Economy":
            "Университет за национално и световно стопанство",
        "University of Architecture, Civil Engineering and Geodesy":
            "Университет по архитектура, строителство и геодезия",
        "State University of Library Studies and Information Technologies":
            "Университет по библиотекознание и информационни технологии",
        "University of Food Technologies":
            "Университет по хранителни технологии",
        "University of Chemical Technology and Metallurgy Sofia":
            "Химикотехнологичен и металургичен университет",
        "South-West University“Neofit Rilski”":
            "Югозападен университет „Неофит Рилски“"
    },
    de: {}
};

export default universities;
