export default {
	"Check for email":
		"Успешна регистрация. На предоставения имейл адрес е изпратен линк за потвърждение.",
	Saved: "Запазено",
	Deleted: "Изтрито",
	Applied: "Кандидатствахте успешно",
	"File Uploaded": "Файлът е запазен",
	"File Removed": "Файлът е премахнат",
	FailedPasswordConfirmation: "Паролите не съвпадат",
	passwordChange: "Възстановяването приключи, можете да влезете с новата си парола",
	noCurrentPasswordProvided: "Трябва да предоставите текуща парола",
	recoveryEmailSubmitted:
		"Изпратихме инструкции за възстановяване на Вашия профил до вашия имейл.",
	MustAgreeWithCompanyGDPR: "Трябва да се съгласите с общите условия за защита на данни на компанията",
	cannotSendEmptyMessages: "Не може да се изпрати празно съобщение",
	hasToAcceptCorrespondence: "{contact} все още не е приел кореспонденция с Вас",
	haveToAcceptCorrespondance: "Все още не сте приели кореспонденция с {contact}",
	contactRequestSent: "Заявката е изпратена"
};