
import React, { Component } from 'react';

import Page from '../Page';
import {setTitle} from "../../pageOptions";
import manager from '../../Manager';
import NotificationSettings from "../sections/NotificationSettings";

class NotificationSettingsPage extends Component {
  static contextType = manager;

  state = {
      settings: [],
      loaded: false
  }

  componentWillMount() {
    const component = this;
    component.context.getAuthManager().pageRestriction({logged: true});
    setTitle("Notification Settings");
    component.context.getNotificationManager().getNotificationSettings(
        component, 
        {
            onError: {toast: false},
            onSuccess: {
                toast: false,
                callback: (data) => {
                    component.setState({
                        settings: component.refactorData(data),
                        loaded: true
                    });
                }
            },
            onFinish: {toast: false}
        }
    );
  }

  refactorData(data) {
    let settings = [];
    for(let i = 0; i<data.length; i++) {
        settings[data[i].notification_name] = data[i].notification_value;
    }
    return settings;
  }

  render() {
    if(!this.state.loaded) return null;
    return (
      <Page location={this.props.location}>
        <NotificationSettings settings={this.state.settings} user={this.context.getAuthManager()._loggedUser} />
      </Page>
    );
  }
}

export default NotificationSettingsPage;
