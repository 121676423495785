import React, { Component } from "react";

import InSystemMessaging from "../sections/InSystemMessaging";
import Page from "../Page";
import { setTitle } from "../../pageOptions";
import manager from "../../Manager";
import { companytype } from "../../config";
import Translation from "../../utils/Translation";

class InSystemMessagingPage extends Component {
  static contextType = manager;

  componentWillMount() {
    this.context
      .getAuthManager()
      .pageRestriction({ logged: true, notCompanyType: companytype.free });
    setTitle(
      Translation.translateStaticText("inSystemTitle", "Insystem messaging")
    );
  }

  render() {
    return (
      <Page location={this.props.location}>
        <InSystemMessaging location={this.props.location} />
      </Page>
    );
  }
}

export default InSystemMessagingPage;
