export default {
	//General Fields
	username: "Benutzername",
	userLogin: "Benutzername/Email",
	email: "Email",
	// fullName: "Full Name",
	phone: "Telefon",
	password: "Passwort",
	passwordConfirmation: "Passwort bestätigen",
	forgottenPassword: "Passwort vergessen",
	// currentPassword: "Current Password",
	// newPassword: "New Password",
	// newPasswordConfirmation: "New Password Confirmation",
	// companyEmail: "Company Email",

	// // inSystemMessages
	// chatPlaceholder: "Your Message...",

	// //Jonseeker Fields
	// searchJobseekerPlaceholder: "Search for positions or companies",
	// jobseekerCompanyAccessButton: "Company Access",
	// jobseekerEmploymentHistoryButton: "Employment History",
	// jobseekerProfileLocationCountry: "--- Select a Country ---",
	// JobseekerProfileLocationCity: "--- Select a City ---",
	// jobseekerProfileCV: "CV (pdf only)",
	// jobseekerProfileVisibility: "Profile Visibility",
	// jobseekerProfileSkillsPLaceholder: "Your Skills...",
	// jobseekerEducationLevel: "Education Level",
	// jobseekerEducationSchool: "School/University",
	// jobseekerEducationSpeciality: "Speciality",
	// summary: "Summary",
	// jobseekerEmploymentHistoryEmployer: "Employer",
	// jobseekerEmploymentHistoryRole: "Role",
	// jobseekerEmploymentHistoryStartDate: "Initial Date",
	// jobseekerEmploymentHistoryEndDate: "End Date",
	// jobseekerEmploymentHistoryPresent: "Present",
	// jobseekerEmploymentHistoryTechnicalStack: "Technical Stack",
	// jobseekerCompanyAccessNameColumn: "Company Name",
	// jobseekerCompanyAccessAccessColumn: "Access Provided",
	// jobseekerCompanyAccessActionsColumn: "Actions",
	// jobseekerCompanyAccessSearchPlaceholder: "Search",

	// //Company fields
	companyName: "Name der Firma",
	// website: "Website",
	contactPerson: "Ansprechpartner",
	// companyGdpr: "GDPR (provide a link to your GDPR)",
	// searchCompanyPlaceholder: "Search jobseekers",
	// aboutUsPlaceholder: "About us",
	// CEOPlaceholder: "CEO",
	// companyLogoAlt: "Company Logo",
	// employeeNamePlaceholder: "Name",
	// logoPlaceholder: "Logo (png, jpg or jpeg)",
	// contactPlaceholder: "Contact",
	// contactPhonePlaceholder: "Phone (set by contact profile)",
	// contactEmailPlaceholder: "Contact Person Email (set by contact profile)",
	// descriptionPlaceholder: "Description",

	// //Search
	// jobseekerSearchPlaceholder: "Search for positions or companies",
	// companySearchPlaceholder: "Search jobseekers",
	// searchTitle: "Search Results",
	// searchMatchedOn: "Matched On",
	// companyJobseekerNameMatch: "Jobseeker Name",
	// companyEducationSpecialityMatch: "Education Speciality",
	// compnayEducationNameMatch: "Education Name",
	// companySkillMatch: "Skill",
	// jobseekerCompanyDescriptionMatch: "Company Description",
	// jobseekerCompanyEmployeeNameMatch: "Company Employee Name",
	// jobseekerCompanyPositionTitleMatch: "Company Position Title",
	// jobseekerCompanyPositionResponsibilitiesMatch:
	// 	"Company Position Responsibilities",
	// jobseekerCompanyPositionRequirementsMatch: "Company Position Requirements",
	// jobseekerCompanyPositionOfferMatch: "Company Position Offer",
	// jobseekerCompanyPositionSpecialityMatch: "Company Position Speciality",
	// jobseekerCompanyPositionCategoryMatch: "Company Position Category",
	// jobseekerCompanyPositionCountryMatch: "Company Position Country",
	// jobseekerCompanyPositionCityMatch: "Company Position City",
	// jobseekerCompanyPositionQualificationMatch:
	// 	"Company Position Qualification",
	// jobseekerSearchResultCompanyName: "Company",
	// jobseekerSearchResultPosition: "Position",
	// jobseekerSearchResultPositionCategory: "Position Category",
	// jobseekerSearchResultPositionCity: "Position City",
	// jobseekerSearchResultPositionCountry: "Position Country",
	// jobseekerSearchResultPositionOffer: "Position Offer",
	// jobseekerSearchResultPositionQualification: "Position Qualification",
	// jobseekerSearchResultPositionRequirements: "Position Requirements",
	// jobseekerSearchResultPositionResponsibilities: "Position Responsibilities",
	// jobseekerSearchResultPositionSpeciality: "Position Speciality",

	// //positions
	// positionExpired: "Expired",
	// positionInactive: "Inactive",
	// companyDetailsHeading: "Company Details",
	// companyInfoPlaceholder: "Company Info",
	// positionDetailsHeading: "Position Details",
	// positionTitlePlaceholder: "Title",
	// responsibilitiesPlaceholder: "Responsibilities",
	// requirementsPlaceholder: "Requirements",
	// offerPlaceholder: "Offer",
	// educationLevelPlaceholder: "Education Level",
	// validFromPlaceholder: "Valid From",
	// validToPlaceholder: "Valid To",
	// yearsOfExperiencePlaceholder: "Years of Experience",
	// salaryFromPlaceholder: "Salary From",
	// salaryToPlaceholder: "Salary To",
	// categoryPlaceholder: "Category",
	// remoteWorkPlaceholder: "Remote Work",
	// freelancePlaceholder: "Freelance",
	// employmentPlaceholder: "Employment",
	// locationsHeading: "Locations",
	// candidatesHeading: "Candidates",
	// emptyPositionSelectPlaceholder: "--Select--",
	// qualificationsPlaceholder: "Qualifications",
	// currencyPlaceholder: "Currency",
	// referenceUrlPlaceholder:
	// 	"Reference URL for the position (from another site)",
	// acceptingFreelancePlaceholder: "Accepting Freelance",
	// ableToWorkRemotelyPlaceholder: "Able to work remotely",

	// //Buttons
	// searchButton: "Search",
	// saveButton: "Save",
	// profilePreviewButton: "Profile Preview",
	// backButton: "Back",
	// addEmploymentButton: "Add Employment",
	// deleteButton: "Delete",
	// deleteEducationButton: "Delete Education",
	// downloadButton: "Download",
	// editButton: "Edit",
	// cancelButton: "Cancel",
	// continueButton: "Continue",
	// firstTooltipButton: "First Page",
	// previousTooltipButton: "Previous Page",
	// nextTooltipButton: "Next Page",
	// lastTooltipButton: "Last Page",
	// loginButton: "Login",
	// requestCorrespondenceButton: "Request Correspondence",
	// requestCorrespondenceAgainButton: "Request Correspondence Again",
	// sendRecoveryEmailButton: "Send Recovery Email",
	// setPasswordButton: "Set Password",
	// signUpButton: "Sign Up",
	// acceptButton: "Accept",
	// rejectButton: "Reject",
	// blockButton: "Block",
	// unblockButton: "Unblock",
	// sendButton: "Send",
	// removeOnlyButton: "Remove Only",
	// applyButton: "Apply",
	// appliedButton: "Applied",
	// companyProfileButton: "Company Profile",
	// employeeProfileButton: "Your Profile",
	// removeLogoButton: "Change/Remove",

	//notification Settings
	// chatNotificationslabel: "Chat notifications",,
	// candidateNotificationslabel: "Нотификация за нов кандидат",
	// candidateChatNotificationslabel: "Position notification on in system messaging"
};
