import UIErrors from "./en/UIErrors";
import UIMessages from "./en/UIMessages";
import UIFormLabels from "./en/UIFormLabels";
import UIStaticTexts from "./en/UIStaticTexts";
import ServerMessages from "./en/ServerMessages";
import FieldValues from "./en/FieldValues";
import FAQStatic from "./en/FAQStatic";
import NavigationLabels from './en/NavigationLabels';
import bool from './vars/bool';
/**
 * Author Simeon-Banov
 */
let messages = {
    // UI messages
    // errors
    errors: UIErrors,
    // messages
    messages: UIMessages,
    // Server Response
    responses: ServerMessages,
    // fields
    fields: UIFormLabels,
    //values
    values: FieldValues,
    //static texts
    staticText: UIStaticTexts,
    //FAQ
    faq: FAQStatic,
    //navigation
    navigation: NavigationLabels,
    //bools
    bool: bool['en']
};

export default messages;
