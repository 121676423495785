import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";

class SearchResultItem extends Component {
	static defaultProps = {
		title: "",
		url: "",
		value: "",
		typographyProps: {}
	};
	render() {
		let { title, url, value, typographyProps } = this.props;
		if (url) {
			value = <a href={url}>{value}</a>;
		}
		return (
			<Typography {...typographyProps}>
				<span style={{ fontWeight: "bold" }}>{title}: </span>
				{value}
			</Typography>
		);
	}
}

export default SearchResultItem;