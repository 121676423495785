import React, { Component } from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import uuidv1 from "uuid/v1";

import manager from "../../../Manager";
import FloatingLabelInput from "../../form_helpers/FloatingLabelInput";
import FloatingLabelAutocomplete from "../../form_helpers/FloatingLabelAutocomplete";
import FloatingLabelTextArea from "../../form_helpers/FloatingLabelTextArea";
import Translation from "../../../utils/Translation";
import { educationLevels } from "../../../translations/vars/positions";

class EducationForm extends Component {
	static contextType = manager;
	static defaultProps = {
		education: [],
		addEducation: () => {},
		removeEducation: () => {},
		handleChange: () => {},
		handleAutocompleteChange: () => {}
	};

	constructor(props) {
		super(props);
		this.state = {
			autocompleteOptionsFetched: false,
			error: false,
			schoolOptions: [],
			levelOptions: []
		};
	}

	componentDidMount() {
		const { education } = this.props;

		//we make a request to backend only if we already have some data to display
		if (education.length > 0) {
			this.fetchAutocompleteOptions();
		}
	}

	addEducation = () => {
		//we make a request to backend only if it's the first time we add education
		const { schoolOptions, levelOptions } = this.state;
		if (schoolOptions.length === 0 || levelOptions.length === 0) {
			this.fetchAutocompleteOptions();
		}
		this.props.addEducation({
			education_id: uuidv1(),
			school: "",
			level: "",
			summary: "",
			speciality: ""
		});
	};

	fetchAutocompleteOptions() {
		//if we already have th autocomplete options
		const onError = {
			message:
				"We have some issues gathering your education, Please contact support!",
			callback: () => {
				this.setState({ ...this.state, error: true });
			}
		};
		this.context.getEducationManager().getEducationLevels(this, {
			onSuccess: {
				callback: levelsResponse => {
					this.context.getEducationManager().getSchools(this, {
						onSuccess: {
							callback: schoolsResponse => {
								const levelOptions = this.formatEducationLevelOptions(
									levelsResponse
								);
								const schoolOptions = this.formatSchoolOptions(
									schoolsResponse
								);
								this.setState({
									...this.state,
									levelOptions: levelOptions,
									schoolOptions: schoolOptions,
									autocompleteOptionsFetched: true
								});
							}
						},
						onError: onError
					});
				}
			},
			onError: onError
		});
	}

	formatEducationLevelOptions = levelsResponse => {
		let options = this.translateValues(levelsResponse);

		return options.reduce((prev, current, idx, self) => {
			const from = options.findIndex(el => {
				return (
					el.label ===
					educationLevels[Translation.getLanguage()][
						"Primary Education"
					]
				);
			});
			const to = 0;
			if (from === to) {
				prev.push(current);
			}
			if (idx === from) {
				return prev;
			}
			if (from < to) {
				prev.push(current);
			}
			if (idx === to) {
				prev.push(self[from]);
			}
			if (from > to) {
				prev.push(current);
			}
			return prev;
		}, []);
	};

	formatSchoolOptions = schoolsResponse => {
		return this.translateValues(schoolsResponse);
	};

	translateValues = value => {
		return value
			.map(val => {
				if (!Translation.valueHasTranslation(val)) {
					return undefined;
				}
				return {
					label: Translation.translateValue(val, val),
					value: val
				};
			})
			.filter(val => val !== undefined);
	};

	changeAutocomplete = (name, key) => {
		return newValue => {
			this.props.handleAutocompleteChange(name, key, newValue);
		};
	};

	onKeyDown = e => {
		if (e.keyCode === 13) {
			e.preventDefault();
		}
	};

	renderEducationForm = () => {
		const { error, levelOptions, schoolOptions } = this.state;
		return this.props.education.map(edu => (
			<div
				className="py-1"
				key={edu.education_id}
				onKeyDown={this.onKeyDown}
			>
				<div className="row py-2">
					<div className="col-sm-12">
						<FloatingLabelAutocomplete
							isClearable
							placeholder={Translation.translateField(
								"jobseekerEducationLevel",
								"Education Level"
							)}
							className="form-control"
							isDisabled={error}
							onChange={this.changeAutocomplete(
								"level",
								edu.education_id
							)}
							options={levelOptions}
							value={
                edu.level
                  ? Translation.getEducationLevelLabelBasedOnLanguage(edu.level)
                  : null
              }
							required={true}
						/>
					</div>
				</div>
				<div className="row py-2">
					<div className="col-sm-6">
						<FloatingLabelAutocomplete
							creatable
							isClearable
							placeholder={Translation.translateField(
								"jobseekerEducationSchool",
								"School"
							)}
							className="form-control"
							isDisabled={error}
							onChange={this.changeAutocomplete(
								"school",
								edu.education_id
							)}
							options={schoolOptions}
							value={edu.school}
							required={true}
						/>
					</div>
					<div className="col-sm-6">
						<FloatingLabelInput
							onChange={this.props.handleChange(
								"speciality",
								edu.education_id
							)}
							type="text"
							className="form-control px-3"
							data-form-field="speciality"
							placeholder={Translation.translateField(
								"jobseekerEducationSpeciality",
								"Speciality"
							)}
							name="speciality"
							readOnly={error}
							value={edu.speciality}
							required={true}
						/>
					</div>
				</div>
				<div className="row py-2">
					<div className="col-sm-12">
						<FloatingLabelTextArea
							onChange={this.props.handleChange(
								"summary",
								edu.education_id
							)}
							className="form-control px-3"
							name="summary"
							data-form-field="summary"
							placeholder={Translation.translateField(
								"summary",
								"Summary"
							)}
							readOnly={error}
							value={edu.summary}
						/>
					</div>
				</div>
				<div className="row py-2">
					<div className="col align-right">
						<span className="input-group-btn">
							<button
								className="btn btn-secondary btn-form display-4"
								onClick={() =>
									this.props.removeEducation(edu.education_id)
								}
							>
								{Translation.translateField(
									"deleteEducationButton",
									"Delete Education"
								)}
							</button>
						</span>
					</div>
				</div>
			</div>
		));
	};

	render() {
		const { error, autocompleteOptionsFetched } = this.state;
		return (
			<div data-for="education">
				<div className="form-group align-left">
					{autocompleteOptionsFetched === true &&
						this.props.education.length > 0 && (
							<div className="py-1">
								{this.renderEducationForm()}
							</div>
						)}
					<h5 className="mbr-white inline px-3">
						{this.props.education.length > 0
							? Translation.translateStaticText(
									"jobseekerProfileMoreEducation",
									"More Education"
							  )
							: Translation.translateStaticText(
									"jobseekerProfileNoEducation",
									"No Education"
							  )}
					</h5>
					<Fab
						color="primary"
						aria-label="Add"
						className="py-2"
						onClick={() => this.addEducation({})}
						disabled={error}
					>
						<AddIcon />
					</Fab>
				</div>
			</div>
		);
	}
}

export default EducationForm;
