
import React, { Component } from 'react';

import Page from '../../Page';
import {setTitle} from "../../../pageOptions";

class ENGdprCandidatesPage extends Component {

  componentWillMount() {
    setTitle("General Terms and Conditions for Data Processing");
  }

  render() {
    return (
        <Page>
          <section className="cid-qTkA127IK8 mbr-fullscreen mbr-parallax-background" id="header2-1">
              <div className="container align-center">
                  <div className="row justify-content-md-center">
                      <div className="mbr-white col-md-10" style={{paddingTop: "50px"}}>
                          <h1>General Terms and Conditions for Data Processing</h1>
                          <div style={{textAlign:"left"}}>
                            1. Scope <br/>
                            The Contractual Terms for Data Processing (“Contractual Terms”) contain the obligations with regard to data protection, which arise in connection with the commission of InspireSoft EOOD by the contracting party pursuant to article 28 Regulation (EU) 679/2016 (“GDPR”).<br/> 
                            2. Data Subjects. Responsibility<br/>
                            2.1. The group of data subjects affected by the processing of their data within this commission includes in particular the users who register a profile as Jobseekers to match.career.<br/>
                            2.2. The parties shall be solely responsible for compliance with the applicable data protection laws. <br/>
                            3. Technical and Organizational Measures<br/>
                            3.1. InspireSoft EOOD shall establish the security in accordance with GDPR. The measures to be taken are measures of data security and measures that guarantee a protection level appropriate to the risk concerning confidentiality, integrity, availability and resilience of the systems. The state of the art, implementation costs, the nature, scope and purposes of processing as well as the probability of occurrence and the severity of the risk to the rights and freedoms of natural persons within the meaning of art. 32 para. 1 GDPR must be taken into account.<br/>
                            6.3. The technical and organizational measures are subject to technical progress and further development. In this respect, it is permissible for InspireSoft EOOD to implement alternative adequate measures. In so doing, the security level of the defined measures must not be reduced. Substantial changes must be documented. The technical and organizational measures to adequately protect the data subject’s data include:<br/>
                            a) Confidentiality: Physical access control, Logical access control, Data access control, Separation control; Pseudonymisation <br/>
                            b) Integrity: Data transfer control,  Entry control.<br/>
                            c) Availability and Resilience <br/>
                            d) Procedures for regular testing, assessment and evaluation:  <br/>
                            -Data protection management: All employees are demonstrably committed to data secrecy and receive a training at least once a year. Inspire Soft EOOD has appointed a data protection officer – Snezhana Raykinska.
                          </div>
                          <div style={{textAlign:"left"}}>
                            · Incident response management: In the event of a data loss, notification to the relevant data protection authority will be happening immediately. <br/>
                            · Data protection by design and default.<br/>
                            · Control of instructions.
                          </div>
                      </div>
                  </div>
              </div>
          </section>
        </Page>
    );
  }
}

export default ENGdprCandidatesPage;
