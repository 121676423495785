export default {
	// "Check for email":
	// 	"Registration successful. An email validation link has been sent to the provided email address.",
	// Saved: "Saved",
	// Deleted: "Deleted",
	// Applied: "Applied",
	// "File Uploaded": "File Saved",
	// "File Removed": "File Removed",
	// FailedPasswordConfirmation: "Password does not match",
	// passwordChange: "Recovery finished, you can login with your new password",
	// noCurrentPasswordProvided: "You have to provide current password",
	// recoveryEmailSubmitted:
	// 	"We have submitted recovery instructions to your email.",
	// MustAgreeWithCompanyGDPR: "You must agree with the company GDPR",
	// cannotSendEmptyMessages: "Can not send empty message",
	// hasToAcceptCorrespondence: "{contact} has yet to accept correspondence with you",
	// haveToAcceptCorrespondance: "You have yet to accept correspondence with {contact}",
	// contactRequestSent: "Request sent"
};
