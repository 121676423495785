export default {
    // "-9999": "TODO",
    "-9900": "Falsche Daten angegeben",
    "-9000": "Bitte versuchen Sie es später noch einmal später",
    "0000": null,
    "0001": "Benutzername / Passwort stimmen nicht überein",
    "0012": "Dateianforderung nicht überprüft",
    "0013": "Formulardatei unbekannt",
    "0015": "Bitte geben Sie eines von beiden an (PDF, PNG, SVG)",
    "0016":
        "Bitte stellen Sie eine kompaktere Datei zur Verfügung(Bis zu 5 MB)",
    "0017": "Die Datei existiert bereits",
    "0900":
        "Wir haben einige Probleme bei der Bearbeitung Ihrer Anfrage. Wenden Sie sich an den Support",
    "0997": "Der angegebene Mitarbeiter kann nicht gespeichert werden",
    "0998": "Eine ähnliche Firma existiert schon",
    "0999": "Beim Speichern Ihres Passworts ist ein Problem aufgetreten",
    "1000": "Datenbankfehler: Wenden Sie sich an den Support.",
    "2001": "Firmenname ist erforderlich.",
    "2002": "Email ist ungültig",
    "2003": "Der Name der Kontaktperson ist ungültig, Länge 4-80",
    "2004": "Name ist nicht gültig, Länge 4-80",
    "2005": "Das Telefon muss eine gültige Nummer sein",
    "2006":
        "Das Passwort ist ungültig, Länge 6-80, mindestens 1 Großbuchstabe, 1 Kleinbuchstabe, 1 Ziffer und 1 Sonderzeichen.",
    "2007": "Passwörter sind nicht gleich",
    "2008": "Wiederherstellungsschlüssel nicht mitgeliefert",
    "2009":
        "Ihr aktuelles Passwort darf nicht mit dem neuen Passwort übereinstimmen und muss folgende Anforderungen erfüllen: (Länge 6-80, mindestens 1 Großbuchstabe, 1 Kleinbuchstabe, 1 Ziffer und 1 Sonderzeichen)",
    "2010": "Sie müssen den Allgemeinen Geschäftsbedingungen zustimmen",
    "2011": "Sie müssen der DSGVO zustimmen",
    "2012": "Die Standortdaten sind nicht korrekt",
    "2013": "Die bereitgestellten Fähigkeiten sind fehlerhaft",
    "2014": "Unbekannte Sichtbarkeitsstufe",
    "2015":
        "Bildungsniveaus sind fehlerhaft. Bitte entfernen Sie und versuchen Sie, Ihre Ausbildung wieder aufzunehmen",
    "2016": "SEO ist kein gültiger Wert - bitte geben Sie einen String-Wert an",
    "2017": "Die Website muss eine gültige URL sein",
    "2018": "Beschreibung ungültig - Bitte geben Sie eine Zeichenfolge an",
    "2019": "Die DSGVO muss ein gültiger Link sein",
    "2020": "Der zu registrierende Kontotyp konnte nicht ermittelt werden",
    "9000": "Sie müssen angemeldet sein, um darauf zugreifen zu können",
    "9001": "Ihre Sitzung ist abgelaufen. Bitte aktualisieren und anmelden",
    "9002": "Ihre Sitzung ist abgelaufen. Bitte aktualisieren und anmelden",
    "9003": "Ihre Sitzung ist abgelaufen. Bitte aktualisieren und anmelden",
    "9004": "Ihre Sitzung ist abgelaufen. Bitte aktualisieren und anmelden",
    "9005": "Unbemessene Ressource",
    "9006": "Bereits angemeldet",
    "9804": "Profil nicht gefunden",
    "9802": "Das gesuchte Profil ist nicht verfügbar",
    "9801": "Sie haben keine Berechtigung für diese Aktion",
    "9902": "Name des Mitarbeiters ist erforderlich",
    "9903": "Mitarbeitertelefon muss eine gültige Nummer sein",
    "9904": "Mitarbeiter-E-Mail ist erforderlich",
    "9905":
        "Passwort ist ungültig, Länge 6-80, mindestens 1 Großbuchstabe, 1 Kleinbuchstabe, 1 Ziffer und 1 Sonderzeichen",
    "9906": "Passwort / Passwortbestätigung stimmen nicht überein",
    "9907": "Falsches aktuelles Passwort",
    "10000": "Positionstitel ist erforderlich",
    "11001": "Firmenname ist erforderlich",
    "11002":
        "Das Gehalt von / bis muss entweder eine Zeichenfolge (mit Zahlen) oder eine Zahl sein",
    "11003": "Das Gehalt von muss niedriger oder gleich dem Gehalt bis sein",
    "11005": "Gültig von muss kleiner als gültig bis sein",
    "11004": "Gültig bis muss innerhalb eines Jahres sein",
    "11006": "Der Positionstitel muss eindeutig und nicht leer sein",
    "11007": "Das Unternehmen für diese Position konnte nicht gefunden werden",
    "11008": "Nicht vorhandene Position kann nicht geändert werden",
    "11009": "Kostenloses Konto ist nur 1 Position erlaubt",
    "11010": "Gültig ab darf nicht in der Vergangenheit liegen",
    "11011": "Freelancer Option ist nicht korrekt",
    "11012": `Die Option "Remote Work" ist nicht korrekt`,
    "11013": "Die jahrelange Erfahrung muss numerisch sein",
    "11014": "Gehalt von muss eine positive Zahl sein",
    "11015": "Gehalt bis muss eine positive Zahl sein",
    "11016": "Jahre Erfahrung müssen positive Zahl sein",
    "11017": "Die Referenz-URL ist keine gültige Webadresse",
    "11400": "Du bist nicht eingeloggt",
    "11401": "Unbekannte position",
    "11402": "Schon angewendet",
    "11403": "Position existiert nicht",
    "11404":
        "Arbeitssuchende konnte nicht gefunden werden, bitte wenden Sie sich an den Support",
    "11500": "Du bist nicht eingeloggt.",
    "11501": "Sie haben keinen Zugriff auf die Kandidatenliste",
    "11502": "Unbekannte Position",
    "11503": "Position ist nicht vorgesehen",
    "11504": "Position nicht gefunden",
    "11505": "keine Kandidaten",
    "11506": "Keine Positionen zum Anzeigen",
    "14000": "Nicht vorhandener Zugriff kann nicht aktualisiert werden",
    "14001": "Die angegebenen Daten sind falsch",
    "14002": "Die angegebenen Daten sind falsch",
    "14003": "Die angegebenen Daten sind falsch",
    "14004": "Diese Art der Berechtigung existiert nicht",
    "15000": "Der Benutzer befindet sich nicht in Ihrer Kontaktliste",
    "15001": "Kontakt nicht vorgesehen",
    "15002": "Angestellte nicht gefunden",
    "15003": "Der Benutzer befindet sich in Ihrer Kontaktliste",
    "15004": "Der Benutzer hat Sie blockiert",
    "15005": "Ungültiger Benutzer",
    "15006": "Kann nicht Kontakt mit sich selbst anfordern",
    "15007":
        "Das Unternehmen ist abgelaufen und kann kein Insystem-Messaging verwenden",
   // "15008": "Notification setting not allowed",
    "16000": "Sie müssen einen Suchstich provozieren",
    "16001": "Die Suchzeichenfolge muss mindestens 2 Buchstaben lang sein",
    "16002": "Kostenlose Firmenprofile können keine Suche verwenden",
    "20101": "Arbeitgeber ist Pflichtfeld",
    "20102": "Rolle ist Pflichtfeld",
    "20103": "Startdatum ist falsch",
    "20104": "Das Enddatum ist falsch",
    "20105": "Bei Ihrer Formularübermittlung ist etwas Verwirrendes passiert",
    "20106": "Bei Ihrer Formularübermittlung ist etwas Verwirrendes passiert",
    "20107": "Bei Ihrer Formularübermittlung ist etwas Verwirrendes passiert",
    "20111":
        "Bei der Suche nach den bereitgestellten Fähigkeiten ist ein Fehler aufgetreten",
    "21111":
        "Bei der Suche nach dem vorgesehenen Arbeitgeber ist ein Fehler aufgetreten",
    "20011":
        "Bei der Berechnung Ihrer Profilvervollständigung treten einige Probleme auf. Wenden Sie sich an den Support, oder versuchen Sie erneut, Ihr Profil zu speichern",
    "20012":
        "Für diese Aktion benötigen Sie eine 100% ige Profilvervollständigung"
};
