import React from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const AdapterLink = React.forwardRef((props, ref) => (
	<Link innerRef={ref} {...props} />
));

function LinkButton(props) {
	return (
		<Button {...props} component={AdapterLink}>
			{props.children}
		</Button>
	);
}

LinkButton.defaultProps = {
	//location towards we route.
	to: "",
	onClick: (e)=>{},
	className: "btn btn-primary",
	style:{
		borderRadius:"5px"
	}
}

export default LinkButton;
