import React from "react";
import Translation from '../../utils/Translation';

export default function JobseekerHowItWorks(props) {
    return (
        <div id="next">
            <section className="timeline1 cid-rgxv6dNGOY" id="timeline1-g">
                <div className="container align-center">
                    <h2 className="mbr-section-title pb-3 mbr-fonts-style display-1">
                        {Translation.translateStaticText(
                            "howItWorksHeading",
                            "How it Works"
                        )}
                        <br />
                    </h2>
                    <div
                        className="container timelines-container"
                        mbri-timelines=""
                    >
                        <div className="row timeline-element reverse separline">
                            <div className="timeline-date-panel col-xs-12 col-md-6  align-left">
                                <div className="time-line-date-content">
                                    <p className="mbr-timeline-date mbr-fonts-style display-5">
                                        {Translation.translateStaticText(
                                            "step1",
                                            "STEP 1"
                                        )}
                                    </p>
                                </div>
                            </div>
                            <span className="iconBackground" />
                            <div className="col-xs-12 col-md-6 align-right">
                                <div className="timeline-text-content">
                                    <h4 className="mbr-timeline-title pb-3 mbr-fonts-style display-5">
                                        <p>
                                            {Translation.translateStaticText(
                                                "jobseekerStep1Heading",
                                                "Create Your Profile"
                                            )}
                                        </p>
                                    </h4>
                                    <p className="mbr-timeline-text mbr-fonts-style display-7">
                                        {Translation.translateStaticText(
                                            "jobseekerStep1Description",
                                            "Sign up and create your profile, containing the skills you have and information about your experience. The good news is you don't need to write CV or cover letter!"
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row timeline-element  separline">
                            <div className="timeline-date-panel col-xs-12 col-md-6 align-right">
                                <div className="time-line-date-content">
                                    <p className="mbr-timeline-date mbr-fonts-style display-5">
                                        {Translation.translateStaticText(
                                            "step2",
                                            "STEP 2"
                                        )}
                                    </p>
                                </div>
                            </div>
                            <span className="iconBackground" />
                            <div className="col-xs-12 col-md-6 align-left ">
                                <div className="timeline-text-content">
                                    <h4 className="mbr-timeline-title pb-3 mbr-fonts-style display-5">
                                        {Translation.translateStaticText(
                                            "jobseekerStep2Heading",
                                            "Apply For a Position"
                                        )}
                                    </h4>
                                    <p className="mbr-timeline-text mbr-fonts-style display-7">
                                        {Translation.translateStaticText(
                                            "jobseekerStep2Description",
                                            "Our system immediately matches jobs, which will be presented to you. After applying, the responsible person from the company you are interested in can contact you."
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row timeline-element reverse separline">
                            <div className="timeline-date-panel col-xs-12 col-md-6  align-left">
                                <div className="time-line-date-content">
                                    <p className="mbr-timeline-date mbr-fonts-style display-5">
                                        {Translation.translateStaticText(
                                            "step3",
                                            "STEP 3"
                                        )}
                                    </p>
                                </div>
                            </div>
                            <span className="iconBackground" />
                            <div className="col-xs-12 col-md-6 align-right">
                                <div className="timeline-text-content">
                                    <h4 className="mbr-timeline-title pb-3 mbr-fonts-style display-5">
                                        {Translation.translateStaticText(
                                            "jobseekerStep3Heading",
                                            "Got Interview Invitation"
                                        )}
                                    </h4>
                                    <p className="mbr-timeline-text mbr-fonts-style display-7">
                                        {Translation.translateStaticText(
                                            "jobseekerStep3Description",
                                            "You meet in person or online with the hiring person or a Manager."
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row timeline-element ">
                            <div className="timeline-date-panel col-xs-12 col-md-6 align-right">
                                <div className="time-line-date-content">
                                    <p className="mbr-timeline-date mbr-fonts-style display-5">
                                        {Translation.translateStaticText(
                                            "finalStep",
                                            "FINAL STEP"
                                        )}
                                    </p>
                                </div>
                            </div>
                            <span className="iconBackground" />
                            <div className="col-xs-12 col-md-6 align-left ">
                                <div className="timeline-text-content">
                                    <h4 className="mbr-timeline-title pb-3 mbr-fonts-style display-5">
                                        {Translation.translateStaticText(
                                            "jobseekerFinalStepHeading",
                                            "Get Hired"
                                        )}
                                    </h4>
                                    <p className="mbr-timeline-text mbr-fonts-style display-7">
                                        {Translation.translateStaticText(
                                            "jobseekerFinalStepDescription",
                                            "Congratulations! You are hired."
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};