import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import HomePage from "./components/pages/HomePage";
import PricingPage from "./components/pages/PricingPage";
import PurchaseGoldAccessPage from "./components/pages/purchase/PurchaseGoldAccessPage";
import PurchasePlatinumAccessPage from "./components/pages/purchase/PurchasePlatinumAccessPage";
import AboutUsPage from "./components/pages/AboutUsPage";
import { default as CompanyLoginPage } from "./components/pages/company/LoginPage";
import { default as JobseekerLoginPage } from "./components/pages/jobseeker/LoginPage";
import { default as CompanySignupPage } from "./components/pages/company/SignupPage";
import { default as JobseekerSignupPage } from "./components/pages/jobseeker/SignupPage";
import CompanyProfilePage from "./components/pages/company/CompanyProfilePage";
import PublicCompanyProfilePage from "./components/pages/company/PublicCompanyProfilePage";
import JobseekerProfilePage from "./components/pages/jobseeker/JobseekerProfilePage";
import PublicJobseekerProfilePage from "./components/pages/jobseeker/PublicJobseekerProfilePage";
import AccountRecoveryPage from "./components/pages/AccountRecoveryPage";
import PositionsPage from "./components/pages/PositionsPage";
import JobseekerTermsAndConditions from "./components/pages/TermsAndConditions/JobseekerTermsAndConditions";
import EmployerTermsAndConditions from "./components/pages/TermsAndConditions/EmployerTermsAndConditions";
import GdprEmployersPage from "./components/pages/GDPR/GdprEmployersPage";
import GdprJobseekersPage from "./components/pages/GDPR/GdprJobseekersPage";
import InSystemMessagingPage from "./components/pages/InSystemMessagingPage";
import FAQPage from "./components/pages/FAQPage";
import ErrorPage from "./components/pages/ErrorPage";
import NotificationSettingsPage from "./components/pages/NotificationSettingsPage";

import manager from "./Manager";
import { frontend, uuidPattern } from "./config";


class App extends Component {
  static contextType = manager;

  state = {
    loadedInfo: false
  };

  componentWillMount() {
    const component = this;
    this.context.getAuthManager().getLoggedUser(component, {
      onSuccess: {
        toast: false,
        callback: () => {
          component.context.getWSManager();
        }
      },
      onFinish: { toast: false, state: { loadedInfo: true } }
    });
  }

  render() {
    if (!this.state.loadedInfo) return <br />;
    return (
      <BrowserRouter>
        <Switch>
          <Route
            path={frontend.route.terms_and_conditions.employers}
            component={EmployerTermsAndConditions}
            exact
          />
          <Route
            path={frontend.route.terms_and_conditions.jobseekers}
            component={JobseekerTermsAndConditions}
            exact
          />
          <Route
            path={frontend.route.gdpr_candidates}
            component={GdprJobseekersPage}
            exact
          />
          <Route
            path={frontend.route.gdpr_employers}
            component={GdprEmployersPage}
            exact
          />

          <Route path={frontend.route.home} component={HomePage} exact />
          <Route path={frontend.route.pricing} component={PricingPage} exact />
          <Route
            path={frontend.route.purchase.goldMembership}
            component={PurchaseGoldAccessPage}
            exact
          />
          <Route
            path={frontend.route.purchase.platinumMembership}
            component={PurchasePlatinumAccessPage}
            exact
          />
          <Route path={frontend.route.aboutus} component={AboutUsPage} exact />
          <Route
            path={frontend.route.company.login}
            component={CompanyLoginPage}
          />
          <Route
            path={frontend.route.jobseeker.login}
            component={JobseekerLoginPage}
          />
          <Route
            path={frontend.route.company.register}
            component={CompanySignupPage}
            exact
          />
          <Route
            path={frontend.route.jobseeker.register}
            component={JobseekerSignupPage}
            exact
          />
          <Route
            path={frontend.route.company.company_profile}
            component={CompanyProfilePage}
            exact
          />
          <Route
            path={`${frontend.route.company.public_profile}/:name`}
            component={PublicCompanyProfilePage}
            exact
          />
          <Route
            path={frontend.route.company.employee_profile}
            component={CompanyProfilePage}
            exact
          />
          <Route
            path={frontend.route.jobseeker.jobseeker_profile.route}
            component={JobseekerProfilePage}
            exact
          />
          <Route
            path={`${frontend.route.jobseeker.public_profile}/:id(${uuidPattern})`}
            component={PublicJobseekerProfilePage}
            exact
          />
          <Route
            path={frontend.route.account_recovery}
            component={AccountRecoveryPage}
          />
          <Route
            path={frontend.route.position.main}
            component={PositionsPage}
            exact
          />
          <Route
            path={frontend.route.position.own}
            component={PositionsPage}
            exact
          />
          <Route
            path={frontend.route.position.add}
            component={PositionsPage}
            exact
          />
          <Route
            path={`${frontend.route.position.edit}/:id(${uuidPattern})`}
            component={PositionsPage}
            exact
          />
          <Route
            path={`${frontend.route.position.view}/:company/:position+`}
            component={PositionsPage}
          />
          <Route
            path={frontend.route.notificationSettings}
            component={NotificationSettingsPage}
            exact
          />
          <Route
            path={`${
              frontend.route.inSystemMessaging
            }/:contact_id(${uuidPattern})`}
            path={`${frontend.route.inSystemMessaging}/:contact_id(${uuidPattern})`}
            component={InSystemMessagingPage}
            exact
          />
          <Route
            path={`${frontend.route.inSystemMessaging}/System`}
            component={InSystemMessagingPage}
            exact
          />
          <Route
            path={frontend.route.inSystemMessaging}
            component={InSystemMessagingPage}
            exact
          />
          <Route path={frontend.route.faq} component={FAQPage} exact />
          <Route
            path={frontend.route.error + "/:errorCode"}
            component={ErrorPage}
            exact
          />
          <Route path={"/error"} component={ErrorPage} />
          <Route component={ErrorPage} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
