import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles({
	rootLabel: {
		marginTop: "0.5rem",
		marginLeft: 0
	},
	label: {
		marginTop: "0.25rem"
	}
});

export default function WhiteCheckbox(props) {
	const {label, ...rest} = props;
	const classes = useStyles();
	return (
		<FormControl className="form-control bg-white">
			<FormControlLabel
				control={
					<Checkbox
						{...rest}
					/>
				}
				classes={{ root: classes.rootLabel, label: classes.label }}
				label={label}
			/>
		</FormControl>
	);
}
