import React from "react";
import Translation from "../../../utils/Translation";
import { languages } from "../../../config";
import ENGdprEmployersPage from './ENGdprEmployersPage';
import BGGdprEmployersPage from './BGGdprEmployersPage';

export default function GdprEmployersPage(props) {
	const getTemplate = lang => {
		switch (lang) {
			case languages.english:
				return <ENGdprEmployersPage />;
			case languages.bulgarian:
				return <BGGdprEmployersPage />;
			case languages.german:
				//TODO implement german langauge GDPR
				return <ENGdprEmployersPage />;
			default:
				return <ENGdprEmployersPage />;
		}
	};

	return getTemplate(Translation.getLanguage());
}
