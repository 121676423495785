/**
 * Copy the file into config.js
 * Modify config.js for those configurations to make effect
 * Update this file with each new field addition to config.js
 */
const uuidMatch =
    "[a-f0-9]{8}-?[a-f0-9]{4}-?[a-f0-9]{4}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}";
const DOMAIN = "localhost";

module.exports = {
    backend: {
        DOMAIN: "http://" + DOMAIN + ":5000",
        WSDOMAIN: "ws://" + DOMAIN + ":5500",
        api: {
            confirmRegistration: "/api/register/confirm_email",
            registration: "/api/register/",
            login: "/api/login/",
            logout: "/api/logout",
            userinfo: "/api/userinfo",
            getLoggedUser: "/api/logged/user/get",
            uploadFile: "/api/file/upload",
            getPublicFile: "/api/file/public/",
            getAuthorizedFile: "/api/file/get/",
            accountRecoverySendEmail: "/api/account/recovery/send/email",
            accountRecoveryResetPassword:
                "/api/account/recovery/change/password",
            getPlatimumCompanies: "/api/get/platimum/companies",
            company: {
                getLoggedCompanyData: "/api/logged/company/data/get",
                setLoggedCompanyData: "/api/logged/company/data/set",
                getLoggedEmployeeData: "/api/logged/employee/data/get",
                setLoggedEmployeeData: "/api/logged/employee/data/set",
                getLoggedCompany: "/api/logged/company/get",
                candidates: "/api/company/candidates",
                getCompanyData: "/api/company",
                isJobseekerACandidate: "/api/company/iscandidate",
                search: "/api/logged/company/searching",
                uploadLogo: "/api/file/upload/logo",
                removeLogo: "/api/logo/remove"
            },
            jobseeker: {
                getLoggedJobseekerData: "/api/logged/jobseeker/get",
                getPublicJobseekerData: "/api/jobseeker",
                setLoggedJobseekerData: "/api/logged/jobseeker/set",
                profileAccessAPI: "/api/jobseeker/access",
                addSkill: "/api/jobseeker/skill",
                search: "/api/logged/jobseeker/searching",
                profileCompletion: "/api/jobseeker/profile/completion",
                uploadCv: "/api/file/upload/cv"
            },
            position: {
                get: "/api/position/get",
                getOwn: "/api/position/getown",
                getOwnPositionsCount: "/api/position/number",
                set: "/api/position/set",
                list: "/api/position/list",
                listOwn: "/api/position/own/list",
                delete: "/api/position/own/delete",
                getCategories: "/api/position/categories/get",
                getQualifications: "/api/position/qualifications/get",
                getCurrencies: "/api/position/currencies/get",
                getDependencies: "/api/position/dependencies/get",
                hasApplied: "/api/postion/isapplied",
                setApply: "/api/position/apply",
                candidates: "/api/position/candidates"
            },
            ws: {
                checkContacts: "/api/ws/checkContacts",
                requestContact: "/api/ws/requestContact",
                hasContactEntity: "/api/ws/hasContactEntity",
                acceptContact: "/api/ws/acceptContact",
                rejectContact: "/api/ws/rejectContact",
                blockContact: "/api/ws/blockContact",
                unblockContact: "/api/ws/unblockContact"
            },
            notificationSettings: {
                getNotificationSettings: "/api/get/notification/settings",
                setNotificationSetting: "/api/set/notification/setting/"
            },
            education: {
                getEducationLevels: "/api/education/levels/get",
                getSchools: "/api/education/schools/get"
            },
            employment: {
                getApprovedEmployers: "/api/employers",
                addEmployer: "/api/employer/create",
                getEmployments: "/api/employments",
                storeEmployment: "/api/employment/store"
            },
            generic: {
                pricing: "/api/get/pricing",
                timeoffset: "/api/time/offset"
            }
        }
    },
    backendErrorCodes: {
        "111000": "No Positions",
        "9901": "unauthorized"
    },
    frontend: {
        DOMAIN: "http://" + DOMAIN + ":3000",
        route: {
            error: "/error",
            home: "/",
            terms_and_conditions: {
                employers: "/site/employers/termsAndConditions",
                jobseekers: "/site/jobseekers/termsAndConditions"
            },
            gdpr_employers: "/site/employers/GDPR",
            gdpr_candidates: "/site/candidates/GDPR",
            pricing: "/pricing",
            account_recovery: "/account/recovery",
            inSystemMessaging: "/insystem/messaging",
            faq: "/faq",
            notificationSettings: "/notification/settings",
            jobseeker: {
                login: "/login/jobseeker",
                register: "/signup/jobseeker",
                jobseeker_profile: {
                    route: "/jobseeker/profile/:mode",
                    edit: "/jobseeker/profile/edit",
                    view: "/jobseeker/profile/view",
                    company_access: "/jobseeker/profile/access",
                    employment: "/jobseeker/profile/employment"
                },
                public_profile: "/jobseeker"
            },
            company: {
                login: "/login/company",
                register: "/signup/company",
                company_profile: "/company/profile",
                employee_profile: "/employee/profile",
                public_profile: "/company"
            },
            position: {
                main: "/positions",
                own: "/positions/own",
                add: "/positions/add",
                edit: "/positions/edit",
                view: "/positions/view"
            },
            aboutus: "/aboutus",
            purchase: {
                platinumMembership: "/purchase/platinum",
                goldMembership: "/purchase/gold"
            }
        }
    },
    usertype: {
        company: "company",
        jobseeker: "jobseeker",
        admin: "admin",
        team: "team"
    },
    companytype: {
        free: "free",
        gold: "gold",
        platimum: "platinum"
    },
    accessibility: {
        full: "full",
        limited: "limited",
        none: "none"
    },
    file: {
        cv: "cv",
        logo: "logo"
    },
    positionType: {
        full_time: "Full Time",
        part_time: "Part Time"
    },
    remoteWork: {
        yes: "yes",
        no: "no",
        location: {
            yes: "Anywhere",
            no: ""
        }
    },
    uuidPattern: uuidMatch,
    locale: "en-DE",
    cookies: {
        cookieConcent: "cookieconsent_status",
        localSessionCookie: "session",
        lang: "lang"
    },
    languages: {
        english: "en",
        bulgarian: "bg",
        german: "de"
    }
};
