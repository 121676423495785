
import React, { Component } from 'react';

import Page from '../../Page';
import {setTitle} from "../../../pageOptions";

class ENTermsAndConditionsEmployersPage extends Component {

  componentWillMount() {
    setTitle("GENERAL TERMS AND CONDITIONS");
  }

  render() {
    return (
        <Page location={this.props.location}>
          <section className="cid-qTkA127IK8 mbr-fullscreen mbr-parallax-background" id="header2-1">
              <div className="container align-center">
                  <div className="row justify-content-md-center">
                      <div className="mbr-white col-md-10" style={{paddingTop: "50px"}}>
                          <h1>GENERAL TERMS AND CONDITIONS</h1>
                          <h3>FOR USE OF SITE MATCH CAREERS</h3>
                          („General Terms and Conditions”)<br/>
                          <br/>
                          <div style={{textAlign:"left"}}>
                            I. General provisions. Subject of General provisions.<br/>
                            1. The current general provisions define the order and terms for using the platform Match Careers, provided by Inspire Soft EOOD, UIC 202675952, referred to as the “Supplier” and accepted by the other side with the registration in the website as a employer or job candidate, referred to as “Client” or “Clients”.<br/>
                            2. The Clients accept the content of the General provisions by marking the field “General Contract Terms” when creating a profile on the website of the Supplier, or by accepting the commercial proposal of the Supplier, referred as “Contract for using the platform Match Careers”, unless otherwise specifically agreed by the Parties.<br/>
                            3. The service may include the following activities:<br/>
                            3.1. Publishing of job offers and the display of the offer for a time agreed by the Parties;<br/>
                            3.2. Creation of data base with the received curriculum vitae, motivational letters and correspondence in response to the job offer;<br/>
                            3.3. Informing the Client in due time regarding requests for providing information in compliance with applicable laws, including but not limited to the Personal Data Laws, E-commerce Law and the Law on Consumer Protection;<br/>
                            3.4. Creating of a profile with curricula vitae.<br/>
                            3.5. Preparations of reports regarding the service requested by the Client.<br/>
                            4. The job offer, subject of the service is published by the Client by using his Client’s profile.<br/>
                            5. The Supplier is not responsible for the quality of the Offer, including, but not limiting to being responsible for the type of colour, the quality of the illustrations, the existence and presence of spelling, grammar and other mistakes and errors in the text of the Assignment, mistakes and errors in the vision and other similar technical parameters of the Assignment.
                          </div>
                          <br/>
                          <div style={{textAlign:"left"}}>
                            ІІ. Duration of the Contract	<br/>
                            6. The Supplier is responsible for providing the service for the duration of the subscription, paid by the Client.<br/>
                            ІІІ. Reports for the service<br/>
                            7. When needed the Supplier prepares reports regarding the provided service in the in the following time frame: 30 days from receipt of the request. <br/>
                            8. In case of a dispute or objection from a natural or a legal person, included in the data base of the Client, the Supplier notifies the Client in a duration within 30 days.<br/>
                            9. The relations between the parties regarding the protection of personal data are arranged by the concluded, between them, personal data processing Contract.
                          </div>
                          <br/>
                          <div style={{textAlign:"left"}}>
                            ІV. Rights and obligations of the Supplier<br/>
                            10. The Supplier is obligated to:<br/>
                            10.1. to execute the service with the care of a good trader and according to the acknowledged standards for this service;<br/>
                            10.2. upon providing the service, to take in consideration all necessary measures for protecting the confidentiality of the information, kept in the Client’s profile.<br/>
                            11. The Supplier has the right to:<br/>
                            11.1. decline the concluding of a contract with a Client, who:<br/>
                            a) Has overdue liabilities on other contracts with the Supplier;<br/>
                            b) Provided information, which doesn’t meet these General provisions.<br/>
                            11.2. To terminate a contract for the service, concluded with the Client, in case that the latter did not paid a due amount price according to the time frames and the conditions of the contract, as in this case occur consequences of contract non-performance and breach laid down in these General provisions;<br/>
                            11.3. To terminate a contract for the service, concluded with the Client , in case that the Client does not provide additional information/documents, requested by the Supplier.<br/>
                            12. The Client is obliged to:<br/>
                            12.1. Publish Offers and fills specific and accurate information, while complying with the conditions of Chapter V from these General provisions;<br/>
                            12.2. Not interfere with the providing of the service on the side of the Supplier;<br/>
                            12.3. To give information to the Supplier regarding the content and the copyrights of the information, publishing on the website when necessary;<br/>
                            12.4. To ensure the acceptance of the services in a way and to the addresses, negotiated between the parties in accordance with these General provisions;<br/>
                            12.5. To pay the price for the service according to the chosen plan;<br/>
                            12.6. To notify, as soon as possible, the Supplier in writing for every change in the name, his address or in other of his registration data.
                          </div>
                          <br/>
                          <div style={{textAlign:"left"}}>
                            V. 	ADMISSIBLE CONTENT. ANTI-SPAM POLICY<br/>
                            13.	The Supplier explicitly prohibits when using the service, the Client to publish, install, share or start the following type of content: (as files or links):<br/>
                            13.1. Materials, which contradict the Bulgarian legislation and laws, applicable foreign law and/or the present General provisions;<br/>
                            13.2. Materials, which infringe the rights of third parties (copyrights or relevant rights, , trademarks, patents or other intellectual property rights, the right of privacy and integrity);<br/>
                            13.3. Materials, which represent a commercial or a professional secret or other confidential information or personal data of third parties and a lack of agreement and consent from the particular third parties for the Client to use this information;<br/>
                            13.4. Materials, which contain a life threat, physical integrity of a person, propagate discrimination, advocate a fascist, racist or other undemocratic ideology or infringe the reputation and good name of third parties;<br/>
                            13.5. Materials, which are calling on to a forced change on the constitutionally established order, to committing crime, to violating any kind of property or non-property rights or legitimate interests of third parties;<br/>
                            13.6. Materials, which contain threatening, false, misleading, fraudulent, indecent, offensive, defamatory, offensive, disgraceful, pornographic or any other content, which threatens the normal mental development of the underage persons or infringes the norms of morality and good ethics;<br/>
                            13.7. Materials with content of a hacking activity or archive;<br/>
                            13.8. Materials with content of computer viruses, Trojan horse, worms, time-bombs programs, keystroke loggers, adware, spyware, denial-of-service attacks, IP spoofing and any other malware programs or codes;<br/>
                            14. The Client agree that the list of activities under art. 13 is not exhaustive and the Supplier has the right to decide if a certain resource is not compliant to the requirements or can put in risk the security of the site and server equipment.<br/>
                            VI. Claims on behalf of the Client<br/>
                            15. The Client has the right to dispute certain Suppliers’ report within 30 days as of date of receipt of the report.<br/>
                            16. The Supplier shall be obliged to review the claim and provide additional information regarding execution within 30 days from receipt of claim.<br/>
                            VІІ. Liability<br/>
                            17. In case of violation of any terms and conditions of this General Terms and Conditions the Client shall owe penalty in the amount of 10% from the price of the Services requested and to pay compensation in the amount of all damages  incurred by the Supplier.
                          </div>
                          <br/>
                          <div style={{textAlign:"left"}}>
                            VІІІ. Amendment of the General Terms and Conditions<br/>
                            18. This General Terms and Conditions can be amended from time to time by Inspire Soft EOOD in writing and the amendments and/or additions shall be in force for the Clients from the date of its post to the site match.careers.<br/>
                            IХ. Dispute Resolution. Applicable law<br/>
                            19. In any case of disputes arising from the agreement, the Parties shall aim to resolve them through negotiation, and if this is impossible, the dispute shall be referred for resolution by the competent court in Sofia.<br/>
                            20. For all matters not covered by this General Terms and Conditions, the applicable law and other relevant legislation in the Republic of Bulgaria shall apply.
                          </div>
                          <br/>
                          <h1>General terms for use of site Match Careers</h1>
                          <h3>For Employers</h3>
                          <br/>
                          <div style={{textAlign:"left"}}>
                            <strong>1. Scope of services provided by the site Match Careers</strong><br/>
                            <strong>Match Careers</strong> is a platform operated by Inspire Soft EOOD, registered under Bulgarian law, with UIC 202675952, head office: Sofia 1124, 15 Tzarigradsko Shosse Blvd., entr. B, floor 1, for provision of services of information society to employers duly registered under Bulgarian Law and physical persons looking for new job opportunities.<br/>
                            By creating an account as Client you sign contract with Inspire Soft OOD for using the platform Match Careers for following services:<br/>
                            - Downloading of job offers;<br/>
                            - Collecting of information from job applicants;<br/>
                            - Storage of data for the Client and  job applicants up to 3 years.
                          </div>
                          <div style={{textAlign:"left"}}>
                          <strong>2. How to use Match Careers</strong><br/>
                          2.1.The use of Match Careers is possible only after proper registration by authorized representative of the legal entity employer or the physical person employer (the Client) in the site through <a href="https://match.careers/signup/company">https://match.careers/signup/company</a> and activation of an Employer’s account. The activation shall be done within 2 working days from the date of creating of profile.<br/>
                          2.2. A Client account can be registered by a capable person who is authorized to represent the Client for the purposes of the use of Match Careers. By registration, the Client declares that the Client meets these conditions and the information filled in to identify the Client is accurate and complete.<br/>
                          2.3. The Client is obliged to provide information for billing and administration of the account.<br/>
                          2.4. Match Careers keeps the right to request additional information to verify the data from the Client’s account.<br/>
                          2.5. The Client shall be fully responsible for all actions carried out through its account accessed via Cleint’s username and password.
                          </div>
                          <div style={{textAlign:"left"}}>
                          <strong>3. Contract Signing and Billing</strong><br/>
                          3.1. By clicking the ‘Registration’ button, the person representing the Client makes an electronic statement and conclude a contract with Match Careers under these Terms (“Contract”).<br/>
                          3.2. When using paid Services, a separate order shall be placed for each Service. The provision of the Services is based on this Contract and Terms.<br/>
                          3.3. The Client shall receive invoice to the email registered and shall pay the amount due within 5 days from the date of issuance.
                          </div>
                          <div style={{textAlign:"left"}}>
                          <strong>4. How to post to Match Careers</strong><br/>
                          4.1. After entering your profile go to positions – add new.<br/>
                          4.2. The Structure for publication needs to be as followed – all the fields are recomendded to be completed with detailed information ( title, responsibilities, requirements, offer, category, education level, specialty, years of experience, qualifications, salary range, country). Period of time ( mandatory ), for which the offer shall be active ( option to be reduced or extended shall remain during that period ).<br/>
                          4.3. The volume and count of offers posted shall be in accordance to the pricing packet that the client has chosen.
                          </div>
                          <div style={{textAlign:"left"}}>
                          <strong>5. Personal Data Processing</strong><br/>
                          5.1. The Client is obliged to comply with the applicable data protection law when using the Services.<br/>
                          5.2 Pursuant to art. 28 European General Data Protection Regulation (“GDPR”), the processing of personal data by Inspire Soft EOOD on behalf of the Client requires a written agreement (“Data Processing Agreement”). The Client hereby commissions Inspire Soft EOOD to process personal data on its behalf by concluding a separate agreement in accordance with the scope and the conditions of the annex “Contractual Terms and Conditions for Data Processing”<br/>
                          5.3. The Client is responsible to obtain and maintain valid consents from all their end-users, as may be necessary (if at all) under applicable law (including data protection or data processing laws and regulations) to process their personal data in the manners and for the purposes set forth in this Agreement.<br/>
                          The terms and conditions related to  personal data processing are described in Data Processing Agreement [ <a href="https://match.careers/site/employers/GDPR">https://match.careers/site/employers/GDPR</a> ].
                          </div>
                          <div style={{textAlign:"left"}}>
                          <strong>6. Liability and Termination</strong><br/>
                          6.1. In case of delay of payment or non-payment for Services, Match Careers shall be entitled to:<br/>
                          (a) Restrict your access to the Services;<br/>
                          (b) Terminate the Contract and your account.<br/>
                          6.2. These Terms govern the relationship between the parties for the use of Match Careers.<br/>
                          6.3. Any disputes under these Terms will be settled by the competent court in Sofia.<br/>
                          6.4. For any unsettled issues the legislation of the Republic of Bulgaria shall apply.
                          </div><br/>
                          <h3>This General Terms and Conditions enter into force on 22.10.2019.</h3>
                      </div>
                  </div>
              </div>
          </section>
        </Page>
    );
  }
}

export default ENTermsAndConditionsEmployersPage;
