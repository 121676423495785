
import {isArray} from './arrayUtils';
import {isString} from './stringUtils';

/**
 * Ensure object structure and replaces values if structure not correct
 * @param {Object} originalObject
 * @param {Object} defaultValues default structure and values
 */
function ensureObjectHas(originalObject, defaultValues, debug=false) {
    if(debug) console.log("-------ensureObjectHas start-------------");
    if(debug) console.log("ensureObjectHas defaultValues: "+JSON.stringify(defaultValues));
    if(debug) console.log("ensureObjectHas originalObject Before: "+JSON.stringify(originalObject));
    if(!isObject(originalObject) || originalObject === {}) {
        if(debug) console.log("is not object");
        originalObject = defaultValues;
    } else if(typeof defaultValues === "object") {
        let ret = originalObject;
        if(debug) console.log("loop");
        Object.keys(defaultValues).forEach((defaultValueKey)=>{
            if(hasProperty(originalObject, defaultValueKey)) {
                if(debug) console.log(`has property ${defaultValueKey}`);
                if(isObject(originalObject[defaultValueKey])) {
                    if(debug) console.log(`is object`);
                    ensureObjectHas(originalObject[defaultValueKey], defaultValues[defaultValueKey], debug);
                } else {
                    if(debug) console.log(`is is not object`);
                }
            } else {
                if(debug) console.log(`does not have property ${defaultValueKey}`);
                ret[defaultValueKey] = defaultValues[defaultValueKey];
            }
        });
        originalObject = ret
    }
    if(debug) console.log("ensureObjectHas originalObject After: "+JSON.stringify(originalObject));
    if(debug) console.log("-------ensureObjectHas end-------------");
}

/**
 * Checks if apram is object
 * @param {Object} object 
 */
function isObject(object) {
    return object !== undefined && object !== null && typeof object === "object" && object;
}

/**
 * Checks if object has property
 * @param {Object} object original object
 * @param {Object} key key to check original object
 */
function hasProperty(object, key) {
    return object && object!==null && object!=={} && object!==undefined && object.hasOwnProperty(key) && object[key] !== null && object[key] !== undefined && object[key] !== {};
}

/**
 * Checks if object has properties
 * @param {Object} object original object
 * @param {mixed} keys keys to check original object
 */
function hasProperties(object, keys) {
    if(!isObject(object)) {
        return undefined;
    }
    if(isObject(keys)) {
        let has = true;
        Object.keys(keys).forEach((key)=>{
            if(!has) {
                if(isObject(object[key])) {
                    has = hasProperties(object[key], keys[key]);
                } else if(isArray(key)) {
                    has = hasProperties(object, key);
                } else if(isString(key) && hasProperty(object, key)) {
                    has = true;
                }
            }
        })
        return has;
    } else if(isString(keys)) {
        return hasProperty(object, keys);
    } else if(isArray(keys)) {
        let has = true;
        keys.forEach((key)=>{
            has = !has?has:hasProperty(object,key);
        })
        return has;
    }
}

export {
    ensureObjectHas,
    hasProperty,
    hasProperties,
    isObject
};