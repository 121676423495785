
import React, { Component } from 'react';

import Page from '../../Page';
import {setTitle} from "../../../pageOptions";

class ENGdprEmployersPage extends Component {
  
  componentWillMount() {
    setTitle("General Terms and Conditions");
  }

  render() {
    return (
        <Page>
          <section className="cid-qTkA127IK8 mbr-fullscreen mbr-parallax-background" id="header2-1">
              <div className="container align-center">
                  <div className="row justify-content-md-center">
                      <div className="mbr-white col-md-10" style={{paddingTop: "50px"}}>
                          <h1>General Terms and Conditions for Data Processing</h1>
                          <div style={{textAlign:"left"}}>
                            1. Scope <br/>
                            The Contractual Terms for Data Processing (“Contractual Terms”) contain the parties’ obligations with regard to data protection, which arise in connection with the commission of InspireSoft EOOD (hereinafter “Processor”) by the contracting party (hereinafter “Controller”) pursuant to article 28 Regulation (EU) 679/2016 (“GDPR”). <br/>
                            2. Service Description<br/>
                            The Processor processes data on behalf on the Controller. Data Processing is the collection, use, retention, alteration, transmission, blocking or deletion of personal data by the Processor on behalf of the Controller. <br/>
                            If applicable, the data will also be passed on to third parties if the Controller instructs the Processor to do so.<br/>
                            3. Data Subjects. Responsibility<br/>
                            3.1. The group of data subjects affected by the processing of their data within this commission includes in particular the users who apply for a job position to the Controller’s announcements and the employees of the Controller who make use of the Services.<br/>
                            3.2. The Controller shall be solely responsible for compliance with the applicable data protection laws. Due to this responsibility, the Controller shall be entitled to request the deletion or return of the data during and after the term of the agreement.<br/>
                            4. Controller’s Rights and Obligations<br/>
                            4.1. The Controller and the Processor are each responsible for compliance with the applicable data protection laws regarding the data to be processed.<br/>
                            4.2. The Controller shall promptly inform the Processor if he discovers any errors and/or irregularities with regard to the applicable data protection laws during his control of the results of such data processing.<br/>
                            4.3. Controller shall be obliged to keep a record of processing activities in accordance with art. 30 GDPR.<br/>
                            5. Processor’s Obligations<br/>
                            5.1. The Processor shall process data only within the scope of the Controller’s instructions as contractually agreed (art. 28 para. 3 GDPR). <br/>
                            5.2. Where a data subject directly addresses the Processor, the Processor shall immediately forward this request to the Controller. <br/>
                            5.3. Processor shall promptly inform the Controller pursuant to art. 28 para. 3 subpara. 2 GDPR if he believes that an instruction is in violation of data protection law.<br/>
                            5.4. The Processor has chosen a data privacy officer in writing, who carries out its activities pursuant to art. 38 and 39 GDPR: Snezhana Raykinska<br/>
                            5.5. The Processor shall promptly inform the Controller in the event of a serious interruption of the operating schedule, suspicion of data protection breaches or any other irregularity related to the processing of the Controller’s data.<br/>
                            5.6. The Processor and the Controller shall cooperate with the supervisory authority on request in carrying out their tasks. Insofar as the Controller is subject to an inspection by the supervisory authority, an administrative or summary offence or criminal procedure, a liability claim by a data subject or by a third party or any other claim in connection with the order or contract data processing by the Processor, the Processor shall make every effort to support the Controller.<br/>
                            5.7. All data carriers provided to Processor as well as any copies thereof remain the Controller’s property. The Processor shall store such data carriers with diligence and protect them against unauthorized access by third parties. The Processor shall be obliged to inform the Controller about its data and records at any time.<br/>
                            5.8. The Processor may claim compensation for support services which are not included in the description of the services and which are not attributable to failures on the part of the Processor.<br/>
                            6. Technical and Organizational Measures<br/>
                            6.1. The Processor shall establish the security in accordance with art. 28 para. 3 point c, and art. 32 GDPR in particular in conjunction with art. 5 para. 1, 2 GDPR. The measures to be taken are measures of data security and measures that guarantee a protection level appropriate to the risk concerning confidentiality, integrity, availability and resilience of the systems. The state of the art, implementation costs, the nature, scope and purposes of processing as well as the probability of occurrence and the severity of the risk to the rights and freedoms of natural persons within the meaning of art. 32 para. 1 GDPR must be taken into account.<br/>
                            6.3. The technical and organizational measures are subject to technical progress and further development. In this respect, it is permissible for the Processor to implement alternative adequate measures. In so doing, the security level of the defined measures must not be reduced. Substantial changes must be documented. The technical and organizational measures to adequately protect the Controller’s data include:<br/>
                            a) Confidentiality (art. 32 para. 1 point b GDPR): Physical access control, Logical access control, Data access control, Separation control; Pseudonymisation (art. 32 para. 1 point a GDPR; art. 25 para. 1 GDPR) <br/>
                            b) Integrity (art. 32 para. 1 point b GDPR): Data transfer control,  Entry control.<br/>
                            c) Availability and Resilience (art. 32 para. 1 point b GDPR)<br/>
                            d) Procedures for regular testing, assessment and evaluation (art. 32 para. 1 point d GDPR; art. 25 para. 1 GDPR):  <br/>
                            -Data protection management: All employees are demonstrably committed to data secrecy and receive a training at least once a year. Inspire Soft EOOD has appointed a data protection officer – Snezhana Raykinska.
                          </div>
                          <div style={{textAlign:"left"}}>
                            · Incident response management: In the event of a data loss, notification to the relevant data protection authority will be happening immediately. <br/>
                            · Data protection by design and default (art. 25 para. 2 GDPR).<br/>
                            · Control of instructions.
                          </div>
                      </div>
                  </div>
              </div>
          </section>
        </Page>
    );
  }
}

export default ENGdprEmployersPage;
