import React, { Component } from "react";

import manager from "../../Manager";
import { usertype, companytype } from "../../config";
import Select from "../form_helpers/Select";
import Translation from "../../utils/Translation";
import { hasProperty } from "../../utils/ObjectUtils";
import { isString } from "../../utils/stringUtils";

class NotificationSettings extends Component {
  static contextType = manager;

  static defaultProps = {
    settings: [],
    user: {}
  };

  state = {
    settings: []
  };

  componentWillMount() {
    this.setState({
      settings: this.props.settings
    });
  }

  onChange(name) {
    const component = this;
    return e => {
      const value = hasProperty(e, "target")
        ? e.target.value
        : isString(e)
        ? e
        : component.state.settings[name];
      component.context
        .getNotificationManager()
        .setNotificationSetting(component, name, value, {
          onError: { toast: true },
          onSuccess: {
            toast: true,
            callback: () => {
              let settings = component.state.settings;
              settings[name] = value;
              component.setState({
                settings: settings
              });
            }
          },
          onFinish: { toast: false }
        });
    };
  }

  render() {
    return (
      <div>
        <section
          className="counters6 counters cid-rg5irxrHy2 visible full-visible"
          id="counters6-6"
        >
          <div className="container">
            <div className="row">
              <div className="container align-center">
                {this.renderNewsLetterSettings()}
                {this.renderExpirationSettings()}
                {this.renderChatSettings()}
                {this.renderPositionSettings()}
                {this.renderRegisteredJobseekersSettings()}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  /**
   * 
    newsletter: ["yes","no"],
    three_month_expiration_warrning: ["yes","no"],
    six_month_expiration_warrning: ["yes","no"],
    twelve_month_expiration_warrning: ["yes","no"],
    chat_messages: ["off","on"],
    candidate_on_position: ["yes","no"],
    number_of_registered_jobseekers: ["off","all"]
   */

  renderNewsLetterSettings() {
    // TODO
    return null;
  }

  renderExpirationSettings() {
    if (this.props.user.user_type !== usertype.company) {
      return null;
    }
    // TODO
    return null;
  }

  renderChatSettings() {
    return (
      <div>
        <div className="help-menu">
          <ul className="help-toolbar">
            <li
              dangerouslySetInnerHTML={{
                __html: Translation.translateStaticText(
                  "chatNotificationSettingsHelpInformation",
                  `We need text to help with chat notifications`
                )
              }}
            />
          </ul>
        </div>
        <Select
          name="chat_messages"
          style={{
            minHeight: "59px"
          }}
          className="form-control px-3"
          placeholder={Translation.translateField(
            "chatNotificationslabel",
            "Chat notification"
          )}
          data-form-field="chat_messages"
          id="chat_messages-header15-b"
          onSelectChange={this.onChange("chat_messages")}
          options={[
            { value: "on", text: Translation.translateBool("On", "On") },
            { value: "off", text: Translation.translateBool("Off", "Off") }
          ]}
          value={this.state.settings["chat_messages"]}
        />
        <br />
      </div>
    );
  }

  renderPositionSettings() {
    if (this.props.user.user_type !== usertype.company) {
      return null;
    }
    let candidateOptions = [];
    if (companytype.free !== this.props.user.company_type) {
      candidateOptions.push({
        value: "on",
        text: Translation.translateBool("On", "On")
      });
    }
    candidateOptions.push({
      value: "off",
      text: Translation.translateBool("Off", "Off")
    });
    return (
      <div>
        <div className="help-menu">
          <ul className="help-toolbar">
            <li
              dangerouslySetInnerHTML={{
                __html:
                  Translation.translateStaticText(
                    "positionNotificationSettingsHelpInformation",
                    `We need text to help with position notifications.`
                  ) +
                  (companytype.free !== this.props.user.company_type
                    ? ""
                    : " " +
                      Translation.translateStaticText(
                        "freeCompanyPositionNotificationSettingsHelpInformation",
                        `You can not change this value on a free profile.`
                      ))
              }}
            />
          </ul>
        </div>
        <Select
          name="candidate_on_position"
          style={{
            minHeight: "59px"
          }}
          className="form-control px-3"
          placeholder={Translation.translateField(
            "candidateNotificationslabel",
            "Position notification"
          )}
          data-form-field="candidate_on_position"
          id="candidate_on_position-header15-b"
          onSelectChange={this.onChange("candidate_on_position")}
          options={candidateOptions}
          value={this.state.settings["candidate_on_position"]}
        />
        <br />
        <Select
          name="candidate_on_position_chat"
          style={{
            minHeight: "59px"
          }}
          className="form-control px-3"
          placeholder={Translation.translateField(
            "candidateChatNotificationslabel",
            "Position notification on in system messaging"
          )}
          data-form-field="candidate_on_position_chat"
          id="candidate_on_position_chat-header15-b"
          onSelectChange={this.onChange("candidate_on_position_chat")}
          options={candidateOptions}
          value={this.state.settings["candidate_on_position_chat"]}
        />
        <br />
      </div>
    );
  }

  renderRegisteredJobseekersSettings() {
    if (this.props.user.user_type !== usertype.company) {
      return null;
    }
    // TODO
    return null;
  }
}

export default NotificationSettings;
