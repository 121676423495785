import { EventEmitter } from 'fbemitter'
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { backend, cookies } from "../config";
import { getCookie } from "./cookies";
import ToastHandler from "./ToastHandler";

// https://github.com/tabvn/pubsub/blob/master/client/src/index.js
class WSClient {

    client = undefined;
    debug = false;
    manager = undefined;

    setManager(manager) {
      this.manager = manager;
    }

    constructor() {
      let token;
      if (
        Boolean(getCookie(cookies.localSessionCookie)) &&
        getCookie(cookies.cookieConcent) === "dismiss"
      ) {
        token = getCookie(cookies.localSessionCookie);
      } else if (sessionStorage.getItem("token") !== null) {
        token = sessionStorage.getItem("token");
      }

      if(this.debug) {
          console.log("debug mode is on.");
          if (!token) {
            console.error("Authorization not provided");
            return;
          }
      }
      if(!token) {
        return;
      }
      // Binding
      this.reconnect = this.reconnect.bind(this)
      this.connect = this.connect.bind(this)
      this.runSubscriptionQueue = this.runSubscriptionQueue.bind(this)
      this.runQueue = this.runQueue.bind(this)
  
      this.unsubscribe = this.unsubscribe.bind(this)
      this.subscribe = this.subscribe.bind(this)
      this.publish = this.publish.bind(this)
  
      // status of client connection
      this.emitter = new EventEmitter()
      this._connected = false
      this._ws = null
      this._queue = []
      this._id = null
  
      // store listeners
      this._listeners = []
  
      //All subscriptions
      this._subscriptions = []
  
      // store settings
  
      this._isReconnecting = false
      this._url = `${backend.WSDOMAIN}?token=${token}`
      this._options = {connect: true, reconnect: true}
    }

    /**
     * Check if webSocket is connected
     */
    isConnected() {
        return this._connected === true && this._ws.readyState === 1;
    }

    _findSubscribtionIndex(topic) {
      let index = -1;
      for(let i=0;i<this._subscriptions.length && index===-1;i++) {
        if(this._subscriptions[i].topic === topic) {
          index = i;
        }
      }
      return index;
    }

    _unsubscribeRecursive(topic) {
      const index = this._findSubscribtionIndex(topic);
      if (index>-1 && this._subscriptions[index]) {
        if(this._subscriptions[index].listener) {
          // first need to remove local listener
          this._subscriptions[index].listener.remove();
        }
        this._subscriptions.splice(index, 1);
      }
      return index>-1?this._unsubscribeRecursive(topic):index;
    }
  
    /**
     * Un Subscribe a topic, no longer receive new message of the topic
     * @param topic
     */
    unsubscribe (topic) {
  
      this._unsubscribeRecursive(topic);
  
      // need to tell to the server side that i dont want to receive message from this topic
      this.send({
        action: 'unsubscribe',
        payload: {
          topic: topic,
        },
      })
  
    }
  
    /**
     * Subscribe client to a topic
     * @param topic
     * @param cb
     */
    subscribe (topic, cb, multible=false) {

      if(this._findSubscribtionIndex(topic)>-1 && !multible) {
        throw new Error("multible subscriptions detected");
      } else if(false) {
        //debug above
        console.trace(topic);
      }
  
      const listener = this.emitter.addListener(`subscribe_topic_${topic}`, cb)
      // add listener to array
      this._listeners.push(listener)
  
      // send server with message
  
      this.send({
        action: 'subscribe',
        payload: {
          topic: topic,
        },
      })
  
      // let store this into subscriptions for later when use reconnect and we need to run queque to subscribe again
      this._subscriptions.push({
        topic: topic,
        callback: cb ? cb : null,
        listener: listener,
      })
    }
  
    /**
     * Publish a message to topic, send to everyone and me
     * @param topic
     * @param message
     */
    publish (topic, message) {
  
      this.send({
        action: 'publish',
        payload: {
          topic: topic,
          message: message,
        },
      })
  
    }
  
    /**
     * Publish a message to the topic and send to everyone, not me
     * @param topic
     * @param message
     */
    broadcast (topic, message) {
  
      this.send({
        action: 'broadcast',
        payload: {
          topic: topic,
          message: message,
        },
      })
    }
  
    /**
     * Return client conneciton ID
     */
    id () {
      return this._id
    }
  
    /**
     * Convert string to JSON
     * @param message
     * @returns {*}
     */
    stringToJson (message) {
  
      try {
        message = JSON.parse(message)
      }
      catch (e) {
        console.log(e)
      }
  
      return message
    }
  
    /**
     * Send a message to the server
     * @param message
     */
    send(message) {
      if (this._connected === true && this._ws.readyState === 1) {
        message = JSON.stringify(message)
        this._ws.send(message)
      } else {
        // let keep it in queue
        this._queue.push({
          type: 'message',
          payload: message,
        })
      }
  
    }
  
    /**
     * Send a request message to the server
     * @param message
     */
    request(requestName, options=undefined) {
      this.send({
        action: 'request',
        payload: {
          name: requestName,
          ...options
        },
      })
    }


    /**
     * Sends a message to user_id
     * @param {string} to_id 
     * @param {string} message 
     */
    sendMessage(to_id, message) {
      this.send({
        action: 'request',
        payload: {
          name: 'sendMessage',
          to_id: to_id,
          message: message
        },
      })
    }

    /**
     * Send a request to get contacts
     */
    getContacts() {
      this.send({
        action: 'request',
        payload: {
          name: 'getContacts'
        },
      })
    }

    /**
     * Send a request to get contacts
     * @param {string} user_id 
     */
    getMessages(user_id) {
      this.send({
        action: 'request',
        payload: {
          user_id: user_id,
          name: 'getMessages'
        },
      })
    }

    /**
     * Send a request to get contacts
     */
    getSystemMessages() {
      this.send({
        action: 'request',
        payload: {
          name: 'getSystemMessages'
        },
      })
    }

    /**
     * Set messages as read from user_id
     * @param {string} from_id 
     */
    readMessages(from_id=undefined) {
      if(from_id) {
        this.send({
          action: 'readMessages',
          payload: {
            from_id: from_id
          },
        })
      } else {
        this.send({
          action: 'readSystemMessages',
          payload: {},
        })
      }
    }
  
    /**
     * Run Queue after connecting successful
     */
    runQueue () {
      if (this._queue.length) {
        this._queue.forEach((q, index) => {
          switch (q.type) {
  
            case 'message':
              this.send(q.payload)
  
              break
  
            default:
  
              break
          }
  
          // remove queue
  
          delete this._queue[index]
  
        })
      }
  
    }
  
    /**
     * Let auto subscribe again
     */
    runSubscriptionQueue () {
  
      if (this._subscriptions.length) {
        this._subscriptions.forEach((subscription) => {
          this.send({
            action: 'subscribe',
            payload: {
              topic: subscription.topic,
            },
          })
  
        })
      }
    }
  
    /**
     * Implement reconnect
     */
    reconnect () {
  
      // if is reconnecting so do nothing
      if (this._isReconnecting || this._connected) {
        return
      }
      // Set timeout
      this._isReconnecting = true
      this._reconnectTimeout = setTimeout(() => {
        if(this.debug) {
          console.log('Reconnecting....')
        }
        this.connect()
      }, 2000)
  
    }
  
    /**
     * Begin connect to the server
     * @param cb
     */
    connect () {
      const ws = new W3CWebSocket(this._url)
      this._ws = ws;
  
      // clear timeout of reconnect
      if (this._reconnectTimeout) {
        clearTimeout(this._reconnectTimeout)
      }
  
      ws.onopen = () => {
  
        // change status of connected
        this._connected = true
        this._isReconnecting = false
        
        if(this.debug) {
          console.log('Connected to the server')
        }
        this.send({
          action: 'request',
          payload: {
            name: 'me',
          }
        })
        // run queue
        this.runQueue()
  
        this.runSubscriptionQueue()
  
      }
      // listen a message from the server
      ws.onmessage = (message) => {
        const jsonMessage = this.stringToJson(message.data)
  
        const action = jsonMessage.action
        const payload = jsonMessage.payload
  
        switch (action) {
  
          case 'info':
            switch(payload.type.toLowerCase()) {
              case 'error':
                ToastHandler.showError(payload.message);
                console.error(message);
                break;
              case 'warn':
                ToastHandler.showWarn(payload.message);
                console.error(message);
                break;
              default:
              case 'info':
                ToastHandler.showInfo(payload.message);
                break;
            }
            break
  
          case 'me':
  
            this._id = payload.id
            if(this.debug) {
              console.log("Server websocket message to me: ", payload);
            }
  
            break
  
          case 'publish':
  
            this.emitter.emit(`subscribe_topic_${payload.topic}`, payload.message)
            if(this.debug) {
              console.log("Server websocket message to publish: ", payload);
            }
            // let emit this to subscribers
            break
  
          default:
  
            break
        }
  
      }
      ws.onerror = (err) => {
        if(this.debug) {
          console.log('unable connect to the server', err)
        }
  
        this._connected = false
        this._isReconnecting = false
        this.reconnect()
  
      }
      ws.onclose = () => {
        if(this.debug) {
          console.log('Connection is closed')
        }
  
        this._connected = false
        this._isReconnecting = false
        this.reconnect()
  
      }
  
    }
  
    /**
     * Disconnect client
     */
    disconnect () {
  
      if (this._listeners.length) {
        this._listeners.forEach((listener) => {
  
          listener.remove()
        })
      }
    }

}

export default new WSClient();
