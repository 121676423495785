const jobseekerProfileProps = {
  cities: [],
  countries: [],
  locations: [],
  approvedSkills: [],
  accessLevels: [],
  jobseeker: {
    jobseeker_name: "",
    phone: "",
    email: "",
    location: {},
    password: "",
    confirmPassword: "",
    currentPassword: "",
    permissionType: "",
    visibility: undefined,
    education: [],
    employments: []
  },
  cv: {},
  skills: [],
  providedAccess: [],
  profileCompletion: 0
};

const styles = theme => ({
  paper: {
    padding: 15
  },
  cv: {
    padding: 15,
    paddingBottom: 37
  },
  heading: {
    color: "white"
  },
  divider: {
    borderColor: "white",
    color: "white",
    backgroundColor: "white"
  },
  chip: {
    marginLeft: "0.5rem"
  },
  logo: {
    margin: "auto",
    width: 100,
    height: 100
  }
});

export { jobseekerProfileProps, styles };
