import React, { Component } from "react";
import Creatable from "react-select/lib/Creatable";
import Select from "react-select";

class AutocompleteSelect extends Component {
	static defaultProps = {
		style: {}
	};
	render() {
		const styles = {
			...this.props.styles,
			menu: provided => ({
				...provided,
				zIndex: 9999
			}),
		};
		if (this.props.creatable) {
			return <Creatable {...this.props} styles={styles} />;
		}
		return <Select {...this.props} styles={styles} />;
	}
}

export default AutocompleteSelect;
