import React, { Component } from "react";

class Question extends Component {

    static defaultProps = {
        isOpen: false,
        id: -1,
        children: null
    }

    render() {
        return (
            <dt>
                {/* <span class="mbri-question mbr-iconfont mbr-iconfont-btn"></span> */}
                <button
                    className="FAQ__question"
                    aria-expanded={this.props.isOpen}
                    aria-controls={this.props.id}
                >
                    {this.props.children}
                </button>
                <span className={"mbri-arrow-"+(this.props.isOpen?"down":"up")+" mbr-iconfont mbr-iconfont-btn"}></span>
            </dt>
        );
    }
}

export default Question;