import React from "react";
import axios from "axios";

import {
    backend,
    frontend,
    file as fileType,
    cookies,
    companytype,
    usertype,
    languages
} from "./config";
import { hasProperty, isObject, ensureObjectHas } from "./utils/ObjectUtils";
import ResponseHandler from "./utils/ResponseHandler";
import ToastHandler from "./utils/ToastHandler";
import { isArray } from "./utils/arrayUtils";
import WSClient from "./utils/WSClient";
import { getCookie, setCookie } from "./utils/cookies";
import Translation from "./utils/Translation";
/**
 * Author Simeon-Banov
 * Application manager
 */
class Manager {
    /**
     * container for Lazy Initialization managers
     */
    managers = {
        authManager: undefined,
        companyManager: undefined,
        jobseekerManager: undefined,
        utilManager: undefined,
        positionManager: undefined,
        fileManager: undefined,
        educationManager: undefined,
        languageManager: undefined,
        genericManager: undefined,
        notificationManager: undefined
    };

    ws = undefined;

    /**
     * Initilization of Manager
     * @param {AuthManager} authManager
     */
    constructor(authManager = undefined) {
        this.ws = WSClient;
        this.managers = {
            authManager: authManager,
            companyManager: undefined,
            jobseekerManager: undefined,
            utilManager: undefined,
            positionManager: undefined,
            fileManager: undefined,
            educationManager: undefined,
            employerManager: undefined,
            languageManager: new LanguageManager(this),
            genericManager: undefined,
            notificationManager: undefined
        };
        Translation.setLanguage(
            this.managers.languageManager.getActiveLanugage()
        );
    }
    /**
     * Get WebSocket client
     */
    getWS() {
        return this.ws;
    }

    /**
     * Lazy Initialization of auth manager
     */
    getAuthManager() {
        if (this.managers.authManager === undefined) {
            this.managers.authManager = new AuthManager();
        }
        return this.managers.authManager;
    }

    /**
     * Lazy Initialization of company manager
     */
    getCompanyManager() {
        if (this.managers.companyManager === undefined) {
            this.managers.companyManager = new CompanyManager(
                this.getAuthManager()
            );
        }
        return this.managers.companyManager;
    }

    /**
     * Lazy Initialization of jobseeker manager
     */
    getJobseekerManager() {
        if (this.managers.jobseekerManager === undefined) {
            this.managers.jobseekerManager = new JobseekerManager(
                this.getAuthManager()
            );
        }
        return this.managers.jobseekerManager;
    }

    /**
     * Lazy Initialization of utils manager
     */
    getUtilManager() {
        if (this.managers.utilManager === undefined) {
            this.managers.utilManager = new UtilManager();
        }
        return this.managers.utilManager;
    }

    /**
     * Lazy Initialization of position manager
     */
    getPositionManager() {
        if (this.managers.positionManager === undefined) {
            this.managers.positionManager = new PositionManager(
                this.getAuthManager()
            );
        }
        return this.managers.positionManager;
    }

    /**
     * Lazy Initialization of file manager
     */
    getFileManager() {
        if (this.managers.fileManager === undefined) {
            this.managers.fileManager = new FileManager(this.getAuthManager());
        }
        return this.managers.fileManager;
    }

    /**
     * Lazy Initialization of websocket manager
     */
    getWSManager() {
        if (this.managers.WSManager === undefined) {
            this.managers.WSManager = new WSManager(this);
        }
        return this.managers.WSManager;
    }

    /**
     * Lazy Initialization of education manager
     */
    getEducationManager() {
        if (this.managers.educationManager === undefined) {
            this.managers.educationManager = new EducationManager(
                this.getAuthManager()
            );
        }
        return this.managers.educationManager;
    }

    /**
     * Lazy Initialization of employer manager
     */
    getEmployerManager() {
        if (this.managers.employerManager === undefined) {
            this.managers.employerManager = new EmployerManager(
                this.getAuthManager()
            );
        }
        return this.managers.employerManager;
    }

    getLanguageManger() {
        if (this.managers.languageManager === undefined) {
            this.managers.languageManager = new LanguageManager(this);
        }
        return this.managers.languageManager;
    }

    getGenericManager() {
        if (this.managers.genericManager === undefined) {
            this.managers.genericManager = new GenericManager(this);
        }
        return this.managers.genericManager;
    }

    getNotificationManager() {
        if (this.managers.notificationManager === undefined) {
            this.managers.notificationManager = new NotificationManager(this);
        }
        return this.managers.notificationManager;
    }
}

/**
 * Author Simeon-Banov
 * Language Manager
 */
class LanguageManager {
    manager = undefined;

    /**
     * Initilization of Manager
     * @param {Manager} manager
     */
    constructor(manager) {
        this.manager = manager;
    }

    getActiveLanugage() {
        if (getCookie(cookies.cookieConcent) && getCookie(cookies.lang)) {
            return getCookie(cookies.lang);
        }
        return Boolean(sessionStorage.getItem(cookies.lang))
            ? sessionStorage.getItem(cookies.lang)
            : languages.english;
    }

    setActiveLanugage(lang) {
        if (getCookie(cookies.cookieConcent)) {
            setCookie(cookies.lang, lang, 60 * 24 * 365); // 1 year
        }
        sessionStorage.setItem(cookies.lang, lang);

        window.location.reload();
        return;
    }

    getLanguageFullName(lang) {
        switch (lang) {
            case "en":
                return "english";
            case "bg":
                return "bulgarian";
            case "de":
                return "deutsch";
            default:
                return "english";
        }
    }
}

/**
 * Author Simeon-Banov
 * Notifications
 */
class NotificationManager {
    manager = undefined;

    /**
     * Initilization of Manager
     * @param {AuthManager} authManager
     */
    constructor(manager) {
        this.manager = manager;
    }

    /**
     * gets list of notification settings
     * @param {React.component} component
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    getNotificationSettings(component, responseHandlerOptions = {}) {
        makeGenericRequest({
            restricted: true,
            component: component,
            url: backend.DOMAIN + backend.api.notificationSettings.getNotificationSettings,
            method: "get",
            responseHandlerOptions: responseHandlerOptions
        });
    }

    /**
     * sets a notification settings
     * @param {React.component} component
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    setNotificationSetting(component, name, value, responseHandlerOptions = {}) {
        makeGenericRequest({
            restricted: true,
            component: component,
            url: backend.DOMAIN + backend.api.notificationSettings.setNotificationSetting + name + "/" + value,
            method: "get",
            responseHandlerOptions: responseHandlerOptions
        });
    }

}

/**
 * Author Simeon-Banov
 * Generic Manager
 */
class GenericManager {
    manager = undefined;

    /**
     * Initilization of Manager
     * @param {AuthManager} authManager
     */
    constructor(manager) {
        this.manager = manager;
    }

    /**
     * checkContacts
     * @param {React.component} component
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    getPricing(component, responseHandlerOptions = {}) {
        makeGenericRequest({
            restricted: false,
            component: component,
            url: backend.DOMAIN + backend.api.generic.pricing,
            method: "get",
            responseHandlerOptions: responseHandlerOptions
        });
    }

    /**
     * get server time offset
     * @param {React.component} component
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    getTimeoffset(component, responseHandlerOptions = {}) {
        makeGenericRequest({
            restricted: false,
            component: component,
            url: backend.DOMAIN + backend.api.generic.timeoffset,
            method: "get",
            responseHandlerOptions: responseHandlerOptions
        });
    }
}

/**
 * Author Simeon-Banov
 * Manage websockets
 */
class WSManager {
    ws = undefined;
    chatIndicatorUpdater = undefined;
    authManager = undefined;

    /**
     * Initilization of Manager
     * @param {AuthManager} authManager
     */
    constructor(manager) {
        this.authManager = manager.getAuthManager();
        this.connect();
    }

    connect() {
        if (this.ws === undefined && this.authManager.isLogged()) {
            WSClient.setManager(manager);
            this.ws = WSClient;
            if (
                this.authManager._loggedUser.user_type !== usertype.company ||
                this.authManager._loggedUser.company_type !== companytype.free
            ) {
                this.ws.connect();
            }
        }
    }

    /**
     * Check if connection is ok
     */
    isActive() {
        return (
            this.authManager.isLogged() &&
            (this.authManager._loggedUser.user_type !== usertype.company ||
                this.authManager._loggedUser.company_type !== companytype.free)
        );
    }

    /**
     * Get WebSocket client
     * @return {WSClient} websocket client
     */
    getWS() {
        return this.ws;
    }

    /**
     * Updated icon of navBar with indicator for unreadMessages
     */
    updateChatIndicator() {
        if (!this.isActive()) {
            return;
        }
        this.ws.request("getMyUnreadMessagesNumber");
    }

    /**
     * checkContacts
     * @param {React.component} component
     * @param {Object} body request form params
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    checkContacts(component, body, responseHandlerOptions = {}) {
        const manager = this;
        manager.authManager.loggedRestrictionAPI(
            "WSManager checkContacts",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url: backend.DOMAIN + backend.api.ws.checkContacts,
                    method: "get",
                    body: prepareBody(body),
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    /**
     * requestContact
     * @param {React.component} component
     * @param {Object} body request form params
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    requestContact(component, body, responseHandlerOptions = {}) {
        const manager = this;
        manager.authManager.loggedRestrictionAPI(
            "WSManager requestContact",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url: backend.DOMAIN + backend.api.ws.requestContact,
                    method: "post",
                    body: prepareBody(body),
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    /**
     * checks if there is a contact entity with the user_id
     * @param {React.component} component
     * @param {String} user_id
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    hasContactEntity(component, user_id, responseHandlerOptions = {}) {
        const manager = this;
        manager.authManager.loggedRestrictionAPI(
            "WSManager hasContactEntity",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url:
                        backend.DOMAIN +
                        backend.api.ws.hasContactEntity +
                        "/" +
                        user_id,
                    method: "get",
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    /**
     * accepts contact with user_id
     * @param {React.component} component
     * @param {String} user_id
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    acceptContact(component, user_id, responseHandlerOptions = {}) {
        const manager = this;
        manager.authManager.loggedRestrictionAPI(
            "WSManager acceptContact",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url:
                        backend.DOMAIN +
                        backend.api.ws.acceptContact +
                        "/" +
                        user_id,
                    method: "get",
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    /**
     * accepts contact with user_id
     * @param {React.component} component
     * @param {String} user_id
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    rejectContact(component, user_id, responseHandlerOptions = {}) {
        const manager = this;
        manager.authManager.loggedRestrictionAPI(
            "WSManager rejectContact",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url:
                        backend.DOMAIN +
                        backend.api.ws.rejectContact +
                        "/" +
                        user_id,
                    method: "get",
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    /**
     * block contact with user_id
     * @param {React.component} component
     * @param {String} user_id
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    blockContact(component, user_id, responseHandlerOptions = {}) {
        const manager = this;
        manager.authManager.loggedRestrictionAPI(
            "WSManager blockContact",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url:
                        backend.DOMAIN +
                        backend.api.ws.blockContact +
                        "/" +
                        user_id,
                    method: "get",
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    /**
     * unblock contact with user_id
     * @param {React.component} component
     * @param {String} user_id
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    unblockContact(component, user_id, responseHandlerOptions = {}) {
        const manager = this;
        manager.authManager.loggedRestrictionAPI(
            "WSManager unblockContact",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url:
                        backend.DOMAIN +
                        backend.api.ws.unblockContact +
                        "/" +
                        user_id,
                    method: "get",
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }
}

/**
 * Author Simeon-Banov
 * Manager of utils for other managers
 */
class UtilManager {
    /**
     * Process get params string to object with key: value
     * @param {String} params
     */
    processGetParams(params) {
        let getParams = {};
        if (params && params.length > 1) {
            const getSections = params.substring(1, params.length).split("&");
            let temp = undefined;
            getSections.forEach(function(getSection) {
                temp = getSection.split("=");
                if (temp.length === 2) {
                    getParams[temp[0]] = temp[1];
                }
            });
        }
        return getParams;
    }
}

/**
 * @param {Object} params
     Structure:
    {
    component: Object,
    url: String, 
    method: "post" | "get",
    responseHandlerOptions: Object (optional) @see ResponseHandler.parse,
    body: FormData (optional),
    restricted: String (optional) (should we sent JWT token)
    }
    */
function makeGenericRequest(params, callback = undefined) {
    let reqOptions = {
        url: params.url,
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": frontend.DOMAIN
        },
        method: params.method
    };
    if (getCookie(cookies.cookieConcent) === "dismiss") {
        reqOptions.withCredentials = true;
    }

    if (hasProperty(params, "body")) {
        if (params.body instanceof FormData) {
            reqOptions["data"] = params.body;
        } else if (typeof params.body === "object") {
            reqOptions["data"] = prepareBody(params.body);
        }
    }
    if (hasProperty(params, "restricted") && params.restricted) {
        if (
            getCookie(cookies.cookieConcent) === "" ||
            getCookie(cookies.localSessionCookie) === ""
        ) {
            reqOptions.headers[
                "Authorization"
            ] = `Bearer ${sessionStorage.getItem("token")}`;
        }
    }
    if (!hasProperty(params, "responseHandlerOptions")) {
        params.responseHandlerOptions = {};
    }
    axios(reqOptions)
        .then(function(response) {
            if (callback === undefined) {
                ResponseHandler.parse(
                    params.component,
                    response.data,
                    params.responseHandlerOptions
                );
            } else {
                callback(response);
            }
        })
        .catch(function(error) {
            console.error(error);
            ToastHandler.showError("Error");
        });
}

/**
 * Prepare object params as FormData for sending to server
 * @param {Object} object
 */
function prepareBody(object) {
    const body = new FormData();
    Object.keys(object).forEach(key => {
        if (isArray(object[key])) {
            body.append(key, ...object[key]);
        } else {
            body.append(key, object[key]);
        }
    });
    return body;
}

/**
 * Author: Simeon-Banov
 */
class JobseekerManager {
    // needed auth link
    authManager = undefined;

    constructor(authManager) {
        this.authManager = authManager;
    }

    /**
     * API request to server set logged employee
     * @param {React.component} component
     * @param {Object} body request form params
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    getLoggedJobseekerData(component, responseHandlerOptions = {}) {
        const manager = this;
        manager.authManager.loggedRestrictionAPI(
            "JobseekerManager getLoggedJobseekerData",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url:
                        backend.DOMAIN +
                        backend.api.jobseeker.getLoggedJobseekerData,
                    method: "get",
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    /**
     * API request to server get public jobseeker data
     * @param {React.component} component
     * @param {Object} body request form params
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    getPublicJobseekerData(component, id, responseHandlerOptions = {}) {
        const manager = this;
        manager.authManager.loggedRestrictionAPI(
            "JobseekerManager getPublicJobseekerData",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url:
                        backend.DOMAIN +
                        backend.api.jobseeker.getPublicJobseekerData +
                        "/" +
                        id,
                    method: "get",
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    /**
     * API request to server set logged employee
     * @param {React.component} component
     * @param {Object} body request form params
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    setLoggedJobseekerData(component, body, responseHandlerOptions = {}) {
        const manager = this;
        manager.authManager.loggedRestrictionAPI(
            "JobseekerManager setLoggedJobseekerData",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url:
                        backend.DOMAIN +
                        backend.api.jobseeker.setLoggedJobseekerData,
                    method: "post",
                    body: prepareBody(body),
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    updateJobseekerAccess(component, body, responseHandlerOptions = {}) {
        const manager = this;
        manager.authManager.loggedRestrictionAPI(
            "JobseekerManager setCompanyAccess",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url:
                        backend.DOMAIN + backend.api.jobseeker.profileAccessAPI,
                    method: "patch",
                    body: prepareBody(body),
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    deleteJobseekerAccess(component, accessId, responseHandlerOptions = {}) {
        const manager = this;
        manager.authManager.loggedRestrictionAPI(
            "JobseekerManager deleteJobseekerAccess",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url: `${backend.DOMAIN +
                        backend.api.jobseeker.profileAccessAPI}/${accessId}`,
                    method: "delete",
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    addSkill(component, skillName, responseHandlerOptions = {}) {
        this.authManager.loggedRestrictionAPI(
            "JobseekerManager, addSkill",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url: backend.DOMAIN + backend.api.jobseeker.addSkill,
                    method: "post",
                    body: prepareBody({ skill: skillName }),
                    responseHandlerOptions: responseHandlerOptions
                });
            }
        );
    }

    /**
     * returns Pagination controlled result of search string.
     * @param {React.component} component
     * @param {String} search
     * @param {Integer} from
     * @param {Integer} size
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse doc
     */
    search(component, search, from, size, responseHandlerOptions = {}) {
        component.context.getAuthManager().loggedRestrictionAPI(
            "JobseekerManager search",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url: `${backend.DOMAIN +
                        backend.api.jobseeker.search}/${encodeURIComponent(
                        search
                    )}/${from}/${size}`,
                    method: "get",
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    getProfileCompletion(component, responseHandlerOptions = {}) {
        component.context
            .getAuthManager()
            .loggedRestrictionAPI(
                "JobseekerManager getProfileCompletion",
                component,
                () => {
                    makeGenericRequest({
                        restricted: true,
                        component: component,
                        url: `${backend.DOMAIN +
                            backend.api.jobseeker.profileCompletion}`,
                        method: "get",
                        responseHandlerOptions: responseHandlerOptions
                    });
                }
            );
    }
}

/**
 * Author: Simeon-Banov
 */
class CompanyManager {
    // Internal use only
    _company = {};
    _companyResponse = undefined;
    _companyWaitingRequests = [];
    _companyLoaded = false;
    _companyLoading = false;

    // needed link to auth manager
    authManager = undefined;

    constructor(authManager) {
        this.authManager = authManager;
    }

    /**
     * Get basic company information.
     * if already loaded, return the privius result.
     * if not loaded - start loading and callback when done
     * @param {React.component} component
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    getLoggedCompany(component, responseHandlerOptions = {}) {
        const manager = this;
        component.context.getAuthManager().loggedRestrictionAPI(
            "CompanyManager getLoggedCompany",
            component,
            () => {
                if (manager._companyLoaded) {
                    ResponseHandler.parse(
                        component,
                        manager._companyResponse,
                        responseHandlerOptions
                    );
                } else if (manager._companyLoading) {
                    manager._companyWaitingRequests.push({
                        component: component,
                        options: responseHandlerOptions
                    });
                } else {
                    manager._companyLoading = true;
                    manager._companyWaitingRequests.push({
                        component: component,
                        options: responseHandlerOptions
                    });
                    makeGenericRequest(
                        {
                            restricted: true,
                            component: component,
                            url:
                                backend.DOMAIN +
                                backend.api.company.getLoggedCompany,
                            method: "get"
                        },
                        response => {
                            manager._companyResponse = response.data;
                            if (manager._companyResponse === undefined) {
                                manager._companyResponse = {
                                    successful: false,
                                    code: "CompanyResponseError",
                                    message: {}
                                };
                            }
                            manager._company = manager._companyResponse.message;
                            manager._companyLoaded = true;
                            manager._companyLoading = false;
                            manager._companyWaitingRequests.forEach(function(
                                request
                            ) {
                                if (isObject(request)) {
                                    ResponseHandler.parse(
                                        request.component,
                                        manager._companyResponse,
                                        request.options
                                    );
                                }
                            });
                            manager._companyWaitingRequests = [];
                        }
                    );
                }
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    /**
     * API request to server set logged company data
     * @param {React.component} component
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    getLoggedCompanyData(component, responseHandlerOptions = {}) {
        component.context.getAuthManager().loggedRestrictionAPI(
            "CompanyManager getLoggedCompanyData",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url:
                        backend.DOMAIN +
                        backend.api.company.getLoggedCompanyData,
                    method: "get",
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    /**
     * API request to server set logged employee
     * @param {React.component} component
     * @param {Object} body request form params
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    setLoggedCompanyData(component, body, responseHandlerOptions = {}) {
        component.context.getAuthManager().loggedRestrictionAPI(
            "CompanyManager setLoggedCompanyData",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url:
                        backend.DOMAIN +
                        backend.api.company.setLoggedCompanyData,
                    method: "patch",
                    body: prepareBody(body),
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    /**
     * API request to server set logged employee
     * @param {React.component} component
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    getLoggedEmployeeData(component, responseHandlerOptions = {}) {
        component.context.getAuthManager().loggedRestrictionAPI(
            "CompanyManager setLoggedCompanyData",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url:
                        backend.DOMAIN +
                        backend.api.company.getLoggedEmployeeData,
                    method: "get",
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    /**
     * API request to server set logged employee
     * @param {React.component} component
     * @param {Object} body request form params
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    setLoggedEmployeeData(component, body, responseHandlerOptions = {}) {
        component.context.getAuthManager().loggedRestrictionAPI(
            "CompanyManager setLoggedEmployeeData",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url:
                        backend.DOMAIN +
                        backend.api.company.setLoggedEmployeeData,
                    method: "patch",
                    body: prepareBody(body),
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    /**
     * API request to server get all platimum
     * @param {React.component} component
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    getPlatimumCompanies(component, responseHandlerOptions = {}) {
        makeGenericRequest({
            restricted: false,
            component: component,
            url: backend.DOMAIN + backend.api.getPlatimumCompanies,
            method: "get",
            responseHandlerOptions: responseHandlerOptions
        });
    }

    /**
     * Send request to server get selected company data
     * @param {React.component} component
     * @param {String} companyName
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    getCompanyData(component, companyName, responseHandlerOptions = {}) {
        makeGenericRequest({
            restricted: true,
            component: component,
            url: `${backend.DOMAIN +
                backend.api.company.getCompanyData}/${encodeURIComponent(
                companyName
            )}`,
            method: "get",
            responseHandlerOptions: responseHandlerOptions
        });
    }

    /**
     * returns true/false if jobseeker is candidate to any position.
     * @param {React.component} component
     * @param {String} jobseeker_id
     * @param {Object} responseHandlerOptions  @see ResponseHandler.parse options params
     */
    isJobseekerACandidate(
        component,
        jobseeker_id,
        responseHandlerOptions = {}
    ) {
        makeGenericRequest({
            restricted: true,
            component: component,
            url: `${backend.DOMAIN +
                backend.api.company.isJobseekerACandidate}/${encodeURIComponent(
                jobseeker_id
            )}`,
            method: "get",
            responseHandlerOptions: responseHandlerOptions
        });
    }

    /**
     * returns Pagination controlled result of search string.
     * @param {React.component} component
     * @param {String} search
     * @param {Integer} from
     * @param {Integer} size
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse doc
     */
    search(component, search, from, size, responseHandlerOptions = {}) {
        // console.log(`${backend.DOMAIN+backend.api.company.search}/${encodeURIComponent(search)}/${from}/${size}`);
        component.context.getAuthManager().loggedRestrictionAPI(
            "JobseekerManager search",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url: `${backend.DOMAIN +
                        backend.api.company.search}/${encodeURIComponent(
                        search
                    )}/${from}/${size}`,
                    method: "get",
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }
}

/**
 * Author: Simeon-Banov
 */
class PositionManager {
    // Internal use only
    _dependenciesWaitingRequests = [];
    _dependenciesResponse = undefined;
    _dependenciesLoaded = false;
    _dependenciesLoading = false;

    // connection to auth manager is needed (Position manager is only for logged users, manly company type users)
    authManager = undefined;

    constructor(authManager) {
        this.authManager = authManager;
    }

    /**
     * Pagination controlled server request.
     * @param {Integer} from
     * @param {Integer} size
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse doc
     */
    getPaginationAllPositionList(
        component,
        from,
        size,
        responseHandlerOptions = {}
    ) {
        makeGenericRequest({
            restricted: true,
            component: component,
            url:
                backend.DOMAIN +
                backend.api.position.list +
                "/" +
                from +
                "/" +
                size,
            method: "get",
            responseHandlerOptions: responseHandlerOptions
        });
    }

    /**
     * Pagination controlled server request.
     * @param {Integer} from
     * @param {Integer} size
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse doc
     */
    getPaginationOwnPositions(
        component,
        from,
        size,
        responseHandlerOptions = {}
    ) {
        makeGenericRequest({
            restricted: true,
            component: component,
            url:
                backend.DOMAIN +
                backend.api.position.listOwn +
                "/" +
                from +
                "/" +
                size,
            method: "get",
            responseHandlerOptions: responseHandlerOptions
        });
    }

    /**
     * Send request to server get position API
     * @param {React.component} component
     * @param {String} title title of the position
     * @param {String} company_name name of the company owning the position
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    getPosition(component, title, company_name, responseHandlerOptions = {}) {
        component.context.getAuthManager().loggedRestrictionAPI(
            "PositionManager getPosition",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url:
                        backend.DOMAIN +
                        backend.api.position.get +
                        "/" +
                        encodeURIComponent(title) +
                        "/" +
                        encodeURIComponent(company_name),
                    method: "get",
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    /**
     * Send request to server get own position API
     * @param {React.component} component
     * @param {String} position_id id of the position
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    getOwnPosition(component, position_id, responseHandlerOptions = {}) {
        component.context.getAuthManager().loggedRestrictionAPI(
            "PositionManager getOwnPosition",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url:
                        backend.DOMAIN +
                        backend.api.position.getOwn +
                        "/" +
                        position_id,
                    method: "get",
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    /**
     * Send request to server set position API
     * @param {React.component} component
     * @param {Object} body request form params
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    setPosition(component, body, responseHandlerOptions = {}) {
        component.context.getAuthManager().loggedRestrictionAPI(
            "PositionManager setPosition",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url: backend.DOMAIN + backend.api.position.set,
                    method: "post",
                    body: prepareBody(body),
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    /**
     * Send request to server get own position API
     * @param {React.component} component
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    getOwnPositionsCount(component, responseHandlerOptions = {}) {
        component.context.getAuthManager().loggedRestrictionAPI(
            "PositionManager getOwnPositionsCount",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url:
                        backend.DOMAIN +
                        backend.api.position.getOwnPositionsCount,
                    method: "get",
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    /**
     * Send request to server get delete own position API
     * @param {React.component} component
     * @param {String} position_id id of the position
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    deleteOwnPosition(component, position_id, responseHandlerOptions = {}) {
        component.context.getAuthManager().loggedRestrictionAPI(
            "PositionManager getOwnPositionsCount",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url:
                        backend.DOMAIN +
                        backend.api.position.delete +
                        "/" +
                        position_id,
                    method: "delete",
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    /**
     * Send request to server get (position) dependencies API
     * @param {React.component} component
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    getDependencies(component, responseHandlerOptions = {}) {
        const manager = this;
        component.context.getAuthManager().loggedRestrictionAPI(
            "PositionManager getDependencies",
            component,
            () => {
                if (manager._dependenciesLoaded) {
                    ResponseHandler.parse(
                        component,
                        manager._dependenciesResponse,
                        responseHandlerOptions
                    );
                } else if (manager._dependenciesLoading) {
                    manager._dependenciesWaitingRequests.push({
                        component: component,
                        options: responseHandlerOptions
                    });
                } else {
                    manager._dependenciesLoading = true;
                    manager._dependenciesWaitingRequests.push({
                        component: component,
                        options: responseHandlerOptions
                    });
                    makeGenericRequest(
                        {
                            restricted: true,
                            component: component,
                            url:
                                backend.DOMAIN +
                                backend.api.position.getDependencies,
                            method: "get"
                        },
                        response => {
                            manager._dependenciesResponse = response.data;
                            // set flag that request was made
                            manager._dependenciesLoaded = true;
                            manager._dependenciesLoading = false;
                            if (
                                response.data.successful !== true &&
                                response.data.successful !== "true"
                            ) {
                                console.error(
                                    "PositionManager getDependencies Response Error"
                                );
                                console.error(response);
                            }
                            manager._dependenciesWaitingRequests.forEach(
                                function(request) {
                                    if (isObject(request)) {
                                        ResponseHandler.parse(
                                            request.component,
                                            manager._dependenciesResponse,
                                            request.options
                                        );
                                    }
                                }
                            );
                            manager._dependenciesWaitingRequests = [];
                        }
                    );
                }
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    /**
     * Send request to server has applied API
     * @param {React.component} component
     * @param {String} position_id id of the position
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    hasApplied(component, position_id, responseHandlerOptions = {}) {
        component.context.getAuthManager().loggedRestrictionAPI(
            "PositionManager hasApplied",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url:
                        backend.DOMAIN +
                        backend.api.position.hasApplied +
                        "/" +
                        position_id,
                    method: "get",
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    /**
     * Send request to server has applied API
     * @param {React.component} component
     * @param {String} position_id id of the position
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    apply(component, position_id, responseHandlerOptions = {}) {
        component.context.getAuthManager().loggedRestrictionAPI(
            "PositionManager setApply",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url:
                        backend.DOMAIN +
                        backend.api.position.setApply +
                        "/" +
                        position_id,
                    method: "post",
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    /**
     * Send request to server has applied API
     * @param {React.component} component
     * @param {Integer} from
     * @param {Integer} size
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    getPaginationApplicationPositionList(
        component,
        from,
        size,
        responseHandlerOptions = {}
    ) {
        makeGenericRequest({
            restricted: true,
            component: component,
            url:
                backend.DOMAIN +
                backend.api.position.candidates +
                "/" +
                component.state.position.position_id +
                "/" +
                from +
                "/" +
                size,
            method: "get",
            responseHandlerOptions: responseHandlerOptions
        });
    }

    /**
     * Send request to server has applied API
     * @param {React.component} component
     * @param {Integer} from
     * @param {Integer} size
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    getPaginationApplicationCompanyList(
        component,
        from,
        size,
        responseHandlerOptions = {}
    ) {
        makeGenericRequest({
            restricted: true,
            component: component,
            url:
                backend.DOMAIN +
                backend.api.company.candidates +
                "/" +
                from +
                "/" +
                size,
            method: "get",
            responseHandlerOptions: responseHandlerOptions
        });
    }
}

class FileManager {
    constructor(authManager) {
        this.authManager = authManager;
    }
    /**
     * Get files based on authorization API
     * @param {React.component} component
     * @param {String} fileId
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    getAuthorizedFile(component, fileId, responseHandlerOptions = {}) {
        component.context.getAuthManager().loggedRestrictionAPI(
            "FileManager getAuthorizedFile",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url: `${backend.DOMAIN}${backend.api.getAuthorizedFile}${fileId}`,
                    method: "get",
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }
    /**
     * Upload user file API
     * @param {React.component} component
     * @param {Object} file
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    uploadFile(component, file, responseHandlerOptions = {}) {
        component.context
            .getAuthManager()
            .loggedRestrictionAPI("FileManager uploadFile", component, () => {
                let endpoint = "";
                switch (file.type) {
                    case fileType.logo:
                        endpoint = backend.api.company.uploadLogo;
                        break;
                    case fileType.cv:
                        endpoint = backend.api.jobseeker.uploadCv;
                        break;
                    default:
                        break;
                }
                delete file.type;
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url: `${backend.DOMAIN}${endpoint}`,
                    method: "put",
                    body: prepareBody(file),
                    responseHandlerOptions: responseHandlerOptions
                });
            });
    }

    removeCompanyLogo(component, responseHandlerOptions = {}) {
        component.context
            .getAuthManager()
            .loggedRestrictionAPI(
                "FileManager removeCompanyLogo",
                component,
                () => {
                    makeGenericRequest({
                        restricted: true,
                        component: component,
                        url: `${backend.DOMAIN}${backend.api.company.removeLogo}`,
                        method: "delete",
                        responseHandlerOptions: responseHandlerOptions
                    });
                }
            );
    }
}

class EducationManager {
    constructor(authManager) {
        this.authManager = authManager;
    }

    /**
     * Get education levels from API
     * @param {React.component} component
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    getEducationLevels(component, responseHandlerOptions = {}) {
        component.context.getAuthManager().loggedRestrictionAPI(
            "EducationManager getEducationLevels",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url: `${backend.DOMAIN}${backend.api.education.getEducationLevels}`,
                    method: "get",
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }

    getSchools(component, responseHandlerOptions = {}) {
        component.context.getAuthManager().loggedRestrictionAPI(
            "EducationManager getSchools",
            component,
            () => {
                makeGenericRequest({
                    restricted: true,
                    component: component,
                    url: `${backend.DOMAIN}${backend.api.education.getSchools}`,
                    method: "get",
                    responseHandlerOptions: responseHandlerOptions
                });
            },
            !("onError" in responseHandlerOptions) ||
                !("toast" in responseHandlerOptions.onError) ||
                responseHandlerOptions.onError.toast
        );
    }
}

class EmployerManager {
    constructor(authManager) {
        this.authManager = authManager;
    }

    /**
     * Get approved employers from API
     * @param {React.component} component
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    getApprovedEmployers(component, responseHandlerOptions = {}) {
        component.context
            .getAuthManager()
            .loggedRestrictionAPI(
                "EmployerManager getApprovedEmployers",
                component,
                () => {
                    makeGenericRequest({
                        restricted: true,
                        component: component,
                        url: `${backend.DOMAIN}${backend.api.employment.getApprovedEmployers}`,
                        method: "get",
                        responseHandlerOptions: responseHandlerOptions
                    });
                }
            );
    }

    /**
     * Create new employer API
     * @param {React.component} component
     * @param {String} employerName
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    addEmployer(component, employerName, responseHandlerOptions = {}) {
        component.context
            .getAuthManager()
            .loggedRestrictionAPI(
                "EmployerManager addEmployer",
                component,
                () => {
                    makeGenericRequest({
                        restricted: true,
                        component: component,
                        url: `${backend.DOMAIN}${backend.api.employment.addEmployer}`,
                        method: "post",
                        body: prepareBody({ employer: employerName }),
                        responseHandlerOptions: responseHandlerOptions
                    });
                }
            );
    }

    /**
     * Get approved employers from API
     * @param {React.component} component
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    getEmployments(component, responseHandlerOptions = {}) {
        component.context
            .getAuthManager()
            .loggedRestrictionAPI(
                "EmployerManager getEmployments",
                component,
                () => {
                    makeGenericRequest({
                        restricted: true,
                        component: component,
                        url: `${backend.DOMAIN}${backend.api.employment.getEmployments}`,
                        method: "get",
                        responseHandlerOptions: responseHandlerOptions
                    });
                }
            );
    }
    /**
     * Store employment history API
     * @param {React.component} component
     * @param {Array.<Object>} employments
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    storeEmployments(component, employments, responseHandlerOptions = {}) {
        component.context
            .getAuthManager()
            .loggedRestrictionAPI(
                "EmployerManager storeEmployment",
                component,
                () => {
                    makeGenericRequest({
                        restricted: true,
                        component: component,
                        url: `${backend.DOMAIN}${backend.api.employment.storeEmployment}`,
                        method: "post",
                        body: prepareBody({
                            employments: JSON.stringify(employments)
                        }),
                        responseHandlerOptions: responseHandlerOptions
                    });
                }
            );
    }
}

class AuthManager {
    /**
     * Internal usage only, user methods.
     */
    _loggedUser = {};
    _loggedUserWaitingRequests = [];
    _loggedUserResponse = undefined;
    _loggedUserLoaded = false;
    _loggedUserLoading = false;

    /**
     * Check if user is logged
     */
    isLogged() {
        return (
            sessionStorage.getItem("token") !== null ||
            getCookie(cookies.localSessionCookie) !== ""
        );
    }

    /**
     * Send request to server to get logged user API
     * @param {React.component} component
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    getLoggedUser(component, responseHandlerOptions = {}) {
        // ensure option has onError
        ensureObjectHas(responseHandlerOptions, { onError: { toast: false } });
        if (getCookie(cookies.cookieConcent) !== "dismiss") {
            setCookie(cookies.localSessionCookie, null, 0);
        }
        // disable toast for onError
        responseHandlerOptions.onError.toast = false;
        ResponseHandler.addOptionsCallback(
            responseHandlerOptions,
            "onError",
            (data, code) => {
                if ([9000, 9001, 9002, 9003].indexOf(parseInt(code)) !== -1) {
                    console.log("removing token");
                    sessionStorage.removeItem("token");
                    setCookie(cookies.localSessionCookie, null, 0);
                }
            }
        );
        const AuthManager = component.context.getAuthManager();
        if (!AuthManager.isLogged()) {
            ResponseHandler.parse(
                component,
                {
                    successful: false,
                    code: "UserNotLoggedIn",
                    message: "User is not logged."
                },
                responseHandlerOptions
            );
        } else if (AuthManager._loggedUserLoaded) {
            ResponseHandler.parse(
                component,
                AuthManager._loggedUserResponse.data,
                responseHandlerOptions
            );
        } else if (AuthManager._loggedUserLoading) {
            AuthManager._loggedUserWaitingRequests.push({
                component: component,
                options: responseHandlerOptions
            });
        } else {
            AuthManager._loggedUserLoading = true;
            AuthManager._loggedUserWaitingRequests.push({
                component: component,
                options: responseHandlerOptions
            });
            makeGenericRequest(
                {
                    restricted: true,
                    component: component,
                    url: backend.DOMAIN + backend.api.getLoggedUser,
                    method: "get"
                },
                response => {
                    AuthManager._loggedUserResponse = response;
                    if (
                        response.data.successful === true ||
                        response.data.successful === "true"
                    ) {
                        if (
                            (getCookie(cookies.cookieConcent) === "dismiss" &&
                                getCookie(cookies.localSessionCookie) === "") ||
                            getCookie(cookies.localSessionCookie) !==
                                response.data.message.token
                        ) {
                            setCookie("session", response.data.message.token);
                        }
                        AuthManager._loggedUser = response.data.message;
                    } else {
                        console.info("User is not logged.");
                    }
                    // set flag that request was made
                    AuthManager._loggedUserLoaded = true;
                    // execute waiting callbacks
                    AuthManager._loggedUserWaitingRequests.forEach(request => {
                        if (request !== undefined) {
                            ResponseHandler.parse(
                                request.component,
                                AuthManager._loggedUserResponse.data,
                                request.options
                            );
                        }
                    });
                    AuthManager._loggedUserWaitingRequests = [];
                }
            );
        }
    }

    /**
     * Send request to server confirm registration API
     * @param {React.component} component
     * @param {String} activationKey from database user table
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    confirmRegistration(component, activationKey, responseHandlerOptions = {}) {
        makeGenericRequest({
            component: component,
            url:
                backend.DOMAIN +
                backend.api.confirmRegistration +
                "?activation_code=" +
                activationKey,
            method: "get",
            responseHandlerOptions: responseHandlerOptions
        });
    }

    /**
     * Send request to server register API
     * @param {React.component} component
     * @param {String} usertype @see config usertype
     * @param {Object} body request form params
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    register(component, usertype, body, responseHandlerOptions = {}) {
        makeGenericRequest({
            component: component,
            url: backend.DOMAIN + backend.api.registration + usertype,
            method: "post",
            body: prepareBody(body),
            responseHandlerOptions: responseHandlerOptions
        });
    }

    /**
     * Send request to server register API
     * @param {React.component} component
     * @param {Object} body request form params
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    login(component, body, responseHandlerOptions = {}) {
        ResponseHandler.addOptionsCallback(
            responseHandlerOptions,
            "onSuccess",
            data => {
                sessionStorage.setItem("token", data);
                if (getCookie(cookies.cookieConcent) === "dismiss") {
                    setCookie(cookies.localSessionCookie, data);
                }
            }
        );
        ResponseHandler.addOptionsCallback(
            responseHandlerOptions,
            "onError",
            data => {
                sessionStorage.removeItem("token");
                setCookie(cookies.localSessionCookie, null, 0);
            }
        );
        makeGenericRequest({
            component: component,
            url: backend.DOMAIN + backend.api.login,
            method: "post",
            body: prepareBody(body),
            responseHandlerOptions: responseHandlerOptions
        });
    }

    /**
     * Logout the user request
     * @param {String} pathname after logout sets window.location.pathname
     */
    logout(pathname) {
        axios({
            url: backend.DOMAIN + backend.api.logout,
            headers: {
                Authorization: "Bearer " + sessionStorage.getItem("token"),
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": `${frontend.DOMAIN}`
            },
            method: "get",
            withCredentials: true
        })
            .then(() => {
                sessionStorage.removeItem("token");
                setCookie(cookies.localSessionCookie, null, 0);
                if (
                    pathname !== undefined &&
                    typeof pathname === typeof "string"
                ) {
                    window.location.pathname = pathname;
                } else {
                    window.location.pathname = frontend.route.home;
                }
            })
            .catch(function(error) {
                console.error(error);
            });
    }

    /**
     * Send request to server register API
     * @param {React.component} component
     * @param {Object} body request form params
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    accountRecoverySendEmail(component, body, responseHandlerOptions) {
        makeGenericRequest({
            component: component,
            url: backend.DOMAIN + backend.api.accountRecoverySendEmail,
            method: "post",
            body: prepareBody(body),
            responseHandlerOptions: responseHandlerOptions
        });
    }

    /**
     * Send request to server register API
     * @param {React.component} component
     * @param {Object} body request form params
     * @param {Object} responseHandlerOptions @see ResponseHandler.parse options params
     */
    accountRecoveryResetPassword = (
        component,
        body,
        responseHandlerOptions
    ) => {
        makeGenericRequest({
            component: component,
            url: backend.DOMAIN + backend.api.accountRecoveryResetPassword,
            method: "post",
            body: prepareBody(body),
            responseHandlerOptions: responseHandlerOptions
        });
    };

    /**
     * Check for page restrictions based on provided options.
     * @param {Object} options 
        logged: boolean, defaut: undefined
        forLoggedUsertype: array (@see default_config usertype), default: []
        notForLoggedUsertype: array (@see default_config usertype), default: [],
        notCompanyType: string (@see default_config companytype), default: undefined
     */
    pageRestriction(
        options = {
            logged: undefined,
            forLoggedUsertype: [],
            notForLoggedUsertype: [],
            notCompanyType: undefined
        },
        callback = undefined
    ) {
        const {
            logged,
            forLoggedUsertype,
            notForLoggedUsertype,
            notCompanyType
        } = options;
        if (logged !== undefined && this.isLogged() !== logged) {
            if (callback === undefined) {
                console.log("pageRestriction user not logged");
                localStorage.setItem('redirect', window.location.pathname);
                window.location.pathname = frontend.route.error;
                return;
            } else {
                callback();
            }
        }
        if (
            forLoggedUsertype !== undefined &&
            forLoggedUsertype.length > 0 &&
            this._loggedUser.user_type !== "not logged" &&
            forLoggedUsertype.indexOf(this._loggedUser.user_type) <= -1
        ) {
            if (callback === undefined) {
                console.log("pageRestriction user not in usertype ");
                console.log(forLoggedUsertype);
                window.location.pathname = frontend.route.error;
                return;
            } else {
                callback();
            }
        }
        if (
            notCompanyType !== undefined &&
            notCompanyType === this._loggedUser.company_type
        ) {
            if (callback === undefined) {
                console.log(
                    "pageRestriction user is company while notCompanyType activw"
                );
                window.location.pathname = frontend.route.error;
                return;
            } else {
                callback();
            }
        }
        if (
            notForLoggedUsertype !== undefined &&
            notForLoggedUsertype.length > 0 &&
            this._loggedUser.user_type !== "not logged" &&
            notForLoggedUsertype.indexOf(this._loggedUser.user_type) > -1
        ) {
            if (callback === undefined) {
                console.log("pageRestriction user is in usertype ");
                console.log(notForLoggedUsertype);
                window.location.pathname = frontend.route.error;
                return;
            } else {
                callback();
            }
        }
    }

    /**
     * execute function if user is logged
     * @param {string} from debug string, not required, but very useful
     * @param {Function} isLoggedCallback function to call if user is logged
     */
    loggedRestrictionAPI(from, component, isLoggedCallback, toast = true) {
        if (!component.context.getAuthManager().isLogged()) {
            console.error(`${from} loggedRestrictionAPI - user is not logged`);
            ResponseHandler.parse(
                component,
                {
                    successful: false,
                    code: "UserNotLoggedIn",
                    message: `${from} loggedRestrictionAPI - user is not logged`
                },
                { onError: { toast: toast } }
            );
        } else {
            isLoggedCallback();
        }
    }
}

const manager = React.createContext(new Manager(new AuthManager()));

export default manager;
