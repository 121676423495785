import React, { Component } from "react";
import { withRouter } from "react-router";

import manager from "../../Manager";
import { frontend, usertype } from "../../config";
import { setTitle } from "../../pageOptions";
import Save from "./positions/Save";
import View from "./positions/View";
import List from "./positions/List";
import CompanyPositions from "./positions/CompanyPositions";
import ToastHandler from "../../utils/ToastHandler";
import Translation from "../../utils/Translation";
import FreeCompanyError from "./FreeCompanyError";

class Positions extends Component {
  static contextType = manager;

  modes = {
    view: "view",
    edit: "edit",
    list: "list",
    own: "own",
    add: "add",
  };

  usertype = "";
  number = 0;

  state = {
    mode: "",
    previousMode: "",
    user: {},
  };

  getDefaultMode(userType) {
    switch (userType) {
      case usertype.company:
        return this.modes.own;
      default:
        return this.modes.list;
    }
  }

  onBackButtonEvent(that) {
    return (e) => {
      if ("state" in e && e.state != null && "mode" in e.state) {
        e.preventDefault();
        e.stopPropagation();
        window.addEventListener("popstate", that.onBackButtonEvent(that));
        that.setState({ ...that.state, mode: e.state.mode });
      }
    };
  }

  componentDidMount() {
    if (window.history.state === null) {
      window.history.replaceState(
        { mode: this.modes.list, url: frontend.route.position.main },
        "List positions",
        frontend.route.position.main
      );
    }
    window.addEventListener("popstate", this.onBackButtonEvent(this));
  }

  getGoToAddClick() {
    let that = this;
    return () => {
      console.log(that.number);
      if (
        that.number &&
        typeof that.number === "number" &&
        that.number > 0 &&
        that.state.user.company_type === "free"
      ) {
        ToastHandler.showError(<FreeCompanyError />);
      } else {
        that.setState({
          ...that.state,
          mode: that.modes.add,
        });
        this.props.history.push(frontend.route.position.add);
        window.history.pushState(
          { mode: this.modes.add, url: frontend.route.position.add },
          "Add new position",
          frontend.route.position.add
        );
        setTitle(
          Translation.translateStaticText("addPositionTitle", "Add Position")
        );
      }
    };
  }

  getDeleteClick() {
    let that = this;
    return (position_id, callback) => {
      that.context.getPositionManager().deleteOwnPosition(that, position_id, {
        onSuccess: {
          callback: (data) => {
            that.number--;
            callback(data);
            that.setState({
              ...that.state,
              mode: that.modes.own,
            });
          },
          toast: false,
        },
      });
    };
  }

  getGoToEditClick() {
    let that = this;
    return (position_id) => {
      that.setState({
        ...that.state,
        previousMode: that.state.mode,
        mode: that.modes.edit,
      });
      this.props.history.push(frontend.route.position.edit + "/" + position_id);
      window.history.pushState(
        {
          mode: this.modes.edit,
          url: frontend.route.position.edit + "/" + position_id,
        },
        "Edit position",
        frontend.route.position.edit + "/" + position_id
      );
      setTitle(
        Translation.translateStaticText("editPositionTitle", "Edit Position")
      );
    };
  }

  getGoToViewClick() {
    let that = this;
    return (company_name, title) => {
      that.setState({
        ...that.state,
        previousMode: that.state.mode,
        mode: that.modes.view,
      });
      this.props.history.push(
        frontend.route.position.main + "/view/" + company_name + "/" + title
      );
      window.history.pushState(
        {
          mode: this.modes.view,
          url:
            frontend.route.position.main +
            "/view/" +
            company_name +
            "/" +
            title,
        },
        "View position",
        frontend.route.position.main + "/view/" + company_name + "/" + title
      );
      setTitle(
        Translation.translateStaticText("viewPositionTitle", "View Position")
      );
    };
  }

  getGoToListClick() {
    let that = this;
    return () => {
      that.setState({
        ...that.state,
        mode: that.modes.list,
      });
      this.props.history.push(frontend.route.position.main);
      window.history.pushState(
        { mode: this.modes.list, url: frontend.route.position.main },
        "List positions",
        frontend.route.position.main
      );
      setTitle(
        Translation.translateStaticText("allPositionTitle", "All Positions")
      );
    };
  }

  getGoToOwnListClick() {
    let that = this;
    return (options = { addedNew: false }) => {
      if ("addedNew" in options && options.addedNew) {
        that.number++;
        ToastHandler.showSuccess(
          Translation.translateMessage("Saved", "Saved")
        );
      }
      that.setState({
        ...that.state,
        mode: that.modes.own,
      });
      this.props.history.push(frontend.route.position.own);
      window.history.pushState(
        { mode: this.modes.own, url: frontend.route.position.own },
        "List own position",
        frontend.route.position.own
      );
      setTitle(
        Translation.translateStaticText("ownPositionTitle", "Own Positions")
      );
    };
  }

  updateNumberOfOwnPositions() {
    let that = this;
    if (this.usertype === usertype.company) {
      that.context.getPositionManager().getOwnPositionsCount(this, {
        onSuccess: {
          toast: false,
          callback: (data) => {
            that.number = data;
          },
        },
      });
    }
  }

  componentWillMount() {
    this.context.getAuthManager().pageRestriction({ logged: true });
    let that = this;
    this.context.getAuthManager().getLoggedUser(that, {
      onSuccess: {
        callback: (user) => {
          that.usertype = user.user_type;
          const path = that.props.location.pathname.split("/");
          that.updateNumberOfOwnPositions();
          if (
            that.state.mode !== that.modes.edit &&
            path.indexOf(that.modes.edit) > -1
          ) {
            if (that.usertype !== usertype.company) {
              console.log("positions - can not edit");
              window.location.pathname = frontend.route.error;
              return;
            }
            that.setState({ ...that.state, user: user, mode: that.modes.edit });
            setTitle(
              Translation.translateStaticText(
                "editPositionTitle",
                "Edit Position"
              )
            );
          } else if (
            that.state.mode !== that.modes.view &&
            path.indexOf(that.modes.view) > -1
          ) {
            that.setState({ ...that.state, user: user, mode: that.modes.view });
            setTitle(
              Translation.translateStaticText(
                "viewPositionTitle",
                "View Position"
              )
            );
          } else if (
            that.state.mode !== that.modes.add &&
            path.indexOf(that.modes.add) > -1
          ) {
            if (that.usertype !== usertype.company) {
              console.log("positions - can not add");
              window.location.pathname = frontend.route.error;
              return;
            }
            that.setState({ ...that.state, user: user, mode: that.modes.add });
            setTitle(
              Translation.translateStaticText(
                "addPositionTitle",
                "Add Position"
              )
            );
          } else if (
            that.state.mode !== that.modes.list &&
            path.indexOf(that.modes.list) > -1
          ) {
            if (
              that.usertype !== usertype.admin &&
              that.usertype !== usertype.team
            ) {
              console.log("positions - can not list");
              window.location.pathname = frontend.route.error;
              return;
            }
            that.setState({ ...that.state, user: user, mode: that.modes.list });
            setTitle(
              Translation.translateStaticText(
                "allPositionTitle",
                "All Positions"
              )
            );
          } else if (
            that.state.mode !== that.modes.own &&
            path.indexOf(that.modes.own) > -1
          ) {
            if (that.usertype !== usertype.company) {
              console.log("positions - can not own");
              window.location.pathname = frontend.route.error;
              return;
            }
            that.setState({ ...that.state, user: user, mode: that.modes.own });
            setTitle(
              Translation.translateStaticText(
                "ownPositionTitle",
                "Own Positions"
              )
            );
          } else {
            console.log("unable to determine mode");
            that.setState({
              ...that.state,
              user: user,
              mode: that.getDefaultMode(that.usertype),
            });
            setTitle(
              Translation.translateStaticText(
                "allPositionTitle",
                "All Positions"
              )
            );
          }
        },
      },
    });
  }

  render() {
    const {
      location,
      match: { params },
    } = this.props;
    const path = location.pathname.split("/");
    const own = (
      <CompanyPositions
        back={this.getGoToListClick()}
        goToAdd={this.getGoToAddClick()}
        goToEdit={this.getGoToEditClick()}
        goToView={this.getGoToViewClick()}
        deletePosition={this.getDeleteClick()}
      />
    );
    let defaultComponent = "";
    switch (this.state.mode) {
      case this.modes.view:
        if (
          params &&
          params.hasOwnProperty("company") &&
          params.hasOwnProperty("position")
        ) {
          return (
            <View
              company={params.company}
              title={params.position}
              goToView={this.getGoToViewClick()}
              back={
                this.state.previousMode !== this.modes.own
                  ? this.getGoToListClick()
                  : this.getGoToOwnListClick()
              }
            />
          );
        } else {
          return defaultComponent;
        }
      case this.modes.edit:
        if (
          path.indexOf("edit") > -1 &&
          path.length > path.indexOf("edit") + 1
        ) {
          return (
            <Save
              mode="edit"
              position_id={path[path.indexOf("edit") + 1]}
              back={
                this.state.previousMode !== this.modes.own
                  ? this.getGoToListClick()
                  : this.getGoToOwnListClick()
              }
            />
          );
        } else {
          return defaultComponent;
        }
      case this.modes.own:
        return own;
      case this.modes.add:
        return <Save mode="add" back={this.getGoToOwnListClick()} />;
      case this.modes.list:
        return (
          <List
            goToAdd={this.getGoToAddClick()}
            goToOwn={this.getGoToOwnListClick()}
            goToView={this.getGoToViewClick()}
            goToEdit={this.getGoToEditClick()}
            deletePosition={this.getDeleteClick()}
          />
        );
      default:
        return defaultComponent;
    }
  }
}

export default withRouter(Positions);
