import React, { Component } from "react";
import { Redirect } from "react-router";
import Button from "@material-ui/core/Button";

import FullProfile from "./FullProfile";
import LimitedProfile from "./LimitedProfile";
import { accessibility, frontend } from "../../../config";
import manager from "../../../Manager";
import ToastHandler from "../../../utils/ToastHandler";
import Translation from "../../../utils/Translation";

class ProfileView extends Component {
	static contextType = manager;

	static defaultProps = {
		visibility: "",
		cv: {},
		skills: {},
		jobseeker: {},
		employments: [],
		children: [] //If some additional UI for higher level compoenents needs to be passed (return button when previewing profile as jobseeker)
	};

	logged_company_id = undefined;
	isCandidate = false;
	hasContactEntity = false;

	componentWillMount() {
		const component = this;
		component.context.getAuthManager().getLoggedUser(component, {
			onFinish: {
				callback: userDataReturn => {
					component.logged_user_id = userDataReturn.user_id;
					component.context
						.getCompanyManager()
						.getLoggedCompany(component, {
							onFinish: {
								callback: companyDataReturn => {
									if (companyDataReturn.company_id) {
										component.logged_company_id =
											companyDataReturn.company_id;
									}
									component.context
										.getCompanyManager()
										.isJobseekerACandidate(
											component,
											this.props.jobseeker.jobseeker_id,
											{
												onFinish: {
													callback: dataReturn => {
														if (
															dataReturn.isCandidate
														) {
															component.isCandidate = /true/i.test(
																dataReturn.isCandidate
															);
														}
														component.context
															.getWSManager()
															.hasContactEntity(
																component,
																this.props
																	.jobseeker
																	.jobseeker_id,
																{
																	onSuccess: {
																		toast: false,
																		callback: dataReturn => {
																			component.hasContactEntity =
																				dataReturn.successful &&
																				/true/i.test(
																					dataReturn.message
																				);
																		}
																	},
																	onError: {
																		toast: false
																	}
																}
															);
														component.setState({
															...component.state
														});
													}
												},
												onError: {
													toast: false
												}
											}
										);
								}
							},
							onError: {
								toast: false
							}
						});
				}
			}
		});
	}

	renderer() {
		const {
			jobseeker,
			cv,
			skills,
			visibility,
			education,
			employments
		} = this.props;
		switch (visibility) {
			case accessibility.full:
				return (
					<FullProfile
						jobseeker={jobseeker}
						cv={cv}
						skills={skills}
						education={education}
						employments={employments}
					>
						{this.props.children}
					</FullProfile>
				);
			case accessibility.limited:
				return (
					<LimitedProfile skills={skills}>
						{this.props.children}
					</LimitedProfile>
				);
			default:
				return (
					<Redirect to={frontend.route.error + "/noAccessToUser"} />
				);
		}
	}

	requestCorrespondence() {
		if (!this.logged_company_id) {
			ToastHandler.showInfo(
				Translation.translateErrorMessage(
					"onlyCompaniesRequestCorrespondance",
					"Only companies can request correspondence with jobseekers"
				)
			);
			return;
		} else if (!this.isCandidate) {
			ToastHandler.showInfo(
				Translation.translateErrorMessage(
					"onlyCandidateJobseekerRequestCorrespondance",
					"Correspondence requests can only be done to candidate jobseekers."
				)
			);
			return;
		} else if (this.hasContactEntity) {
			ToastHandler.showInfo(
				Translation.translateErrorMessage(
					"correspondenceAlreadyAvailable",
					"Contact request already sent or user has blocked it."
				)
			);
			return;
		} else if (
			this.props.jobseeker.jobseeker_id === this.logged_company_id
		) {
			ToastHandler.showInfo(
				Translation.translateErrorMessage(
					"cannotSelfCorrespondance",
					"Can not request contact with yourself."
				)
			);
			return;
		}
		//TODO: Modal with choices for employes to make contact with
		//for now there is only one, so no problem
		const component = this;
		this.context.getWSManager().requestContact(
			component,
			{ user: this.props.jobseeker.jobseeker_id, isCompany: false },
			{
				onSuccess: {
					message: Translation.translateMessage(
						"contactRequestSent",
						"Request sent"
					)
				}
			}
		);
	}

	render() {
		let requestcorispondanceButton = undefined;
		if (this.logged_company_id) {
			requestcorispondanceButton = (
				<Button
					onClick={() => {
						this.requestCorrespondence();
					}}
					className={"btn btn-secondary btn-form display-4"}
				>
					{Translation.translateField(
						"requestCorrespondenceButton",
						"Request correspondence"
					)}
				</Button>
			);
		}
		return (
			<div className="row">
				{requestcorispondanceButton}
				{this.renderer()}
			</div>
		);
	}
}

export default ProfileView;
