import BaseBoard from "./BaseBoard";
import manager from "../../../Manager";
import Translation from '../../../utils/Translation';

class SystemMessageBoard extends BaseBoard {
    static contextType = manager;

    componentDidMount() {
        let messages = [];
        messages.push({
            message: Translation.translateStaticText(
                "systemInitMessage",
                `Hello, you may add contacts by visiting their public profile page and clicking "Request correspondence", after which the contact will be visible on the left side. When and if the user accepts your request, you will be able to use the live chat system. This is a system mesage, you cannot reply!`
            ),
            date: new Date()
        });
        this.setState({ ...this.state, messages: messages }, () => {
            super.componentDidMount(messages);
            this.context
                .getWSManager()
                .getWS()
                .getSystemMessages();
        });
    }
}

export default SystemMessageBoard;
