import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import { withStyles } from "@material-ui/core/styles";

import { setTitle } from "../../../pageOptions";
import Page from "../../Page";
import manager from "../../../Manager";
import { frontend } from "../../../config";
import { Redirect } from "react-router-dom";
import { styles } from "../../../defaultProps";
import { Button } from "@material-ui/core";
import ToastHandler from "../../../utils/ToastHandler";
import { fileURLFactory } from "../../../utils/Images";
import Translation from "../../../utils/Translation";

class PublicCompanyProfilePage extends Component {
	static contextType = manager;
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			error: false,
			ceo: null,
			email: null,
			name: null,
			description: null,
			website: null,
			logo: null
		};
	}

	submited = false;
	hasContactEntity = false;

	rememberData = dataReturn => {
		const {
			company_name,
			company_email,
			ceo,
			description,
			website,
			logo
		} = dataReturn;
		this.setState({
			...this.state,
			name: company_name,
			email: company_email,
			ceo: ceo,
			description: description,
			website: website,
			logo: logo,
			loading: false
		});
	};

	componentDidMount() {
		let component = this;
		//get company data
		this.context
			.getCompanyManager()
			.getCompanyData(component, component.props.match.params.name, {
				onSuccess: {
					toast: false,
					callback: dataReturn => {
						component.rememberData(dataReturn);
						component.company_id = dataReturn.company_id;
						this.context
							.getWSManager()
							.hasContactEntity(component, component.company_id, {
								onError: {
									toast: false
								},
								onSuccess: {
									toast: false,
									callback: dataReturn => {
										component.hasContactEntity =
											dataReturn.successful &&
											/true/i.test(dataReturn.message);
									}
								}
							});
					}
				},
				onError: {
					callback: () => {
						component.setState({ ...component.state, error: true });
					},
					toast: false
				}
			});
		this.context.getCompanyManager().getLoggedCompany(component, {
			onSuccess: { toast: false },
			onError: {
				toast: false
			},
			onFinish: {
				toast: false,
				callback: dataReturn => {
					component.logged = component.context
						.getAuthManager()
						.isLogged();
					if (component.logged && dataReturn.company_id) {
						component.logged_company_id = dataReturn.company_id;
					}
					component.setState({ ...component.state });
				}
			}
		});
		setTitle(
			Translation.translateStaticText(
				"companyProfileTitle",
				"Company Profile"
			)
		);
	}

	requestCorrespondence() {
		if (this.company_id === this.logged_company_id) {
			ToastHandler.showInfo(
				Translation.translateErrorMessage(
					"cannotSelfCorrespondance",
					"Can not request correspondence with yourself."
				)
			);
			return;
		} else if (this.logged_company_id) {
			ToastHandler.showInfo(
				Translation.translateErrorMessage(
					"cannotCorrespondWithAnotherCompany",
					"Can not request correspondence with another company."
				)
			);
			return;
		} else if (this.hasContactEntity) {
			ToastHandler.showInfo(
				Translation.translateErrorMessage(
					"correspondenceAlreadyAvailable",
					"Contact request already sent or user has blocked it."
				)
			);
			return;
		}
		//TODO: Modal with choices for employes to make contact with
		//for now there is only one, so no problem
		const component = this;
		this.context.getWSManager().requestContact(
			component,
			{ user: this.company_id, isCompany: true },
			{
				onSuccess: {
					message: Translation.translateMessage(
						"contactRequestSent",
						"Request sent"
					)
				}
			}
		);
	}

	logged = false;
	logged_company_id = undefined;
	company_id = undefined;

	renderer = () => {
		const {
			error,
			loading,
			name,
			email,
			ceo,
			description,
			website,
			logo
		} = this.state;
		const { classes } = this.props;
		const { route } = frontend;
		if (error === true) {
			return <Redirect to={route.error} />;
		}
		//data is loaded
		else if (loading === false && error === false) {
			let requestcorrespondenceButton = undefined;
			if (this.logged) {
				requestcorrespondenceButton = (
					<Button
						onClick={() => {
							this.requestCorrespondence();
						}}
						className={"btn btn-secondary btn-form display-4"}
					>
						{Translation.translateField(
							"requestCorrespondenceButton",
							"Request correspondence"
						)}
					</Button>
				);
			}
			return (
				<section
					className="cid-rgbYhVM41a mbr-fullscreen mbr-parallax-background flex-start"
					id="header15-b"
				>
					<div
						className="mbr-overlay"
						style={{
							opacity: 0.5,
							backgroundColor: "rgb(7, 59, 76)"
						}}
					/>
					<div className="container" style={{ marginTop: "50px" }}>
						{requestcorrespondenceButton}
						<Grid container spacing={2}>
							<Grid item xs={12}>
								{logo && (
									<Avatar
										alt={Translation.translateField(
											"companyLogoAlt",
											"Company Logo"
										)}
										src={fileURLFactory(logo, true)}
										className={classes.logo}
									/>
								)}
								<Typography
									align="center"
									variant="h5"
									className={classes.heading}
								>
									{name}
								</Typography>
								<Divider className={classes.divider} />
							</Grid>
							<Grid item xs={12}>
								<Paper className={classes.paper}>
									<Typography variant="caption">
										<strong>
											{Translation.translateField(
												"aboutUsPlaceholder",
												"About us"
											)}
											:{" "}
										</strong>
									</Typography>
									<Typography component="p">
										{description}
									</Typography>
								</Paper>
							</Grid>
							<Grid item xs={4}>
								<Paper className={classes.paper}>
									<Typography variant="caption">
										<strong>
											{Translation.translateField(
												"email",
												"Email"
											)}
											:
										</strong>{" "}
										{email}
									</Typography>
								</Paper>
							</Grid>
							<Grid item xs={4}>
								<Paper className={classes.paper}>
									<Typography variant="caption">
										<strong>
											{Translation.translateField(
												"website",
												"Website"
											)}
											:
										</strong>{" "}
										{website}
									</Typography>
								</Paper>
							</Grid>
							<Grid item xs={4}>
								<Paper className={classes.paper}>
									<Typography variant="caption">
										<strong>
											{Translation.translateField(
												"CEOPlaceholder",
												"CEO"
											)}
											:
										</strong>{" "}
										{ceo}
									</Typography>
								</Paper>
							</Grid>
						</Grid>
					</div>
				</section>
			);
		} else {
			return;
		}
	};

	render() {
		return <Page location={this.props.location}>{this.renderer()}</Page>;
	}
}

export default withStyles(styles)(PublicCompanyProfilePage);
