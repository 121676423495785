import React, { Component } from "react";

import Page from "../../Page";
import PurchaseInfo from "../../sections/PurchaseInfo";
import Plan from "../../sections/Plan";
import { usertype } from "../../../config";
import manager from "../../../Manager";
import { setTitle } from "../../../pageOptions";
import Translation from "../../../utils/Translation";

class PurchaseGoldAccessPage extends Component {
	static contextType = manager;

	state = {
		pricing: [],
		loaded: false
	};

	componentWillMount() {
		this.context.getAuthManager().pageRestriction({
			logged: true,
			forLoggedUsertype: [usertype.company]
		});
		let component = this;
		component.context.getAuthManager().getLoggedUser(component, {
			onFinish: {
				callback: loggedUser => {
					component.context
						.getGenericManager()
						.getPricing(component, {
							onFinish: {
								callback: PricingResponse => {
									component.setState({
										...component.state,
										pricing: PricingResponse,
										user: loggedUser,
										loaded: true
									});
								}
							}
						});
				}
			}
		});
		setTitle(
			Translation.translateStaticText(
				"goldPurchaseTitle",
				"Purchase Gold Access"
			)
		);
	}

	render() {
		if (!this.state.loaded) return null;
		return (
			<Page location={this.props.location}>
				<section
					className="cid-rgc6tm1qbG mbr-parallax-background"
					id="pricing-tables1-f"
				>
					<div className="container">
						<div className="media-container-row">
							<Plan
								name={this.state.pricing[1].name}
								currentPrice={this.state.pricing[1].price}
								previousPrice={
									this.state.pricing[1].promotion_from !==
									undefined
										? this.state.pricing[0].promotion_from
										: undefined
								}
								perks={[
									Translation.translateStaticText(
										"perk1",
										"Create Company Profile"
									),
									Translation.translateStaticText(
										"perk3",
										"Publish Unlimited Number of Positions"
									),
									Translation.translateStaticText(
										"perk4",
										"Send *InSystem Messages"
									),
									Translation.translateStaticText(
										"perk5",
										"Have Access To a Pool of Profiles"
									)
								]}
							/>
							<div className="mbr-bg-white my-2 mx-2 col-12 col-lg-6">
								<PurchaseInfo type="gold" />
							</div>
						</div>
					</div>
				</section>
			</Page>
		);
	}
}

export default PurchaseGoldAccessPage;
