import React, { Component } from "react";
import manager from "../../Manager";
import Question from "./FAQ/Question";
import Answer from "./FAQ/Answer";
import Item from "./FAQ/Item";
import Translation from "../../utils/Translation";

class CompanyFAQ extends Component {
    static contextType = manager;

    componentWillMount() {
        this.context.getAuthManager().pageRestriction({ logged: true });
    }

    data = [
        {
            question: Translation.translateFAQ(
                "companyFirstQuestion",
                "What makes Match Careers different? There are many other platforms out there, why should i choose you?"
            ),
            answer: Translation.translateFAQ(
                "companyFirstAnswer",
                "We strive to provide simplicity and easy to use tools. Also we struggle on a dalily basis to expand the variety of options in the platform for both candidates and companies. The best way to answer this question is to take a peek and enjoy :-)"
            )
        },
        {
            question: Translation.translateFAQ(
                "companySecondQuestion",
                "Which positions do you specialize in?"
            ),
            answer: Translation.translateFAQ(
                "companySecondAnswer",
                "We do not limit ourselves to only high-level or too unexperienced positions. We have it all covered, so we can match as many people with their desirable career as possible."
            )
        },
        {
            question: Translation.translateFAQ(
                "companyThirdQuestion",
                "How can I register as a company?"
            ),
            answer: Translation.translateFAQ(
                "companyThirdAnswer",
                "Simply fill out the requested data and follow the instructions."
            )
        },
        {
            question: Translation.translateFAQ(
                "companyFourthQuestion",
                "Can I keep my account open on several devices?"
            ),
            answer: Translation.translateFAQ(
                "companyFourthAnswer",
                "Sure you can. Just keep in mind that messages and notifications won't reflect on each of them until you refresh the page on the current device."
            )
        },
        {
            question: Translation.translateFAQ(
                "companyFifthQuestion",
                "How soon will I receive applications from my job postings?"
            ),
            answer: Translation.translateFAQ(
                "companyFifthAnswer",
                "You will be blown with candidates, but you need to make sure, that the ad and requirements are tempting and the opportunity itself is detailed, so there are no discrepancies."
            )
        },
        {
            question: Translation.translateFAQ(
                "companySixthQuestion",
                "Have you verified the skill set of the candidates that are already in your platform?"
            ),
            answer: Translation.translateFAQ(
                "companySixthAnswer",
                "Match Careers is only a platform - we provide the ground and the tools to connect. We are not an HR agency."
            )
        },
        {
            question: Translation.translateFAQ(
                "companySeventhQuestion",
                "When can I reach out directly to a candidate?"
            ),
            answer: Translation.translateFAQ(
                "companySeventhAnswer",
                "As soon as you receive is/her application or if the candidate already added your company to his/her watchlist - in that case you can use our cutting edge insystem messaging tool."
            )
        },
        {
            question: Translation.translateFAQ(
                "companyEighthQuestion",
                "Which candidates are visible to us as a company?"
            ),
            answer: Translation.translateFAQ(
                "companyEighthAnswer",
                "Anyone, matching based on your preferences, who has not put restrictions on his profile."
            )
        },
        {
            question: Translation.translateFAQ(
                "companyNinthQuestion",
                "Since you will have my email address does that mean that I will receive numerous spam messages?"
            ),
            answer: Translation.translateFAQ(
                "companyNinthAnswer",
                "That will be really bad, so no way."
            )
        }
    ];

    render() {
        let faq = [];
        for (let [i] of this.data.entries()) {
            faq.push(
                <Item id={i} key={i}>
                    <Question>{this.data[i].question}</Question>
                    <Answer>{this.data[i].answer}</Answer>
                </Item>
            );
        }
        return (
            <div id="next">
                <section className="cid-rgxv6dNGOY" id="company_faq">
                    <div className="container align-center">
                        <h2 className="mbr-section-title pb-3 mbr-fonts-style display-1">
                            {Translation.translateFAQ(
                                "heading",
                                "Frequently asked questions"
                            )}
                        </h2>
                        <p>
                            {Translation.translateFAQ(
                                "guidelines",
                                "Click on a question to read the answer."
                            )}
                        </p>
                        <br />
                        {faq}
                        <br />
                        <br />
                        <p>
                            {Translation.translateFAQ(
                                "footer",
                                "Still have unanswered Questions? You can use our online chat to either send us a message or an email, if we are offline."
                            )}
                        </p>
                    </div>
                </section>
            </div>
        );
    }
}

export default CompanyFAQ;
