import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Divider } from "@material-ui/core";
import uuidv1 from "uuid/v1";

import { frontend } from "../../../config";
import BaseSearchResult from "./BaseSearchResult";
import SearchResultItem from "./SearchResultItem";
import Translation from "../../../utils/Translation";

class JobseekerSearchResult extends BaseSearchResult {
    classes = undefined;

    getMatchText(matches) {
        let matchText = "";
        let divider = "";
        /**
         * 'company_name' 'company_email' 'description' 'employee_name' 'position_title'
         * 'position_responsabilities' 'position_requirements' 'position_offer' 'position_speciality'
         * 'position_category' 'position_country' 'position_city' 'position_qualification'
         */
        if (matches.indexOf("company_name") > -1) {
            matchText +=
                divider +
                Translation.translateField(
                    "jobseekerCompanyAccessNameColumn",
                    "Company Name"
                );
            divider = ", ";
        }
        if (matches.indexOf("company_email") > -1) {
            matchText +=
                divider +
                Translation.translateField("companyEmail", "Company Email");
            divider = ", ";
        }
        if (matches.indexOf("description") > -1) {
            matchText +=
                divider +
                Translation.translateField(
                    "jobseekerCompanyDescriptionMatch",
                    "Company Description"
                );
            divider = ", ";
        }
        if (matches.indexOf("employee_name") > -1) {
            matchText +=
                divider +
                Translation.translateField(
                    "jobseekerCompanyEmployeeNameMatch",
                    "Company employee name"
                );
            divider = ", ";
        }
        if (matches.indexOf("position_title") > -1) {
            matchText +=
                divider +
                Translation.translateField(
                    "jobseekerCompanyPositionTitleMatch",
                    "Company position title"
                );
            divider = ", ";
        }
        if (matches.indexOf("position_responsabilities") > -1) {
            matchText +=
                divider +
                Translation.translateField(
                    "jobseekerCompanyPositionResponsibilitiesMatch",
                    "Company position responsibilities"
                );
            divider = ", ";
        }
        if (matches.indexOf("position_requirements") > -1) {
            matchText +=
                divider +
                Translation.translateField(
                    "jobseekerCompanyPositionRequirementsMatch",
                    "Company position requirements"
                );
            divider = ", ";
        }
        if (matches.indexOf("position_offer") > -1) {
            matchText +=
                divider +
                Translation.translateField(
                    "jobseekerCompanyPositionOfferMatch",
                    "Company position offer"
                );
            divider = ", ";
        }
        if (matches.indexOf("position_speciality") > -1) {
            matchText +=
                divider +
                Translation.translateField(
                    "jobseekerCompanyPositionSpecialityMatch",
                    "Company position speciality"
                );
            divider = ", ";
        }
        if (matches.indexOf("position_category") > -1) {
            matchText +=
                divider +
                Translation.translateField(
                    "jobseekerCompanyPositionCategoryMatch",
                    "Company position category"
                );
            divider = ", ";
        }
        if (matches.indexOf("position_country") > -1) {
            matchText +=
                divider +
                Translation.translateField(
                    "jobseekerCompanyPositionCountryMatch",
                    "Company position country"
                );
            divider = ", ";
        }
        if (matches.indexOf("position_city") > -1) {
            matchText +=
                divider +
                Translation.translateField(
                    "jobseekerCompanyPositionCityMatch",
                    "Company position city"
                );
            divider = ", ";
        }
        if (matches.indexOf("position_qualification") > -1) {
            matchText +=
                divider +
                Translation.translateField(
                    "jobseekerCompanyPositionQualificationMatch",
                    "Company position qualification"
                );
            divider = ", ";
        }
        return matchText;
    }

    divider = undefined;
    getdivider() {
        if (this.divider) {
            return this.divider;
        }
        this.divider = <Divider />;
        return null;
    }

    parse(data) {
        this.divider = undefined;
        return (
            <Box component="div" maxWidth="sm" className="align-left">
                {data.map(row => {
                    return (
                        <Card key={uuidv1()}>
                            {this.getdivider()}
                            <CardContent classes={{ root: "selectable" }}>
                                <Typography color="textSecondary" gutterBottom>
                                    <strong>
                                        {Translation.translateField(
                                            "searchMatchedOn",
                                            "Matched on"
                                        )}
                                        :{" "}
                                    </strong>
                                    {this.getMatchText(row.matches)}
                                </Typography>
                                <SearchResultItem
                                    title={Translation.translateField(
                                        "jobseekerSearchResultCompanyName",
                                        "Company"
                                    )}
                                    url={
                                        frontend.DOMAIN +
                                        frontend.route.company.public_profile +
                                        "/" +
                                        row.company_name
                                    }
                                    value={row.company_name}
                                />
                                {row.matches.indexOf("company_email") > -1 ? (
                                    <SearchResultItem
                                        title={Translation.translateField(
                                            "email",
                                            "Email"
                                        )}
                                        value={row.company_email}
                                    />
                                ) : null}
                                {row.matches.indexOf("description") > -1 ? (
                                    <SearchResultItem
                                        title={Translation.translateField(
                                            "jobseekerCompanyDescriptionMatch",
                                            "Company Description"
                                        )}
                                        value={row.description}
                                    />
                                ) : null}
                                {row.matches.indexOf("employee_name") > -1 ? (
                                    <SearchResultItem
                                        title={Translation.translateField(
                                            "jobseekerCompanyEmployeeNameMatch",
                                            "Company Employee Name"
                                        )}
                                        value={row.employee_name}
                                    />
                                ) : null}
                                {row.matches.indexOf("position_title") > -1 ||
                                row.matches.indexOf("position_category") > -1 ||
                                row.matches.indexOf("position_city") > -1 ||
                                row.matches.indexOf("position_country") > -1 ||
                                row.matches.indexOf("position_offer") > -1 ||
                                row.matches.indexOf("position_qualification") >
                                    -1 ||
                                row.matches.indexOf("position_requirements") >
                                    -1 ||
                                row.matches.indexOf(
                                    "position_responsabilities"
                                ) > -1 ||
                                row.matches.indexOf("position_speciality") >
                                    -1 ? (
                                    <SearchResultItem
                                        title={Translation.translateField(
                                            "jobseekerSearchResultPosition",
                                            "Position"
                                        )}
                                        url={
                                            frontend.DOMAIN +
                                            frontend.route.position.view +
                                            "/" +
                                            row.company_name +
                                            "/" +
                                            row.position_title
                                        }
                                        value={row.position_title}
                                    />
                                ) : null}
                                {row.matches.indexOf("position_category") >
                                -1 ? (
                                    <SearchResultItem
                                        title={Translation.translateField(
                                            "jobseekerSearchResultPositionCategory",
                                            "Position Category"
                                        )}
                                        value={row.position_category}
                                    />
                                ) : null}
                                {row.matches.indexOf("position_city") > -1 ? (
                                    <SearchResultItem
                                        title={Translation.translateField(
                                            "jobseekerSearchResultPositionCity",
                                            "Position City"
                                        )}
                                        value={row.position_city}
                                    />
                                ) : null}
                                {row.matches.indexOf("position_country") >
                                -1 ? (
                                    <SearchResultItem
                                        title={Translation.translateField(
                                            "jobseekerSearchResultPositionCountry",
                                            "Position Country"
                                        )}
                                        value={row.position_country}
                                    />
                                ) : null}
                                {row.matches.indexOf("position_offer") > -1 ? (
                                    <SearchResultItem
                                        title={Translation.translateField(
                                            "jobseekerSearchResultPositionOffer",
                                            "Position Offer"
                                        )}
                                        value={row.position_offer}
                                    />
                                ) : null}
                                {row.matches.indexOf("position_qualification") >
                                -1 ? (
                                    <SearchResultItem
                                        title={Translation.translateField(
                                            "jobseekerSearchResultPositionQualification",
                                            "Position Qualification"
                                        )}
                                        value={row.position_qualification}
                                    />
                                ) : null}
                                {row.matches.indexOf("position_requirements") >
                                -1 ? (
                                    <SearchResultItem
                                        title={Translation.translateField(
                                            "jobseekerSearchResultPositionRequirements",
                                            "Position Requirements"
                                        )}
                                        value={row.position_requirements}
                                    />
                                ) : null}
                                {row.matches.indexOf(
                                    "position_responsabilities"
                                ) > -1 ? (
                                    <SearchResultItem
                                        title={Translation.translateField(
                                            "jobseekerSearchResultPositionResponsibilities",
                                            "Position Responsibilities"
                                        )}
                                        value={row.position_responsabilities}
                                    />
                                ) : null}
                                {row.matches.indexOf("position_speciality") >
                                -1 ? (
                                    <SearchResultItem
                                        title={Translation.translateField(
                                            "jobseekerSearchResultPositionSpeciality",
                                            "Position Speciality"
                                        )}
                                        value={row.position_speciality}
                                    />
                                ) : null}
                            </CardContent>
                        </Card>
                    );
                })}
            </Box>
        );
    }
}

export default JobseekerSearchResult;
