import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import SkillListing from "./SkillListing";
import { styles } from "../../../defaultProps";
import Translation from "../../../utils/Translation";


class EmploymentListing extends Component {
	static defaultProps = {
		employments: [],
		classes: {}
	};

	render() {
		const { classes, employments } = this.props;
		if (employments.length <= 0) {
			return "";
		}
		return (
			<Fragment>
				{employments.map(employment => (
					<Fragment key={employment.employmentId}>
						<Grid item xs={6}>
							<Paper className={classes.paper}>
								<Typography variant="caption">
									{Translation.translateField("jobseekerEmploymentHistoryEmployer","Employer")}:
								</Typography>
								<Typography component="p">
									{employment.employer}
								</Typography>
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper className={classes.paper}>
								<Typography variant="caption">{Translation.translateField("jobseekerEmploymentHistoryRole","Role")}:</Typography>
								<Typography component="p">
									{employment.role}
								</Typography>
							</Paper>
						</Grid>
						<Grid item xs={6} sm={4}>
							<Paper className={classes.paper}>
								<Typography variant="caption">
									{Translation.translateField("jobseekerEmploymentHistoryStartDate","Initial Date") }:
								</Typography>
								<Typography component="p">
									{employment.initialDate}
								</Typography>
							</Paper>
						</Grid>
						<Hidden xsDown>
							<Grid item sm={2} />
						</Hidden>
						<Grid item xs={6} sm={4}>
							<Paper className={classes.paper}>
								<Typography variant="caption">
									{Translation.translateField("jobseekerEmploymentHistoryEndDate","End Date") }:
								</Typography>
								<Typography component="p">
									{employment.present
										? "Currently Employed"
										: employment.endDate}
								</Typography>
							</Paper>
						</Grid>
						<SkillListing
							skills={employment.technicalStack}
							removeHeading={true}
							label={Translation.translateField("jobseekerEmploymentHistoryTechnicalStack", "Technical Stack")}
						/>
						<Grid item xs={12}>
							<Paper className={classes.paper}>
								<Typography variant="caption">
									{Translation.translateField("summary","Summary")}:
								</Typography>
								<Typography component="p">
									{employment.summary}
								</Typography>
							</Paper>
						</Grid>
						<Grid item xs={12} />
					</Fragment>
				))}
			</Fragment>
		);
	}
}
export default withStyles(styles)(EmploymentListing);
