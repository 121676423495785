import React, { Fragment } from "react";
import Translation from "../../utils/Translation";
import { languages } from "../../config";

export default function TermsOfServiceAcceptance(props) {
	const getTemplate = language => {
		const englishTemplate = (
			<Fragment>
				I agree with the{" "}
				<a
					href={props.location}
					rel="noopener noreferrer"
					target="_blank"
				>
					Terms and Conditions
				</a>
				.
			</Fragment>
		);

		const bulgarianTemplate = (
			<Fragment>
				Съгласен съм с{" "}
				<a
					href={props.location}
					rel="noopener noreferrer"
					target="_blank"
				>
					Правила и условия за ползване
				</a>
				.
			</Fragment>	
			)

		switch (language) {
			case languages.english:
				return englishTemplate;
			case languages.bulgarian:
				return bulgarianTemplate;
			case languages.german:
				return englishTemplate;
			default:
				return englishTemplate;
		}
	};

	return getTemplate(Translation.getLanguage());
}
