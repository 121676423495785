import React from "react";
import Translation from "../../utils/Translation";

export default function AboutUs(props) {
    return (
        <div>
            <section
                className="mbr-fullscreen mbr-parallax-background aboutUsBackground"
                style={{ alignItems: "start", minHeight: "700px" }}
            >
                <div className="container pt-4 mt-2">
                    <h1
                        className="mbr-section-title pb-3 align-center mbr-fonts-style display-2"
                        style={{ color: "white", paddingTop: "100px" }}
                    >
                        {Translation.translateStaticText(
                            "aboutUsHeading",
                            "About us"
                        )}
                    </h1>
                </div>
            </section>
            <section
                className="counters6 counters cid-rg5irxrHy2 visible full-visible"
                id="counters6-6"
                style={{ paddingTop: "0" }}
            >
                <div className="container pt-4 mt-2">
                    <h2 className="mbr-section-title pb-3 align-center mbr-fonts-style display-2">
                        {Translation.translateStaticText(
                            "whyUsHeading",
                            "Why us?"
                        )}
                    </h2>
                    <h3 className="mbr-section-subtitle pb-5 align-center mbr-fonts-style display-5">
                        <p className="">
                            {Translation.translateStaticText(
                                "whyUsDescription",
                                "We offer the best match between companies and specialists"
                            )}
                        </p>
                    </h3>
                    <div>
                        <div className="cards-container">
                            <div className="card col-12 col-md-6 col-lg-4 pb-md-4">
                                <div className="panel-item align-center">
                                    <div className="card-img pb-3">
                                        <h3 className="img-text mbr-fonts-style display-1">
                                            01.
                                        </h3>
                                    </div>
                                    <div className="card-text">
                                        <h4 className="mbr-content-title mbr-bold mbr-fonts-style display-7">
                                            {Translation.translateStaticText(
                                                "whyUsFirstHeading",
                                                "Honest and Reliable"
                                            )}
                                        </h4>
                                        <p className="mbr-content-text mbr-fonts-style display-7">
                                            {Translation.translateStaticText(
                                                "whyUsFirstDescription",
                                                "We strive on matching quality with transparency. Those being our core values, we're on the verge of providing a platform, that will meet you with the best opportunities"
                                            )}
                                            <br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card col-12 col-md-6 col-lg-4 pb-md-4">
                                <div className="panel-item align-center">
                                    <div className="card-img pb-3">
                                        <h3 className="img-text mbr-fonts-style display-1">
                                            02.
                                        </h3>
                                    </div>
                                    <div className="card-text">
                                        <h4 className="mbr-content-title mbr-bold mbr-fonts-style display-7">
                                            {Translation.translateStaticText(
                                                "whyUsSecondHeading",
                                                "We are Always Improving"
                                            )}
                                        </h4>
                                        <p className="mbr-content-text mbr-fonts-style display-7">
                                            {Translation.translateStaticText(
                                                "whyUsSecondDescription",
                                                `"We all need people who will give us feedback. That’s how we improve."`
                                            )}
                                            <br />
                                            {Translation.translateStaticText(
                                                "billGatesName",
                                                "Bill Gates"
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card col-12 col-md-6 col-lg-4 last-child">
                                <div className="panel-item align-center">
                                    <div className="card-img pb-3">
                                        <h3 className="img-text mbr-fonts-style display-1">
                                            03.
                                        </h3>
                                    </div>
                                    <div className="card-text">
                                        <h4 className="mbr-content-title mbr-bold mbr-fonts-style display-7">
                                            {Translation.translateStaticText(
                                                "whyUsThirdHeading",
                                                "Passion in Technology and Humanity"
                                            )}
                                        </h4>
                                        <p className="mbr-content-text mbr-fonts-style display-7">
                                            {Translation.translateStaticText(
                                                "whyUsThirdDescription",
                                                "We do care for the needs of each of you, no matter if you are a jobseeker or an employer. Yes, computers and the programmed algorithms behind can serve us, but having a nice word or a tip from a person is something that distinguish the usage of online service from real live experience. You can always contact a real person from our team."
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
