import React from "react";
import TextField from "@material-ui/core/TextField";

WhiteFilledTextField.defaultProps = {
	className: '',
	variant: 'filled',
	classes: {}
}

export default function WhiteFilledTextField(props){
	const {variant, classes, ...rest} = props;
	return (
		<TextField
			variant={ variant }
			InputProps={{
				disableUnderline:true,
				classes: {
					...classes,
					root: "bg-white br-25"
					}
			}}
			{...rest}
		/>
	);
}
