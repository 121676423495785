import React from "react";
import { Link } from "react-router-dom";

import manager from "../../Manager";
import { backend, frontend } from "../../config";
import Translation from '../../utils/Translation';

class PremiumClients extends React.Component {
    static contextType = manager;

    state = {
        dataLoaded: false
    };
    controls = "";
    companiesRender = "";

    componentWillMount() {
        let that = this;
        this.context.getCompanyManager().getPlatimumCompanies(this, {
            onSuccess: {
                toast: false,
                callback: data => {
                    that.getCompaniesRender(data, () => {
                        that.setState({
                            dataLoaded: true,
                            companies: data
                        });
                    });
                }
            },
            onError: {
                callback: data => {
                    console.error("PremiumClients componentWillMount ERROR:");
                    console.error(data);
                }
            }
        });
    }

    getCompaniesRender(companies, callback) {
        if (companies.length > 5) {
            this.controls = (
                <div className="carousel-controls">
                    <a
                        data-app-prevent-settings=""
                        className="carousel-control carousel-control-prev"
                        role="button"
                        data-slide="prev"
                        href="#clients-i-carousel"
                    >
                        <span
                            aria-hidden="true"
                            className="mbri-left mbr-iconfont"
                        />
                        <span className="sr-only">
                            {Translation.translateStaticText(
                                "navigationPrevious",
                                "Previous"
                            )}
                        </span>
                    </a>
                    <a
                        data-app-prevent-settings=""
                        className="carousel-control carousel-control-next"
                        role="button"
                        data-slide="next"
                        href="#clients-i-carousel"
                    >
                        <span
                            aria-hidden="true"
                            className="mbri-right mbr-iconfont"
                        />
                        <span className="sr-only">
                            {Translation.translateStaticText(
                                "navigationNext",
                                "Next"
                            )}
                        </span>
                    </a>
                </div>
            );
        }

        let groups = [];
        let items = [];
        const groupClass = "carousel-item";
        let firstGroupClassAddon = " active";
        const itemClass = "col-md-12 col-lg-15 clonedCol";
        companies.forEach(function(company, index) {
            items.push(
                <div
                    className={itemClass + index}
                    data-cloned-index={index}
                    key={"premium-item-" + index}
                    id={"premium-item-" + index}
                >
                    <div className="wrap-img ">
                        <Link
                            to={`${frontend.route.company.public_profile}/${
                                company.company_name
                            }`}
                        >
                            {company.logo === null ? (
                                <span>{company.company_name}</span>
                            ) : (
                                <img
                                    src={
                                        backend.DOMAIN +
                                        backend.api.getPublicFile +
                                        company.logo
                                    }
                                    alt={company.company_name}
                                    className="img-responsive clients-img"
                                />
                            )}
                        </Link>
                    </div>
                </div>
            );
            if (items.length >= 5) {
                let i = 0;
                groups.push(
                    <div
                        className={groupClass + firstGroupClassAddon}
                        id={"groupPC-" + i++}
                        key={"groupPC-" + i++}
                    >
                        <div
                            className="media-container-row"
                            key={"group-more-than-five"}
                        >
                            {items}
                        </div>
                    </div>
                );
                items = [];
                firstGroupClassAddon = "";
            }
        });
        if (items.length > 0) {
            groups.push(
                <div
                    className={groupClass + firstGroupClassAddon}
                    key={"group-about-than-five"}
                >
                    <div className="media-container-row">{items}</div>
                </div>
            );
            items = [];
        }

        this.companiesRender = (
            <div className="carousel-inner slides5" data-visible="5">
                {groups}
            </div>
        );
        callback();
    }

    render() {
        if (!this.state.dataLoaded || this.state.companies.length <= 0) {
            return null;
        }
        return (
            <section className="clients cid-rhZ925n3EB" id="clients-i">
                <div className="container mb-5">
                    <div className="media-container-row">
                        <div className="col-12 align-center">
                            <h2 className="mbr-section-title premium-clients pb-3 mbr-fonts-style display-2">
                                {Translation.translateStaticText(
                                    "premiumClients",
                                    "Premium Clients"
                                )}
                            </h2>
                            <h3 className="mbr-section-subtitle mbr-light mbr-fonts-style display-5">
                                &nbsp;
                            </h3>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div
                        className="carousel slide"
                        data-ride="carousel"
                        role="listbox"
                        id="clients-i-carousel"
                    >
                        {this.companiesRender}
                        {this.controls}
                    </div>
                </div>
            </section>
        );
    }
}

export default PremiumClients;
