import React, { Component } from "react";
import manager from "../../../Manager";
import Table from "./Table";
import Pagination from "../../../utils/Pagination";
import Translation from "../../../utils/Translation";
import ToastHandler from "../../../utils/ToastHandler";

class ListOwn extends Component {
  static contextType = manager;

  static defaultProps = {
    goToView: position_id => {},
    goToEdit: position_id => {},
    deletePosition: position_id => {},
    companyInfo: {}
  };

  dataLoaded = false;
  pagination = undefined;
  state = {
    loading: true,
    data: [],
    noPositions: true,
    company: {}
  };

  componentDidMount() {
    this.pagination = new Pagination(
      1,
      20,
      Translation.translateStaticText("paginationJobsTitle", "Jobs"),
      this.onPageChange
    );
    this.pagination.init();
  }

  onPageChange = (page = 1) => {
    let component = this;
    return e => {
      component.context
        .getPositionManager()
        .getPaginationOwnPositions(
          component,
          (page - 1) * component.pagination.size,
          component.pagination.size,
          {
            onSuccess: {
              toast: false,
              callback: data => {
                if (!("totalCount" in data) || !("items" in data)) {
                  console.error(
                    "Position OwnList onPageChange ERROR: managerAPI response not containing pagination structure!"
                  );
                  return;
                }
                component.pagination.totalCount = data.totalCount;
                component.pagination.page = page;
                component.pagination.from =
                  page * component.pagination.size - component.pagination.size;
                component.populateData(component, data.items, data);
                window.scrollTo(0, 0);
              }
            },
            onError: {
              toast: false,
              callback: data => {
                component.populateData(component, {}, data);
              }
            }
          }
        );
    };
  };

  populateData(component, data, debugApiReturnData) {
    let companiesDict = {};
    if ("companies" in data && data.companies.length > 0) {
      data.companies.map(company => {
        companiesDict[company.company_id] = company.company_name;
        return null;
      });
    }
    component.dataLoaded = true;
    component.setState({
      ...component.state,
      loading: false,
      data: "positions" in data ? data.positions : [],
      companyNames: companiesDict
    });
  }

  getDeletePosition() {
    let component = this;
    return position_id => {
      component.props.deletePosition(position_id, () => {
        let positions = component.state.data;
        positions.forEach(function(position, index) {
          if (position.position_id === position_id) {
            positions.splice(index, 1);
          }
        });
        ToastHandler.showSuccess(
          Translation.translateMessage("Deleted", "Deleted")
        );
        let noPositions = false;
        if (positions.length <= 0) {
          noPositions = true;
        }
        component.setState({
          ...component.state,
          data: positions,
          noPositions: noPositions
        });
      });
    };
  }

  render() {
    if (this.loading) {
      this.pagination.init();
      return null;
    }
    let positions = "";
    if (this.state.data.length > 0) {
      positions = (
        <div>
          <h2>
            {Translation.translateField(
              "yourPositionsHeading",
              "Your positions"
            )}
            :
          </h2>
          <Table
            positions={this.state.data}
            companyNames={this.state.companyNames}
            loggedCompanyId={this.props.companyInfo.company_id}
            goToView={this.props.goToView}
            goToEdit={this.props.goToEdit}
            deletePosition={this.getDeletePosition()}
            pagination={this.pagination}
          />
        </div>
      );
    } else
      positions = Translation.translateStaticText(
        "noPunlishedPositions",
        "No published positions."
      );
    return positions;
  }
}

export default ListOwn;
