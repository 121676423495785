export default {
	//Homepage
	premiumClients: "Премиум клиенти",
	navigationPrevious: "Назад",
	navigationNext: "Напред",
	matchCareersHeading: "Новото Ви работно място е тук!",
	matchCareersSlogan: "мястото, където специалистите срещат своите бъдещи работодатели",
	searchingJob: "Търсите работа?",
	searchingTalent: "Търсите специалисти?",

	//Registration & Account Recovery
	createNewAccount: "Създай нов профил",
	checkEmail: "Проверете пощата си",
	howItWorksHeading: "Как работи",
	step1: "Първа стъпка",
	step2: "Втора стъпка",
	step3: "Трета стъпка",
	finalStep: "Финална стъпка",
	accountRecovery: "Възстановяване на акаунт",

	//Company Registration
	companyRegistrationHeading: "РАБОТОДАТЕЛИ",
	companyRegistrationHeadlight:
		"Отделете си минута за регистрация и  създайте контакти с професионалисти от вашия бранш",
	companyStep1Heading: "Регистрирайте се",
	companyStep1Description:
		"След въвеждане на нужната информация ще получите имейл за потвърждение",
	companyStep2Heading: "Разкажете ни история за себе си",
	companyStep2Description:
		"Отделете известно време, за да попълните възможно най-много информация за вашата компания  - колкото повече, толкова по-добре. Това ще увеличи интереса на кандидатите към вашата работа и проекти.",
	companyStep3Heading: "Изберете своя ценови план",
	companyStep3Description:
		"Въз основа на вашите нужди - вземете плана, който съвпада с тях и започнете своето пътешествие в нашата платформа.",
	companyFinalStepHeading: "Разпространете информацията",
	companyFinalStepDescription:
		"Пуснете обявата си за работа на пазара и започнете да наемате през Match.Careers.",

	//Jobseeker Registration
	jobseekerRegistrationHeading: "КАНДИДАТИ",
	jobseekerRegistrationHeadlight:
		"Отделете си минута за регистрация и разгледайте предоставените кариерни възможности",
	jobseekerStep1Heading: "Създайте свой профил",
	jobseekerStep1Description:
		"Създайте потребителския си профил, съдържащ уменията и Вашия опит. Добрата новина е, че няма нужда да пишете дълга автобиография или мотивационно писмо!",
	jobseekerStep2Heading: "Кандидатствайте за позиция",
	jobseekerStep2Description:
		"Нашата система веднага подбира работни предложения, които ще ви бъдат представени. След кандидатстване, отговорното лице от компанията, от която се интересувате, може да се свърже с вас.",
	jobseekerStep3Heading: "Получете покана за интервю",
	jobseekerStep3Description:
		"Срещате се лично или онлайн с представител на компанията, отговорен за наемането на нови хора.",
	jobseekerFinalStepHeading: "Получете оферта",
	jobseekerFinalStepDescription: "Честито. Нает/а сте!",

	//Registration password tooltip
	passwordRequirementsHeading:
		"Вашата парола трябва да съответства със следните критерии:",
	passwordLengthRequirement: "Дължина 6-16 символа",
	passwordCapitalLetterRequirement: "1 Главна буква",
	passwordLowerCaseLetterRequirement: "1 Малка буква",
	passwordDigitRequirement: "1 Цифра",
	passwordSpecialSymbolRequirement: "1 Специален символ",

	//Jobseeker Profile
	jobseekerProfileCompletion: "Попълване на профила",
	jobseekerProfileCompletionProfileFields: "Полета на профила",
	jobseekerLocationPlaceholder: "Локация",
	jobseekerCountry: "Държава",
	jobseekerCity: "Град",
	jobseekerProfileSkillsHeading: "Умения и образование",
	jobseekerPublicProfileEducationHeading: "Образование",
	jobseekerProfileCompletionSkillsPlaceholder: "Умения",
	jobseekerProfileInformationHeading: "Информация за профила",
	jobseekerProfileLocation: "--- Изберете държава ---",
	jobseekerProfileCV: "Автобиография (Само pdf формат)",
	jobseekerNoCV: "Няма качена автобиография",
	jobseekerCVPlaceholder: "Автобиография",
	helperToolbarRestrictionFull: "Пълен",
	helperToolbarRestrictionFullDetails:
		"Фирми, към които не сте конфигурирали достъп, могат да виждат цялата ви информация за профила",
	helperToolbarRestrictionLimited: "Лимитиран",
	helperToolbarRestrictionLimitedDetails:
		"Фирмите, към които не сте конфигурирали достъп, могат да виждат само вашите умения",
	helperToolbarRestrictionNone: "Без достъп",
	helperToolbarRestrictionNoneDetails:
		"Фирмите, към които не сте конфигурирали достъп, не могат да видят вашия профил",
	jobseekerProfileSkillsHelper: `Можете да пропуснете функцията за автоматично довършване и да <strong>създадете</strong> (добавите) свое собствено умение или училище / университет, като напишете името и изберете <strong>Създаване на „име“</strong>. <strong>Препоръчително е да избирате от имената за автоматично довършване, когато е възможно.</strong>`,
	jobseekerProfileNoEducation: "Няма Образование",
	jobseekerProfileMoreEducation: "Допълнително Образование",
	footerAddressHeading: "Адрес",
	footerAddress:
		"Бул. Цариградско шосе 15, 3-ти вход, ет. 2, 1124, София, България",
	footerFAQHeader: "Имате въпроси?",
	footerFAQ: `Посетете нашите <a href="{location}">Често задавани въпроси</a>`,
	footerContactsHeader: "Контакти",
	footerContactsEmailHeader: "Имейл",
	footerContactsPhoneHeader: "Телефон",
	footerCopyright: "Copyright {year} InspireSoft- Всички права са запазени",
	employmentHistoryHeading: "История на заетостта",
	jobseekerPublicProfileInformationHeading: "Лична информация",
	jobseekerPublicProfileSkillsHeading: "Лични умения",
	jobseekerPublicProfileNoSkills: "Няма налични умения",
	JobseekerCompanyAccessHeading: "Фирмен достъп",
	JobseekerCompanyAccessHelper:
		"Имате нужда от <strong>100%</strong> попълване на потребителски профил, за да имате достъп до редакции на разрешения.",
	jobseekerCompanyAccessNoRecordsPlaceholder: "Няма записи за показване",
	jobseekerCompanyAccessDeleteText: "Премахване на този достъп?",
	jobseekerCompanyAccessPaginationLabel: "Записа",
	jobseekerCompanyAccessPaginationCount: "{from}-{to} от {count}",
	jobseekerComapnyAccessProfileCompletionRequirement:
		"Имате нужда от 100% попълване на потребителски профил, за да имате достъп до редакции на разрешения.",
	jobseekerEmploymentHistoryHelperText:
		"Добавете, редактирайте или премахнете работни места във вашия профил",
	jobseekerCompanyAccessHelperText:
		"Променете или премахнете специфичната видимост на фирмите за вашия профил",
	changePasswordHeading: "Смяна на парола",
	changePasswordHelperTooltip:
		"Внимавайте, като промените паролата си, няма да можете да влезете в профила си използвайки предишната си парола. Новата парола трябва да съвпада и да има 1 главна буква, 1 малка буква, 1 специален символ и 1 цифра",
	jobseekerSaveWarning: "Моля, запазете промените, преди да продължите!",
	jobseekerSaveWarningTitle:
		"Може да загубите промените, които сте направили!",
	jobseekerProfileCompletionModificationsNotSaved:
		"Промените не са запазени!",

	//Company Profile
	"free account": "Безплатен акаунт",
	"gold account": "Златен акаунт",
	"platinum account": "Платинен акаунт",

	//inSystemMessages
	requestCorrespondenceMessage: "{contact} е поискал кореспонденция",
	hasToAcceptCorrespondence:
		"{contact} все още не е приел кореспонденция с вас",
	sendEmailToCompnay: "Бързате? Изпратете имейл до компанията на",
	blockUser: "Вие блокирахте {contact}",
	haveBlockedYou: "{contact} ви е блокирал",
	haveRemovedYou: "{contact} ви е премахнал от контактите си",
	oneHourAgo: "преди 1 час",
	hoursAgo: "преди {interval} часа",
	oneMinuteAgo: "преди 1 минута",
	minutesAgo: "преди {interval} минути",
	oneSecondAgo: "преди секунда",
	now: "Сега",
	secondsAgo: "преди {interval} секунди",
	warningHeader: "Внимание",
	removeContactWarning:
		"Наистина ли искате да премахнете {contact} от контактите си? Това действие не може да бъде отменено.",
	systemUser: "Система",
	systemInitMessage: `Здравейте, можете да добавите контакти, като посетите страницата им и кликнете върху „Заявка за кореспонденция“, след което контактът ще бъде видим отляво. Когато и ако потребителят приеме вашата заявка, вие ще можете да използвате системата за чат на живо. Това е системно съобщение, не можете да отговорите на него!`,

	//about us
	aboutUsHeading: "За нас",
	whyUsHeading: "Защо да изберете нас?",
	whyUsDescription:
		"Стремим се да предлагаме лесен и ефективен начин за свръзка между компании и кандидати",
	//about us listing
	whyUsFirstHeading: "Прозрачност",
	whyUsFirstDescription:
		"Основните ни ценности са качество, съчетано с ясни условия и надеждност за предлаганата услуга",
	whyUsSecondHeading: "Постоянно се стремим към надграждане на предлаганата услуга",
	whyUsSecondDescription: `„Всички имаме нужда от хора, които ни дават обратна връзка. Така се развиваме.“`,
	billGatesName: "Бил Гейтс",
	whyUsThirdHeading: "Страст към Технологиите, но и към човешкия фактор",
	whyUsThirdDescription:
		"Интересуваме се от нуждите на всеки един от Вас, без значение дали сте търсещ работа или работодател. Компютрите и програмираните алгоритми могат да ни служат добре, но комуникацията с човек е нещото,което отличава използването на онлайн услугата от живия контакт. Винаги можете да се свържете с човек от Нашия екип.",

	//Positions
	allPositionsHeading: "Всички позиции",
	loadingPositions: "Зареждане на позиции",
	noPunlishedPositions: "Няма публикувани позиции",
	positionLocationNotChoosen: "Не е избрана",
	morePositionLocations: "Още...",
	deletePositionTitle: "Изтрий позиция",
	deletePositionWarning:
		"Това действие е необратимо, сигурен ли сте, че искате да продължите?",
	invalidPosition: "Невалидна позиция",
	hiddenCandidatesCount: "Брой скрити кандидати {count}",
	freeStatusCandidateNotice:
		"Статусът на вашата фирма е безплатен. За да видите скритите кандидати, моля, изберете план от страницата за ценообразуване.",
	noCandidates: "Няма кандидати",
	candidateRevoceAccess: "Отменил достъп",

	//Page Titles
	addPositionTitle: "Добави позиция",
	editPositionTitle: "Редактирай позиция",
	viewPositionTitle: "Разгледай позиция",
	allPositionTitle: "Всички позиции",
	ownPositionTitle: "Мои позиции",
	platinumPurchaseTitle: "Закупи платинум достъп",
	goldPurchaseTitle: "Purchase Gold Access",
	pricingTitle: "Цени",
	signupTitle: "Регистрация",
	candidateProfileTitle: "Профил на кандидата",
	jobseekerProfileTitle: "Профилна информация",
	loginPageTitle: "Вход в системата",
	inSystemTitle: "Чат",
	homePageTitle: "Начална страница",
	searchPageTitle: "Търсене",
	FAQPageTitle: "Често задавани въпроси",
	errorPageTitle: "Грешка",
	companyProfileTitle: "Профилна информация на компанията",
	profileInformationTitle: "Профил",
	recoveryPageTitle: "Възстановяване на акаунт",
	aboutUsTitle: "За нас",

	//pricing
	pricingLoggedInWarning: "Нужно е да сте влезли в профила си.",
	ownedPlan: "Активен",
	perk1: "Създаване на фирмен профил",
	perk2: "Публикуване на една позиция",
	perk3: "Публикуване на неограничен брой позиции",
	perk4: "Достъп до вътрешната система за писмена комуникация",
	perk5: "Достъп до профили на кандидати",
	perk6: "Гарантирано лого на първата страница и реклами в социалните мрежи",
	inSystemMessagesDefinition:
		" ***Система за писмена комуникация - директен и лесен начин да се свържете с кандидат, чиито умения намирате за подходящи за Вашата компания.",
	withoutVAT: "На месец без ДДС",
	Free: "Безплатен пакет",
	Gold: "Златен пакет",
	Platinum: "Платинен Пакет",

	//notification Settings
	chatNotificationSettingsHelpInformation: "Изпращаме имейл за непрочетени съобщения във вътрешната система за писмена комуникация за неактивни потребители, всеки ден в 11ч и 17ч сървърно време.",
	positionNotificationSettingsHelpInformation: "Посочене начин за нотификация при нов кандидат по ваша позиция.",
	freeCompanyPositionNotificationSettingsHelpInformation: "(Не може да променяте тази стойност на безплатен профил)",
  
	//Pagination
	paginationShowing: "Показване на {from} - {to} от {total} {title}",
	paginationCandidateTitle: "Кандидати",
	paginationJobsTitle: "Позиции",

	//Search
	searchNoResults: "Няма Резултати"
};
