import React, { Component } from "react";
// RCE CSS
import "react-chat-elements/dist/main.css";
// MessageBox component
//https://www.npmjs.com/package/react-chat-elements
import { MessageList, Input } from "react-chat-elements";
import Button from "@material-ui/core/Button";
import manager from "../../../Manager";
import ToastHandler from "../../../utils/ToastHandler";
import { isArray } from "../../../utils/arrayUtils";
import { isString } from "../../../utils/stringUtils";
import { isNumber } from "util";
import SendEmailToCompany from "./SendEmailToCompany";
import Translation from "../../../utils/Translation";

class BaseBoard extends Component {
    static contextType = manager;

    static defaultProps = {
        loggedUserId: undefined,
        contact: undefined,
        channel: "getSystemMessages",
        disableInput: true,
        acceptContact: contact => {},
        rejectContact: contact => {},
        blockContact: contact => {},
        unblockContact: contact => {},
        requestCorrespondence: contact => {}
    };

    state = {
        messages: [],
        scrollUpdate: false,
        disableInput: true,
        form: {
            message: undefined
        }
    };

    subChannel = false;
    serveroffset = 0;

    setServeroffset(offset) {
        this.serveroffset = offset;
    }

    componentDidMount() {
        this.openWS();
    }

    componentDidUpdate() {
        if (!this.state.scrollUpdate) {
            this.updateScroll(true);
        }
    }

    componentWillUnmount() {
        this.closeWS();
    }

    openWS() {
        window.addEventListener("resize", this.updateScroll.bind(this));
        const component = this;
        let channel = component.props.channel;
        if (channel !== "getSystemMessages") {
            channel += "/" + component.props.contact.user_id;
        }
        if (!this.subChannel) {
            this.subChannel = true;
            component.context
                .getWSManager()
                .getWS()
                .subscribe(channel, message => {
                    let messages = component.state.messages;
                    if (channel !== "getSystemMessages") {
                        component.context
                            .getWSManager()
                            .getWS()
                            .readMessages(component.props.contact.user_id);
                    }
                    if (
                        component.props.contact &&
                        component.props.contact.me_status === "accepted" &&
                        component.props.contact.you_status === "accepted"
                    ) {
                        for (let index = 0; index < message.length; index++) {
                            messages.push({
                                message: message[index].message,
                                from_id: message[index].from_id,
                                date: component.UTCToLocal(message[index].date)
                            });
                        }
                    } else if (component.props.contact) {
                        // User message
                        switch (component.props.contact.me_status) {
                            case "not_accepted":
                                messages.push({
                                    message: (
                                        <div>
                                            {Translation.translateStaticText(
                                                "requestCorrespondenceMessage",
                                                `{contact} has requested correspondence.`
                                            ).replace(
                                                "{contact}",
                                                component.props.contact
                                                    .company_name
                                                    ? component.props.contact
                                                          .company_name
                                                    : component.props.contact
                                                          .name
                                            )}
                                            <br />
                                            <br />
                                            {"\u00A0"}
                                            <Button
                                                className="btn btn-secondary btn-form display-4"
                                                onClick={() => {
                                                    this.props.acceptContact(
                                                        component.props.contact
                                                    );
                                                }}
                                            >
                                                {Translation.translateField(
                                                    "acceptButton",
                                                    "Accept"
                                                )}
                                            </Button>
                                            {"\u00A0"}
                                            <Button
                                                className="btn btn-secondary btn-form display-4"
                                                onClick={() => {
                                                    this.props.rejectContact(
                                                        component.props.contact
                                                    );
                                                }}
                                            >
                                                {Translation.translateField(
                                                    "rejectButton",
                                                    "Reject"
                                                )}
                                            </Button>
                                        </div>
                                    ),
                                    date: component.UTCToLocal(
                                        component.props.contact.contact_date
                                    )
                                });
                                break;
                            case "has_blocked":
                                messages.push({
                                    message: (
                                        <div>
                                            {Translation.translateStaticText(
                                                "blockUser",
                                                "You have Blocked {contact}"
                                            ).replace(
                                                "{contact}",
                                                component.props.contact
                                                    .company_name
                                                    ? component.props.contact
                                                          .company_name
                                                    : component.props.contact
                                                          .name
                                            )}
                                            <br />
                                            <br />
                                            {"\u00A0"}
                                            <Button
                                                className="btn btn-secondary btn-form display-4"
                                                onClick={() => {
                                                    this.props.unblockContact(
                                                        component.props.contact
                                                    );
                                                }}
                                            >
                                                {Translation.translateField(
                                                    "unblockButton",
                                                    "Unblock"
                                                )}
                                            </Button>
                                        </div>
                                    ),
                                    date: component.UTCToLocal(
                                        component.props.contact.contact_date
                                    )
                                });
                                break;
                            case "blocked":
                                messages.push({
                                    message: `${Translation.translateStaticText(
                                        "haveBlockedYou",
                                        "{contact} has Blocked you"
                                    ).replace(
                                        "{contact}",
                                        component.props.contact.name
                                    )}.`,
                                    date: component.UTCToLocal(
                                        component.props.contact.contact_date
                                    )
                                });
                                break;
                            case "removed":
                                messages.push({
                                    message: (
                                        <div>
                                            {Translation.translateStaticText(
                                                "haveRemovedYou",
                                                "{contact} has Removed you from contacts"
                                            ).replace(
                                                "{contact}",
                                                component.props.contact
                                                    .company_name
                                                    ? component.props.contact
                                                          .company_name
                                                    : component.props.contact
                                                          .name
                                            )}
                                            <br />
                                            <br />
                                            {"\u00A0"}
                                            <Button
                                                className="btn btn-secondary btn-form display-4"
                                                onClick={() => {
                                                    this.props.requestCorrespondence(
                                                        component.props.contact
                                                    );
                                                }}
                                            >
                                                {Translation.translateField(
                                                    "requestCorrespondenceAgainButton",
                                                    "Request correspondence again"
                                                )}
                                            </Button>
                                        </div>
                                    ),
                                    date: component.UTCToLocal(
                                        component.props.contact.contact_date
                                    )
                                });
                                break;
                            case "accepted":
                                if (
                                    component.props.contact.you_status ===
                                    "not_accepted"
                                ) {
                                    messages.push({
                                        message: (
                                            <div>
                                                {Translation.translateStaticText(
                                                    "hasToAcceptCorrespondence",
                                                    "{contact} has yet to accept correspondence with you"
                                                ).replace(
                                                    "{contact}",
                                                    component.props.contact
                                                        .company_name
                                                        ? component.props
                                                              .contact
                                                              .company_name
                                                        : component.props
                                                              .contact.name
                                                )}
                                                {component.props.contact
                                                    .company_name &&
                                                component.props.contact
                                                    .company_email ? (
                                                    <div>
                                                        <br />
                                                        <SendEmailToCompany
                                                            contact={
                                                                component.props
                                                                    .contact
                                                                    .company_email
                                                            }
                                                        />
                                                    </div>
                                                ) : null}
                                            </div>
                                        ),
                                        date: component.UTCToLocal(
                                            component.props.contact.contact_date
                                        )
                                    });
                                }
                                break;
                            default:
                                break;
                        }
                    } else {
                        // System message
                        if (message.length > 0 && isArray(message)) {
                            for (
                                let index = 0;
                                index < message.length;
                                index++
                            ) {
                                switch (message[index].type) {
                                    case "blocked":
                                        messages.push({
                                            message: (
                                                <div>
                                                    {Translation.translateStaticText(
                                                        "blockUser",
                                                        "You have Blocked, {contact}"
                                                    ).replace(
                                                        "{contact}",
                                                        message[index].name
                                                    )}
                                                    <br />
                                                    <br />
                                                    {"\u00A0"}
                                                    <Button
                                                        className="btn btn-secondary btn-form display-4"
                                                        onClick={() => {
                                                            this.props.unblockContact(
                                                                {
                                                                    user_id:
                                                                        message[
                                                                            index
                                                                        ]
                                                                            .sysdata
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        {Translation.translateField(
                                                            "unblockButton",
                                                            "Unblock"
                                                        )}
                                                    </Button>
                                                </div>
                                            ),
                                            date: component.UTCToLocal(
                                                message[index].date
                                            )
                                        });
                                        break;
                                    default:
                                        let sysData = undefined;
                                        if(message[index].sysdata.indexOf("{")===0) {
                                            sysData = JSON.parse(message[index].sysdata);
                                        }
                                        const onClick = ()=>{
                                            console.log(sysData.onClickRedirect);
                                            if(sysData.onClickRedirect) {
                                                console.log("redirecting");
                                                window.location.pathname = sysData.onClickRedirect;
                                            }
                                        }
                                        messages.push({
                                            message:(<div dangerouslySetInnerHTML={{ __html: message[index].message }} onClick={onClick}></div>), 
                                            date: component.UTCToLocal(
                                                message[index].date
                                            )
                                        });
                                }
                            }
                        }
                    }
                    const update =
                        this.state.messages && this.state.messages.length > 0;
                    component.setState(
                        {
                            ...component.state,
                            messages: messages,
                            disableInput:
                                component.props.disableInput ||
                                !component.props.contact ||
                                component.props.contact.me_expired === "yes" ||
                                component.props.contact.you_expired === "yes" ||
                                component.props.contact.me_status !==
                                    "accepted" ||
                                component.props.contact.you_status !==
                                    "accepted"
                        },
                        () => {
                            if (update) {
                                component.updateScroll(true);
                            }
                            console.log(component.props.contact);
                            if (
                                component.props.contact &&
                                (component.props.contact.me_expired === "yes" ||
                                    component.props.contact.you_expired ===
                                        "yes")
                            ) {
                                ToastHandler.showInfo(
                                    Translation.translateResponse(
                                        "15007",
                                        "The company has expired and can not use insystem messaging."
                                    )
                                );
                            }
                        }
                    );
                });
        }
    }

    closeWS() {
        const component = this;
        let channel = component.props.channel;
        if (channel !== "getSystemMessages") {
            channel += "/" + component.props.contact.user_id;
        }
        this.subChannel = false;
        component.context
            .getWSManager()
            .getWS()
            .unsubscribe(channel);
    }

    updateScroll(updateState = false) {
        let messageListContainer = document.getElementById(
            "messageListContainer"
        );
        messageListContainer.scrollTop = messageListContainer.scrollHeight;
        if (updateState) {
            this.setState({
                ...this.state,
                scrollUpdate: true
            });
        }
    }

    changeMessage() {
        const component = this;
        return element => {
            element.target.onKeyPress = component.sendMessage;
            component.setState({
                ...component.state,
                form: {
                    message: element.target.value
                }
            });
        };
    }

    submitIfEnter = e => {
        if (e.charCode === 13) {
            e.preventDefault();
            e.stopPropagation();
            this.sendMessage();
        }
    };

    sendMessage() {
        if (!this.state.form.message) {
            ToastHandler.showInfo(
                Translation.translateMessage(
                    "cannotSendEmptyMessages",
                    "Can not send empty message"
                )
            );
            return;
        }
        if (this.props.contact.you_status !== "accepted") {
            ToastHandler.showInfo(
                Translation.translateMessage(
                    "hasToAcceptCorrespondence",
                    "{contact} has yet to accept correspondence with you"
                ).replace("{contact}", this.props.contact.name)
            );
            return;
        }
        if (this.props.contact.me_status !== "accepted") {
            ToastHandler.showInfo(
                Translation.translateMessage(
                    "haveToAcceptCorrespondance",
                    "You have yet to accept correspondence with {contact}"
                ).replace("{contact}", this.props.contact.name)
            );
            return;
        }
        this.context
            .getWSManager()
            .getWS()
            .sendMessage(this.props.contact.user_id, this.state.form.message);
        let messages = this.state.messages;
        messages.push({
            from_id: this.props.loggedUserId,
            message: this.state.form.message,
            date: new Date()
        });
        this.refs.input.clear();
        this.setState({
            ...this.state,
            messages: messages,
            scrollUpdate: false,
            form: {
                message: undefined
            }
        });
    }

    UTCToLocal(str) {
        if (isNumber(str)) {
            return new Date(str);
        } else if (
            isString(str) &&
            str[str.length - 1] !== "Z" &&
            str.split(" ").length === 2
        ) {
            let split = str.split(" ");
            return new Date(split[0] + "T" + split[1] + "Z");
        } else if (isString(str) && str[str.length - 1] === "Z") {
            return new Date(str);
        }
        return str;
    }

    exactDate(date) {
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        return [
            date.getFullYear(),
            (mm > 9 ? "" : "0") + mm,
            (dd > 9 ? "" : "0") + dd
        ].join("-");
    }
    timeSince(date) {
        var seconds = Math.floor((new Date() - date) / 1000);
        var interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
            return this.exactDate(date);
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
            return this.exactDate(date);
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
            return this.exactDate(date);
        }
        interval = Math.floor(seconds / 3600);
        if (interval === 1) {
            return `~ ${Translation.translateStaticText(
                "oneHourAgo",
                "1 hour ago"
            )}`;
        }
        if (interval > 1) {
            return `~ ${Translation.translateStaticText(
                "hoursAgo",
                "{interval} hours ago"
            ).replace("{interval}", interval)}`;
        }
        interval = Math.floor(seconds / 60);
        if (interval === 1) {
            return `~ ${Translation.translateStaticText(
                "oneMinuteAgo",
                "1 minute ago"
            )}`;
        }
        if (interval > 1) {
            return `~ ${Translation.translateStaticText(
                "minutesAgo",
                "{interval} minutes ago"
            ).replace("{interval}", interval)}`;
        }
        let secs = Math.floor(seconds);
        if (secs === 0) {
            return Translation.translateStaticText("now", "Now");
        } else if (secs === 1) {
            return Translation.translateStaticText(
                "oneSecondAgo",
                "a second ago"
            );
        }
        return `~ ${Translation.translateStaticText(
            "secondsAgo",
            "{interval} seconds ago"
        ).replace("{interval}", secs)}`;
    }

    render() {
        let component = this;
        return (
            <div style={{ position: "relative", height: "inhar" }}>
                <div
                    style={{ height: "53vh", width: "100%", overflowY: "auto" }}
                    id="messageListContainer"
                >
                    <MessageList
                        className="message-list"
                        lockable={true}
                        toBottomHeight={"100%"}
                        dataSource={this.state.messages.map(message => {
                            return {
                                position:
                                    message.from_id &&
                                    message.from_id !== this.props.loggedUserId
                                        ? "left"
                                        : "right",
                                type: "text",
                                text: message.message,
                                dateString: component.timeSince(message.date)
                            };
                        })}
                    />
                </div>
                {this.state.disableInput ? (
                    ""
                ) : (
                    <Input
                        ref="input"
                        placeholder={Translation.translateField(
                            "chatPlaceholder",
                            "Your Message..."
                        )}
                        multiline={false}
                        onChange={this.changeMessage()}
                        onKeyPress={this.submitIfEnter}
                        autofocus={true}
                        maxHeight="25"
                        defaultValue={this.state.form.message}
                        inputStyle={{ border: "1px solid black" }}
                        rightButtons={
                            <Button
                                className="btn btn-secondary btn-form display-4"
                                onClick={() => {
                                    this.sendMessage();
                                }}
                            >
                                {Translation.translateField(
                                    "sendButton",
                                    "Send"
                                )}
                            </Button>
                        }
                    />
                )}
            </div>
        );
    }
}

export default BaseBoard;
