export default {
	employers: "Работодатели",
	jobseekers: "Кандидати",	
	signUp: "Регистрация",
	aboutUs: "За нас",
	pricing: "Цени",
	login: "Вход",
	search: "Търси",
	positions: "Позиции",
	chat: "Чат",
	profile: "Профил",
	notificationSettings: "Известия",
	logout: "Изход"
};
