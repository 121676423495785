import React from "react";
import swal from "sweetalert";

import Plan from "./Plan";
import manager from "../../Manager";
import LinkButton from "../navigation/LinkButton";
import { usertype, companytype, frontend } from "../../config";
import Translation from '../../utils/Translation';

class Pricing extends React.Component {
    static contextType = manager;

    state = {
        user: {},
        pricing: [],
        loaded: false
    };

    componentWillMount() {
        const component = this;
        if (this.context.getAuthManager().isLogged()) {
            component.context
                .getAuthManager()
                .pageRestriction({ forLoggedUsertype: [usertype.company] });
        }
        component.context.getAuthManager().getLoggedUser(component, {
            onFinish: {
                callback: loggedUser => {
                    component.context
                        .getGenericManager()
                        .getPricing(component, {
                            onFinish: {
                                callback: PricingResponse => {
                                    component.setState({
                                        ...component.state,
                                        pricing: PricingResponse,
                                        user: loggedUser,
                                        loaded: true
                                    });
                                }
                            }
                        });
                }
            }
        });
    }

    checkIfLogged = e => {
        if (!this.context.getAuthManager().isLogged()) {
            e.preventDefault();
            swal({
                icon: "warning",
                title: Translation.translateStaticText(
                    "warningHeader",
                    "Warning"
                ),
                text: Translation.translateStaticText(
                    "pricingLoggedInWarning",
                    "You need to be logged in first."
                ),
                buttons: {
                    cancel: Translation.translateField(
                        "cancelButton",
                        "Cancel"
                    ),
                    login: {
                        text: Translation.translateField(
                            "loginButton",
                            "Login"
                        )
                    },
                    register: {
                        text: Translation.translateField(
                            "registerButton",
                            "Register"
                        )
                    }
                }
            }).then(value => {
                switch (value) {
                    case "login":
                        window.location.assign(
                            frontend.DOMAIN + frontend.route.company.login
                        );
                        break;

                    case "register":
                        window.location.assign(
                            frontend.DOMAIN + frontend.route.company.register
                        );
                        break;

                    default:
                        break;
                }
            });
        }
    };

    render() {
        if (!this.state.loaded) return null;
        let freeButton;
        let goldButton;
        let platinumButton;

        if (!this.context.getAuthManager().isLogged()) {
            freeButton = (
                <LinkButton
                    onClick={this.checkIfLogged}
                    to={frontend.route.company.register}
                    className="btn btn-primary display-4"
                    style={{ fontSize: "16px", borderRadius: "5px" }}
                >
                    {Translation.translateField("buyNowButton", "BUY NOW")}
                </LinkButton>
            );
            goldButton = freeButton;
            platinumButton = freeButton;
        } else {
            freeButton = (
                <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                    {Translation.translateStaticText("ownedPlan", "Owned")}
                </span>
            );
            goldButton = (
                <LinkButton
                    onClick={this.checkIfLogged}
                    to={frontend.route.purchase.goldMembership}
                >
                    {Translation.translateField("purchaseButton", "Purchase")}
                </LinkButton>
            );
            platinumButton = (
                <LinkButton
                    onClick={this.checkIfLogged}
                    to={frontend.route.purchase.platinumMembership}
                >
                    {Translation.translateField("purchaseButton", "Purchase")}
                </LinkButton>
            );
            if (this.state.user.user_type === usertype.company) {
                switch (this.state.user.company_type) {
                    case companytype.gold:
                        goldButton = freeButton;
                        break;
                    case companytype.platimum:
                        goldButton = freeButton;
                        platinumButton = freeButton;
                        break;
                    default:
                        break;
                }
            }
        }

        return (
            <div>
                <section
                    className="cid-rgc6tm1qbG mbr-parallax-background"
                    id="pricing-tables1-f"
                >
                    <div className="container">
                        {this.state.pricing.length <= 0 ? null : (
                            <div className="media-container-row">
                                <Plan
                                    name={this.state.pricing[0].name}
                                    currentPrice={this.state.pricing[0].price}
                                    previousPrice={
                                        this.state.pricing[0].promotion_from !==
                                        undefined
                                            ? this.state.pricing[0]
                                                  .promotion_from
                                            : undefined
                                    }
                                    perks={[
                                        Translation.translateStaticText(
                                            "perk1",
                                            "Create Company Profile"
                                        ),
                                        Translation.translateStaticText(
                                            "perk2",
                                            "Publish One Position"
                                        )
                                    ]}
                                    button={freeButton}
                                />
                                <Plan
                                    name={this.state.pricing[1].name}
                                    currentPrice={this.state.pricing[1].price}
                                    previousPrice={
                                        this.state.pricing[1].promotion_from !==
                                        undefined
                                            ? this.state.pricing[1]
                                                  .promotion_from
                                            : undefined
                                    }
                                    perks={[
                                        Translation.translateStaticText(
                                            "perk1",
                                            "Create Company Profile"
                                        ),
                                        Translation.translateStaticText(
                                            "perk3",
                                            "Publish Unlimited Number of Positions"
                                        ),
                                        Translation.translateStaticText(
                                            "perk4",
                                            "Send *InSystem Messages"
                                        ),
                                        Translation.translateStaticText(
                                            "perk5",
                                            "Have Access To a Pool of Profiles"
                                        )
                                    ]}
                                    button={goldButton}
                                />
                                <Plan
                                    name={this.state.pricing[2].name}
                                    currentPrice={this.state.pricing[2].price}
                                    previousPrice={
                                        this.state.pricing[2].promotion_from !==
                                        undefined
                                            ? this.state.pricing[2]
                                                  .promotion_from
                                            : undefined
                                    }
                                    perks={[
                                        Translation.translateStaticText(
                                            "perk1",
                                            "Create Company Profile"
                                        ),
                                        Translation.translateStaticText(
                                            "perk3",
                                            "Publish Unlimited Number of Positions"
                                        ),
                                        Translation.translateStaticText(
                                            "perk4",
                                            "Send *InSystem Messages"
                                        ),
                                        Translation.translateStaticText(
                                            "perk5",
                                            "Have Access To a Pool of Profiles"
                                        ),
                                        Translation.translateStaticText(
                                            "perk6",
                                            "Guaranteed Logo Place on First Page and Social Media Ads"
                                        )
                                    ]}
                                    button={platinumButton}
                                />
                            </div>
                        )}
                    </div>
                </section>
                <section
                    className="mbr-section article content1 cid-rh6yn6gBtl"
                    id="content2-h"
                >
                    <div className="container">
                        <div className="media-container-row">
                            <div className="mbr-text col-12 col-md-8 mbr-fonts-style display-7">
                                <blockquote className="">
                                    <strong>
                                        {Translation.translateStaticText(
                                            "inSystemMessagesDefinition",
                                            "***InSystem Messages - direct and easy way to contact a person, whose skills you find suitable for your company."
                                        )}
                                    </strong>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Pricing;
