import React, {Component} from 'react';
import AutocompleteSelect from '../../form_helpers/AutocompleteSelect';

class Edit extends Component {
  state = {
    showDropdown: false,
    selectedSkill: [],
  }

  toggleDropdown = (inputValue, event) => {
      let showDropdown = false;
      if((event.action !== 'menu-close' || event.action !== 'set-value') 
        &&  inputValue.length > 0) {
        showDropdown = true;
      }

      this.setState({
        ...this.state, 
        showDropdown: showDropdown,
      })
    }
  addSkill = (newValue, actionMeta) => {
    this.setState({...this.state,showDropdown: false, selectedSkill: newValue})
    this.props.addSkill(newValue)
  }

  render(){
    const {selectedSkills,approvedSkills,placeholder} = this.props
    return (
    <AutocompleteSelect 
      creatable={true}
      isMulti
      menuIsOpen={this.state.showDropdown}
      onInputChange={this.toggleDropdown}
      openMenuOnClick={false}
      value={selectedSkills}
      onChange={this.addSkill}
      options={approvedSkills}
      menuPlacement="top"
      placeholder={placeholder}
      styles={{
          dropdownIndicator: provided => ({
            ...provided,
            display: "none"
          })
        }}
    />
  )}
}

export default Edit;