import { positionType } from "../../config";
const positionAvailabilityTypes = {
	en: {
		...positionType
	},
	bg: {
		full_time: "Пълен Работен Ден",
		part_time: "Непълен Работен Ден"
	},
	de: {}
};

const educationLevels = {
	en: {
		"Primary Education": "Primary Education",
		"Lower Secondary Education": "Lower Secondary Education",
		"Higher Secondary Education": "Higher Secondary Education",
		"Post-secondary Education": "Post-secondary Education",
		"Bachelor or Equivalent": "Bachelor or Equivalent",
		"Master or Equivalent": "Master or Equivalent",
		"Doctoral or Equivalent": "Doctoral or Equivalent"
	},
	bg: {
		"Primary Education": "Oсновно образование",
		"Lower Secondary Education": "Средно образование",
		"Higher Secondary Education": "Средно специално образование",
		"Post-secondary Education": "Професионален бакалавър",
		"Bachelor or Equivalent": "Бакалавърска степен",
		"Master or Equivalent": "Магистърска степен",
		"Doctoral or Equivalent": "Доцент доктор"
	},
	de: {}
};

const positionCategories = {
	en: {
		"Accounting, Finance, Banking": "Accounting, Finance, Banking",
		"Administrative and office activities":
			"Administrative and office activities",
		"Agriculture and Forestry, Fisheries":
			"Agriculture and Forestry, Fisheries",
		"Architecture, Construction and Urban Planning":
			"Architecture, Construction and Urban Planning",
		"Art, Entertainment, Promotions": "Art, Entertainment, Promotions",
		"Aviation, Airports and Airlines": "Aviation, Airports and Airlines",
		"Cars, Auto Service, Petrol Stations":
			"Cars, Auto Service, Petrol Stations",
		"Cleaning, Household Services": "Cleaning, Household Services",
		"Contact centers {Call centers)": "Contact centers {Call centers)",
		"Design, Creative, Video and Animation":
			"Design, Creative, Video and Animation",
		"Drivers, couriers": "Drivers, couriers",
		"Education, Courses, Translations": "Education, Courses, Translations",
		"Energy and Utilities {Electricity/Water/Steam/Gas)":
			"Energy and Utilities {Electricity/Water/Steam/Gas)",
		Engineers: "Engineers",
		"Hardware development / support": "Hardware development / support",
		"Health and pharmacy": "Health and pharmacy",
		"Human Resources": "Human Resources",
		"Institutions, State Administration":
			"Institutions, State Administration",
		Insurance: "Insurance",
		"Law, Legal Services": "Law, Legal Services",
		"Manufacturing - Textiles and Clothing":
			"Manufacturing - Textiles and Clothing",
		"Marketing, Advertising, PR": "Marketing, Advertising, PR",
		"Media, Publishing": "Media, Publishing",
		"Production - Chemistry and Fuels": "Production - Chemistry and Fuels",
		"Production - Electronics, Electrical Engineering, Machine Building":
			"Production - Electronics, Electrical Engineering, Machine Building",
		"Production - Food and Beverages": "Production - Food and Beverages",
		"Production - Furniture and Carpentry":
			"Production - Furniture and Carpentry",
		"Production - Metallurgy and Mining":
			"Production - Metallurgy and Mining",
		"Production - Pharmacy": "Production - Pharmacy",
		"Real Estate": "Real Estate",
		"Repairs and Assembly Activities": "Repairs and Assembly Activities",
		"Restaurant, Catering, Hotels": "Restaurant, Catering, Hotels",
		"Security and Guarding": "Security and Guarding",
		"Software development / support": "Software development / support",
		"Sports, Kinesitherapy": "Sports, Kinesitherapy",
		Telecommunications: "Telecommunications",
		"Trading and sales": "Trading and sales",
		"Transport, Logistics, Forwarding, Sea and River Transport":
			"Transport, Logistics, Forwarding, Sea and River Transport"
	},
	bg: {
		"Accounting, Finance, Banking":
			"Счетоводство, финанси, банкиране/банково дело",
		"Administrative and office activities":
			"Административни и офис дейности",
		"Agriculture and Forestry, Fisheries":
			"Селско и горско стопанство, рибарство",
		"Architecture, Construction and Urban Planning":
			"Архитектура, строителство и градоустройство",
		"Art, Entertainment, Promotions": "Изкуство, развлечения, промоции",
		"Aviation, Airports and Airlines": "Авиация, летища и авиокомпании",
		"Cars, Auto Service, Petrol Stations":
			"Автомобили, Автосервиз, Бензиностанции",
		"Cleaning, Household Services": "Почистване, битови услуги",
		"Contact centers {Call centers)": "Контактни центрове (Call центрове)",
		"Design, Creative, Video and Animation":
			"Дизайн, творчество, видео и анимация",
		"Drivers, couriers": "Шофьори, куриери",
		"Education, Courses, Translations":
			"Образование, курсове, транслация/преводи",
		"Energy and Utilities {Electricity/Water/Steam/Gas)":
			"Енергия и комунални услуги (Електричество / Вода / Пара / Газ]",
		Engineers: "Инженери",
		"Hardware development / support": "Разработка / поддръжка на хардуер",
		"Health and pharmacy": "Здраве и фармация",
		"Human Resources": "Човешки ресурси",
		"Institutions, State Administration":
			"Институции, държавна администрация",
		Insurance: "Застраховане",
		"Law, Legal Services": "Право, правни услуги",
		"Manufacturing - Textiles and Clothing":
			"Производство - текстил и облекло",
		"Marketing, Advertising, PR":
			"Маркетинг, реклама, връзки с обществеността",
		"Media, Publishing": "Медии, издателство",
		"Production - Chemistry and Fuels": "Производство - Химия и горива",
		"Production - Electronics, Electrical Engineering, Machine Building":
			"Производство - електроника, електротехника, машиностроене",
		"Production - Food and Beverages": "Производство - храни и напитки",
		"Production - Furniture and Carpentry":
			"Производство - мебели и дърводелство",
		"Production - Metallurgy and Mining":
			"Производство - металургия и минен добив",
		"Production - Pharmacy": "Производство - Фармация",
		"Real Estate": "Недвижими имоти",
		"Repairs and Assembly Activities": "Ремонт и монтажни дейности",
		"Restaurant, Catering, Hotels":
			"Ресторантьорство, кетъринг, хотелиерство",
		"Security and Guarding": "Сигурност и охрана",
		"Software development / support": "Разработка / поддръжка на софтуер",
		"Sports, Kinesitherapy": "Спорт, Кинезитерапия",
		Telecommunications: "Телекомуникации",
		"Trading and sales": "Търговия и продажби",
		"Transport, Logistics, Forwarding, Sea and River Transport":
			"Транспорт, логистика, спедиция, морски и речен транспорт"
	},
	de: {}
};

const positionQualifications = {
	en: {
		"Higher management level": "Higher management level",
		"Middle management level": "Middle management level",
		"non-managerial / Experts level": "non-managerial / Experts level",
		"Administrative level": "Administrative level",
		Workers: "Workers",
		Unqualified: "Unqualified",
		"Students / Trainees": "Students / Trainees"
	},
	bg: {
		"Higher management level": "Високо ниво на управление",
		"Middle management level": "Средно ниво на управление",
		"non-managerial / Experts level": "неменажиращо / експертно ниво",
		"Administrative level": "Административно ниво",
		Workers: "Работници",
		Unqualified: "Неквалифициран",
		"Students / Trainees": "Студенти / стажанти"
	},
	de: {}
};

export {
	positionAvailabilityTypes,
	positionCategories,
	educationLevels,
	positionQualifications
};
