const accessLevels = {
    en: {
        full: "Full",
        limited: "Limited",
        none: "None"
    },
    bg: {
        full: "Пълен",
        limited: "Лимитиран",
        none: "Без достъп"
    },
    de: {}
};

export default accessLevels;
