import React, { Component, Fragment } from "react";
import MaterialTable from "material-table";
import { MTableToolbar } from "material-table";

import Select from "../../form_helpers/Select";
import manager from "../../../Manager";
import { usertype } from "../../../config";
import Translation from "../../../utils/Translation";

class ProfileAccess extends Component {
	static contextType = manager;
	static defaultProps = {
		accessLevels: [],
		providedAccess: [],
		data: [],
		locale: "en-DE",
		profileCompletion: 0
	};

	columns = [];

	constructor(props) {
		super(props);
		const parsedData = this.props.data.map(row => {
			return {
				access: row.access,
				company: row.company.company_id,
				permission: row.permission_type
			};
		});
		this.state = {
			data: parsedData,
			actions: {}
		};
	}

	companyLookup(data) {
		let companyLookup = {};
		data.forEach(
			row => (companyLookup[row.company.company_id] = row.company.name)
		);
		return companyLookup;
	}

	accessLevelsLookup(accessLevels) {
		return accessLevels.reduce((reducer, level) => {
			reducer[level.value] = level.text;
			return reducer;
		}, {});
	}

	rowUpdate = (newData, oldData) => {
		return new Promise((resolve, reject) => {
			/** We use old data because of a bug in the library where
			 * lookup with non editable fields display the key instead of the value
			 * we hack throught that by allowing the field to be editable, but
			 * for security precaution we use the old that for the fields, that are not supposed
			 * to be updated.
			 */
			const { tableData, ...rest } = oldData;
			const body = {
				...rest,
				permission: newData.permission
			};
			this.context
				.getJobseekerManager()
				.updateJobseekerAccess(this, body, {
					onError: {
						callback: error => {
							resolve();
						}
					},
					onSuccess: {
						message: Translation.translateMessage("Saved", "Saved"),
						callback: dataReturn => {
							let data = this.state.data;
							const index = data.indexOf(oldData);
							data[index] = {
								...data[index],
								permission: body.permission
							};
							this.setState({ data }, () => resolve());
						}
					}
				});
			resolve();
		});
	};

	rowDelete = oldData => {
		return new Promise((resolve, reject) => {
			let component = this;
			this.context
				.getJobseekerManager()
				.deleteJobseekerAccess(component, oldData.access, {
					onFinish: {
						message: Translation.translateMessage(
							"Deleted",
							"Deleted"
						),
						callback: dataReturn => {
							let data = component.state.data;
							const index = data.indexOf(oldData);
							data.splice(index, 1);
							component.setState({ data }, () => resolve());
							resolve();
						}
					},
					onError: {
						callback: error => {
							resolve();
						}
					}
				});
		});
	};

	componentWillMount() {
		this.context.getAuthManager().pageRestriction({
			logged: true,
			forLoggedUsertype: [usertype.jobseeker]
		});
		this.context.getJobseekerManager().getProfileCompletion(this, {
			onSuccess: {
				callback: data => {
					const profileCompletion =
						this.props.profileCompletion !== data
							? data
							: this.props.profileCompletion;
					if (profileCompletion === 100) {
						this.setState({
							...this.state,
							actions: {
								onRowUpdate: this.rowUpdate,
								onRowDelete: this.rowDelete
							}
						});
					}
				}
			}
		});
	}

	render() {
		return (
			<Fragment>
				<MaterialTable
					className="mb-2"
					title={Translation.translateStaticText(
						"JobseekerCompanyAccessHeading",
						"Company Access"
					)}
					columns={[
						{
							title: Translation.translateField(
								"jobseekerCompanyAccessNameColumn",
								"Company Name"
							),
							field: "company",
							lookup: this.companyLookup(this.props.data),
							editable: "never"
						},
						{
							title: Translation.translateField(
								"jobseekerCompanyAccessAccessColumn",
								"Access Provided"
							),
							field: "permission",
							lookup: this.accessLevelsLookup(this.props.accessLevels),
							editComponent: props => (
								<Select
									name="visibility"
									value={props.value}
									options={this.props.accessLevels}
									onSelectChange={props.onChange}
								/>
							)
						}
					]}
					data={this.state.data}
					options={{ actionsColumnIndex: -1 }}
					localization={{
						header: {
							actions: Translation.translateField(
								"jobseekerCompanyAccessActionsColumn",
								"Actions"
							)
						},
						body: {
							editRow: {
								deleteText: Translation.translateStaticText(
									"jobseekerCompanyAccessDeleteText",
									"Remove this access?"
								),
								cancelTooltip: Translation.translateField(
									"cancelButton",
									"Cancel"
								),
								saveTooltip: Translation.translateField(
									"saveButton",
									"Save"
								)
							},
							emptyDataSourceMessage: Translation.translateStaticText(
								"jobseekerCompanyAccessNoRecordsPlaceholder",
								"No records to display"
							),
							deleteTooltip: Translation.translateField(
								"deleteButton",
								"Delete"
							),
							editTooltip: Translation.translateField(
								"editButton",
								"Edit"
							)
						},
						toolbar: {
							searchPlaceholder: Translation.translateField(
								"jobseekerCompanyAccessSearchPlaceholder",
								"Search"
							)
						},
						pagination: {
							labelDisplayedRows: Translation.translateStaticText(
								"jobseekerCompanyAccessPaginationCount",
								"{from}-{to} of {count}"
							),
							labelRowsSelect: Translation.translateStaticText(
								"jobseekerCompanyAccessPaginationLabel",
								"rows"
							),
							firstTooltip: Translation.translateField(
								"firstTooltipButton",
								"First Page"
							),
							previousTooltip: Translation.translateField(
								"previousTooltipButton",
								"Previous Page"
							),
							nextTooltip: Translation.translateField(
								"nextTooltipButton",
								"Next Page"
							),
							lastTooltip: Translation.translateField(
								"lastTooltipButton",
								"Last Page"
							)
						}
					}}
					editable={this.state.actions}
					components={{
						Toolbar: props => (
							<div>
								<MTableToolbar {...props} />
								<div style={{ padding: "0 10px" }}>
									<p>
										{Translation.translateStaticText(
											"jobseekerComapnyAccessProfileCompletionRequirement"
										)}
									</p>
								</div>
							</div>
						)
					}}
				/>
				{this.props.children}
			</Fragment>
		);
	}
}

export default ProfileAccess;
