import React, { Fragment } from "react";
import { languages } from "../../../config";
import Translation from "../../../utils/Translation";

export default function SendEmailToCompany(props) {
	const getTemplate = language => {
		const enTemplate = (
			<Fragment>
				<p className="mb-0">In a hurry? Send email to the company on</p>
				<a href={`mailto:${props.contact}?subject=Match%20Careers`}>
					{props.contact}
				</a>
			</Fragment>
		);
		
		const bgTemplate = (
			<Fragment>
				<p className="mb-0">Бързате? Изпратете имейл до компанията на</p>
				<a href={`mailto:${props.contact}?subject=Match%20Careers`}>
					{props.contact}
				</a>
			</Fragment>
			);
		const deTemplate = enTemplate;

		switch (language) {
			case languages.english:
				return enTemplate;
			case languages.bulgarian:
				return bgTemplate;
			case languages.german:
				return deTemplate;
			default:
				return enTemplate;
		}
	};

	return getTemplate(Translation.getLanguage());
}

SendEmailToCompany.defaultProps = {
	contact: "" //company email
};
