import React, { Component } from "react";
import manager from "../../Manager";
import Translation from "../../utils/Translation";
import { frontend } from '../../config';
class Footer extends Component {
    static contextType = manager;

    state = {
        loaded: false,
        logged: false,
        user: {}
    };

    componentWillMount() {
        const component = this;
        component.context.getAuthManager().getLoggedUser(component, {
            onSuccess: {
                toast: false
            },
            onFinish: {
                callback: data => {
                    if (data) {
                        component.setState({
                            ...component.state,
                            loaded: true,
                            user: data,
                            logged: data !== "User is not logged."
                        });
                    }
                }
            }
        });
    }

    render() {
        if (!this.state.loaded) {
            return null;
        }
        return (
            <section className="cid-qTkAaeaxX5" id="footer1-2">
                <div className="container">
                    <div className="media-container-row content text-white">
                        <div className="col-12 col-md-3">
                            <div className="media-wrap">
                                <a href="/" className="">
                                    <img
                                        src="/assets/images/logo-match-career.jpg"
                                        alt="Home"
                                        title="Match.Careers"
                                        className=""
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mbr-fonts-style display-7">
                            <h5 className="pb-3">
                                {Translation.translateStaticText(
                                    "footerAddressHeading",
                                    "Address"
                                )}
                            </h5>
                            <p
                                className="mbr-text"
                                dangerouslySetInnerHTML={{
                                    __html: Translation.translateStaticText(
                                        "footerAddress",
                                        "15, Tsarigradsko Shose Blvd.<br/>3rd entrance, fl.2, 1124, Sofia, Bulgaria"
                                    )
                                }}
                            />
                        </div>
                        <div className="col-12 col-md-4 mbr-fonts-style display-7">
                            {this.state.logged ? (
                                <div>
                                    <h5 className="pb-3">
                                        {Translation.translateStaticText(
                                            "footerFAQHeader",
                                            "Have questions?"
                                        )}
                                    </h5>
                                    <p
                                        className="mbr-text"
                                        dangerouslySetInnerHTML={{
                                            __html: Translation.translateStaticText(
                                                "footerFAQ",
                                                `Visit our <a href={location}>FAQ</a>`
                                            ).replace("{location}", frontend.DOMAIN+frontend.route.faq)
                                        }}
                                    />
                                </div>
                            ) : null}
                            <h5 className="pb-3">
                                {Translation.translateStaticText(
                                    "footerContactsHeader",
                                    "Contacts"
                                )}
                            </h5>
                            <p className="mbr-text">
                                {Translation.translateStaticText(
                                    "footerContactsEmailHeader",
                                    "Email"
                                )}
                                : office@match.careers
                                <br />
                                {Translation.translateStaticText(
                                    "footerContactsPhoneHeader",
                                    "Phone"
                                )}
                                : +359 898 774 992 | +359 898 774 993
                            </p>
                        </div>
                    </div>
                    <div className="footer-lower">
                        <div className="media-container-row">
                            <div className="col-sm-12">
                                <hr />
                            </div>
                        </div>
                        <div className="media-container-row mbr-white">
                            <div className="col-sm-6 copyright">
                                <p className="mbr-text mbr-fonts-style display-7">
                                    ©{" "}
                                    {Translation.translateStaticText(
                                        "footerCopyright",
                                        `Copyright ${new Date().getFullYear()} InspireSoft- All Rights Reserved`
                                    ).replace(
                                        "{year}",
                                        new Date().getFullYear()
                                    )}
                                </p>
                            </div>
                            <div className="col-md-6">
                                <div className="social-list align-right">
                                    <div className="soc-item">
                                        <a
                                            href="https://twitter.com/MatchCareers"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className=""
                                        >
                                            <span className="socicon-twitter socicon mbr-iconfont mbr-iconfont-social" />
                                        </a>
                                    </div>
                                    <div className="soc-item">
                                        <a
                                            href="https://www.facebook.com/pg/MatchCareers-1440023142805676"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className=""
                                        >
                                            <span className="socicon-facebook socicon mbr-iconfont mbr-iconfont-social" />
                                        </a>
                                    </div>
                                    <div className="soc-item">
                                        <a
                                            href="https://www.linkedin.com/company/matchcareerss"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className=""
                                        >
                                            <span className="socicon-linkedin socicon mbr-iconfont mbr-iconfont-social" />
                                        </a>
                                    </div>
                                    <div className="soc-item">
                                        <a
                                            href="https://www.youtube.com/channel/UCSLPaGQdENYj9BtoTjeSTzA?disable_polymer=true"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className=""
                                        >
                                            <span className="socicon-youtube socicon mbr-iconfont mbr-iconfont-social" />
                                        </a>
                                    </div>
                                    <div className="soc-item">
                                        <a
                                            href="https://www.instagram.com/match_careers_/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className=""
                                        >
                                            <span className="socicon-instagram socicon mbr-iconfont mbr-iconfont-social" />
                                        </a>
                                    </div>
                                    <br />
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="scrollToTop" className="scrollToTop mbr-arrow-up">
                    <a href="#top" style={{ textAlign: "center" }}>
                        <i className="mbr-arrow-up-icon mbr-arrow-up-icon-cm cm-icon cm-icon-smallarrow-up" />
                    </a>
                </div>
            </section>
        );
    }
}

export default Footer;
