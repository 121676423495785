
import { toast } from 'react-toastify';

// https://github.com/fkhadra/react-toastify#installation

class ToastHandler {

  showError(message, options=undefined) {
    if(options===undefined) {
      options = {position: toast.POSITION.TOP_RIGHT}
    }
    toast.error(message, options);
  }

  showInfo(message, options=undefined) {
    if(options===undefined) {
      options = {position: toast.POSITION.TOP_RIGHT}
    }
    toast.info(message, options);
  }

  showWarn(message, options=undefined) {
    if(options===undefined) {
      options = {position: toast.POSITION.TOP_RIGHT}
    }
    toast.warn(message, options);
  }

  showSuccess(message, options=undefined) {
    if(options===undefined) {
      options = {position: toast.POSITION.TOP_RIGHT}
    }
    toast.success(message, options);
  }

}

export default new ToastHandler();