import React, { Component } from "react";

import NextJobIsHere from "../sections/NextJobIsHere";
import PremiumClients from "../sections/PremiumClients";
import Page from "../Page";
import Search from "../sections/Search";
import { setTitle } from "../../pageOptions";
import manager from "../../Manager";
import { usertype } from "../../config";
import Translation from "../../utils/Translation";

class HomePage extends Component {
  static contextType = manager;

  state = {
    logged: false,
    user: {}
  };

  componentWillMount() {
    const component = this;
    component.context.getAuthManager().getLoggedUser(component, {
      onSuccess: {
        toast: false
      },
      onFinish: {
        callback: data => {
          if (data) {
            component.setState({
              ...component.state,
              user: data,
              logged: data !== "User is not logged."
            });
          }
          if (data === "User is not logged.") {
            setTitle(Translation.translateStaticText("homePageTitle", "Home"));
          } else {
            setTitle(
              Translation.translateStaticText("searchPageTitle", "Search")
            );
          }
        }
      }
    });
  }

  render() {
    let page = undefined;
    if (this.state.logged) {
      page = (
        <Page location={this.props.location}>
          <Search location={this.props.location} user={this.state.user} />
          {this.state.user &&
          this.state.user.user_type === usertype.jobseeker ? (
            <PremiumClients />
          ) : null}
        </Page>
      );
    } else {
      page = (
        <Page location={this.props.location}>
          <div>
            <NextJobIsHere />
          </div>
          <PremiumClients />
        </Page>
      );
    }

    return page;
  }
}

export default HomePage;
