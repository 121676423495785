
import React  from 'react';

import manager from '../Manager';

class SubMenuItem extends React.Component {
    static contextType = manager;

    static defaultProps = {
        renderOnLogged: true,
        renderOnUnlogged: true,
        renderOnUserType: undefined,
        name: '',
        href: "/",
        onClick: undefined
    }

    state = {
        render: false
    }

    componentWillMount() {
        const component = this;
        component.context.getAuthManager().getLoggedUser(component, {
            onFinish: {
                callback: (data) => {
                    if(component.context.getAuthManager().isLogged()) {
                        if(!component.props.renderOnLogged || (component.props.renderOnUserType!==undefined && data.user_type!==component.props.renderOnUserType)) {
                            return;
                        }
                    }
                    if(!component.context.getAuthManager().isLogged() && !component.props.renderOnUnlogged) {
                        return;
                    }
                    component.setState({render: true});
                }
            }
        });
    }

    onClick = e => {
        e.preventDefault();
        if(this.props.onClick!==undefined) {
            this.props.onClick(e);
        } else {
            window.location.href = window.location.origin+this.props.href;
        }
    }

    render() {
        if(!this.state.render) return null;
        let link = undefined;
        if(this.props.onClick) {
            link = <a className="text-white dropdown-item display-7" onClick={this.onClick} href={this.props.href} aria-expanded="false">{this.props.name}<br/></a>;
        } else {
            link = <a className="text-white dropdown-item display-7" href={this.props.href} aria-expanded="false">{this.props.name}<br/></a>;
        }
        return link;
    }
}

export default SubMenuItem;