import React, { Component, Fragment } from "react";
import HelpOutlinedIcon from "@material-ui/icons/HelpOutlined";

import HelperTooltip from "../form_helpers/HelperTooltip";
import Translation from '../../utils/Translation';

class Plan extends Component {
	static defaultProps = {
		name: "",
		previousPrice: undefined,
		currentPrice: 0,
		perks: [],
		button: undefined
	};
	render() {
		const { name, previousPrice, currentPrice, perks, button } = this.props;
		return (
			<div
				className="plan col-12 mx-2 my-2 justify-content-center col-lg-4"
				id={name.toLowerCase()}
			>
				<div className="plan-header text-center pt-5">
					<h3 className="plan-title mbr-fonts-style display-5">
						{Translation.translateStaticText(name, name)}
					</h3>
					<div className="plan-price">
						<span className="price-value mbr-fonts-style display-5">
							&nbsp;
						</span>
						<span className="price-figure mbr-fonts-style display-5">
							{previousPrice && (
								<Fragment>
									<strike>
										{previousPrice}{" "}
										{Translation.translateValue(
											"EUR",
											"EUR"
										)}
									</strike>
									<br />
								</Fragment>
							)}
							{currentPrice}{" "}
							{Translation.translateValue("EUR", "EUR")}
						</span>
						<br />
						<small className="price-term mbr-fonts-style display-7">
							&nbsp;{" "}
							{Translation.translateStaticText(
								"withoutVAT",
								"excl. VAT monthly"
							)}
						</small>

						<HelperTooltip
							title={`${Number(
								(currentPrice * 1.95583).toFixed(1)
							)} ${Translation.translateValue(
								"BGN",
								"BGN"
							)} ${Translation.translateStaticText(
								"withoutVAT",
								"excl. VAT monthly"
							)} `}
						>
							<HelpOutlinedIcon
								classes={{
									root: "header-tooltip-icon"
								}}
							/>
						</HelperTooltip>
					</div>
				</div>
				<div className="plan-body pb-5">
					<div className="plan-list align-center">
						{perks.length > 0 && (
							<ul className="list-group list-group-flush mbr-fonts-style display-7">
								{perks.map(perk => (
									<li key={perk} className="list-group-item">
										{perk}
									</li>
								))}
							</ul>
						)}
					</div>
					{button && (
						<div className="mbr-section-btn text-center pt-4">
							{button}
						</div>
					)}
				</div>
			</div>
		);
	}
}
export default Plan;
