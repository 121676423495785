import React, { Component } from "react";

import Pricing from "../sections/Pricing";
import Page from "../Page";
import { setTitle } from "../../pageOptions";
import manager from "../../Manager";
import { usertype } from "../../config";
import Translation from "../../utils/Translation";

class PricingPage extends Component {
  static contextType = manager;

  componentWillMount() {
    if (this.context.getAuthManager().isLogged()) {
      this.context
        .getAuthManager()
        .pageRestriction({ forLoggedUsertype: [usertype.company] });
    }
    setTitle(Translation.translateStaticText("pricingTitle", "Pricing"));
  }

  render() {
    return (
      <Page location={this.props.location}>
        <Pricing />
      </Page>
    );
  }
}

export default PricingPage;
