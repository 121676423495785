import React, { Component } from "react";

import JobseekerProfile from "../../sections/jobseeker/JobseekerProfile";
import Page from "../../Page";
import manager from "../../../Manager";
import { usertype } from "../../../config";
import { setTitle } from "../../../pageOptions";
import Translation from "../../../utils/Translation";

class JobseekerProfilePage extends Component {
  static contextType = manager;

  componentWillMount() {
    this.context.getAuthManager().pageRestriction({
      logged: true,
      forLoggedUsertype: [usertype.jobseeker]
    });
    setTitle(
      Translation.translateStaticText("jobseekerProfileTitle", "Profile")
    );
  }

  render() {
    return (
      <Page location={this.props.location}>
        <section
          className="cid-rgbYhVM41a mbr-fullscreen mbr-parallax-background flex-start"
          id="header15-b"
        >
          <br />
          <br />
          <br />
          <div
            className="mbr-overlay"
            style={{ opacity: 0.5, backgroundColor: "rgb(7, 59, 76)" }}
          />
          <div className="container" style={{ marginTop: "50px" }}>
            <JobseekerProfile location={this.props.location.pathname} />
          </div>
        </section>
      </Page>
    );
  }
}

export default JobseekerProfilePage;
