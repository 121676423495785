import React, { Component } from "react";

import MenuItem from "./MenuItem";
import SubMenuItem from "./SubMenuItem";
import LanguageMenu from "./LanguageMenu";
import manager from "../Manager";
import { frontend } from "../config";
import Translation from "../utils/Translation";

class NavBar extends Component {
    static contextType = manager;
    selectedLanguage = {
        textDecoration: "underline",
        textDecorationColor: "#c1c1c1"
    };
    state = {
        language: {
            en: undefined,
            bg: undefined,
            de: undefined
        }
    };

    componentWillMount() {
        this.setState({
            ...this.state,
            language: {
                ...this.state.language,
                [this.context.getLanguageManger().getActiveLanugage()]: this
                    .selectedLanguage
            }
        });
    }

    render() {
        const authManager = this.context.getAuthManager();
        return (
            <section className="menu cid-qTkzRZLJNu" id="menu1-0">
                <nav className="navbar navbar-expand beta-menu navbar-dropdown align-items-center navbar-fixed-top navbar-toggleable-sm">
                    <button
                        className="navbar-toggler navbar-toggler-right"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <div className="hamburger">
                            <span />
                            <span />
                            <span />
                            <span />
                        </div>
                    </button>
                    <div className="menu-logo">
                        <div className="navbar-brand">
                            <span className="navbar-logo">
                                <a href={frontend.route.home}>
                                    <img
                                        src="/assets/images/logo-match-career.jpg"
                                        alt=""
                                        title=""
                                        style={{ height: 3.8 + "rem" }}
                                    />
                                </a>
                            </span>
                        </div>
                    </div>
                    <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                        style={{ paddingRight: "0" }}
                    >
                        <ul
                            className="navbar-nav nav-dropdown nav-right"
                            data-app-modern-menu="true"
                        >
                            <MenuItem
                                name={Translation.translateNavigation(
                                    "employers",
                                    "Employers"
                                )}
                                renderOnLogged={false}
                                className="jobseekers-icon custom-menu-icon mbr-iconfont mbr-iconfont-btn"
                                location={this.props.location}
                            >
                                <SubMenuItem
                                    name={Translation.translateNavigation(
                                        "login",
                                        "login"
                                    )}
                                    href={frontend.route.company.login}
                                    renderOnLogged={false}
                                    location={this.props.location}
                                />
                                <SubMenuItem
                                    name={Translation.translateNavigation(
                                        "signUp",
                                        "Sign Up Free"
                                    )}
                                    href={frontend.route.company.register}
                                    renderOnLogged={false}
                                    location={this.props.location}
                                />
                                <SubMenuItem
                                    name={Translation.translateNavigation(
                                        "pricing",
                                        "Pricing"
                                    )}
                                    href={frontend.route.pricing}
                                    renderOnLogged={false}
                                    location={this.props.location}
                                />
                            </MenuItem>
                            <MenuItem
                                name={Translation.translateNavigation(
                                    "jobseekers",
                                    "Jobseekers"
                                )}
                                renderOnLogged={false}
                                className="employers-icon custom-menu-icon mbr-iconfont mbr-iconfont-btn"
                                location={this.props.location}
                            >
                                <SubMenuItem
                                    name={Translation.translateNavigation(
                                        "login",
                                        "Login"
                                    )}
                                    href={frontend.route.jobseeker.login}
                                    renderOnLogged={false}
                                    location={this.props.location}
                                />
                                <SubMenuItem
                                    name={Translation.translateNavigation(
                                        "signUp",
                                        "Sign Up Free"
                                    )}
                                    href={frontend.route.jobseeker.register}
                                    renderOnLogged={false}
                                    location={this.props.location}
                                />
                            </MenuItem>
                            <MenuItem
                                name={Translation.translateNavigation(
                                    "search",
                                    "Search"
                                )}
                                renderOnUnlogged={false}
                                href={frontend.route.home}
                                className="mbri-search mbr-iconfont mbr-iconfont-btn"
                                location={this.props.location}
                            />
                            <MenuItem
                                name={Translation.translateNavigation(
                                    "positions",
                                    "Positions"
                                )}
                                renderOnUnlogged={false}
                                href={frontend.route.position.main}
                                className="mbri-edit mbr-iconfont mbr-iconfont-btn"
                                location={this.props.location}
                            />
                            <MenuItem
                                name={Translation.translateNavigation(
                                    "aboutUs",
                                    "About Us"
                                )}
                                renderOnLogged={false}
                                href={frontend.route.aboutus}
                                className="about-us-icon custom-menu-icon mbr-iconfont mbr-iconfont-btn"
                                location={this.props.location}
                            />
                            <MenuItem
                                name={Translation.translateNavigation(
                                    "chat",
                                    "Chat"
                                )}
                                isChat={true}
                                href={frontend.route.inSystemMessaging}
                                renderOnUnlogged={false}
                                className="mbri-chat mbr-iconfont mbr-iconfont-btn"
                                location={this.props.location}
                            />
                            <MenuItem
                                name=""
                                renderOnUnlogged={false}
                                className="mbri-setting mbr-iconfont mbr-iconfont-btn"
                                location={this.props.location}
                            >
                                <SubMenuItem
                                    name={Translation.translateNavigation(
                                        "profile",
                                        "Profile"
                                    )}
                                    renderOnUnlogged={false}
                                    renderOnUserType="company"
                                    href={
                                        frontend.route.company.company_profile
                                    }
                                    location={this.props.location}
                                />
                                <SubMenuItem
                                    name={Translation.translateNavigation(
                                        "profile",
                                        "Profile"
                                    )}
                                    renderOnUnlogged={false}
                                    renderOnUserType="jobseeker"
                                    href={
                                        frontend.route.jobseeker
                                            .jobseeker_profile.edit
                                    }
                                    location={this.props.location}
                                />
                                <SubMenuItem
                                    name={Translation.translateNavigation(
                                        "notificationSettings",
                                        "Notification settings"
                                    )}
                                    renderOnUnlogged={false}
                                    href={
                                        frontend.route.notificationSettings
                                    }
                                    location={this.props.location}
                                />
                                <SubMenuItem
                                    name={Translation.translateNavigation(
                                        "pricing",
                                        "Pricing"
                                    )}
                                    renderOnUnlogged={false}
                                    renderOnUserType="company"
                                    href={frontend.route.pricing}
                                    location={this.props.location}
                                />
                                <SubMenuItem
                                    name={Translation.translateNavigation(
                                        "aboutUs",
                                        "About Us"
                                    )}
                                    renderOnUnlogged={false}
                                    renderOnUserType="jobseeker"
                                    href={frontend.route.aboutus}
                                    location={this.props.location}
                                />
                                <SubMenuItem
                                    name={Translation.translateNavigation(
                                        "logout",
                                        "Logout"
                                    )}
                                    onClick={authManager.logout}
                                    renderOnUnlogged={false}
                                    location={this.props.location}
                                />
                            </MenuItem>
                            <LanguageMenu
                                currentLanguage={this.context
                                    .getLanguageManger()
                                    .getActiveLanugage()}
                            />
                        </ul>
                    </div>
                </nav>
            </section>
        );
    }
}

export default NavBar;
