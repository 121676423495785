
import ResponseHandler from './ResponseHandler';
import {hasProperties} from './ObjectUtils';
import { isArray } from './arrayUtils';
import Translation from './Translation';

/**
 * Author Simeon-Banov
 * field validator
 */
class Validator {

    /**
     * @param {React.component} component 
     * @param {Array} requestValidators
        Object array with structure:
        [
            {name:"validators key", value:"validator passing value", errorCode: "validator validation fail code from translations"}
        ] 
     * @param {*} callback 
     */
    validate(component, requestValidators, callback) {
        let valid = true;
        let errorCode = "0000";
        requestValidators.forEach(validator => {
            if(valid) {
                if(hasProperties(validator, ["name", "value", "errorCode"])) {
                    valid = avaliableValidators[validator.name](component, validator.value, validator.errorCode, validator);
                    errorCode = valid.code;
                    valid = valid.successful;
                } else {
                    console.error("Validator.validate ERROR: validator is missing required properties (name, value, errorCode):");
                    console.error(validator);
                    valid = false;
                    errorCode = "-9900";
                }
            }
        });
        if(!valid) {
            ResponseHandler.set(component, errorCode, {
                onError: {
                    message: Translation.translateResponse(errorCode),
                    callback: ()=>{callback(false)}
                }
            });
        } else {
            callback(true);
        }
    }

}

const avaliableValidators = {
    "equal": checkIfEqual,
    "checkboxChecked": checkIfChecked
}

function checkIfChecked(component, value, errorCode) {
    if(isArray(value)) {
        if(value.lenght<=0) {
            console.error("Validator.validators checkIfChecked ERROR: validator can not work without value!");
            return getUIErrorResult();
        }
        let checked = true;
        value.forEach((checkbox)=>{
            if(checked && checkbox===0) {
                checked = false;
            }
        })
        return getResult(checked, errorCode);
    } else if(typeof value === "number") {
        if(value===0) {
            return getErrorResult(errorCode);
        }
        return getSuccessfulResult();
    } else {
        console.error("Validator.validators checkIfChecked ERROR: value is not supported!");
    }
}

function checkIfEqual(component, values, errorCode) {
    if(isArray(values)) {
        if(values.lenght<2) {
            console.error("Validator.validators equal ERROR: validator can not compare values, when there are not enough to do compare!");
            return getUIErrorResult();
        }
        let equal = true;
        const first = values[0];
        values.forEach((value)=>{
            equal = !equal?false:value===first;
        });
        return getResult(equal, errorCode);
    } else {
        console.error("Validator.validators equal ERROR: validator can not compare "+String(typeof values)+"!");
        return getUIErrorResult();
    }
}


// Internal usage only
function getUIErrorResult(errorCode) {
    return {successful: false, code: "UIError", message: "validator error, see console."};;
}
function getErrorResult(code) {
    return {successful: false, code: code, message: ""};
}
function getSuccessfulResult() {
    return {successful: true, code: "0000", message: ""};
}
function getResult(successful, code) {
    return {successful: successful, code: code, message: ""};
}

export default new Validator();