import React from "react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Translation from '../../../utils/Translation';

export default function ProfileCompletionList(props) {
	const { loading, missingFields, profileFields } = props;
	const titleMap = {
		jobseeker: Translation.translateStaticText("jobseekerProfileCompletionProfileFields", "Profile Fields"),
		education: Translation.translateStaticText("jobseekerProfileSkillsHeading", "Skills & Education"),
	};
	const fieldNamesMap = {
		jobseeker: {
			jobseeker_name: Translation.translateField("fullName", "Full Name"),
			email: Translation.translateField("email", "Email"),
			location: Translation.translateStaticText("jobseekerCountry", "Country"),
			permissionType: Translation.translateField("jobseekerProfileVisibility", "Profile Visibility"),
			phone: Translation.translateField("phone", "Phone")
		},
		education: {
			level: Translation.translateField("jobseekerEducationLevel", "Education Level"),
			school: Translation.translateField("jobseekerEducationSchool", "School/University"),
			speciality: Translation.translateField("jobseekerEducationSpeciality", "Speciality"),
			summary: Translation.translateField("summary", "Summary"),
			skills: Translation.translateStaticText("jobseekerProfileCompletionSkillsPlaceholder", "Skills")
		}
	};

	if (loading) {
		return (
			<div>
				<CircularProgress />
				<Typography color="textSecondary">
					Give us a few moments to check your profile for completion
				</Typography>
			</div>
		);
	}
	return (
		<Grid container spacing={2}>
			{Object.keys(profileFields).map(model => {
				return (
					<Grid key={model} item xs={12} md={6} classes={{root:"align-left"}}>
						<Typography classes={{ root: "profile-field-heading" }}>
							{titleMap[model]}
						</Typography>
						<List dense={true}>
							{profileFields[model].map(field => {
								const fieldIsMissing = missingFields[
									model
								].includes(field);
								const color = fieldIsMissing
									? "danger"
									: "success";
								return (
									<ListItem key={field} dense={true}>
										<ListItemIcon>
											{fieldIsMissing ? (
												<ClearIcon
													classes={{ root: color }}
												/>
											) : (
												<CheckIcon
													classes={{ root: color }}
												/>
											)}
										</ListItemIcon>
										<ListItemText
											primary={
												fieldNamesMap[model][field]
											}
											primaryTypographyProps={{
												classes: {
													root: color
												}
											}}
										/>
									</ListItem>
								);
							})}
						</List>
					</Grid>
				);
			})}
		</Grid>
	);
}

ProfileCompletionList.defaultProps = {
	loading: true,
	missingFields: [],
	profileFields: []
};
