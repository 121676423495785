import * as validationRules from "./validationRules";

function applyRules(data, rules, errorKey = "", errors = {}, action = "") {
	const availableRulesType = ["String", "Object", "Array"];
	for (let key in rules) {
		if (!availableRulesType.includes(rules[key].constructor.name)) {
			throw new Error(
				"Validation rule not supported, please provide a String or an Object with strings"
			);
		} else if (rules[key].constructor.name === "Object") {
			for (let complexRuleKey in rules[key]) {
				errors = applyRules(
					data,
					rules[key],
					errorKey ? errorKey : key,
					errors,
					complexRuleKey
				);
			}
		} else if (rules[key].constructor.name === "Array" && !action) {
			errors = applyRules(data, rules[key], key, errors);
		} else {
			const error = checkForErros(
				data,
				{
					[errorKey ? errorKey : key]: rules[key]
				},
				action
			);
			if (error.hasOwnProperty("message")) {
				if (!errors.hasOwnProperty(key)) {
					errors[errorKey ? errorKey : key] = {
						reason:
							typeof rules[key] !== "string" ? key : rules[key],
						message: error.message
					};
				}
			}
		}
	}
	return errors;
}

function checkForErros(data, rule, action) {
	const field = Object.keys(rule)[0];
	let rulesResult = [];
	for (let _rule in validationRules) {
		rulesResult.push(validationRules[_rule](data, rule, field, action));
	}
	return rulesResult.reduce((rule, nextRule) => {
		return {
			...rule,
			...nextRule
		};
	});
}

export { applyRules };
