export default {
	en: {
		no: "no",
		yes: "yes",
		Off: "Off",
		On: "On"
	},
	bg: {
		no: "Не",
		yes: "Да",
		Off: "Изключено",
		On: "Включено"
	},
	de: {}
};
