export default {
	employers: "Arbeitgeber",
	jobseekers: "Jobsuchende",
	signUp: "kostenlos anmelden",
	aboutUs: "Über uns",
	pricing: "Preisplänen",
	login: "Einloggen",
	// search: "Search",
	// positions: "Positions",
	// chat: "Chat",
	// profile: "Profile",
	//notificationSettings: "Notifications",
	// logout: "Logout"
};
