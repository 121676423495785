import React, { Component } from "react";

import Page from "../../Page";
import { setTitle } from "../../../pageOptions";

class BGGdprCandidatesPage extends Component {
  componentWillMount() {
    setTitle("ОБЩИ УСЛОВИЯ ЗА ОБРАБОТВАНЕ НА ЛИЧНИ ДАННИ");
  }

  render() {
    return (
      <Page>
        <section
          className="cid-qTkA127IK8 mbr-fullscreen mbr-parallax-background"
          id="header2-1"
        >
          <div className="container align-center">
            <div className="row justify-content-md-center">
              <div className="mbr-white col-md-10">
                <h1 className="mbr-section-title mbr-bold mt-5 pb-3 mbr-fonts-style display-1">
                  ОБЩИ УСЛОВИЯ ЗА ОБРАБОТВАНЕ НА ЛИЧНИ ДАННИ
                </h1>
                <br />
                <div style={{ textAlign: "left" }}>
                  <ol className="listing">
                    <li>
                      <p>Обхват</p>
                      <div>
                        Договорните условия за Обработване на лични данни
                        (“Договорните условия”) съдържат задълженията на
                        страните относно защитата на личните данни, които
                        възникват във връзка с ангажирането на ИнспайърСофт ЕООД
                        (за краткост “Обработващ лични данни”) от възложителя
                        (за краткост “Администратор на лични данни”) съгласно
                        член 28 от Регламент (ЕС) 679/2016 (“ОРЗД”).
                      </div>
                    </li>
                    <li>
                      <p>Описание на услугите</p>
                      <div>
                        Обработващият лични данни обработва данни от името на
                        Администратора на лични данни. Обработването на лични
                        данни включва събирането, използването, запазването,
                        промяната, предаването, блокирането или изтриването на
                        лични данни от Обработващия лични данни от името на
                        Администратора на лични данни. Ако е приложимо, данните
                        ще бъдат прехвърляни на трети лица, ако Администраторът
                        на лични данни нареди на Обработващия лични данни да го
                        направи.
                      </div>
                    </li>
                    <li>
                      <p>Субекти на данни. Отговорност</p>
                      <ol className="listing">
                        <li>
                          Групата от субекти на данни, засегнати от
                          обработването на техните данни в рамките на този
                          ангажимент, включва по-конкретно потребителите, които
                          кандидатстват за работа по обяви на Администратора на
                          лични данни и служителите на Администратора на лични
                          данни, които използват Услугите.
                        </li>
                        <li>
                          Администраторът на лични данни отговаря единствено за
                          спазването на приложимите закони за защита на данните.
                          С оглед на тази отговорност, Администраторът на лични
                          данни има право да изисква изтриването или връщането
                          на данни в течение на срока на договора.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <p>Права и задължения на Администратора на лични данни</p>
                      <ol className="listing">
                        <li>
                          Администраторът на лични данни и Обработващият лични
                          данни отговарят всека за себе си за спазването на
                          приложимите закони за защита на данните във връзка с
                          данните, които ще бъдат обработвани.
                        </li>
                        <li>
                          Администраторът на лични данни уведомява незабавно
                          Обработващия лични данни в случай че докато упражнява
                          контрол върху резултатите от тази обработване на лични
                          данни установи някакви грешки и/или нередности,
                          свързани с приложимите закони за защита на данните.
                        </li>
                        <li>
                          Администраторът на лични данни се задължава да води
                          регистър на дейностите по обработване в съответствие с
                          чл. 30 ОРЗД.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <p>Задължения на Обработващия лични данни</p>
                      <ol className="listing">
                        <li>
                          Обработващият лични данни обработва данни само в
                          обхвата на документираните нареждания на
                          Администратора на лични данни съобразно уговореното в
                          договора (чл. 28 ал. 3 ОРЗД).
                        </li>
                        <li>
                          Когато субект на данни се обърне директно към
                          Обработващия лични данни, последният трябва незабавно
                          да препрати това искане на Администратора на лични
                          данни.
                        </li>
                        <li>
                          Обработващият лични данни трябва незабавно да уведоми
                          Администратора на лични данни съгласно чл. 28 ал. 3,
                          параграф 2 ОРЗД, ако счита че някое нареждане е в
                          нарушение на закон за защита на данните.
                        </li>
                        <li>
                          Обработващият лични данни е избрал служител за защита
                          на личните данни с писмен документ, който да изпълнява
                          дейностите си в съответствие с чл. 38 и 39 ОРЗД:
                          Снежана Райкинска.
                        </li>
                        <li>
                          Обработващият лични данни уведомява незабавно
                          Администратора на лични данни в случай на сериозно
                          нарушаване на графика за работа, подозрение за пробив
                          на защитата на личните данни или за някакви други
                          нередности, свързани с обработването на лични данните
                          на Администратора на лични данни.
                        </li>
                        <li>
                          При поискване, Обработващият лични данни и
                          Администраторът на лични данни си сътрудничат с
                          надзорния орган при изпълнението на техните задачи.
                          Доколкото Администраторът на лични данни подлежи на
                          проверка от надзорния орган, административно или общо
                          производство, или наказателно производство, отправен
                          иск за отговорност от страна на субект на данни или от
                          трето лице, или някакъв друг иск, касаещ реда или
                          обработването на лични данни по договор от страна на
                          Обработващия лични данни, последният полага всички
                          усилия, за да окаже подкрепа на Администратора на
                          лични данни.
                        </li>
                        <li>
                          Всички носители на данни, предоставени на Обработващия
                          лични данни, както и всякакви копия от тях, остават
                          собственост на Администратора на лични данни.
                          Обработващият лични данни съхранява усърдно тези
                          носители на данни и ги защитава срещу непозволен
                          достъп от трети лица. Обработващият лични данни е
                          задължен да уведомява във всеки един момент
                          Администратора на лични данни за неговите данни и
                          документи.
                        </li>
                        <li>
                          Обработващият лични данни може да претендира за
                          възнаграждение за поддържащи услуги, които не са
                          включени в описанието на Услугите и които не се дължат
                          на неизпълнение от страна на Обработващия лични данни.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <p>Технически и организационни мерки</p>
                      <ol className="listing">
                        <li>
                          Обработващият лични данни трябва да въведе мерки за
                          сигурност в съответствие с чл. 28 ал. 3 буква „в“, и
                          чл. 32 ОРЗД, по-конкретно във връзка с чл. 5 ал. 1, 2
                          ОРЗД. Мерките, които трябва да бъдат предприети, са
                          мерки за сигурност на данните и мерки, които
                          гарантират ниво на защита, подходящо за риска, касаещ
                          поверителността, целостта, наличието и устойчивостта
                          на системите. Под внимание трябва да бъдат взети
                          съвременното състояние, разходите за въвеждане,
                          естеството, обхвата и целите на обработването, както и
                          вероятността от настъпване и тежестта на риска за
                          правата и свободите на физическите лица по смисъла на
                          чл. 32 ал. 1 ОРЗД.
                        </li>
                        <li>
                          Техническите и организационните мерки зависят от
                          техническия прогрес и бъдещото развитие. В тази връзка
                          е допустимо Обработващият лични данни да въведе и
                          алтернативни, подходящи мерки. Докато прави това,
                          нивото на сигурност на дефинираните мерки не трябва да
                          бъде намалявано. Всякакви съществени промени трябва да
                          бъдат документирани. Техническите и организационните
                          мерки, които осигуряват подходяща защита на данните на
                          Администратора на лични данни, включват:
                        </li>
                        <ol type="a">
                          <li>
                            Поверителност (чл. 32 ал. 1 подточка „б“ ОРЗД):
                            Физически контрол върху достъпа, Логически контрол
                            върху достъпа, Контрол върху достъпа до данните,
                            Контрол върху разделянето; Псевдонимизация (чл. 32
                            ал. 1 подточка a ОРЗД; чл. 25 ал. 1 ОРЗД)
                          </li>
                          <li>
                            Цялостност (чл. 32 ал. 1 подточка „б“ ОРЗД): Контрол
                            върху трансфера на данни, Контрол върху въвеждането.
                          </li>
                          <li>
                            Наличност и устойчивост (чл. 32 ал. 1 подточка „б“
                            ОРЗД)
                          </li>
                          <li>
                            Процес на редовно изпитване, преценяване и оценка
                            (чл. 32 ал. 1 подточка „г“ ОРЗД; чл. 25 ал. 1 ОРЗД):
                          </li>
                          <ul>
                            <li>
                              Управление на защитата на личните данни: Всички
                              служители са явно ангажирани за запазването на
                              тайната на данните и преминават през обучение поне
                              веднъж годишно. ИнспайърСофт ЕООД е назначило
                              служител по защита на личните данни – Снежана
                              Райкинска.
                            </li>
                            <li>
                              Управление на отговорите на инциденти: В случай на
                              загуба на данни, съответният орган за защита на
                              личните данни се уведомява незабавно.
                            </li>
                            <li>
                              Защита на личните данни още при разработването и
                              при неизпълнение (чл. 25 ал. 2 ОРЗД).
                            </li>
                            <li>
                              Контрол върху нарежданията. Общи условия за
                              обработване на лични данни
                            </li>
                            <ol className="listing">
                              <li>
                                <p>Обхват</p>
                                <div>
                                  Договорните условия за Обработване на лични
                                  данни (“Договорните условия”) съдържат
                                  задълженията на страните относно защита на
                                  личните данни, които възникват във връзка с
                                  ангажирането на ИнспайърСофт ЕООД (за краткост
                                  “Обработващ лични данни”) от възложителя (за
                                  краткост “Администратор на лични данни”)
                                  съгласно член 28 от Регламент (ЕС) 679/2016
                                  (“ОРЗД”).
                                </div>
                              </li>
                              <li>
                                <p>Субекти на данни. Отговорност</p>
                                <ol className="listing">
                                  <li>
                                    Групата от субекти на данни, засегнати от
                                    обработването на техните данни в рамките на
                                    този ангажимент, включва по-конкретно
                                    потребителите, които регистрират профил като
                                    Търсещи работа в match.career.
                                  </li>
                                  <li>
                                    Страните отговарят единствено за спазването
                                    на приложимите закони за защита на данните.
                                  </li>
                                </ol>
                              </li>
                              <li>
                                <p>Технически и организационни мерки</p>
                                <ol className="listing">
                                  <li>
                                    ИнспайърСофт ЕООД трябва да въведе мерки за
                                    сигурност в съответствие с ОРЗД. Мерките,
                                    които трябва да бъдат предприети, са мерки
                                    за сигурност на данните и мерки, които
                                    гарантират ниво на защита, подходящо за
                                    риска, касаещ поверителността, целостта,
                                    наличието и устойчивостта на системите. Под
                                    внимание трябва да бъдат взети съвременното
                                    състояние, разходите за въвеждане,
                                    естеството, обхвата и целите на
                                    обработването, както и вероятността от
                                    настъпване и тежестта на риска за правата и
                                    свободите на физическите лица по смисъла на
                                    чл. 32 ал. 1 ОРЗД.
                                  </li>
                                </ol>
                              </li>
                            </ol>
                          </ul>
                        </ol>
                        <li>
                          Техническите и организационните мерки зависят от
                          техническия прогрес и бъдещото развитие. В тази връзка
                          е допустимо ИнспайърСофт ЕООД да въведе и
                          алтернативни, подходящи мерки. Докато прави това,
                          нивото на сигурност на дефинираните мерки не трябва да
                          бъде намалявано. Всякакви съществени промени трябва да
                          бъдат документирани. Техническите и организационните
                          мерки, които осигуряват подходяща защита на данните
                          включват:
                        </li>
                        <ol type="a">
                          <li>
                            Поверителност: Физически контрол върху достъпа,
                            Логически контрол върху достъпа, Контрол върху
                            достъпа до данните, Контрол върху разделянето;
                            Псевдонимизация
                          </li>
                          <li>
                            Цялостност: Контрол върху трансфера на данни,
                            Контрол върху въвеждането.
                          </li>
                          <li>Наличност и устойчивост</li>
                          <li>
                            Процес на редовно изпитване, преценяване и оценка:
                          </li>
                          <ul>
                            <li>
                              Управление на защитата на личните данни: Всички
                              служители са явно ангажирани за запазването на
                              тайната на данните и преминават през обучение поне
                              веднъж годишно. ИнспайърСофт ЕООД е назначило
                              служител по защита на личните данни – Снежана
                              Райкинска.
                            </li>
                            <li>
                              Управление на отговорите на инциденти: В случай на
                              загуба на данни, съответният орган за защита на
                              личните данни се уведомява незабавно.
                            </li>
                            <li>
                              Защита на личните данни още при разработването и
                              при неизпълнение
                            </li>
                            <li>Контрол върху нарежданията.</li>
                          </ul>
                        </ol>
                      </ol>
                    </li>
                  </ol>
                </div>
                <br />
              </div>
            </div>
          </div>
        </section>
      </Page>
    );
  }
}

export default BGGdprCandidatesPage;
