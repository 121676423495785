import React, { Fragment } from "react";
import Translation from "../../../utils/Translation";
import { languages } from "../../../config";

export default function CompanyGDPRAcceptance(props) {
	const getTemplate = language => {
		const englishTemplate = (
			<Fragment>
				I agree with the company{" "}
				<a
					href={props.location}
					style={{ textDecoration: "underline" }}
					rel="noopener noreferrer"
					target="_blank"
				>
					GDPR
				</a>
				.
			</Fragment>
		);

		const bulgarianTemplate = (
			<Fragment>
				Съгласявам се с{" "}
				<a
					href={props.location}
					style={{ textDecoration: "underline" }}
					rel="noopener noreferrer"
					target="_blank"
				>
					общия регламен за защита на лични данни{" "}
				</a>
				на компанията.
			</Fragment>
		);

		const germanTemplate = englishTemplate;

		switch (language) {
			case languages.english:
				return englishTemplate;
			case languages.bulgarian:
				return bulgarianTemplate;
			case languages.german:
				return germanTemplate;
			default:
				return englishTemplate;
		}
	};

	return getTemplate(Translation.getLanguage());
}
