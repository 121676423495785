export default {
	//Homepage
	premiumClients: "Premium Clients",
	navigationPrevious: "Previous",
	navigationNext: "Next",
	matchCareersHeading: "Your Next Job is Here",
	matchCareersSlogan: "Where Specialists Meet Employers",
	searchingJob: "Searching For a Job?",
	searchingTalent: "Searching For a Talent?",

	//Registration & Account Recovery
	createNewAccount: "Create New Account",
	checkEmail: "Check your email.",
	howItWorksHeading: "How it Works",
	step1: "STEP1",
	step2: "STEP2",
	step3: "STEP3",
	finalStep: "FINAL STEP",
	accountRecovery: "Account Recovery",

	//Company Registration
	companyRegistrationHeading: "EMPLOYERS",
	companyRegistrationHeadlight:
		"Spend a Minute to Register so You Can Be Approached by Professionals",
	companyStep1Heading: "Get registered",
	companyStep1Description:
		"Just a few details and you are in - you will receive a confirmation email once everything is done.",
	companyStep2Heading: "Tell Us a Story About You",
	companyStep2Description:
		"Spend some time to fill as much info as possible about your enterprise - the more the better. This will increase the interest in candidates about your work and projects.",
	companyStep3Heading: "Choose your pricing plan",
	companyStep3Description:
		"Based on your needs - get the plan that matches it and start your journey through our platform.",
	companyFinalStepHeading: "Spread The Word",
	companyFinalStepDescription:
		"Put your job ad on the market and start hiring through Match.Careers.",

	//Jobseeker Registration
	jobseekerRegistrationHeading: "JOBSEEKERS",
	jobseekerRegistrationHeadlight:
		"Allow a Minute to Register and Explore Careers Boost Opportunities",
	jobseekerStep1Heading: "Create Your Profile",
	jobseekerStep1Description:
		"Sign up and create your profile, containing the skills you have and information about your experience. The good news is you don't need to write CV or cover letter!",
	jobseekerStep2Heading: "Apply For a Position",
	jobseekerStep2Description:
		"Our system immediately matches jobs, which will be presented to you. After applying, the responsible person from the company you are interested in can contact you.",
	jobseekerStep3Heading: "Got Interview Invitation",
	jobseekerStep3Description:
		"You meet in person or online with the hiring person or a Manager.",
	jobseekerFinalStepHeading: "Get Hired",
	jobseekerFinalStepDescription: "Congratulations! You are hired.",

	//Registration password tooltip
	passwordRequirementsHeading:
		"Your password must match the following complexity criterias:",
	passwordLengthRequirement: "6-16 Characters Long",
	passwordCapitalLetterRequirement: "1 Capital Letter",
	passwordLowerCaseLetterRequirement: "1 Lower Case Letter",
	passwordDigitRequirement: "1 Digit",
	passwordSpecialSymbolRequirement: "1 Special Symbol",

	//Jobseeker Profile
	jobseekerProfileCompletion: "Profile Completion",
	jobseekerProfileCompletionProfileFields: "Profile Fields",
	jobseekerLocationPlaceholder: "Location",
	jobseekerCountry: "Country",
	jobseekerCity: "City",
	jobseekerProfileSkillsHeading: "Skills & Education",
	jobseekerPublicProfileEducationHeading: "Education",
	jobseekerProfileCompletionSkillsPlaceholder: "Skills",
	jobseekerProfileInformationHeading: "Profile Information",
	jobseekerProfileLocation: "--- Select a Country ---",
	jobseekerProfileCV: "CV (pdf only)",
	jobseekerNoCV: "No CV uploaded",
	jobseekerCVPlaceholder: "CV",
	helperToolbarRestrictionFull: "Full",
	helperToolbarRestrictionFullDetails:
		"Companies that you have not configured access can see all your profile info",
	helperToolbarRestrictionLimited: "Limited",
	helperToolbarRestrictionLimitedDetails:
		"Companies that you have not configured access can see only your skills",
	helperToolbarRestrictionNone: "None",
	helperToolbarRestrictionNoneDetails:
		"Companies that you have not configured access cannot see your profile",
	jobseekerProfileSkillsHelper: `You can skip the autocomplete feature and <strong>Create</strong> (add) your own Skill or School / University by typing the name and selecting <strong>Create "name"</strong>. <strong>It is recommended to choose from the autocomplete names whenever possible.</strong>`,
	jobseekerProfileNoEducation: "No Education",
	jobseekerProfileMoreEducation: "More Education",
	footerAddressHeading: "Address",
	footerAddress:
		"15, Tsarigradsko Shose Blvd.<br>3rd entrance, fl.2, 1124, Sofia, Bulgaria",
	footerFAQHeader: "Have questions?",
	footerFAQ: `Visit our <a href="{location}">FAQ</a>`,
	footerContactsHeader: "Contacts",
	footerContactsEmailHeader: "Email",
	footerContactsPhoneHeader: "Phone",
	footerCopyright: "Copyright {year} InspireSoft- All Rights Reserved",
	employmentHistoryHeading: "Employment History",
	jobseekerPublicProfileInformationHeading: "Personal Information",
	jobseekerPublicProfileSkillsHeading: "Personal Skills",
	jobseekerPublicProfileNoSkills: "No Available Skills",
	JobseekerCompanyAccessHeading: "Company Access",
	JobseekerCompanyAccessHelper:
		"You need <strong>100%</strong> profile completion in order to have access to edit permissions.",
	jobseekerCompanyAccessNoRecordsPlaceholder: "No records to display",
	jobseekerCompanyAccessDeleteText: "Remove this access?",
	jobseekerCompanyAccessPaginationLabel: "Rows",
	jobseekerCompanyAccessPaginationCount: "{from}-{to} of {count}",
	jobseekerComapnyAccessProfileCompletionRequirement:
		"You need 100% profile completion in order to have access to edit permissions.",
	jobseekerEmploymentHistoryHelperText:
		"Add, Edit or Remove employments to your profile",
	jobseekerCompanyAccessHelperText:
		"Modify or remove specific visibility of companies for your profile",
	changePasswordHeading: "Change your password",
	changePasswordHelperTooltip:
		"Be careful, by changing your password you won't be able to login with your previous password. The new password must match and have 1 Capital Letter, 1 Small Case Letter, 1 Special Symbol and 1 Number",
	jobseekerSaveWarning: "Please save before continuing!",
	jobseekerSaveWarningTitle: "You may lose your changes!",
	jobseekerProfileCompletionModificationsNotSaved: "Modifications not saved!",

	//Company Profile
	"free account": "Free Account",
	"gold account": "Gold Accound",
	"platinum account": "Platinum Account",

	//inSystemMessages
	requestCorrespondenceMessage: "{contact} has requested correspondence.",
	hasToAcceptCorrespondence:
		"{contact} has yet to accept correspondence with you",
	sendEmailToCompnay: "In a hurry? Send email to the company on",
	blockUser: "You have Blocked {contact}",
	haveBlockedYou: "{contact} has Blocked you",
	haveRemovedYou: "{contact} has Removed you from contacts",
	oneHourAgo: "1 hour ago",
	hoursAgo: "{interval} hours ago",
	oneMinuteAgo: "1 minute ago",
	minutesAgo: "{interval} minutes ago",
	oneSecondAgo: "a second ago",
	secondsAgo: "{interval} seconds ago",
	now: "Now",
	warningHeader: "Warning",
	removeContactWarning:
		"Are you sure you want to remove {contact} from contacts? This can not be easily reverted.",
	systemUser: "System",
	systemInitMessage: `Hello, you may add contacts by visiting their public profile page and clicking "Request correspondence", after which the contact will be visible on the left side. When and if the user accepts your request, you will be able to use the live chat system. This is a system mesage, you cannot reply!`,

	//about us
	aboutUsHeading: "About us",
	whyUsHeading: "Why us?",
	whyUsDescription:
		"We offer the best match between companies and specialists",
	//about us listing
	whyUsFirstHeading: "Honest and Reliable",
	whyUsFirstDescription:
		"We strive on matching quality with transparency. Those being our core values, we're on the verge of providing a platform, that will meet you with the best opportunities",
	whyUsSecondHeading: "We are Always Improving",
	whyUsSecondDescription: `"We all need people who will give us feedback. That’s how we improve."`,
	billGatesName: "Bill Gates",
	whyUsThirdHeading: "Passion in Technology and Humanity",
	whyUsThirdDescription:
		"We do care for the needs of each of you, no matter if you are a jobseeker or an employer. Yes, computers and the programmed algorithms behind can serve us, but having a nice word or a tip from a person is something that distinguish the usage of online service from real live experience. You can always contact a real person from our team.",

	//Positions
	allPositionsHeading: "All Positions",
	loadingPositions: "Loading positions...",
	noPunlishedPositions: "No published positions.",
	positionLocationNotChoosen: "Not Choosen",
	morePositionLocations: "More...",
	deletePositionTitle: "Delete Position",
	deletePositionWarning:
		"This action is irreversible, are you sure you want to proceed?",
	invalidPosition: "Invalid position",
	hiddenCandidatesCount: "Number of hidden candidates: {count}",
	freeStatusCandidateNotice:
		"Your company status is Free. To see the candidates choice a Pricing plan from the Pricing page.",
	noCandidates: "No Candidates",
	candidateRevoceAccess: "Has revoked access",

	//Page Titles
	addPositionTitle: "Add Position",
	editPositionTitle: "Edit Position",
	viewPositionTitle: "View Position",
	allPositionTitle: "All Positions",
	ownPositionTitle: "Own Positions",
	platinumPurchaseTitle: "Purchase Platinum Access",
	goldPurchaseTitle: "Purchase Gold Access",
	pricingTitle: "Pricing",
	signupTitle: "Signup",
	candidateProfileTitle: "Candidate Profile",
	jobseekerProfileTitle: "Profile",
	loginPageTitle: "Login",
	inSystemTitle: "Insystem messaging",
	homePageTitle: "Home",
	searchPageTitle: "Search",
	FAQPageTitle: "FAQ",
	errorPageTitle: "Error",
	companyProfileTitle: "Company Profile",
	profileInformationTitle: "Profile",
	recoveryPageTitle: "Account Recovery",
	aboutUsTitle: "About us",

	//pricing
	pricingLoggedInWarning: "You need to be logged in first.",
	ownedPlan: "Owned",
	perk1: "Create Company Profile",
	perk2: "Publish One Position",
	perk3: "Publish Unlimited Number of Positions",
	perk4: "Send *InSystem Messages",
	perk5: "Have Access To a Pool of Profiles",
	perk6: "Guaranteed Logo Place on First Page and Social Media Ads",
	inSystemMessagesDefinition:
		"***InSystem Messages - direct and easy way to contact a person, whose skills you find suitable for your company.",
	withoutVAT: "excl. VAT monthly",
	Free: "free",
	Gold: "gold",
	Platinum: "platinum",
  
	//notification Settings
	chatNotificationSettingsHelpInformation: "We send email notifications for unread messages for inactive profiles everyday as 11h and 17h server time.",
	positionNotificationSettingsHelpInformation: "Please select a way to notify you about new candidate on your positions.",
	freeCompanyPositionNotificationSettingsHelpInformation: "(You can not change this value on a free profile)",
  
	//Pagination
	paginationShowing: "Showing {from} - {to} Of {total} {title}",
	paginationCandidateTitle: "Candidates",
	paginationJobsTitle: "Jobs",

	//Search
	searchNoResults: "No Results"
};
