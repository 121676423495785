import React, { useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import LinearProgress from "@material-ui/core/LinearProgress";
import ProfileCompletionList from "./ProfileCompletionList";
import Translation from '../../../utils/Translation';

export default function ProfileCompletion(props) {
	const profileFields = {
		jobseeker: [
			"jobseeker_name",
			"email",
			"location",
			"permissionType",
			"phone"
		],
		education: ["level", "school", "speciality", "summary", "skills"]
	};
	const [missingFields, setMissingFields] = useState({
		jobseeker: [],
		education: []
	});
	const [loading, setLoading] = useState(false);

	const {
		percentage,
		modifications,
		jobseeker,
		skills,
		education,
		employment
	} = props;
	// we need to calculate the final percentage if some changes have been made to employment.
	//this saves making another request to backend for the percentage - but it maybe not accurate.
	let percentages = percentage;
	const checkJobseekerFields = jobseeker => {
		let normalizedModel = {
			...jobseeker,
			location: jobseeker.location.location_id
		};
		return profileFields.jobseeker.filter(
			field =>
				!normalizedModel[field] || normalizedModel[field].length < 1
		);
	};

	const checkEducationAndSkillsFields = (education, skills) => {
		let emptyFields = [];
		if (skills.length < 1) {
			emptyFields.push("skills");
		}
		//initialize with full set of fields as missing,
		//so we can get the lowest number of missing fields from all educations.
		let educationFields = profileFields.education.filter(
			field => field !== "skills"
		);
		let educationMissingFields = [...educationFields];
		for (let i = 0; i < education.length; i++) {
			let missingFields = educationFields.filter(field => {
				return !education[i][field] || education[i][field].length < 1;
			});
			if (educationMissingFields.length > missingFields.length) {
				educationMissingFields = missingFields;
			}
		}
		return [...emptyFields, ...educationMissingFields];
	};

	const checkFields = (jobseeker, skills, education, employment) => {
		const emptyJobseekerFields = checkJobseekerFields(jobseeker);
		const emptyEducationFields = checkEducationAndSkillsFields(
			education,
			skills
		);
		setMissingFields({
			jobseeker: emptyJobseekerFields,
			education: emptyEducationFields
		});
		setLoading(false);
	};

	const checkMissingFields = (event, isExpanded) => {
		setLoading(isExpanded ? true : false);
		checkFields(jobseeker, skills, education, employment);
	};

	return (
		<div id="profile_completion">
			<ExpansionPanel onChange={checkMissingFields}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography
						variant="body2"
						classes={{
							root:
								"mbr-text mbr-fonts-style profile_completion_heading align-left"
						}}
					>
						{Translation.translateStaticText("jobseekerProfileCompletion", "Profile Completion")}
					</Typography>
					<div
						id="profile_completion_progress_bar"
						className="f-as-c"
					>
						<LinearProgress
							variant="determinate"
							value={percentage}
						/>
					</div>
					<Typography
						variant="body2"
						classes={{ root: "mbr-text mbr-fonts-style" }}
					>
						{percentages}%
					</Typography>
					{modifications ? (
						<Typography
							variant="button"
							color="secondary"
							classes={{
								root:
									"mbr-text mbr-fonts-style profile_completion_heading align-center f-as-c"
							}}
						>
							{Translation.translateStaticText("jobseekerProfileCompletionModificationsNotSaved", "Modifications not saved!")}
						</Typography>
					) : (
						""
					)}
				</ExpansionPanelSummary>
				<ExpansionPanelDetails
					classes={{ root: loading ? "flex-center" : "" }}
				>
					<ProfileCompletionList
						loading={loading}
						missingFields={missingFields}
						profileFields={profileFields}
					/>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</div>
	);
}

ProfileCompletion.defaultProps = {
	percentage: 0,
	jobseeker: {},
	skills: [],
	education: {},
	employment: [],
	modifications: false
};
