import default_config from "./default_config";

default_config.backend.DOMAIN = "https://match.careers";
default_config.frontend.DOMAIN = "https://match.careers";
default_config.backend.WSDOMAIN = "wss://match.careers";

export const backend = default_config.backend;
export const frontend = default_config.frontend;
export const companytype = default_config.companytype;
export const usertype = default_config.usertype;
export const backendErrorCodes = default_config.backendErrorCodes;
export const accessibility = default_config.accessibility
export const file = default_config.file;
export const uuidPattern = default_config.uuidPattern;
export const locale = default_config.locale;
export const positionType = default_config.positionType;
export const remoteWork = default_config.remoteWork;
export const cookies = default_config.cookies;
export const languages = default_config.languages;
