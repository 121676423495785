
import BaseBoard from "./BaseBoard";
import manager from '../../../Manager';

class MessageBoard extends BaseBoard {
    static contextType = manager;

    componentDidMount() {
        super.componentDidMount();
        let component = this;
        component.context.getGenericManager().getTimeoffset(component, {
            onSuccess: {toast:false},
            onFinish: {
                toast:false,
                callback:(resultMessage)=>{
                    super.setServeroffset(resultMessage);
                    this.context.getWSManager().getWS().getMessages(this.props.contact.user_id);
                }
            }
        })
    }

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps && nextProps.contact && (!this.state.contact || nextProps.contact.user_id !== this.state.contact.user_id)) {
            super.closeWS();
            this.setState({ 
                contact: nextProps.contact,
                messages: [],
                scrollUpdate: false,
                disableInput: true,
                form:{
                    message: undefined
                } 
            }, ()=>{
                super.openWS();
                this.context.getWSManager().getWS().getMessages(this.props.contact.user_id);
            });
        }
    }
}

export default MessageBoard;