import React, { Component } from 'react';
import HelperTooltip from './HelperTooltip';

class FloatingLabelInput extends Component {

  static defaultProps = {
      value: '',
      disabled: false,
      placeholder: "",
      alwaysShowPlaceholderAsLabel: false,
      placeholderColor: undefined,
      focusInfo: ""
  }

  state = {
    isFocused: false
  };

  handleFocus = () => this.setState({ isFocused: true });
  handleBlur = () => this.setState({ isFocused: false });

  render() {
    let { alwaysShowPlaceholderAsLabel, placeholderColor, focusInfo, ...props } = this.props;
    const { isFocused } = this.state;
    let label = undefined;
    const labelStyle = {
      position: 'absolute',
      left: 2,
      top: 2,
      fontSize: 14,
      color: placeholderColor?placeholderColor:'#8c9298',
    };
    let placeholderHandler = props.placeholder;

    if(!props.value && props.disabled) {
      return null;
    }
    
    if((isFocused || props.value || alwaysShowPlaceholderAsLabel) && placeholderHandler) {
      label = placeholderHandler;
      if(!props.disabled) {
        label+=":";
      }
      placeholderHandler = undefined;
    }

    if(props.type==="file" && props.value==="") {
      delete props.value;
    }

    let containerStyle = {};
    if(this.props.containerStyle) {
      containerStyle = Object.assign({}, this.props.containerStyle);
    }
    containerStyle.position = "relative";
    return (
      <HelperTooltip disableHoverListener title={focusInfo} placement="right">
      <div style={containerStyle} className={this.props.containerClass}>
        <div style={labelStyle}>
          {label}
        </div>
        <input
          {...props}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          placeholder={placeholderHandler}
        />
      </div>
      </HelperTooltip>
    );
  }
}


export default FloatingLabelInput;