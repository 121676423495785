import React, { Component, Fragment } from "react";
import HelpOutlinedIcon from "@material-ui/icons/HelpOutlined";

import manager from "../../../Manager";
import Pagination from "../../../utils/Pagination";
import { frontend } from "../../../config";
import HelperTooltip from "../../form_helpers/HelperTooltip";
import Translation from "../../../utils/Translation";

class ApplicationList extends Component {
  static contextType = manager;

  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
  }

  static defaultProps = {
    position_id: false, //optional
    goToView: position_id => {},
    freeCompany: true
  };

  pagination = undefined;
  candidatesLoaded = false;

  state = {
    position_id: undefined,
    candidates: [],
    candidateCount: -1,
    hiddenCandidateCount: -1,
    positionsTitles: {}
  };

  componentDidMount() {
    this.context.getAuthManager().pageRestriction({ logged: true });
    const component = this;
    if (component.props.position_id) {
      component.setState(
        {
          ...component.state,
          position: { position_id: component.props.position_id }
        },
        () => {
          component.pagination = new Pagination(
            1,
            20,
            Translation.translateStaticText("paginationCandidateTitle", "Candidates"),
            this.onPageChange1
          );
          component.pagination.init();
        }
      );
    } else {
      component.pagination = new Pagination(
        1,
        20,
        Translation.translateStaticText("paginationCandidateTitle", "Candidates"),
        this.onPageChange2
      );
      component.pagination.init();
    }
  }

  componentWillReceiveProps() {
    const component = this;
    if (component.props.position_id) {
      component.setState({
        ...component.state,
        position: { position_id: component.props.position_id }
      });
    }
  }

  onPageChange1 = (page = 1) => {
    let component = this;
    return e => {
      component.context
        .getPositionManager()
        .getPaginationApplicationPositionList(
          component,
          (page - 1) * component.pagination.size,
          component.pagination.size,
          {
            onSuccess: {
              toast: false,
              callback: data => {
                if (!("totalCount" in data) || !("items" in data)) {
                  console.error(
                    "ApplicationList onPageChange1 ERROR: managerAPI response not containing pagination structure!"
                  );
                  return;
                }
                // initialize screen position at the beginning when first initializing
                if (this.candidatesLoaded) {
                  window.scrollTo(0, this.scrollRef.current.offsetTop);
                }
                component.pagination.totalCount = data.totalCount;
                component.populateData(component, data.items, data);
              }
            },
            onError: {
              toast: false,
              callback: data => {
                component.populateData(component, {}, data);
              }
            }
          }
        );
    };
  };

  onPageChange2 = (page = 1) => {
    let component = this;
    return e => {
      component.context
        .getPositionManager()
        .getPaginationApplicationCompanyList(
          component,
          (page - 1) * component.pagination.size,
          component.pagination.size,
          {
            onSuccess: {
              toast: false,
              callback: data => {
                if (!("totalCount" in data) || !("items" in data)) {
                  console.error(
                    "ApplicationList onPageChange2 ERROR: managerAPI response not containing pagination structure!"
                  );
                  return;
                }
                if (this.candidatesLoaded) {
                  window.scrollTo(0, this.scrollRef.current.offsetTop);
                }
                component.pagination.totalCount = data.totalCount;
                component.populateData(component, data.items, data);
              }
            },
            onError: {
              toast: false,
              callback: data => {
                component.populateData(component, {}, data);
              }
            }
          }
        );
    };
  };

  populateData(component, candidatesPageData, data) {
    let positionsTitles = {};
    if (
      "positionsTitleAndId" in candidatesPageData &&
      candidatesPageData.positionsTitleAndId.length > 0
    ) {
      candidatesPageData.positionsTitleAndId.map(position => {
        positionsTitles[position.position_id] = position.title;
        return null;
      });
    }
    component.candidatesLoaded = true;
    component.setState({
      ...component.state,
      candidates:
        "candidates" in candidatesPageData ? candidatesPageData.candidates : [],
      candidateCount: data.totalCount,
      hiddenCandidateCount: data.hiddenCandidateCount,
      positionsTitles: positionsTitles
    });
  }

  getViewCandidate(candidate) {
    return () => {
      window.location.pathname =
        frontend.route.jobseeker.public_profile + "/" + candidate.user_id;
    };
  }

  render() {
    if (!this.candidatesLoaded) {
      return null;
    }
    let info = undefined;

    if (
      this.state.candidates.length <= 0 &&
      this.state.hiddenCandidateCount <= 0
    ) {
      info = (
        <div>{Translation.translateStaticText("noCandidates", "No Candidates")}</div>
      );
    } else {
      if (this.props.freeCompany) {
        info = (
          <Fragment>
            {this.state.hiddenCandidateCount > 0 ? (
              <li className="job-listing d-block d-sm-flex pb-3 pb-sm-0 align-items-center">
                <strong>
                  {Translation.translateStaticText(
                    "hiddenCandidatesCount",
                    "Number of hidden candidates: {count}"
                  ).replace("{count}",this.state.hiddenCandidateCount)}
                  <HelperTooltip
                    title={Translation.translateStaticText(
                      "freeStatusCandidateNotice",
                      "Your company status is Free. To see the candidates choice a Pricing plan from the Pricing page."
                    )}
                  >
                    <HelpOutlinedIcon
                      classes={{
                        root: "header-tooltip-icon"
                      }}
                    />
                  </HelperTooltip>
                </strong>
              </li>
            ) : null}
            {this.state.candidates.length > 0 ? (
              <Fragment>
                {this.state.candidates.length > 0 ? (
                  this.state.candidates.map(candidate => {
                    return (
                      <li
                        className="job-listing d-block d-sm-flex pb-3 pt-3 pb-sm-0 justify-content-between"
                        key={candidate.id}
                      >
                        <div
                          className="job-listing-position custom-width mb-3 mb-sm-0 link"
                          onClick={this.getViewCandidate(candidate)}
                        >
                          <h2>{candidate.name}</h2>{" "}
                          <strong>{candidate.email}</strong>
                        </div>
                        {Object.keys(this.state.positionsTitles).length > 0 ? (
                          <div
                            className="job-listing-position custom-width mb-3 mb-sm-0 link"
                            onClick={() => {
                              this.props.goToView(
                                this.state.positionsTitles[
                                  candidate.position_id
                                ]
                              );
                            }}
                          >
                            <strong>
                              {
                                this.state.positionsTitles[
                                  candidate.position_id
                                ]
                              }
                            </strong>
                          </div>
                        ) : null}
                      </li>
                    );
                  })
                ) : (
                  <li className="job-listing d-block d-sm-flex pb-3 pb-sm-0 align-items-center">
                    <h2 id="no_cand">
                      {Translation.translateStaticText(
                        "noCandidates",
                        "No Candidates"
                      )}
                    </h2>
                  </li>
                )}
              </Fragment>
            ) : null}
            {this.state.hiddenCandidateCount <= 0 &&
            this.state.candidates.length <= 0 ? (
              <li className="job-listing d-block d-sm-flex pb-3 pb-sm-0 align-items-center">
                <h2 id="no_cand">
                  {Translation.translateStaticText("noCandidates", "No Candidates")}
                </h2>
              </li>
            ) : null}
          </Fragment>
        );
      } else {
        info = (
          <Fragment>
            {this.state.candidates.length > 0 ? (
              this.state.candidates.map(candidate => {
                return (
                  <li
                    className="job-listing d-block d-sm-flex pb-3 pt-3 pb-sm-0 justify-content-between"
                    key={candidate.id}
                  >
                    <div
                      className="job-listing-position custom-width mb-3 mb-sm-0 link"
                      onClick={this.getViewCandidate(candidate)}
                    >
                      {candidate.has_access === "yes" ? (
                        <Fragment>
                          <h2>{candidate.name}</h2>{" "}
                          <strong>{candidate.email}</strong>
                        </Fragment>
                      ) : (
                        <strong>
                          {Translation.translateStaticText(
                            "candidateRevoceAccess",
                            "Has revoked access"
                          )}
                        </strong>
                      )}
                    </div>
                    {Object.keys(this.state.positionsTitles).length > 0 ? (
                      <div
                        className="job-listing-position custom-width mb-3 mb-sm-0 link"
                        onClick={() => {
                          this.props.goToView(
                            this.state.positionsTitles[candidate.position_id]
                          );
                        }}
                      >
                        <strong>
                          {this.state.positionsTitles[candidate.position_id]}
                        </strong>
                      </div>
                    ) : null}
                  </li>
                );
              })
            ) : (
              <li className="job-listing d-block d-sm-flex pb-3 pb-sm-0 align-items-center">
                <h2 id="no_cand">
                  {Translation.translateStaticText(
                    "noCandidates",
                    "No Candidates"
                  )}
                </h2>
              </li>
            )}
          </Fragment>
        );
      }
    }
    return (
      <section
        ref={this.scrollRef}
        className="counters6 counters visible full-visible"
        id="counters6-6"
        style={{ backgroundColor: "white" }}
      >
        <div className="container">
          <div className="row">
            <div className="container align-center">
              <ul id="candidates" className="job-listings mb-5">
                {info}
              </ul>
              {this.pagination ? this.pagination.render() : null}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ApplicationList;
