import React, { Component } from "react";

class Item extends Component {

    static defaultProps = {
        id: -1,
        children: null
    }

    state={
        isOpen: false,
    }

    onToggle() {
        let component = this;
        return e => {
            e.preventDefault();
            component.setState({
                ...component.state,
                isOpen: !component.state.isOpen
            });
        }
    }

    render() {
        let c = React.Children.toArray(this.props.children);
        return (
            <div style={{border:"1px dotted black", marginBottom: "5px"}} onClick={this.onToggle()} className="FAQ_item">
                {React.cloneElement(c[0], {isOpen: this.state.isOpen, id: this.props.id})}
                {React.cloneElement(c[1], {isOpen: this.state.isOpen, id: this.props.id})}
            </div>
        );
    }
}

export default Item;