export default {
    "-9999": "TODO",
    "-9900": "Wrong data provided",
    "-9000": "Too many requests, please try again later",
    "0000": null,
    "0001": "Username/Password mismatch",
    "0012": "File Request Unathorized",
    "0013": "Form File unknown.",
    "0015": "Please provide one of either (PDF, PNG, SVG)",
    "0016":
        "File size bigger than expected, please provide a more compact file. (Up to 5MB)",
    "0017": "File already exists",
    "0900":
        "Oops we have some issues with handling your request, please contact support",
    "0996": "E-mail is already registered",
    "0997": "Unable to save the provided employee",
    "0998": "Similar company already exists",
    "0999": "We have an issue storing your password, please contact support.",
    "1000": "Database error: contact support",
    "2001": "Company name is required",
    "2002": "Email is not valid",
    "2003": "Contact person name is not valid, lenght 4-80",
    "2004": "Name is not valid, lenght 4-80",
    "2005": "Phone must be a valid number",
    "2006":
        "Password is not valid, lenght 6-80, atleast 1 capital, 1 lower case, 1 number and 1 special character",
    "2007": "Password are not equal",
    "2008": "Recovery key not provided",
    "2009":
        "Your current password must not match new password and have requirements of (lenght 6-80, atleast 1 capital, 1 lower case, 1 number and 1 special character)",
    "2010": "You must agree to the terms and conditions.",
    "2011": "You must agree to the GDPR.",
    "2012": "The location data is not correct",
    "2013": "The provided skills are malformed",
    "2014": "Unknown Visiblity Level",
    "2015":
        "Education level data is malformed. Please remove and try re-entering your education",
    "2016": "SEO is not a valid value - please provide a string value",
    "2017": "Website must be a valid URL",
    "2018": "Description invalid - please provide a string",
    "2019": "GDPR must be a valid link",
    "2020": "Unable to determine type of account to register",
    "2021": "Recovery key expired, please request a new account recovery email",
    "9000": "You must be logged in to access this.",
    "9001": "Your session has expired, please refresh and login.",
    "9002": "Your session has expired, please refresh and login.",
    "9003": "Your session has expired, please refresh and login.",
    "9004": "Your session has expired, please refresh and login.",
    "9005": "Unathorized Resource.",
    "9006": "Already logged",
    "9804": "Profile not found",
    "9802": "The profile you are currently looking for, it's not available.",
    "9801": "You do not have permission for this action.",
    "9902": "Employee name is required",
    "9903": "Employee phone must be a valid number",
    "9904": "Employee email is required",
    "9905":
        "Password is invalid, lenght 6-80, atleast 1 capital, 1 lower case, 1 number and 1 special character",
    "9906": "Password/Password confirmation do not match",
    "9907": "Wrong current password",
    "10000": "Position title is required",
    "11001": "Company name is required",
    "11002": "Salary from/to must be either string (with numbers) or numeretic",
    "11003": "Salary from must be lower or equal to salary to",
    "11005": "Valid from must be lower than valid to",
    "11004": "Valid to must be within a year in the future",
    "11006": "Position title must be unique and non-empty",
    "11007": "Unable to find the company for this position",
    "11008": "Unable to modify non existing position",
    "11009": "Free account is only allowed 1 position",
    "11010": "Valid from must not be in the past",
    "11011": "Freelancer option is not correct",
    "11012": "Remote Work option is not correct",
    "11013": "Years of experience must be numeric",
    "11014": "Salary From must be positive number",
    "11015": "Salary To must be positive number",
    "11016": "Years Of experience must be positive number",
    "11017": "Reference url is not a valid web address",
    "11400": "You are not logged in.",
    "11401": "Unknown position.",
    "11402": "Already applied.",
    "11403": "Position does not exist.",
    "11404": "Jobseeker could not be found, please contact support.",
    "11500": "You are not logged in",
    "11501": "You do not have access to candidates list.",
    "11502": "Unknown position.",
    "11503": "position id not provided.",
    "11504": "position not found.",
    "11505": "no candidates",
    "11506": "No positions to show candidates",
    "14000": "Cannot update non existing access",
    "14001": "The provided data is wrong",
    "14002": "The provided data is wrong",
    "14003": "The provided data is wrong",
    "14004": "This type of permission does not exists",
    "15000": "The user is not in your contact list",
    "15001": "Contact not provided",
    "15002": "Employee not found",
    "15003": "The user is in your contact list",
    "15004": "The user has blocked you",
    "15005": "Invalid user.",
    "15006": "Can not request contact with yourself.",
    "15007": "The company has expired and can not use insystem messaging.",
    "15008": "Notification setting not allowed",
    "16000": "You have to provide a search sting.",
    "16001": "Search sting must be atleast 2 letters long.",
    "16002": "Free company profiles can not use search.",
    "20101": "Employer is mandatory field",
    "20102": "Role is mandatory field",
    "20103": "Start date is incorrect",
    "20104": "End date is incorrect",
    "20105": "Something confusing happened with your form submission",
    "20106": "Something confusing happened with your form submission",
    "20107": "Something confusing happened with your form submission",
    "20111": "Something went wrong finding the provided skills.",
    "21111": "Something went wrong finding the provided employer.",
    "20011":
        "We are having some issues calculating your profile completion, please contact support or try again saving your profile.",
    "20012": "You need 100% profile completion for this action"
};
