import React from "react";
import Translation from '../../../utils/Translation';

class BaseSearchResult extends React.Component {
    static defaultProps = {
        data: undefined,
        search: ""
    };

    parse(data) {
        return <span>Not implemented</span>;
    }

    render() {
        if (this.props.data === undefined) {
            return null;
        }
        return (
            <div>
                {this.props.data.length <= 0 ? (
                    <span>
                        {Translation.translateStaticText(
                            "searchNoResults",
                            "No results"
                        )}
                    </span>
                ) : (
                    this.parse(this.props.data)
                )}
            </div>
        );
    }
}

export default BaseSearchResult;
