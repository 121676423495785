import React, { Component } from "react";
import Button from "@material-ui/core/Button";

import manager from "../../Manager";
import CompanySearchResult from "./Search/CompanySearchResult";
import JobseekerSearchResult from "./Search/JobseekerSearchResult";
import { usertype } from "../../config";
import Pagination from "../../utils/Pagination";
import ToastHandler from "../../utils/ToastHandler";
import Translation from "../../utils/Translation";

class Search extends Component {
  static contextType = manager;

  static defaultProps = {
    location: {},
    user: { user_type: usertype.company }
  };

  state = {
    form: {
      search: "",
      submited: false
    },
    result: undefined,
    searchText: "Search"
  };

  pagination = undefined;
  _ismounted = false;

  componentWillMount() {
    if (usertype.company === this.props.user.user_type) {
      this.setState({
        ...this.state,
        searchText: Translation.translateField(
          "companySearchPlaceholder",
          "Search jobseekers"
        )
      });
    } else {
      this.setState({
        ...this.state,
        searchText: Translation.translateField(
          "jobseekerSearchPlaceholder",
          "Search for positions or companies"
        )
      });
    }
    this._ismounted = true;
  }

  componentWillUnmount() {
    if (this.pagination) {
    }
    this._ismounted = false;
  }

  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.onSubmit();
    }
  };

  handeChange = name => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [name]: value,
        submited: false
      }
    });
  };

  onSubmit() {
    const component = this;
    if (this.state.form.search.length < 2) {
      ToastHandler.showError(
        Translation.translateErrorMessage(
          "searchStringRestriction",
          "Search string cannot be empty or 1 character long"
        )
      );
      return;
    }
    this.setState({
      ...this.state,
      form: { ...this.state.form, submited: true }
    });
    if (component.props.user.user_type === usertype.company) {
      this.pagination = new Pagination(
        1,
        20,
        Translation.translateField("searchTitle", "Search Results"),
        this.onCompanyPageChange
      );
    } else {
      this.pagination = new Pagination(
        1,
        20,
        Translation.translateField("searchTitle", "Search Results"),
        this.onJobseekerPageChange
      );
    }
    this.pagination.init();
  }

  onJobseekerPageChange = (page = 1) => {
    let component = this;
    return e => {
      component.context
        .getJobseekerManager()
        .search(
          component,
          component.state.form.search,
          component.pagination.from,
          component.pagination.size,
          {
            onSuccess: {
              toast: false,
              callback: data => {
                if (!("totalCount" in data) || !("items" in data)) {
                  console.error(
                    "Search onCompanyPageChange ERROR: managerAPI response not containing pagination structure!"
                  );
                  return;
                }
                component.pagination.totalCount = data.totalCount;
                component.populateData(component, data.items, data);
                window.scrollTo(0, 0);
              }
            },
            onError: {
              toast: false,
              callback: data => {
                component.populateData(component, {}, data);
              }
            }
          }
        );
    };
  };

  onCompanyPageChange = (page = 1) => {
    let component = this;
    return e => {
      component.context
        .getCompanyManager()
        .search(
          component,
          component.state.form.search,
          (page - 1) * component.pagination.size,
          component.pagination.size,
          {
            onSuccess: {
              toast: false,
              callback: data => {
                if (!("totalCount" in data) || !("items" in data)) {
                  console.error(
                    "Search onCompanyPageChange ERROR: managerAPI response not containing pagination structure!"
                  );
                  return;
                }
                component.pagination.totalCount = data.totalCount;
                component.populateData(component, data.items, data);
                window.scrollTo(0, 0);
              }
            },
            onError: {
              toast: false,
              callback: data => {
                component.populateData(component, {}, data);
              }
            }
          }
        );
    };
  };

  populateData(that, data, response = undefined) {
    if (
      response.constructor.name !== "Object" ||
      Object.keys(response).length === 0
    ) {
      ToastHandler.showError(
        typeof response === "string"
          ? Translation.translateErrorMessage(response, response)
          : Translation.translateErrorMessage(
              "searchNotLoaded",
              "Unable to load your search. Please try again"
            )
      );
      that.setState({
        ...that.state,
        form: {
          ...that.state.form,
          submited: false
        },
        result: undefined
      });
      return;
    }
    that.setState({
      ...that.state,
      form: {
        ...that.state.form,
        submited: false
      },
      result: data
    });
  }

  render() {
    return (
      <section
        className="counters6 counters cid-rg5irxrHy2 visible full-visible"
        id="counters6-6"
      >
        <div className="container">
          <div className="row">
            <div className="container align-center">
              <div className="input-group mb-3">
                <input
                  style={{ minHeight: "59px" }}
                  onKeyPress={this.handleKeyPress}
                  onChange={this.handeChange("search")}
                  type="text"
                  className="form-control"
                  name="search"
                  data-form-field="search"
                  placeholder={this.state.searchText}
                  id="search-header15-3"
                  value={this.state.form.search}
                />
                <div className="input-group-append">
                  <Button
                    style={{ minHeight: "59px" }}
                    onClick={() => {
                      this.onSubmit();
                    }}
                    className={
                      "btn btn-" +
                      (this.state.form.submited ? "primary" : "secondary")
                    }
                    disabled={this.state.form.submited}
                  >
                    {Translation.translateField("searchButton", "Search")}
                  </Button>
                </div>
              </div>
              <br />
              {this.props.user.user_type === usertype.company ? (
                <CompanySearchResult
                  data={this.state.result}
                  search={this.state.form.search}
                />
              ) : (
                <JobseekerSearchResult
                  data={this.state.result}
                  search={this.state.form.search}
                />
              )}
              <br />
              {this.pagination ? this.pagination.render() : null}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Search;
