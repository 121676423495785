import React, { Component } from 'react';

import Positions from '../sections/Positions';
import Page from '../Page';
import manager from '../../Manager';

class PositionsPage extends Component {
  static contextType = manager;

  componentWillMount() {
    this.context.getAuthManager().pageRestriction({ logged: true });
  }

  render() {
    const { location } = this.props;
    return (
      <Page location={location}>
        <Positions />
      </Page>
    );
  }
}

export default PositionsPage;
