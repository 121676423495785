import React, { Component } from "react";
import Button from "@material-ui/core/Button";

import FloatingLabelTextArea from "../form_helpers/FloatingLabelTextArea";
import FloatingLabelInput from "../form_helpers/FloatingLabelInput";
import Select from "../form_helpers/Select";
import manager from "../../Manager";
import { file } from "../../config";
import Translation from "../../utils/Translation";
import { fileURLFactory } from "../../utils/Images";

class CompanyProfileData extends Component {
  static contextType = manager;

  state = {
    user: {},
    userLoaded: false,
    companyDataLoaded: false,
    data: undefined,
    form: {
      company_name: "",
      ceo: "",
      company_email: "",
      website: "",
      description: "",
      contact_options: [],
      contact: {},
      gdpr: "",
      file: {
        file: "",
        extension: "",
        name: ""
      },
      submited: false
    }
  };
  company_id = undefined;

  getContact(employees, id) {
    let form = { contact_options: [] };
    employees.forEach(function(employee) {
      if (id === employee.employee_id) {
        form.contact = employee;
      }
      form.contact_options = [
        ...form.contact_options,
        { value: employee.employee_id, text: employee.employee_name }
      ];
    });
    return form;
  }

  rememberData(dataReturn) {
    let form = this.getContact(
      dataReturn.employees,
      dataReturn.company.contact_id
    );
    this.company_id = dataReturn.company.company_id;
    form.company_name = dataReturn.company.company_name;
    if (dataReturn.company.ceo === null) {
      form.ceo = "";
    } else {
      form.ceo = dataReturn.company.ceo;
    }
    if (dataReturn.company.description === null) {
      form.description = "";
    } else {
      form.description = dataReturn.company.description;
    }
    if (dataReturn.company.website === null) {
      form.website = "";
    } else {
      form.website = dataReturn.company.website;
    }
    if (dataReturn.company.gdpr === null) {
      form.gdpr = "";
    } else {
      form.gdpr = dataReturn.company.gdpr;
    }
    form.file = this.state.form.file;
    if (dataReturn.logo.file) {
      form.file = dataReturn.logo;
    }
    if (dataReturn.company.company_email === null) {
      form.company_email = "";
    } else {
      form.company_email = dataReturn.company.company_email;
    }
    form.phone = dataReturn.company.phone;
    form.submited = false;
    this.setState({
      ...this.state,
      form: form,
      data: dataReturn,
      companyDataLoaded: true
    });
  }

  componentWillMount() {
    this.context.getAuthManager().pageRestriction({ logged: true });
    let component = this;
    this.context.getAuthManager().getLoggedUser(component, {
      onSuccess: {
        toast: false,
        callback: serverUser =>
          component.setState({
            ...component.state,
            user: serverUser,
            userLoaded: true
          })
      }
    });
    this.context.getCompanyManager().getLoggedCompanyData(component, {
      onSuccess: { callback: dataReturn => component.rememberData(dataReturn) }
    });
  }

  handeSelectChange = function(value) {
    let contact = this.state.form.contact;
    if (contact.contact_id !== value) {
      contact = this.getContact(this.state.data.employees, value).contact;
    }
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        contact: contact,
        submited: false
      }
    });
  };

  handleChange = name => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [name]: value,
        submited: false
      }
    });
  };

  handleFileChange = e => {
    this.context.getFileManager().uploadFile(
      this,
      { file: e.target.files[0], type: file.logo },
      {
        onSuccess: {
          message: Translation.translateMessage("File Uploaded", "File Saved"),
          callback: data => {
            this.setState({
              ...this.state,
              form: {
                ...this.state.form,
                file: data
              }
            });
          }
        }
      }
    );
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.onSubmit();
    }
  };

  getRequestFrom() {
    let form = {
      company_name: this.state.form.company_name,
      ceo: this.state.form.ceo,
      company_email: this.state.form.company_email,
      website: this.state.form.website,
      gdpr: this.state.form.gdpr,
      contact_id: this.state.form.contact.employee_id,
      description: this.state.form.description
    };
    return form;
  }

  onSubmit() {
    const component = this;
    this.setState({
      ...this.state,
      form: { ...this.state.form, submited: true }
    });
    this.context
      .getCompanyManager()
      .setLoggedCompanyData(component, this.getRequestFrom(), {
        onSuccess: {
          message: Translation.translateMessage("Saved", "Saved"),
          callback: data => {
            if (data.constructor === Object) {
              let newData = component.state.data;
              newData.company = data;
              component.rememberData(newData);
            }
          }
        },
        onError: {
          state: {
            ...component.state,
            form: { ...component.state.form, submited: false }
          }
        },
        OnFinish: {
          state: {
            ...component.state,
            form: { ...component.state.form, submited: false }
          }
        }
      });
  }

  removeLogo = e => {
    this.context.getFileManager().removeCompanyLogo(this, {
      onSuccess: {
        message: Translation.translateMessage("File Removed", "File Removed"),
        state: {
          ...this.state,
          form: {
            ...this.state.form,
            file: {
              file: "",
              extension: "",
              name: ""
            }
          }
        }
      }
    });
  };

  render() {
    if (!this.state.companyDataLoaded) return <br />;
    const {
      form: {
        company_name,
        ceo,
        company_email,
        website,
        gdpr,
        description,
        file
      },
      data: {
        company: { company_type }
      }
    } = this.state;
    let upload;
    if (file.file) {
      upload = (
        <div className="form-group">
          <img
            className=".pl-3"
            src={fileURLFactory(file.file)}
            alt="Company Logo (png, jpg or jpeg)"
            style={{ maxWidth: "100%", maxHeight: "650px" }}
          />
          <span onClick={this.removeLogo} style={{ color: "white" }}>
            {Translation.translateField("removeLogoButton", "Change/Remove")}
          </span>
        </div>
      );
    } else {
      upload = (
        <div className="form-group">
          <FloatingLabelInput
            onChange={this.handleFileChange}
            placeholder={Translation.translateField(
              "logoPlaceholder",
              "Logo (png, jpg or jpeg)"
            )}
            alwaysShowPlaceholderAsLabel="true"
            type="file"
            className="form-control px-3"
            name="file"
            data-form-field="file"
            id="logo-header15-3"
            value={file.name}
          />
        </div>
      );
    }
    return (
      <div className="container align-right">
        <div className="mbr-text pb-3 mbr-fonts-style display-5">
          <h4
            style={{ color: "white" }}
            className={`align-center text-capitalize ${company_type}`}
          >
            {Translation.translateStaticText(
              `${company_type} account`,
              `${company_type} account`
            )}
          </h4>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-5">
            <div className="media-container-column" data-form-type="formoid">
              <div data-for="file">{upload}</div>
            </div>
          </div>
          <div className="mbr-white col-lg-6 col-md-7 content-container">
            <form className="mbr-form">
              <div data-for="company_name">
                <div className="form-group">
                  <FloatingLabelInput
                    onKeyPress={this.handleKeyPress}
                    onChange={this.handleChange("company_name")}
                    type="text"
                    className="form-control px-3"
                    name="company_name"
                    data-form-field="company_name"
                    placeholder={Translation.translateField(
                      "companyName",
                      "Company Name"
                    )}
                    id="company_name-header15-3"
                    value={company_name}
                  />
                </div>
              </div>
              <div data-for="ceo">
                <div className="form-group">
                  <FloatingLabelInput
                    onKeyPress={this.handleKeyPress}
                    onChange={this.handleChange("ceo")}
                    type="text"
                    className="form-control px-3"
                    name="ceo"
                    data-form-field="ceo"
                    placeholder={Translation.translateField(
                      "CEOPlaceholder",
                      "CEO"
                    )}
                    id="ceo-header15-3"
                    value={ceo}
                  />
                </div>
              </div>
              <div data-for="company_email">
                <div className="form-group">
                  <FloatingLabelInput
                    onKeyPress={this.handleKeyPress}
                    onChange={this.handleChange("company_email")}
                    type="text"
                    className="form-control px-3"
                    name="company_email"
                    data-form-field="company_email"
                    placeholder={Translation.translateField(
                      "companyEmail",
                      "Company Email"
                    )}
                    id="company_email-header15-3"
                    value={company_email}
                  />
                </div>
              </div>
              <div data-for="website">
                <div className="form-group">
                  <FloatingLabelInput
                    onKeyPress={this.handleKeyPress}
                    onChange={this.handleChange("website")}
                    type="text"
                    className="form-control px-3"
                    name="website"
                    data-form-field="website"
                    placeholder={Translation.translateField(
                      "website",
                      "Website"
                    )}
                    id="website-header15-3"
                    value={website}
                  />
                </div>
              </div>
              <div data-for="gdpr">
                <div className="form-group">
                  <FloatingLabelInput
                    onKeyPress={this.handleKeyPress}
                    onChange={this.handleChange("gdpr")}
                    type="text"
                    className="form-control px-3"
                    name="gdpr"
                    data-form-field="gdpr"
                    placeholder={Translation.translateField(
                      "companyGdpr",
                      "GDPR (provide a link to your GDPR)"
                    )}
                    id="gdpr-header15-3"
                    value={gdpr}
                  />
                </div>
              </div>
              <div data-for="contact_person">
                <div className="form-group">
                  <Select
                    onSelectChange={this.handeSelectChange}
                    className="form-control px-3"
                    placeholder={Translation.translateField(
                      "contactPlaceholder",
                      "Contact"
                    )}
                    name="contact_person"
                    value={this.state.form.contact.contact_id}
                    options={this.state.form.contact_options}
                  />
                </div>
              </div>
              <div data-for="phone">
                <div className="form-group">
                  <FloatingLabelInput
                    onKeyPress={this.handleKeyPress}
                    onChange={this.handleChange("phone")}
                    style={{ backgroundColor: "#e9ecefcc" }}
                    type="text"
                    className="form-control px-3"
                    name="phone"
                    data-form-field="phone"
                    placeholder={Translation.translateField(
                      "contactPhonePlaceholder",
                      "Phone (set by contact profile)"
                    )}
                    id="phone-header15-3"
                    value={this.state.form.contact.phone}
                    disabled
                  />
                </div>
              </div>
              <div data-for="contact_person_email">
                <div className="form-group">
                  <FloatingLabelInput
                    onKeyPress={this.handleKeyPress}
                    onChange={this.handleChange("contact_person_email")}
                    style={{ backgroundColor: "#e9ecefcc" }}
                    type="text"
                    className="form-control px-3"
                    name="contact_person_email"
                    data-form-field="contact_person_email"
                    placeholder={Translation.translateField(
                      "contactEmailPlaceholder",
                      "Contact Person Email (set by contact profile)"
                    )}
                    id="contact_person_email-header15-3"
                    value={this.state.form.contact.email}
                    disabled
                  />
                </div>
              </div>
              <div data-for="description">
                <div className="form-group">
                  <FloatingLabelTextArea
                    onChange={this.handleChange("description")}
                    className="form-control px-3"
                    name="description"
                    data-form-field="description"
                    placeholder={Translation.translateField(
                      "descriptionPlaceholder",
                      "Description"
                    )}
                    id="description-header15-3"
                    value={description}
                  />
                </div>
              </div>
              <div className="container align-center">
                <span className="input-group-btn">
                  <Button
                    onClick={() => {
                      this.onSubmit();
                    }}
                    className={
                      "btn btn-" +
                      (this.state.form.submited ? "primary" : "secondary") +
                      " btn-form display-4"
                    }
                    disabled={this.state.form.submited}
                  >
                    {Translation.translateField("saveButton", "Save")}
                  </Button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyProfileData;
