import React, { Component } from "react";
import manager from "../../Manager";
import Question from "./FAQ/Question";
import Answer from "./FAQ/Answer";
import Item from "./FAQ/Item";
import Translation from "../../utils/Translation";

class JobseekerFAQ extends Component {
    static contextType = manager;

    componentWillMount() {
        this.context.getAuthManager().pageRestriction({ logged: true });
    }

    data = [
        {
            question: Translation.translateFAQ(
                "jobseekerFirstQuestion",
                "Who is this platform for?"
            ),
            answer: Translation.translateFAQ(
                "jobseekerFirstAnswer",
                "Anyone seeking new opportunities to develop his/her skills or extend even further with the help of recognizable companies in the market, and any company looking for a new rockstar, so you can kick off the new project."
            )
        },
        {
            question: Translation.translateFAQ(
                "jobseekerSecondQuestion",
                "Who can see my personal info?"
            ),
            answer: Translation.translateFAQ(
                "jobseekerSecondAnswer",
                "Totally depends on you - whether you decide to make your profile public or give access to certain companies you’ve liked."
            )
        },
        {
            question: Translation.translateFAQ(
                "jobseekerThirdQuestion",
                "Can I keep my account open on several devices?"
            ),
            answer: Translation.translateFAQ(
                "jobseekerThirdAnswer",
                "Sure you can. Just keep in mind that messages and notifications won't reflect on each of them until you refresh the page on the current device."
            )
        },
        {
            question: Translation.translateFAQ(
                "jobseekerFourthQuestion",
                "Can I reach out to companies directly?"
            ),
            answer: Translation.translateFAQ(
                "jobseekerFourthAnswer",
                "Sure, as long as you wave to them by sending an invitation and they accept it. Matching begins."
            )
        },
        {
            question: Translation.translateFAQ(
                "jobseekerFifthQuestion",
                "Can I deactivate my profile and make it invisible while not actively searching for a new position?"
            ),
            answer: Translation.translateFAQ(
                "jobseekerFifthAnswer",
                "Yes, it is one of the good features we’ve implemented. You are in control of your profile at all time."
            )
        },
        {
            question: Translation.translateFAQ(
                "jobseekerSixthQuestion",
                "What is the price for joining the platform?"
            ),
            answer: Translation.translateFAQ(
                "jobseekerSixthAnswer",
                "Absolutely free."
            )
        },
        {
            question: Translation.translateFAQ(
                "jobseekerSeventhQuestion",
                "Then what is in it for you?"
            ),
            answer: Translation.translateFAQ(
                "jobseekerSeventhAnswer",
                "The companies that want to have access to registered profiles or to post job ads have to settle up with certain amount."
            )
        },
        {
            question: Translation.translateFAQ(
                "jobseekerEighthQuestion",
                "What is the purpose of the platform?"
            ),
            answer: Translation.translateFAQ(
                "jobseekerEighthAnswer",
                "Matching the talents with the companies who are looking for their skills. Providing a tool, that is easy to use, connect and via which you can communicate directly."
            )
        },
        {
            question: Translation.translateFAQ(
                "jobseekerNinthQuestion",
                "How can I hide my profile from the companies?"
            ),
            answer: Translation.translateFAQ(
                "jobseekerNinthAnswer",
                "Go to your profile and click on settings - simply follow the instructions."
            )
        },
        {
            question: Translation.translateFAQ(
                "jobseekerTenthQuestion",
                "Is there a way to recommend a colleague for an open position?"
            ),
            answer: Translation.translateFAQ(
                "jobseekerTenthAnswer",
                "The best way is to invite him/her to join the platform. That way will be given access to all the opportunities you have."
            )
        },
        {
            question: Translation.translateFAQ(
                "jobseekerEleventhQuestion",
                "Is there a way to report a company when an issue arises?"
            ),
            answer: Translation.translateFAQ(
                "jobseekerEleventhAnswer",
                "If you have any discrepancies please feel free to contact us via online chat support."
            )
        },
        {
            question: Translation.translateFAQ(
                "jobseekerTwelfthQuestion",
                "Why am I not visible to some companies?"
            ),
            answer: Translation.translateFAQ(
                "jobseekerTwelfthAnswer",
                "There might be several factors. Please check if you haven’t put special restrictions on your profile. Also makes sure you’ve listed all of the skills you have  - most of the times companies search by certain criterias."
            )
        },
        {
            question: Translation.translateFAQ(
                "jobseekerThirtheenthQuestion",
                "Are there only Bulgarian companies in the platform?"
            ),
            answer: Translation.translateFAQ(
                "jobseekerThirtheenthAnswer",
                "The platform is free to use globally. You can easily connect with registered companies all over the world that can provide a new scale of opportunities."
            )
        },
        {
            question: Translation.translateFAQ(
                "jobseekerFourteenthQuestion",
                "Is it necessary to upload my CV in order to join the platform?"
            ),
            answer: Translation.translateFAQ(
                "jobseekerFourteenthAnswer",
                "It is not mandatory but highly recommendable - that way you will specify in detail what separates you from the crowd."
            )
        },
        {
            question: Translation.translateFAQ(
                "jobseekerFifteenQuestion",
                "Can I reveal and respectively hide portions of my profile?"
            ),
            answer: Translation.translateFAQ(
                "jobseekerFifteenAnswer",
                "Absolutely, based on the access profile configuration."
            )
        },
        {
            question: Translation.translateFAQ(
                "jobseekerSixteenthQuestion",
                "Can I join the platform from outside of the EU?"
            ),
            answer: Translation.translateFAQ(
                "jobseekerSixteenthAnswer",
                "Yes, there are no restrictions from where you can join or use the platform."
            )
        },
        {
            question: Translation.translateFAQ(
                "jobseekerSeventeenthQuestion",
                "Which part of my personal info is visible to the companies?"
            ),
            answer: Translation.translateFAQ(
                "jobseekerSeventeenthAnswer",
                "Based on your profile configuration you could show all your personal information that you have introduced, only your skills or not show anything."
            )
        }
    ];

    render() {
        let faq = [];
        for (let [i] of this.data.entries()) {
            faq.push(
                <Item id={i} key={i}>
                    <Question>{this.data[i].question}</Question>
                    <Answer>{this.data[i].answer}</Answer>
                </Item>
            );
        }
        return (
            <div id="next">
                <section className="cid-rgxv6dNGOY" id="company_faq">
                    <div className="container align-center">
                        <h2 className="mbr-section-title pb-3 mbr-fonts-style display-1">
                            {Translation.translateFAQ(
                                "heading",
                                "Frequently asked questions"
                            )}
                        </h2>
                        <p>
                            {Translation.translateFAQ(
                                "guidelines",
                                "Click on a question to read the answer."
                            )}
                        </p>
                        <br />
                        {faq}
                        <br />
                        <br />
                        <p>
                            {Translation.translateFAQ(
                                "footer",
                                "Still have unanswered Questions? You can use our online chat to either send us a message or an email, if we are offline."
                            )}
                        </p>
                    </div>
                </section>
            </div>
        );
    }
}

export default JobseekerFAQ;
