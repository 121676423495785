import UIErrors from "./bg/UIErrors";
import UIMessages from "./bg/UIMessages";
import UIFormLabels from "./bg/UIFormLabels";
import UIStaticTexts from "./bg/UIStaticTexts";
import ServerMessages from "./bg/ServerMessages";
import FieldValues from "./bg/FieldValues";
import FAQStatic from "./bg/FAQStatic";
import NavigationLabels from './bg/NavigationLabels';
import bool from './vars/bool';
/**
 * Author Simeon-Banov
 */
let messages = {
    // UI messages
    // errors
    errors: UIErrors,
    // messages
    messages: UIMessages,
    // Server Response
    responses: ServerMessages,
    // fields
    fields: UIFormLabels,
    //values
    values: FieldValues,
    //static texts
    staticText: UIStaticTexts,
    //FAQ
    faq: FAQStatic,
    //navigation
    navigation: NavigationLabels,
    //bools
    bool: bool['bg']
};

export default messages;
