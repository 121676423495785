import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import ClientCaptcha from "react-client-captcha";
import FloatingLabelInput from "../form_helpers/FloatingLabelInput";
import ToastHandler from "../../utils/ToastHandler";
import manager from "../../Manager";
import { frontend, usertype } from "../../config";
import Translation from "../../utils/Translation";

class Login extends Component {
    static contextType = manager;

    defaultFormValues = {
        email: "",
        password: "",
        captcha: "",
        submited: false
    };
    state = {
        type: usertype.company,
        form: this.defaultFormValues,
        captcha: undefined,
        failedAttempts: 0
    };

    componentWillMount = () => {
        this.context.getAuthManager().pageRestriction({ logged: false });
    };

    handeChange = name => ({ target: { value } }) => {
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                [name]: value,
                submited: false
            }
        });
    };

    handleKeyPress = event => {
        if (event.key === "Enter") {
            this.onSubmit(this.props.type);
        }
    };

    validateCaptcha = (captchaCode, captchaValue) => {
        if (
            !captchaCode ||
            captchaCode.toUpperCase() === captchaValue.toUpperCase()
        ) {
            return false;
        }
        return true;
    };

    onSubmit(type) {
        const component = this;
        const invalidCaptcha = this.validateCaptcha(
            this.state.captcha,
            this.state.form.captcha
        );
        if (invalidCaptcha) {
            this.setState({
                ...this.state,
                form: { ...this.state.form, captcha: "" }
            });
            ToastHandler.showError("Invalid captcha");
            return;
        }
        component.setState({
            ...component.state,
            form: { ...component.state.form, submited: true }
        });
        component.context.getAuthManager().login(
            component,
            {
                usertype: type,
                username: component.state.form.email,
                password: component.state.form.password
            },
            {
                onSuccess: {
                    callback: data => {
                        component.setState(
                            {
                                ...component.state,
                                failedAttempts: 0
                            },
                            () => {
                                if(localStorage.getItem('redirect')!==null && localStorage.getItem('redirect')) {
                                    const pathname = localStorage.getItem('redirect');
                                    localStorage.removeItem('redirect');
                                    window.location.pathname = pathname;
                                } else {
                                    window.location.pathname = frontend.route.home
                                }
                            }
                        );
                    }
                },
                onFinish: {
                    state: {
                        ...component.state,
                        form: component.defaultFormValues,
                        failedAttempts: component.state.failedAttempts + 1
                    }
                }
            }
        );
    }

    render() {
        const {
            form: { email, password, captcha },
            failedAttempts
        } = this.state;
        if (this.props.type === usertype.company) {
            return (
                <section
                    className={
                        "cid-rgbYhVM41a mbr-fullscreen " +
                        this.props.type +
                        "Background mbr-parallax-background"
                    }
                    id="header15-b"
                >
                    <br />
                    <br />
                    <br />
                    <div
                        className="mbr-overlay"
                        style={{
                            opacity: 0.5,
                            backgroundColor: "rgb(7, 59, 76)"
                        }}
                    />
                    <div
                        className="container align-right"
                        style={{ marginTop: "50px" }}
                    >
                        <div className="row">
                            <div className="mbr-white col-lg-8 col-md-7 content-container">
                                {this.props.type === usertype.company ? (
                                    <h2 className="login-sign-up-employers">
                                        {Translation.translateStaticText(
                                            "companyRegistrationHeading",
                                            "EMPLOYERS"
                                        )}
                                    </h2>
                                ) : (
                                    <h2 className="login-sign-up-jobseekers">
                                        {Translation.translateStaticText(
                                            "jobseekerRegistrationHeading",
                                            "JOBSEEKERS"
                                        )}
                                    </h2>
                                )}
                                <h1 className="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-2">
                                    {Translation.translateNavigation(
                                        "login",
                                        "Login"
                                    )}
                                </h1>
                                <p className="mbr-text pb-3 mbr-fonts-style display-5" />
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <div className="form-container">
                                    <div
                                        className="media-container-column"
                                        data-form-type="formoid"
                                    >
                                        <form className="mbr-form">
                                            <input
                                                type="hidden"
                                                name="email"
                                                data-form-email="true"
                                                value="F69B3NcZe0l3wRLV5UF5Og6P1j1i3UcH9UDLW3cDpMcZoe8HbiWdkLSGqC6PhwHMrVoN5MwD7MMLXUO8aYnCwxS96h63iWLWDbrb3wllJpcPzN87pJFAVT6lEwlTnpqt"
                                            />
                                            <div data-for="username">
                                                <div className="form-group">
                                                    <FloatingLabelInput
                                                        onKeyPress={
                                                            this.handleKeyPress
                                                        }
                                                        onChange={this.handeChange(
                                                            "email"
                                                        )}
                                                        type="email"
                                                        className="form-control px-3"
                                                        name="username"
                                                        data-form-field="username"
                                                        placeholder={Translation.translateField(
                                                            "userLogin",
                                                            "Username/Email"
                                                        )}
                                                        required="required"
                                                        id="username-header15-b"
                                                        value={email}
                                                    />
                                                </div>
                                            </div>
                                            <div data-for="password">
                                                <div className="form-group">
                                                    <FloatingLabelInput
                                                        onKeyPress={
                                                            this.handleKeyPress
                                                        }
                                                        onChange={this.handeChange(
                                                            "password"
                                                        )}
                                                        type="password"
                                                        className="form-control px-3"
                                                        name="password"
                                                        data-form-field="Password"
                                                        placeholder={Translation.translateField(
                                                            "password",
                                                            "Password"
                                                        )}
                                                        required="required"
                                                        id="password-header15-b"
                                                        value={password}
                                                    />
                                                </div>
                                            </div>
                                            {failedAttempts >= 3 && (
                                                <div className="flex">
                                                    <FloatingLabelInput
                                                        onKeyPress={
                                                            this.handleKeyPress
                                                        }
                                                        onChange={this.handeChange(
                                                            "captcha"
                                                        )}
                                                        type="text"
                                                        className="form-control px-3"
                                                        name="captcha"
                                                        data-form-field="Captcha"
                                                        id="captcha-input"
                                                        placeholder="Captcha"
                                                        value={captcha}
                                                    />
                                                    <ClientCaptcha
                                                        captchaCode={code =>
                                                            this.setState({
                                                                ...this.state,
                                                                captcha: code
                                                            })
                                                        }
                                                    />
                                                </div>
                                            )}
                                            <span className="input-group-btn">
                                                <Button
                                                    onClick={() => {
                                                        this.onSubmit(
                                                            this.props.type
                                                        );
                                                    }}
                                                    className={
                                                        "btn btn-" +
                                                        (this.state.form
                                                            .submited
                                                            ? "primary"
                                                            : "secondary") +
                                                        " btn-form display-4"
                                                    }
                                                    disabled={
                                                        this.state.form
                                                            .submited ||
                                                        (this.state.captcha &&
                                                            this.state.captcha
                                                                .length !==
                                                                captcha.length)
                                                    }
                                                >
                                                    {Translation.translateField(
                                                        "loginButton",
                                                        "Login"
                                                    )}
                                                </Button>
                                            </span>
                                        </form>
                                        <a
                                            href={
                                                frontend.route.account_recovery
                                            }
                                        >
                                            {Translation.translateField(
                                                "forgottenPassword",
                                                "Forgotten password"
                                            )}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
        } else if (this.props.type === usertype.jobseeker) {
            return (
                <section
                    className={
                        "cid-rg5fGgmhmj mbr-fullscreen " +
                        this.props.type +
                        "Background mbr-parallax-background"
                    }
                    id="header15-3"
                >
                    <div
                        className="mbr-overlay"
                        style={{
                            opacity: 0.5,
                            backgroundColor: "rgb(7, 59, 76)"
                        }}
                    />
                    <div
                        className="container align-right"
                        style={{ marginTop: "50px" }}
                    >
                        <div className="row">
                            <div className="mbr-white col-lg-8 col-md-7 content-container">
                                {this.props.type === usertype.company ? (
                                    <h2 className="login-sign-up-employers">
                                        {Translation.translateStaticText(
                                            "companyRegistrationHeading",
                                            "EMPLOYERS"
                                        )}
                                    </h2>
                                ) : (
                                    <h2 className="login-sign-up-jobseekers">
                                        {Translation.translateStaticText(
                                            "jobseekerRegistrationHeading",
                                            "JOBSEEKERS"
                                        )}
                                    </h2>
                                )}
                                <h1 className="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-2">
                                    {Translation.translateNavigation(
                                        "login",
                                        "Login"
                                    )}
                                </h1>
                                <p className="mbr-text pb-3 mbr-fonts-style display-5" />
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <div className="form-container">
                                    <div
                                        className="media-container-column"
                                        data-form-type="formoid"
                                    >
                                        <div className="mbr-form">
                                            <input
                                                type="hidden"
                                                name="email"
                                                data-form-email="true"
                                                value="t00ENcxonzmNmuh5hi4SyezQcHgIWZxKl2x9gI+yWi4fufnGk3XVHz/R5ZeppcxVk/AfiNB6pnXuDM9Zra5tEyaZlr0tnIsSKiLtSiWIOf03f3ZUPDbzMou4BBx2I5ln"
                                            />

                                            <div data-for="username">
                                                <div className="form-group">
                                                    <FloatingLabelInput
                                                        onKeyPress={
                                                            this.handleKeyPress
                                                        }
                                                        type="text"
                                                        onChange={this.handeChange(
                                                            "email"
                                                        )}
                                                        className="form-control px-3"
                                                        name="username"
                                                        data-form-field="username"
                                                        placeholder={Translation.translateField(
                                                            "userLogin",
                                                            "Username/Email"
                                                        )}
                                                        required=""
                                                        id="username-header15-3"
                                                        value={email}
                                                    />
                                                </div>
                                            </div>
                                            <div data-for="password">
                                                <div className="form-group">
                                                    <FloatingLabelInput
                                                        onKeyPress={
                                                            this.handleKeyPress
                                                        }
                                                        onChange={this.handeChange(
                                                            "password"
                                                        )}
                                                        type="password"
                                                        className="form-control px-3"
                                                        name="password"
                                                        data-form-field="Password"
                                                        placeholder={Translation.translateField(
                                                            "password",
                                                            "Password"
                                                        )}
                                                        id="password-header15-3"
                                                        value={password}
                                                    />
                                                </div>
                                            </div>
                                            {failedAttempts >= 3 && (
                                                <div className="flex">
                                                    <FloatingLabelInput
                                                        onKeyPress={
                                                            this.handleKeyPress
                                                        }
                                                        onChange={this.handeChange(
                                                            "captcha"
                                                        )}
                                                        type="text"
                                                        className="form-control px-3"
                                                        name="captcha"
                                                        data-form-field="Captcha"
                                                        id="captcha-input"
                                                        placeholder={Translation.translateField(
                                                            "captcha",
                                                            "Captcha"
                                                        )}
                                                        value={captcha}
                                                    />
                                                    <ClientCaptcha
                                                        captchaCode={code =>
                                                            this.setState({
                                                                ...this.state,
                                                                captcha: code
                                                            })
                                                        }
                                                    />
                                                </div>
                                            )}
                                            <span className="input-group-btn">
                                                <Button
                                                    onClick={() => {
                                                        this.onSubmit(
                                                            this.props.type
                                                        );
                                                    }}
                                                    className={
                                                        "btn btn-" +
                                                        (this.state.form
                                                            .submited
                                                            ? "primary"
                                                            : "secondary") +
                                                        " btn-form display-4"
                                                    }
                                                    disabled={
                                                        this.state.form
                                                            .submited ||
                                                        (this.state.captcha &&
                                                            this.state.captcha
                                                                .length !==
                                                                captcha.length)
                                                    }
                                                >
                                                    {Translation.translateField(
                                                        "loginButton",
                                                        "Login"
                                                    )}
                                                </Button>
                                            </span>
                                        </div>
                                        <a
                                            href={
                                                frontend.route.account_recovery
                                            }
                                        >
                                            {Translation.translateField(
                                                "forgottenPassword",
                                                "Forgotten password"
                                            )}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
        }
    }
}

export default Login;
