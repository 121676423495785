import React, { Component, Fragment } from "react";
import Translation from '../../utils/Translation';

class PasswordTooltip extends Component {
	render() {
		return (
			<Fragment>
				<strong className="helper-heading-text">
					{Translation.translateStaticText("passwordRequirementsHeading", "Your password must match the following complexity criterias:")}
				</strong>
				<ul className="helper-text">
					<li>
						<em>{Translation.translateStaticText("passwordLengthRequirement", "6-16 Characters Long")}</em>
					</li>
					<li>
						<em>{Translation.translateStaticText("passwordCapitalLetterRequirement", "1 Capital Letter")}</em>
					</li>
					<li>
						<em>{Translation.translateStaticText("passwordLowerCaseLetterRequirement", "1 Lower Case Letter")}</em>
					</li>
					<li>
						<em>{Translation.translateStaticText("passwordDigitRequirement", "1 Digit")}</em>
					</li>
					<li>
						<em>{Translation.translateStaticText("passwordSpecialSymbolRequirement", "1 Special Symbol")}</em>
					</li>
				</ul>
			</Fragment>
		);
	}
}

export default PasswordTooltip;
