export default {
	employers: "Employers",
	jobseekers: "Jobseekers",
	signUp: "Sign Up Free",
	aboutUs: "About Us",
	pricing: "Pricing",
	login: "Login",
	search: "Search",
	positions: "Positions",
	chat: "Chat",
	profile: "Profile",
	notificationSettings: "Notifications",
	logout: "Logout"
};
