import React, { Component } from "react";
import Page from "../../Page";

import { setTitle } from "../../../pageOptions";

class BGTermsAndConditionsEmployersPage extends Component {
  componentWillMount() {
    setTitle("ОБЩИ ДОГОВОРНИ УСЛОВИЯ");
  }

  render() {
    return (
      <Page>
        <section
          className="cid-qTkA127IK8 mbr-fullscreen mbr-parallax-background"
          id="header2-1"
        >
          <div className="container align-center">
            <div className="row justify-content-md-center">
              <div
                className="mbr-white col-md-10"
                style={{ paddingTop: "50px" }}
              >
                <h1>ОБЩИ ДОГОВОРНИ УСЛОВИЯ</h1>
                <h3>за ползване на сайта Match Careers</h3>
                („Общи условия”)
                <br />
                (в сила от 22.10.2019 г.)
                <br />
                <br />
                <div style={{ textAlign: "left" }}>
                  I. Общи положения. Предмет на Общите условия
                  <br />
                  1. С настоящите Общи условия се определят редът и условията за
                  използване на платформата Match Careers, предоставяна от
                  Инспайър Софт ЕООД, ЕИК 202675952, наричано по-долу за
                  краткост Доставчик и приета от другата страна с регистрация на
                  сайта като работодател или кандидат за работа, наричани
                  по-долу и в договора за краткост Клиент/и.
                  <br />
                  2. Клиентите приемат съдържанието на Общите условия с
                  маркирането на поле „Общи договорни условия” при създаване на
                  профил на сайта на Доставчика, или приемането на търговското
                  предложение на Доставчика, наричани по-долу „договор за
                  използване на платформата Match Careers, доколкото друго не е
                  уговорено между страните.
                  <br />
                  3. Услугата, включва следните видове дейности:
                  <br />
                  3.1. Публикуване на обяви за работа и визуализирането на
                  обявата за срок договорен между страните;
                  <br />
                  3.2. Създаване на база данни с постъпилите в отговор на
                  обявата автобиографии и мотивационни писма, кореспонденция;
                  <br />
                  3.3. Своевременно информиране на Клиента относно искания за
                  предоставяне на информация по приложимите закони, включително
                  но не само Закона за защита на личните данни, Закона за
                  електронната търговия и Закона за защита на потребителите;
                  <br />
                  3.4. Създаване на профил с автобиография.
                  <br />
                  3.5. Изготвяне на отчети относно услугата по заявка на
                  Клиента.
                  <br />
                  4. Обявата, предмет на услугата се публикува от Клиента чрез
                  използване на Клиентския си профил.
                  <br />
                  5. Доставчикът не носи отговорност за качеството на Обявата,
                  включително но не само не носи отговорност относно вида на
                  цветовете, качеството на илюстрациите, наличието на
                  правописни, пунктуационни и други грешки в текста на
                  Заданието, грешки във визии и други подобни технически
                  параметри на Заданието.
                </div>
                <br />
                <div style={{ textAlign: "left" }}>
                  ІІ. Срок на договора <br />
                  6. Доставчикът се задължава да предостави услугата за срока на
                  абонамент, заплатен от Клиента.
                </div>
                <br />
                <div style={{ textAlign: "left" }}>
                  ІІІ. Изготвяне на доклади относно предоставената услуга
                  <br />
                  7. При нужда Доставчикът изготвя доклади относно
                  предоставената услуга в следните срокове:30 дни. <br />
                  8. В случай на спор или възражение от физическо или юридическо
                  лице, включено в базата данни на Клиента, Доставчикът
                  уведомява Клиента в срок до 30 дни.
                  <br />
                  9. Отношенията между страните относно защитата на лични данни
                  се уреждат от сключения между тях Договор за обработване на
                  лични данни.
                </div>
                <br />
                <div style={{ textAlign: "left" }}>
                  ІV. Права и задължения на Доставчика
                  <br />
                  10. Доставчикът се задължава:
                  <br />
                  10.1. Да изпълни услугата с грижата на добър търговец и
                  съгласно утвърдените стандарти за тази услуга;
                  <br />
                  10.2. При предоставяне на услугата да вземе всички необходими
                  мерки за запазване на конфиденциалността на информацията
                  съхраняванa в профила на Клиента.
                  <br />
                  11. Доставчикът има право да:
                  <br />
                  11.1. Да откаже сключването на договор за услугата с Клиент,
                  който:
                  <br />
                  а) има просрочени задължения по други договори с Доставчика;
                  <br />
                  б) предоставил е информация, което не отговоря на настоящите
                  Общи условия.
                  <br />
                  11.2. Да прекрати договора за услугата, сключен с Клиента, в
                  случай че последният не заплати дължима цена съгласно
                  сроковете и условията на договора, като в този случай
                  настъпват последиците от неизпълнение на договора, предвидени
                  в тези Общи условия;
                  <br />
                  11.3. Да прекрати договора за услугата, сключен с Клиента, в
                  случай че Клиентът не предостави допълнителната
                  информация/документи, изискани от Доставчика.
                  <br />
                  12. Клиентът се задължава:
                  <br />
                  12.1. Да публикува Обяви и попълва точна и вярна информация
                  като спазва условията на Раздел V от настоящите Общи условия;
                  <br />
                  12.2. Да не пречи на предоставянето на услугата от страна на
                  Доставчика;
                  <br />
                  12.3. Да дава информация на Доставчика относно съдържанието и
                  авторските права на информацията, публикуване на сайта при
                  необходимост;
                  <br />
                  12.4. Да осигури приемането на услугите по начина и на
                  адресите, договорени между страните по реда на настоящите Общи
                  условия;
                  <br />
                  12.5. Да заплати цената за услугата спрямо избрания план;
                  <br />
                  12.6. Да уведомява в най-кратък срок писмено Доставчика за
                  всяка промяна в името/наименованието, адреса  си или в други
                  свои регистрационни данни.
                </div>
                <br />
                <div style={{ textAlign: "left" }}>
                  V. ДОПУСТИМО СЪДЪРЖАНИЕ. АНТИ-СПАМ ПОЛИТИКА
                  <br />
                  13. Доставчикът изрично забранява при използване на услугата
                  Клиентът да разполага, инсталира, споделя или стартира следния
                  тип съдържание (като файлове или връзки):
                  <br />
                  13.1. Материали, които противоречат на българското
                  законодателство, приложимите чужди закони и/или настоящите
                  Общи условия;
                  <br />
                  13.2. Материали, които нарушават права на трети лица (авторски
                  права или сродни на тях права, права върху марки, патент или
                  други права на интелектуална собственост, правото на личен
                  живот и неприкосновеност);
                  <br />
                  13.3. Материали, които представляват търговска/ служебна тайна
                  или друга конфиденциална информация или лични данни на трети
                  лица и липсва съгласие от съответните трети лица Клиентът да
                  използва такава информация;
                  <br />
                  13.4. Материали, които съдържат заплаха за живота, телесната
                  неприкосновеност на човека, пропагандират дискриминация,
                  проповядват фашистка, расистка или друга недемократична
                  идеология или накърняват доброто името на трети лица;
                  <br />
                  13.5. Материали, които призовават към насилствена промяна на
                  конституционно установения ред, към извършване на престъпление
                  или към нарушаване на каквито и да е други имуществени или
                  неимуществени права или законни интереси на трети лица;
                  <br />
                  13.6. Материали, които съдържат заплашително, невярно,
                  подвеждащо, измамно, неприлично, оскърбително, клеветническо,
                  обидно, опозоряващо, порнографско или каквото и да било друго
                  съдържание, което застрашава нормалното психическо развитие на
                  ненавършилите пълнолетие лица или нарушава нормите на морала и
                  добрите нрави;
                  <br />
                  13.7. Материали, които представляват хакерски програми или
                  архиви;
                  <br />
                  13.8. „атериали, които съдържат вируси, троянски коне, червеи,
                  програми, които спират да функционират след определена дата
                  (time-bombs), програми за следене на използването от даден
                  потребител клавиши (“keystroke loggers”), шпионски софтуер
                  (adware), софтуер, заразяващ компютрите с рекламни съобщения
                  (spyware), атаки за отказ от услуги (denial-of-service
                  attacks), спуфинг (IP spoofing) и всякакви други „злонамерени”
                  или вредни програми или компютърни кодове (malware);
                  <br />
                  14. Клиентът се съгласява, че изброяването по чл. 13 не е
                  изчерпателно и Доставчикът има право, по свое усмотрение, да
                  прецени, че даден ресурс не отговаря на изискванията за
                  допустимо съдържание или че съдържа риск за сигурността и/или
                  нарушава нормалната работа на сървърното оборудване.
                </div>
                <br />
                <div style={{ textAlign: "left" }}>
                  VI. Рекламация от страна на Клиента
                  <br />
                  15. В срок от 30, Клиентът има право да оспори предоставения
                  от Доставчика отчет по договора.
                  <br />
                  16. Доставчикът се задължава в срок от 30 да разгледа
                  оспорването и да предостави допълнителна информация относно
                  изпълнението.
                </div>
                <br />
                <div style={{ textAlign: "left" }}>
                  VІІ. Отговорност
                  <br />
                  17. В случай, че Клиентът наруши условията по настоящите Общи
                  условия, Клиентът се задължава да заплати неустойка в размер
                  на 10% от стойността на заявените услуги и да обезщети
                  Доставчика за всички претърпени вреди.
                </div>
                <br />
                <div style={{ textAlign: "left" }}>
                  VІІІ. Изменение на Общите условия
                  <br />
                  18. Настоящите Общи условия могат да бъдат изменяни или
                  допълвани от „Инспайър Софт” ООД, като съответните изменения и
                  допълнения влизат в сила по отношение на Клиентите от датата
                  на публикуването им на интернет страницата match.careers.
                </div>
                <br />
                <div style={{ textAlign: "left" }}>
                  IХ. Разрешаване на спорове. Приложимо право
                  <br />
                  19. Споровете между страните, възникнали по повод изпълнението
                  на договора по настоящите Общи условия, се решават чрез
                  преговори между тях. Когато постигането на съгласие се окаже
                  невъзможно, всяка от страните може да отнесе спора до
                  компетентния съд в София.
                  <br />
                  20. За неуредените с тези Общи условия случаи се прилагат
                  разпоредбите на действащото законодателство в Република
                  България.
                  <br />
                  21. При противоречие между клаузите на конкретния договор и
                  текстовете на настоящите Общи условия, условията на договора
                  ще имат приоритет пред Общите условия при прилагане и
                  изпълнение и при тълкуване на волята на страните.
                </div>
                <br />
                <h1>ОБЩИ УСЛОВИЯ ЗА ПОЛЗВАНЕ НА MATCH CAREERS</h1>
                <h3>ЗА РАБОТОДАТЕЛИ</h3>
                <br />
                <div style={{ textAlign: "left" }}>
                  <strong>
                    1. Обхват на услугите, които се предлагат от Match Careers
                  </strong>
                  <br />
                  <strong>Match Careers</strong> е платформа, поддържана от
                  Инспайър Софт ЕООД, регистрирано по българското
                  законодателство, с ЕИК 202675952, седалище: гр. София 1124,
                  бул. Цариградско шосе 15, вх. Б, ет. 1, за предоставяне на
                  услуги на информационното общество на работодатели, надлежно
                  регистрирани съгласно българското законодателство и физически
                  лица, които търсят нови възможности за работа.
                  <br />
                  Създавайки акаунт като Клиент, вие сключвате договор с
                  Инспайър Софт ЕООД за използване на платформата Match Careers
                  за следните услуги:
                  <br />
                  - Публикуване на оферти за работа;
                  <br />
                  - Събиране на информация от кандидати;
                  <br />- Съхранение на данни за Клиента и кандидатите за работа
                  до 3 години.
                </div>
                <div style={{ textAlign: "left" }}>
                  <strong>2. Как да използвате Match Careers</strong>
                  <br />
                  2.1.Използването на Match Careers е възможно само след
                  правилна регистрация от упълномощен представител на
                  юридическото лице работодател или физическото лице работодател
                  (Клиентът) в сайта чрез{" "}
                  <a href="https://match.careers/signup/company">
                    https://match.careers/signup/company
                  </a>{" "}
                  и активиране на акаунт на работодателя. Активирането се
                  извършва в рамките на 2 работни дни от датата на създаване на
                  профила.
                  <br />
                  2.2. Клиентски акаунт може да бъде регистриран от дееспособно
                  лице, което е упълномощено да представлява Клиента за целите
                  на използването на Match Careers. Чрез регистрацията си
                  Клиентът декларира, че Клиентът отговаря на тези условия и
                  информацията, попълнена за идентифициране на Клиента, е точна
                  и пълна.
                  <br />
                  2.3. Клиентът е длъжен да предостави информация за фактуриране
                  и администриране на акаунта.
                  <br />
                  2.4. Match Careers си запазва правото да изисква допълнителна
                  информация за проверка на данните от клиентския акаунт.
                  <br />
                  2.5. Клиентът е изцяло отговорен за всички действия, извършени
                  чрез неговия акаунт, достъпни чрез потребителското име и
                  паролата на Клиента.
                </div>
                <div style={{ textAlign: "left" }}>
                  <strong>3. Подписване на договор и фактуриране</strong>
                  <br />
                  3.1. С натискане на бутона „Регистрация“ лицето, което
                  представлява Клиента, прави електронно изявление и сключва
                  договор с Match Careers съгласно тези условия („Договор“).
                  <br />
                  3.2. При използване на платени Услуги се прави отделна поръчка
                  за всяка Услуга. Предоставянето на Услугите се основава на
                  този Договор и Условия.
                  <br />
                  3.3. Клиентът получава фактура на регистрирания имейл и
                  заплаща дължимата сума в рамките на 5 дни от датата на
                  издаване.
                </div>
                <div style={{ textAlign: "left" }}>
                  <strong>4. Как да публикувате в Match Careers</strong>
                  <br />
                  4.1. Обяви за работа се публикуват в профила на Клиента в поле
                  Свободни позиции – Нови.
                  <br />
                  4.2. Обявата за трябва да съдържа следната информация –
                  препоръчително е да се попълнят всички полета с детайлна
                  информация [наименование на длъжността, отговорности,
                  изисквания за длъжността, оферта, категория, изисквания за
                  образование, специалност, предишен опит, квалификация, размер
                  на възнаграждението, държава]. Задължително се посочва
                  периодът, в който обявата е активна (през този период ще бъде
                  активна и опция за намаляване или продължаване на срока на
                  обявата).
                  <br />
                  4.3. Броят на обявите за работа и тяхното съдържание ще се
                  определят в зависимост от ценивият пакет от услуги, които
                  Клиентът е избрал.
                </div>
                <div style={{ textAlign: "left" }}>
                  <strong>5. Обработка на лични данни</strong>
                  <br />
                  5.1. Клиентът е длъжен да спазва приложимия закон за защита на
                  данните при използване на Услугите.
                  <br />
                  5.2. Съгласно чл. 28 от Европейския общ регламент за защита на
                  данните (“GDPR”), обработката на лични данни от Инспайър Софт
                  ЕООД от името на клиента изисква писмено споразумение
                  (“Споразумение за обработка на данни”). Клиентът с настоящото
                  възлага на Инспайър Софт ЕООД да обработва лични данни от
                  негово име, като сключва отделно споразумение в съответствие с
                  обхвата и условията на анекса „Договорни условия за обработка
                  на данни“.
                  <br />
                  5.3. Клиентът е отговорен да получи и поддържа
                  валидни/актуални съгласията от всички свои крайни потребители,
                  както е възможно (ако изобщо е необходимо) съгласно
                  приложимото законодателство (включително законите и
                  разпоредбите за защита на данните или обработката на данни) за
                  обработка на личните им данни по начин и целите, посочени в
                  настоящото споразумение.
                  <br />
                  Общите условия, свързани с обработката на лични данни, са
                  описани в Споразумението за обработка на данни [{" "}
                  <a href="https://match.careers/bg/site/employers/GDPR">
                    https://match.careers/bg/site/employers/GDPR
                  </a>{" "}
                  ].
                </div>
                <div style={{ textAlign: "left" }}>
                  <strong>6. Отговорност и прекратяване</strong>
                  <br />
                  6.1. В случай на забавяне на плащането или неплащане за
                  Услугите, Match Careers имат право да:
                  <br />
                  (a) Ограничат достъпа Ви до Услугите;
                  <br />
                  (b) Прекратят Договора и да прекратят вашия акаунт.
                  <br />
                  6.2. Тези условия уреждат отношенията между страните за
                  използване на Match Careers.
                  <br />
                  6.3. Всички спорове по тези условия ще бъдат уредени от
                  компетентния съд в София.
                  <br />
                  6.4. За неуредени въпроси се прилага законодателството на
                  Република България.
                </div>
                <br />
                <h3>
                  Настоящите Общи условия влизат в сила от 22.10.2019 година.
                </h3>
              </div>
            </div>
          </div>
        </section>
      </Page>
    );
  }
}

export default BGTermsAndConditionsEmployersPage;
