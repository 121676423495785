export default {
	//General Fields
	username: "Потребителско име",
	userLogin: "Потребителско име/Имейл",
	email: "Имейл",
	fullName: "Цялото Ви Име",
	phone: "Телефон",
	password: "Парола",
	passwordConfirmation: "Потвърдете паролата",
	forgottenPassword: "Забравена парола",
	currentPassword: "Сегашна парола",
	newPassword: "Нова парола",
	newPasswordConfirmation: "Потвърдете нова парола",
	companyEmail: "Корпоративен имейл",

	//Used in terms of service and GDPR agreement
	iAgree: "Съгласявам се с",
	registerGDPRLinkName: "Общия регламент за защита на лични данни",
	registerTermsOfServiceLinkName: "правила и условия",

	//inSystemMessages
	chatPlaceholder: "Вашето съобщение...",

	//Jonseeker Fields
	searchJobseekerPlaceholder: "Търсене на позиции или компании",
	jobseekerCompanyAccessButton: "Фирмен достъп",
	jobseekerEmploymentHistoryButton: "История на заетостта",
	jobseekerProfileLocationCountry: "--- Изберете държава ---",
	JobseekerProfileLocationCity: "--- Изберете град ---",
	jobseekerProfileCV: "Автобиография (само в pdf формат)",
	jobseekerProfileVisibility: "Видимост на профила",
	jobseekerProfileSkillsPLaceholder: "Вашите умения...",
	jobseekerEducationLevel: "Ниво на образование",
	jobseekerEducationSchool: "Училище/Университет",
	jobseekerEducationSpeciality: "Специалност",
	summary: "Резюме",
	jobseekerEmploymentHistoryEmployer: "Работодател",
	jobseekerEmploymentHistoryRole: "Позиция",
	jobseekerEmploymentHistoryStartDate: "Начална дата",
	jobseekerEmploymentHistoryEndDate: "Крайна дата",
	jobseekerEmploymentHistoryPresent: "До момента",
	jobseekerEmploymentHistoryTechnicalStack: "Набор от технологии",
	jobseekerCompanyAccessNameColumn: "Име на компанията",
	jobseekerCompanyAccessAccessColumn: "Осигурен достъп",
	jobseekerCompanyAccessActionsColumn: "Управление",
	jobseekerCompanyAccessSearchPlaceholder: "Търси",

	//Company fields
	companyName: "Име на компанията",
	website: "Сайт",
	contactPerson: "Контактно лице",
	companyGdpr: "GDPR (предоставете връзка към GDPR)",
	searchCompanyPlaceholder: "Търсете кандидати",
	aboutUsPlaceholder: "За нас",
	CEOPlaceholder: "Изпълнителен директор",
	companyLogoAlt: "Корпоративно лого",
	employeeNamePlaceholder: "Име",
	logoPlaceholder: "Лого (png, jpg или jpeg формат)",
	contactPlaceholder: "Контакти",
	contactPhonePlaceholder: "Телефон (зададен от профила за контакт)",
	contactEmailPlaceholder:
		"имейл на лицето за контакт (зададено от профила за контакт)",
	descriptionPlaceholder: "Описание",

	//Search
	jobseekerSearchPlaceholder: "Търсене на позиции или компании",
	companySearchPlaceholder: "Търсене на кандидати",
	searchTitle: "Резултати от търсенето",
	searchMatchedOn: "Съвпадение по",
	companyJobseekerNameMatch: "Име на кандидат",
	companyEducationSpecialityMatch: "Специалност на образование",
	compnayEducationNameMatch: "Образование",
	companySkillMatch: "Умение",
	jobseekerCompanyDescriptionMatch: "Описание на компанията",
	jobseekerCompanyEmployeeNameMatch: "Име на служител на компанията",
	jobseekerCompanyPositionTitleMatch: "Заглавие на длъжността на компанията",
	jobseekerCompanyPositionResponsibilitiesMatch:
		"Отговорности за позиция на компанията",
	jobseekerCompanyPositionRequirementsMatch:
		"Изисквания за позиция на компанията",
	jobseekerCompanyPositionOfferMatch: "Предложение за позиция на компанията",
	jobseekerCompanyPositionSpecialityMatch:
		"Специалност изисквана за позиция на компанията",
	jobseekerCompanyPositionCategoryMatch: "Категория на позиция на компания",
	jobseekerCompanyPositionCountryMatch: "Държава, за където е позицията",
	jobseekerCompanyPositionCityMatch: "Град, за където е позицията",
	jobseekerCompanyPositionQualificationMatch:
		"Квалификация за позиция на компанията",
	jobseekerSearchResultCompanyName: "Компания",
	jobseekerSearchResultPosition: "Позиция",
	jobseekerSearchResultPositionCategory: "Категория на позиция",
	jobseekerSearchResultPositionCity: "Град, за където е позицията",
	jobseekerSearchResultPositionCountry: "Държава, за където е позицията",
	jobseekerSearchResultPositionOffer: "Предложение на позицията",
	jobseekerSearchResultPositionQualification: "Квалификация на позицията",
	jobseekerSearchResultPositionRequirements: "Изисквания на позицията",
	jobseekerSearchResultPositionResponsibilities: "Отговорности на позицията",
	jobseekerSearchResultPositionSpeciality: "Специалност на позицията",

	//positions
	positionExpired: "Изтекла",
	positionInactive: "Неактивна",
	companyDetailsHeading: "Детайли за компанията",
	companyInfoPlaceholder: "Информация за компанията",
	positionDetailsHeading: "Детайли за позицията",
	positionTitlePlaceholder: "Заглавие",
	responsibilitiesPlaceholder: "Отговорности",
	requirementsPlaceholder: "Изисквания",
	offerPlaceholder: "Предложение",
	educationLevelPlaceholder: "Ниво на образование",
	validFromPlaceholder: "Валидна от",
	validToPlaceholder: "Валидна до",
	yearsOfExperiencePlaceholder: "Години опит",
	salaryFromPlaceholder: "Минимално възнаграждение",
	salaryToPlaceholder: "Максимално възнаграждение",
	categoryPlaceholder: "Категория",
	remoteWorkPlaceholder: "Отдалечена работа",
	freelancePlaceholder: "Свободна практика",
	employmentPlaceholder: "Вид",
	locationsHeading: "Локации",
	candidatesHeading: "Кандидати",
	yourPositionsHeading: "Вашите позиции",
	emptyPositionSelectPlaceholder: "--Избери--",
	qualificationsPlaceholder: "Квалификации",
	currencyPlaceholder: "Валута",
	referenceUrlPlaceholder: "Референтен URL адрес за позицията (от друг сайт)",
	acceptingFreelancePlaceholder: "Приета е свободна практика",
	ableToWorkRemotelyPlaceholder: "Възможност за отдалечена работа",

	//Buttons
	searchButton: "Търси",
	saveButton: "Запази",
	profilePreviewButton: "Преглед на профила",
	backButton: "Назад",
	addEmploymentButton: "Добави опит",
	deleteButton: "Изтрий",
	deleteEducationButton: "Изтрий образование",
	downloadButton: "Изтегли",
	editButton: "Редактирай",
	cancelButton: "Отказ",
	continueButton: "Продължи",
	firstTooltipButton: "Първа страница",
	previousTooltipButton: "Предишна страница",
	nextTooltipButton: "Следваща страница",
	lastTooltipButton: "Последна страница",
	loginButton: "Влез",
	requestCorrespondenceButton: "Поискайте кореспонденция",
	requestCorrespondenceAgainButton: "Поискайте кореспонденция отново",
	sendRecoveryEmailButton: "Изпрати имейл за възстановяване",
	setPasswordButton: "Задаване на парола",
	signUpButton: "Регистрация",
	acceptButton: "Приеми",
	rejectButton: "Откажи",
	blockButton: "Блокирай",
	unblockButton: "Деблокирай",
	sendButton: "Изпрати",
	removeOnlyButton: "Само премахни",
	applyButton: "Кандидатствай",
	appliedButton: "Вече сте кандидатствали",
	companyProfileButton: "Профил на компанията",
	employeeProfileButton: "Вашият профил",
	removeLogoButton: "Промени/Премахни",
	registerButton: "Регистрация",
	buyNowButton: "Купи",
	purchaseButton: "Купи",
	addNewButton: "Добави нова",
  
	//notification Settings
	chatNotificationslabel: "Имейл нотификации за чат",
	candidateNotificationslabel: "Имейл нотификация за нов кандидат",
	candidateChatNotificationslabel: "Нотификация за нов кандидат във вътрешната система за писмена комуникация",
  
	previousPaginationButton: "Предишна",
	nextPaginationButton: "Следваща"
};
