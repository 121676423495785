export default {
	//Homepage
	premiumClients: "Premium Kunden",
	// navigationPrevious: "Previous",
	// navigationNext: "Next",
	matchCareersHeading: "Ihr nächster Job ist hier",
	matchCareersSlogan: "Hier treffen sich Spezialisten mit Arbeitgebern",
	searchingJob: "Job suchen?",
	searchingTalent: "Talente suchen?",

	//Registration & Account Recovery
	createNewAccount: "Neuen Account erstellen",
	// checkEmail: "Check your email.",
	howItWorksHeading: "Wie es funktioniert?",
	step1: "SCHRITT 1",
	step2: "SCHRITT 2",
	step3: "SCHRITT 3",
	finalStep: "Letzter Schritt",
	// accountRecovery: "Account Recovery",

	//Company Registration
	companyRegistrationHeading: "ARBEITGEBER",
	companyRegistrationHeadlight:
		"Verbringen Sie eine Minute, um sich zu registrieren, damit Sie von Fachleuten angesprochen werden können.",
	companyStep1Heading: "Lassen Sie sich registrieren",
	companyStep1Description:
		"Nur ein paar Details und Sie sind fertig - Sie erhalten eine Bestätigungs-E-Mail, sobald alles erledigt ist.",
	companyStep2Heading: "Erzählen Sie uns eine Geschichte über sich",
	companyStep2Description:
		"Nehmen Sie sich etwas Zeit, um so viele Information wie möglich über Ihr Unternehmen einzugeben - je mehr desto besser. Dies erhöht das Interesse an Kandidaten für Ihre Arbeit und Projekte.",
	companyStep3Heading: "Wählen Sie Ihren Preisplan",
	companyStep3Description:
		"Basierend auf Ihren Bedürfnissen - holen Sie sich den passenden Plan und starten Sie Ihre Reise über unsere Plattform.",
	companyFinalStepHeading: "Verbreiten Sie das Wort",
	companyFinalStepDescription:
		"Platzieren Sie Ihre Stellenanzeige auf dem Markt und beginnen Sie mit der Einstellung über Match.Careers.",

	//Jobseeker Registration
	jobseekerRegistrationHeading: "JOBSUCHENDE",
	// jobseekerRegistrationHeadlight:
	// 	"Allow a Minute to Register and Explore Careers Boost Opportunities",
	jobseekerStep1Heading: "Erstellen Sie Ihr Profil",
	jobseekerStep1Description:
		"Registrieren Sie sich und erstellen Sie Ihr Profil mit Ihren Fähigkeiten und Informationen zu Ihrer Erfahrung. Die gute Nachricht ist, dass Sie keinen Lebenslauf oder Anschreiben schreiben müssen!",
	jobseekerStep2Heading: "Bewerben Sie sich",
	jobseekerStep2Description:
		"Unser System vergleicht sofort Jobs, die Ihnen präsentiert werden. Nach der Bewerbung kann sich die zuständige Person des Unternehmens, an dem Sie interessiert sind, an Sie wenden.",
	jobseekerStep3Heading: "Eine Einladung zum Vorstellungsgespräch bekommen",
	jobseekerStep3Description:
		"Sie treffen sich persönlich oder online mit der anstellenden Person oder mit einem Manager.",
	jobseekerFinalStepHeading: "Angestellt werden",
	jobseekerFinalStepDescription:
		"Herzliche Glückwünsche! Sie sind eingestellt.",

	//Registration password tooltip
	// passwordRequirementsHeading:
	// 	"Your password must match the following complexity criterias:",
	// passwordLengthRequirement: "6-16 Characters Long",
	// passwordCapitalLetterRequirement: "1 Capital Letter",
	// passwordLowerCaseLetterRequirement: "1 Lower Case Letter",
	// passwordDigitRequirement: "1 Digit",
	// passwordSpecialSymbolRequirement: "1 Special Symbol",

	//Jobseeker Profile
	// jobseekerProfileCompletion: "Profile Completion",
	// jobseekerProfileCompletionProfileFields: "Profile Fields",
	// jobseekerLocationPlaceholder: "Location",
	// jobseekerCountry: "Country",
	// jobseekerCity: "City",
	// jobseekerProfileSkillsHeading: "Skills & Education",
	// jobseekerPublicProfileEducationHeading: "Education",
	// jobseekerProfileCompletionSkillsPlaceholder: "Skills",
	// jobseekerProfileInformationHeading: "Profile Information",
	// jobseekerProfileLocation: "--- Select a Country ---",
	// jobseekerProfileCV: "CV (pdf only)",
	// jobseekerNoCV: "No CV uploaded",
	// jobseekerCVPlaceholder: "CV",
	// helperToolbarRestrictionFull: "Full",
	// helperToolbarRestrictionFullDetails:
	// 	"Companies that you have not configured access can see all your profile info",
	// helperToolbarRestrictionLimited: "Limited",
	// helperToolbarRestrictionLimitedDetails:
	// 	"Companies that you have not configured access can see only your skills",
	// helperToolbarRestrictionNone: "None",
	// helperToolbarRestrictionNoneDetails:
	// 	"Companies that you have not configured access cannot see your profile",
	// jobseekerProfileSkillsHelper: `You can skip the autocomplete feature and <strong>Create</strong> (add) your own Skill or School / University by typing the name and selecting <strong>Create "name"</strong>. <strong>It is recommended to choose from the autocomplete names whenever possible.</strong>`,
	// jobseekerProfileNoEducation: "No Education",
	// jobseekerProfileMoreEducation: "More Education",
	footerAddressHeading: "Adresse",
	// footerAddress:
	// 	"15, Tsarigradsko Shose Blvd.<br>3rd entrance, fl.2, 1124, Sofia, Bulgaria",
	// footerFAQHeader: "Have questions?",
	// footerFAQ: `Visit our <a href="{location}">FAQ</a>`,
	footerContactsHeader: "Kontakte",
	footerContactsEmailHeader: "Email",
	footerContactsPhoneHeader: "Telefon",
	// footerCopyright: "Copyright {year} InspireSoft- All Rights Reserved",
	// employmentHistoryHeading: "Employment History",
	// jobseekerPublicProfileInformationHeading: "Personal Information",
	// jobseekerPublicProfileSkillsHeading: "Personal Skills",
	// jobseekerPublicProfileNoSkills: "No Available Skills",
	// JobseekerCompanyAccessHeading: "Company Access",
	// JobseekerCompanyAccessHelper:
	// 	"You need <strong>100%</strong> profile completion in order to have access to edit permissions.",
	// jobseekerCompanyAccessNoRecordsPlaceholder: "No records to display",
	// jobseekerCompanyAccessDeleteText: "Remove this access?",
	// jobseekerCompanyAccessPaginationLabel: "Rows",
	// jobseekerCompanyAccessPaginationCount: "{from}-{to} of {count}",
	// jobseekerComapnyAccessProfileCompletionRequirement:
	// 	"You need 100% profile completion in order to have access to edit permissions.",
	// jobseekerEmploymentHistoryHelperText:
	// 	"Add, Edit or Remove employments to your profile",
	// jobseekerCompanyAccessHelperText:
	// 	"Modify or remove specific visibility of companies for your profile",
	// changePasswordHeading: "Change your password",
	// changePasswordHelperTooltip:
	// 	"Be careful, by changing your password you won't be able to login with your previous password. The new password must match and have 1 Capital Letter, 1 Small Case Letter, 1 Special Symbol and 1 Number",
	// jobseekerSaveWarning: "Please save before continuing!",
	// jobseekerSaveWarningTitle: "You may lose your changes!",
	// jobseekerProfileCompletionModificationsNotSaved: "Modifications not saved!",

	//Company Profile
	// "free account": "Free Account",
	// "gold account": "Gold Accound",
	// "platinum account": "Platinum Account",

	//inSystemMessages
	// requestCorrespondenceMessage: "{contact} has requested correspondence.",
	// hasToAcceptCorrespondence:
	// 	"{contact} has yet to accept correspondence with you",
	// sendEmailToCompnay: "In a hurry? Send email to the company on",
	// blockUser: "You have Blocked {contact}",
	// haveBlockedYou: "{contact} has Blocked you",
	// haveRemovedYou: "{contact} has Removed you from contacts",
	// oneHourAgo: "1 hour ago",
	// hoursAgo: "{interval} hours ago",
	// oneMinuteAgo: "1 minute ago",
	// minutesAgo: "{interval} minutes ago",
	// oneSecondAgo: "a second ago",
	// secondsAgo: "{interval} seconds ago",
	// warningHeader: "Warning",
	// removeContactWarning:
	// 	"Are you sure you want to remove {contact} from contacts? This can not be easily reverted.",
	// systemUser: "System",
	// systemInitMessage: `Hello, you may add contacts by visiting their public profile page and clicking "Request correspondence", after which the contact will be visible on the left side. When and if the user accepts your request, you will be able to use the live chat system. This is a system mesage, you cannot reply!`,

	//about us
	// aboutUsHeading: "About us",
	whyUsHeading: "Warum uns wählen??",
	whyUsDescription:
		"Wir bieten die beste Übereinstimmung zwischen Unternehmen und Spezialisten",
	//about us listing
	whyUsFirstHeading: "Ehrlich und zuverlässig",
	whyUsFirstDescription:
		"Wir bemühen uns, Qualität mit Transparenz in Einklang zu bringen. Da dies unsere Grundwerte sind, stehen wir kurz davor, eine Plattform bereitzustellen, die Ihnen die besten Möglichkeiten bietet",
	whyUsSecondHeading: "Wir verbessern uns ständig",
	whyUsSecondDescription: `"Wir alle brauchen Menschen, die uns Feedback geben. So verbessern wir uns."`,
	billGatesName: "Bill Gates",
	whyUsThirdHeading: "Leidenschaft für Technologie und Menschlichkeit",
	whyUsThirdDescription:
		"Wir kümmern uns um die Bedürfnisse jedes Einzelnen von Ihnen, egal ob Sie Arbeitssuchender oder Arbeitgeber sind. Ja, Computer und die programmierten Algorithmen dahinter können uns helfen, aber ein nettes Wort oder einen Tipp von einer Person zu haben, unterscheidet die Nutzung von Online-Diensten von echten Live-Erlebnissen. Sie können jederzeit eine echte Person aus unserem Team kontaktieren."
,
	//Positions
	// allPositionsHeading: "All Positions",
	// loadingPositions: "Loading positions...",
	// noPunlishedPositions: "No published positions.",
	// positionLocationNotChoosen: "Not Choosen",
	// morePositionLocations: "More...",
	// deletePositionTitle: "Delete Position",
	// deletePositionWarning:
	// 	"This action is irreversible, are you sure you want to proceed?",
	// invalidPosition: "Invalid position",
	// hiddenCandidatesCount: "Number of hidden candidates: {count}",
	// freeStatusCandidateNotice:
	// 	"Your company status is Free. To see the candidates choice a Pricing plan from the Pricing page.",
	// noCandidates: "No Candidates",
	// candidateRevoceAccess: "Has revoked access",

	//Page Titles
	// addPositionTitle: "Add Position",
	// editPositionTitle: "Edit Position",
	// viewPositionTitle: "View Position",
	// allPositionTitle: "All Positions",
	// ownPositionTitle: "Own Positions",

	//notification Settings
	// chatNotificationSettingsHelpInformation: "We need texdt to help with chat notifications.",
	// positionNotificationSettingsHelpInformation: "We need text to help with position notifications.",
	// freeCompanyPositionNotificationSettingsHelpInformation: "You can not change this value on a free profile."
};
