import React, { Component } from "react";
import { createBrowserHistory } from "history";

import LinkButton from "../../navigation/LinkButton";
import { jobseekerProfileProps } from "../../../defaultProps";
import { frontend, accessibility } from "../../../config";
import manager from "../../../Manager";
import Edit from "./Edit";
import ProfileAccess from "./ProfileAccess";
import ProfileView from "./ProfileView";
import EmploymentHistory from "./EmploymentHistory";
import { capitalize } from "../../../utils/stringUtils";
import Translation from "../../../utils/Translation";

class JobseekerProfile extends Component {
  static contextType = manager;

  state = {
    loadedData: false,
    route: this.props.location,
    data: {
      ...jobseekerProfileProps
    }
  };

  rememberData = dataReturn => {
    const {
      jobseeker,
      cities,
      countries,
      locations,
      accessLevels,
      providedAccess,
      profileCompletion
    } = dataReturn;

    const jobseekerData = {
      jobseeker_name:
        jobseeker && jobseeker.jobseeker_name ? jobseeker.jobseeker_name : "",
      phone: jobseeker && jobseeker.phone ? jobseeker.phone : "",
      email: jobseeker && jobseeker.email ? jobseeker.email : "",
      location: {
        location_id:
          jobseeker && jobseeker.location_id ? jobseeker.location_id : "",
        ...locations.filter(loc => {
          if (loc.location_id === jobseeker.location_id) {
            return {
              country: loc.country,
              city: loc.city
            };
          }
          return false;
        })[0]
      },
      password: "",
      permissionType: jobseeker.permission_type,
      visibility: accessLevels.filter(
        level => level.permission_type_id === jobseeker.permission_type
      )[0].name,
      education: jobseeker.education.map(edu => {
        return {
          education_id: edu.jobseeker_education_id,
          summary: edu.summary ? edu.summary : "",
          speciality: edu.speciality ? edu.speciality : "",
          school: {
            label: Translation.translateValue(edu.school, edu.school),
            value: edu.school
          },
          level: {
            label: Translation.translateValue(edu.level, edu.level),
            value: edu.level
          }
        };
      })
    };

    const skills =
      jobseeker.skills instanceof Array
        ? jobseeker.skills.map(skill => {
            return { label: skill, value: skill };
          })
        : [];
    const approvedSkills =
      dataReturn.approvedSkills instanceof Array
        ? dataReturn.approvedSkills.map(skill => {
            return { label: skill, value: skill };
          })
        : [];

    const visibilityLevels = accessLevels.map(function(level) {
      return {
        text: capitalize(
          Translation.translateValue(
            accessibility[level.name],
            accessibility[level.name]
          )
        ),
        value: level.permission_type_id
      };
    });
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        cities: cities ? cities : [],
        countries: countries ? countries : [],
        locations: locations ? locations : [],
        jobseeker: jobseekerData,
        skills: skills,
        cv: jobseeker && jobseeker.cv ? jobseeker.cv : {},
        approvedSkills: approvedSkills,
        accessLevels: visibilityLevels,
        providedAccess: providedAccess,
        profileCompletion: profileCompletion
      }
    });
  };

  redirectTo = route => {
    createBrowserHistory().push(route);
    this.setState({ ...this.state, route: route });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ ...this.state, route: nextProps.location });
  }

  componentWillMount() {
    let component = this;
    this.context.getJobseekerManager().getLoggedJobseekerData(component, {
      toast: false,
      onSuccess: {
        callback: dataReturn => {
          component.rememberData(dataReturn);
          this.context.getEmployerManager().getEmployments(component, {
            onSuccess: {
              callback: employments => {
                this.context
                  .getJobseekerManager()
                  .getProfileCompletion(component, {
                    onSuccess: {
                      callback: profileCompletion => {
                        this.setState({
                          ...this.state,
                          data: {
                            ...this.state.data,
                            employments: employments.map(employment => {
                              const {
                                employment_id,
                                end_date,
                                initial_date,
                                technical_stack,
                                ...restOfEmopymentProps
                              } = employment;
                              return {
                                employmentId: employment_id,
                                endDate: end_date ? end_date : "",
                                initialDate: initial_date,
                                technicalStack: technical_stack,
                                ...restOfEmopymentProps
                              };
                            }),
                            profileCompletion: profileCompletion
                          },
                          loadedData: true
                        });
                      }
                    }
                  });
              }
            }
          });
        }
      }
    });
  }

  updateCv = cv => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        cv: cv
      }
    });
  };

  addSkill = (skillName, callback = () => {}) => {
    let component = this;
    this.context.getJobseekerManager().addSkill(component, skillName, {
      onSuccess: {
        callback: newSkill => {
          let approvedSkills = component.state.data.approvedSkills;

          approvedSkills.push({ label: newSkill, value: newSkill });
          component.setState(
            {
              ...component.state,
              data: {
                ...component.state.data,
                approvedSkills: approvedSkills
              }
            },
            callback
          );
        }
      }
    });
  };

  setEmployments = employments => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        employments: employments
      }
    });
  };

  backButtonComponent = () => {
    return (
      <div className="container align-center">
        <LinkButton
          className="btn btn-black btn-form display-4 page-menu mt-2"
          to={frontend.route.jobseeker.jobseeker_profile.edit}
        >
          {Translation.translateField("backButton", "Back")}
        </LinkButton>
      </div>
    );
  };

  renderer() {
    if (this.state.loadedData) {
      const {
        jobseeker,
        cv,
        skills,
        employments,
        profileCompletion,
        accessLevels,
        providedAccess,
        approvedSkills
      } = this.state.data;
      switch (this.state.route) {
        case frontend.route.jobseeker.jobseeker_profile.edit:
          return (
            <Edit
              data={this.state.data}
              rememberData={this.rememberData}
              redirect={this.redirectTo}
              updateCv={this.updateCv}
            />
          );
        case frontend.route.jobseeker.jobseeker_profile.company_access:
          return (
            <ProfileAccess
              accessLevels={accessLevels}
              data={providedAccess}
              profileCompletion={profileCompletion}
            >
              {this.backButtonComponent()}
            </ProfileAccess>
          );
        case frontend.route.jobseeker.jobseeker_profile.employment:
          return (
            <EmploymentHistory
              employments={employments}
              approvedSkills={approvedSkills}
              addSkill={this.addSkill}
              setEmployments={this.setEmployments}
            >
              {this.backButtonComponent()}
            </EmploymentHistory>
          );
        case frontend.route.jobseeker.jobseeker_profile.view:
        default:
          const education = jobseeker.education.map(education => {
            return {
              ...education,
              level: education.level.value,
              school: education.school.value
            };
          });
          let formattedEmployments = employments.map(employment => {
            return {
              ...employment,
              employer: employment.employer.label,
              technicalStack: employment.technicalStack.map(skill => {
                return {
                  ...skill,
                  value: skill.label
                };
              })
            };
          });
          return (
            <ProfileView
              jobseeker={jobseeker}
              cv={cv}
              skills={skills}
              visibility={jobseeker.visibility}
              education={education}
              employments={formattedEmployments}
            >
              {this.backButtonComponent()}
            </ProfileView>
          );
      }
    }
    return <p />;
  }

  render() {
    return this.renderer();
  }
}

export default JobseekerProfile;
