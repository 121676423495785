import React from "react";

import { frontend } from "../../config";
import Translation from "../../utils/Translation";

export default function NextJobIsHere() {
    return (
        <section
            className="cid-qTkA127IK8 mbr-fullscreen mbr-parallax-background"
            id="header2-1"
        >
            <div className="container align-center">
                <div className="row justify-content-md-center">
                    <div className="mbr-white col-md-10">
                        <h1 className="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1">
                            {Translation.translateStaticText(
                                "matchCareersHeading",
                                "Your Next Job is Here"
                            )}
                        </h1>
                        <p className="mbr-text pb-3 mbr-fonts-style display-5">
                            {Translation.translateStaticText(
                                "matchCareersSlogan",
                                "Where Specialists Meet Employers",
                                false
                            )}
                        </p>
                        <div className="mbr-section-btn">
                            <a
                                className="btn btn-md btn-secondary display-5"
                                href={frontend.route.jobseeker.register}
                            >
                                {Translation.translateStaticText(
                                    "searchingJob",
                                    "Searching For a Job?"
                                )}
                            </a>
                            <a
                                className="btn btn-md btn-primary display-5"
                                href={frontend.route.company.register}
                            >
                                {Translation.translateStaticText(
                                    "searchingTalent",
                                    "Searching For a Talent?"
                                )}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
