import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { Checkbox } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import FloatingLabelInput from "../../form_helpers/FloatingLabelInput";
import FloatingLabelTextArea from "../../form_helpers/FloatingLabelTextArea";
import manager from "../../../Manager";
import Select from "../../form_helpers/Select";
import { frontend, usertype } from "../../../config";
import Locations from "../../form_helpers/Locations";
import Translation from "../../../utils/Translation";

class Save extends Component {
    static contextType = manager;

    static modes = {
        add: "add",
        edit: "edit"
    };

    static defaultProps = {
        back: () => {},
        position_id: undefined,
        mode: Save.modes.add
    };
    companyLoaded = false;
    companyInfo = {};
    userLoaded = false;
    userInfo = {};
    positionLoaded = false;
    position = {};
    dependenciesLoaded = false;
    categories_options = [];
    educations_options = [];
    educationLevels_options = [];
    qualifications_options = [];
    currencies_options = [];
    defaultsSet = false;
    locations = [];
    countries = [];
    cities = [];
    positionLocations = [];

    state = {
        form: {
            title: "",
            responsabilities: "",
            requirements: "",
            offer: "",
            position_category_id: "",
            education_level_id: "",
            speciality: "",
            years_of_experience: "",
            qualification_id: "",
            salary_from: "",
            salary_to: "",
            currency_id: "",
            valid_from: "",
            valid_to: "",
            reference_url: "",
            freelance: "no",
            remote_work: "no",
            locations: [],
            employment_time: "full_time",
            submited: false
        }
    };

    parseDateToString(date) {
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        return (
            year +
            "-" +
            (month < 10 ? "0" + month : month) +
            "-" +
            (day < 10 ? "0" + day : day)
        );
    }

    componentDidMount() {
        let component = this;
        if (
            Save.modes.edit === component.props.mode &&
            !component.props.position_id
        ) {
            if (component.props.back) {
                component.props.back();
            } else {
                window.location.pathname = frontend.route.error;
                return;
            }
        } else if (Save.modes.add === component.props.mode) {
            component.context
                .getPositionManager()
                .getOwnPositionsCount(component, {
                    onSuccess: {
                        toast: false,
                        callback: data => {
                            if (
                                data &&
                                typeof data === "number" &&
                                data > 0 &&
                                this.userInfo.company_type === "free"
                            ) {
                                if (component.props.back) {
                                    component.props.back();
                                } else {
                                    window.location.pathname =
                                        frontend.route.error;
                                }
                            }
                        }
                    }
                });
        }
    }

    years_of_experience_options = [];
    componentWillMount() {
        this.context.getAuthManager().pageRestriction({
            logged: true,
            forLoggedUsertype: [usertype.company]
        });

        this.years_of_experience_options = [
            {
                value: "",
                text: Translation.translateField(
                    "emptyPositionSelectPlaceholder",
                    "--Select--"
                )
            }
        ];
        for (let i = 1; i <= 39; i++) {
            this.years_of_experience_options.push({ value: i, text: i });
        }
        this.years_of_experience_options.push({ value: "40+", text: "40+" });
        let that = this;

        if (
            !this.companyLoaded ||
            !this.userLoaded ||
            !this.dependenciesLoaded
        ) {
            this.context.getCompanyManager().getLoggedCompany(this, {
                onSuccess: {
                    callback: companyInfo => {
                        // flags, not in state due to too many state updates with changes that overwrite eachother
                        this.companyLoaded = true;
                        this.companyInfo = companyInfo;
                        this.setState({ ...this.state }); //update render purpose
                    }
                }
            });
            this.context.getAuthManager().getLoggedUser(this, {
                onSuccess: {
                    callback: data => {
                        this.userLoaded = true;
                        this.userInfo = data;
                        this.setState({ ...this.state }); //update render purpose
                    }
                }
            });
            this.context.getPositionManager().getDependencies(this, {
                onSuccess: {
                    callback: data => {
                        let categories_options = [
                            {
                                value: "",
                                text: Translation.translateField(
                                    "emptyPositionSelectPlaceholder",
                                    "--Select--"
                                ),
                                number: "0"
                            }
                        ];
                        if (
                            data.position_category !== undefined &&
                            data.position_category.constructor === Array
                        ) {
                            data.position_category.forEach(function(category) {
                                categories_options.push({
                                    value: category.position_category_id,
                                    text: Translation.translateValue(
                                        category.name,
                                        category.name
                                    ),
                                    number: category.number
                                });
                            });
                        }
                        this.categories_options = categories_options;

                        let educationLevels_options = [
                            {
                                value: "",
                                text: Translation.translateField(
                                    "emptyPositionSelectPlaceholder",
                                    "--Select--"
                                ),
                                number: "0"
                            }
                        ];
                        if (
                            data.education_level &&
                            data.education_level.constructor === Array
                        ) {
                            data.education_level.forEach(function(
                                educationLevel
                            ) {
                                if (
                                    Translation.valueHasTranslation(
                                        educationLevel.level
                                    )
                                ) {
                                    educationLevels_options.push({
                                        value:
                                            educationLevel.education_level_id,
                                        text: Translation.translateValue(
                                            educationLevel.level,
                                            educationLevel.level
                                        ),
                                        number: educationLevel.number
                                    });
                                }
                            });
                        }
                        this.educationLevels_options = educationLevels_options;

                        let qualifications_options = [
                            {
                                value: "",
                                text: Translation.translateField(
                                    "emptyPositionSelectPlaceholder",
                                    "--Select--"
                                ),
                                number: "0"
                            }
                        ];
                        if (
                            data.qualification &&
                            data.qualification.constructor === Array
                        ) {
                            data.qualification.forEach(function(qualification) {
                                qualifications_options.push({
                                    value: qualification.qualification_id,
                                    text: Translation.translateValue(
                                        qualification.name,
                                        qualification.name
                                    ),
                                    number: qualification.number
                                });
                            });
                        }
                        this.qualifications_options = qualifications_options;

                        let currencies_options = [
                            {
                                value: "",
                                text: Translation.translateField(
                                    "emptyPositionSelectPlaceholder",
                                    "--Select--"
                                )
                            }
                        ];
                        if (
                            data.currency &&
                            data.currency.constructor === Array
                        ) {
                            data.currency.forEach(function(currency) {
                                currencies_options.push({
                                    value: currency.currency_id,
                                    text: currency.name
                                });
                            });
                        }
                        this.currencies_options = currencies_options.map(
                            opt => {
                                return {
                                    text: Translation.translateValue(
                                        opt.text,
                                        opt.text
                                    ),
                                    value: opt.value
                                };
                            }
                        );

                        if (
                            data.locations &&
                            data.locations.constructor === Array
                        ) {
                            this.locations = data.locations;
                        }
                        if (
                            data.countries &&
                            data.countries.constructor === Array
                        ) {
                            this.countries = data.countries;
                        }
                        if (data.cities && data.cities.constructor === Array) {
                            this.cities = data.cities;
                        }

                        this.dependenciesLoaded = true;
                        this.setState({ ...this.state }); //update render purpose

                        if (
                            this.props.mode === Save.modes.edit &&
                            this.props.position_id
                        ) {
                            this.context
                                .getPositionManager()
                                .getOwnPosition(this, this.props.position_id, {
                                    onSuccess: {
                                        callback: positionData => {
                                            const educationLevelId = this.getEducationLevelForLanguage(
                                                positionData.position
                                            );
                                            this.position = {
                                                ...positionData.position,
                                                education_level_id: educationLevelId
                                            };
                                            if (
                                                positionData.positionLocations &&
                                                positionData.positionLocations
                                                    .constructor === Array
                                            ) {
                                                this.positionLocations =
                                                    positionData.positionLocations;
                                            }
                                            let defaultLocationsValues = this.positionLocations.map(
                                                function(pl) {
                                                    let ret = {};
                                                    that.locations.forEach(
                                                        function(l) {
                                                            if (
                                                                l.location_id ===
                                                                pl.location_id
                                                            ) {
                                                                ret = l;
                                                            }
                                                        }
                                                    );
                                                    return ret;
                                                }
                                            );
                                            this.positionLoaded = true;
                                            if (!this.defaultsSet) {
                                                this.defaultPositionValues(
                                                    this.position,
                                                    defaultLocationsValues
                                                );
                                            }
                                        }
                                    }
                                });
                        } else {
                            var d = new Date();
                            var year = d.getFullYear();
                            var month = d.getMonth();
                            var day = d.getDate();
                            var c = new Date(year + 1, month, day);
                            this.setState({
                                ...this.state,
                                form: {
                                    ...this.state.form,
                                    valid_from: this.parseDateToString(
                                        new Date()
                                    ),
                                    valid_to: this.parseDateToString(c)
                                }
                            });
                        }
                    }
                }
            });
        }
    }

    getEducationLevelForLanguage(position) {
        return this.educationLevels_options.filter(
            level => level.value === position.education_level_id
        ).length > 0
            ? position.education_level_id
            : this.educationLevels_options.filter(
                  level =>
                      level.text ===
                      Translation.getEducationLevelLabelBasedOnLanguage({
                          label: Translation.translateField(
                              "emptyPositionSelectPlaceholder",
                              "--Select--"
                          ),
                          value: Translation.translateField(
                              "emptyPositionSelectPlaceholder",
                              "--Select--"
                          ),
                      }).label
              )[0].value;
    }

    defaultPositionValues(positionData, defaultLocationsValues) {
        let form = this.state.form;
        Object.keys(positionData).forEach(function(field) {
            if (positionData[field] !== "0000-00-00 00:00:00") {
                if (positionData[field]) {
                    if (
                        (field === "valid_from" || field === "valid_to") &&
                        positionData[field].indexOf(" ") > -1
                    ) {
                        positionData[field] = positionData[field].split(" ")[0];
                    }
                    form[field] = positionData[field];
                }
            }
        });
        form.locations = defaultLocationsValues;
        this.defaultsSet = true;
        this.setState({ ...this.state, form: form });
    }

    handeChange = name => ({ target: { value } }) => {
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                [name]: value,
                submited: false
            }
        });
    };

    onChangeLocations(locations) {
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                locations: locations,
                submited: false
            }
        });
    }

    getOnSelectChangeEmploymentTime(value) {
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                employment_time: value,
                submited: false
            }
        });
    }

    getToggleCheckBox() {
        let that = this;
        return name => {
            if (name === "remote_work") {
                let value = that.state.form.remote_work;
                if (value === "yes") {
                    value = "no";
                } else {
                    value = "yes";
                }
                that.setState({
                    ...that.state,
                    form: {
                        ...that.state.form,
                        remote_work: value,
                        submited: false
                    }
                });
            } else if (name === "freelance") {
                let value = that.state.form.freelance;
                if (value === "yes") {
                    value = "no";
                } else {
                    value = "yes";
                }
                that.setState({
                    ...that.state,
                    form: {
                        ...that.state.form,
                        freelance: value,
                        submited: false
                    }
                });
            }
        };
    }

    handeCategoriesSelectChange = value => {
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                position_category_id: value,
                submited: false
            }
        });
    };

    handeEducationLevelsSelectChange = value => {
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                education_level_id: value,
                submited: false
            }
        });
    };

    handeYearsOfExperienceSelectChange = value => {
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                years_of_experience: value,
                submited: false
            }
        });
    };

    handeQualificationsSelectChange = value => {
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                qualification_id: value,
                submited: false
            }
        });
    };

    handeCurrencySelectChange = value => {
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                currency_id: value,
                submited: false
            }
        });
    };

    getRequestForm() {
        let form = {
            company_id: this.companyInfo.company_id,
            title: this.state.form.title,
            responsabilities: this.state.form.responsabilities,
            requirements: this.state.form.requirements,
            offer: this.state.form.offer,
            position_category_id: this.state.form.position_category_id,
            education_level_id: this.state.form.education_level_id,
            speciality: this.state.form.speciality,
            years_of_experience: this.state.form.years_of_experience,
            qualification_id: this.state.form.qualification_id,
            salary_from: this.state.form.salary_from,
            salary_to: this.state.form.salary_to,
            currency_id: this.state.form.currency_id,
            valid_from: this.state.form.valid_from,
            valid_to: this.state.form.valid_to,
            reference_url: this.state.form.reference_url,
            freelance: this.state.form.freelance,
            remote_work: this.state.form.remote_work,
            employment_time: this.state.form.employment_time,
            locations: JSON.stringify(this.state.form.locations)
        };
        if (this.props.mode !== Save.modes.add) {
            form["position_id"] = this.position.position_id;
        }
        return form;
    }

    sortbyNumber(a, b) {
        if (a.number < b.number) {
            return -1;
        }
        if (a.number > b.number) {
            return 1;
        }
        return 0;
    }

    handleKeyPress = event => {
        if (event.key === "Enter") {
            this.onSubmit();
        }
    };

    onSubmit() {
        let component = this;
        return () => {
            component.setState({
                ...component.state,
                form: { ...component.state.form, submited: true }
            });
            component.context
                .getPositionManager()
                .setPosition(component, component.getRequestForm(), {
                    onSuccess: {
                        callback: () => {
                            component.props.back({ addedNew: true });
                        }
                    },
                    onError: {
                        state: {
                            ...component.state,
                            form: { ...component.state.form, submited: false }
                        }
                    }
                });
        };
    }

    render() {
        if (
            !this.companyLoaded ||
            !this.userLoaded ||
            !this.dependenciesLoaded ||
            (this.props.mode !== Save.modes.add && !this.positionLoaded)
        ) {
            return <br />;
        }
        let saveButtonText = Translation.translateField("addButton", "Add");
        if (this.props.mode !== Save.modes.add) {
            saveButtonText = Translation.translateField("saveButton", "Save");
        }
        return (
            <section
                className="counters6 counters cid-rg5irxrHy2 visible full-visible"
                id="counters6-6"
            >
                <div className="container pt-4 mt-2">
                    <div className="row">
                        <div className="container align-center">
                            <span className="input-group-btn">
                                <Button
                                    onClick={() => {
                                        this.props.back();
                                    }}
                                    className="btn btn-secondary btn-form display-4 page-menu"
                                >
                                    {Translation.translateField(
                                        "backButton",
                                        "Back"
                                    )}
                                </Button>
                            </span>
                            <br />
                            <br />
                            <div className="form-container">
                                <div
                                    className="media-container-column"
                                    data-form-type="formoid"
                                >
                                    <form className="mbr-form">
                                        <input
                                            type="hidden"
                                            name="email"
                                            data-form-email="true"
                                            value="F69B3NcZe0l3wRLV5UF5Og6P1j1i3UcH9UDLW3cDpMcZoe8HbiWdkLSGqC6PhwHMrVoN5MwD7MMLXUO8aYnCwxS96h63iWLWDbrb3wllJpcPzN87pJFAVT6lEwlTnpqt"
                                        />

                                        <div data-for="title">
                                            <div className="form-group">
                                                <FloatingLabelInput
                                                    style={{
                                                        minHeight: "59px"
                                                    }}
                                                    onKeyPress={
                                                        this.handleKeyPress
                                                    }
                                                    onChange={this.handeChange(
                                                        "title"
                                                    )}
                                                    type="text"
                                                    className="form-control px-3"
                                                    name="title"
                                                    data-form-field="Title"
                                                    placeholder={Translation.translateField(
                                                        "positionTitlePlaceholder",
                                                        "Title"
                                                    )}
                                                    required=""
                                                    id="title-header15-b"
                                                    value={
                                                        this.state.form.title
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div data-for="responsabilities">
                                            <div className="form-group">
                                                <FloatingLabelTextArea
                                                    onChange={this.handeChange(
                                                        "responsabilities"
                                                    )}
                                                    type="text"
                                                    className="form-control px-3"
                                                    name="responsabilities"
                                                    data-form-field="responsabilities"
                                                    placeholder={Translation.translateField(
                                                        "responsibilitiesPlaceholder",
                                                        "Responsibilities"
                                                    )}
                                                    required=""
                                                    id="responsabilities-header15-b"
                                                    value={
                                                        this.state.form
                                                            .responsabilities
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div data-for="requirements">
                                            <div className="form-group">
                                                <FloatingLabelTextArea
                                                    onChange={this.handeChange(
                                                        "requirements"
                                                    )}
                                                    type="text"
                                                    className="form-control px-3"
                                                    name="requirements"
                                                    data-form-field="requirements"
                                                    placeholder={Translation.translateField(
                                                        "requirementsPlaceholder",
                                                        "Requirements"
                                                    )}
                                                    required=""
                                                    id="requirements-header15-b"
                                                    value={
                                                        this.state.form
                                                            .requirements
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div data-for="offer">
                                            <div className="form-group">
                                                <FloatingLabelTextArea
                                                    onChange={this.handeChange(
                                                        "offer"
                                                    )}
                                                    type="text"
                                                    className="form-control px-3"
                                                    name="offer"
                                                    data-form-field="offer"
                                                    placeholder={Translation.translateField(
                                                        "offerPlaceholder",
                                                        "Offer"
                                                    )}
                                                    required=""
                                                    id="offer-header15-b"
                                                    value={
                                                        this.state.form.offer
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div data-for="position_category_id">
                                            <div className="form-group">
                                                <Select
                                                    style={{
                                                        minHeight: "59px"
                                                    }}
                                                    onSelectChange={
                                                        this
                                                            .handeCategoriesSelectChange
                                                    }
                                                    className="form-control px-3"
                                                    placeholder={Translation.translateField(
                                                        "categoryPlaceholder",
                                                        "Category"
                                                    )}
                                                    name="position_category_id"
                                                    data-form-field="position_category_id"
                                                    id="position_category_id-header15-b"
                                                    value={
                                                        this.state.form
                                                            .position_category_id
                                                    }
                                                    options={this.categories_options.sort(
                                                        this.sortbyNumber
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div data-for="education_level_id">
                                            <div className="form-group">
                                                <Select
                                                    style={{
                                                        minHeight: "59px"
                                                    }}
                                                    onSelectChange={
                                                        this
                                                            .handeEducationLevelsSelectChange
                                                    }
                                                    className="form-control px-3"
                                                    placeholder={Translation.translateField(
                                                        "educationLevelPlaceholder",
                                                        "Education level"
                                                    )}
                                                    name="education_level_id"
                                                    data-form-field="education_level_id"
                                                    id="education_level_id-header15-b"
                                                    value={
                                                        this.state.form
                                                            .education_level_id
                                                    }
                                                    options={this.educationLevels_options.sort(
                                                        this.sortbyNumber
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div data-for="speciality">
                                            <div className="form-group">
                                                <FloatingLabelTextArea
                                                    onChange={this.handeChange(
                                                        "speciality"
                                                    )}
                                                    type="text"
                                                    className="form-control px-3"
                                                    name="speciality"
                                                    data-form-field="speciality"
                                                    placeholder={Translation.translateField(
                                                        "jobseekerEducationSpeciality",
                                                        "Speciality"
                                                    )}
                                                    required=""
                                                    id="speciality-header15-b"
                                                    value={
                                                        this.state.form
                                                            .speciality
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div data-for="years_of_experience">
                                            <div className="form-group">
                                                <Select
                                                    style={{
                                                        minHeight: "59px"
                                                    }}
                                                    onSelectChange={
                                                        this
                                                            .handeYearsOfExperienceSelectChange
                                                    }
                                                    className="form-control px-3"
                                                    placeholder={Translation.translateField(
                                                        "yearsOfExperiencePlaceholder",
                                                        "Years of experience"
                                                    )}
                                                    name="years_of_experience"
                                                    data-form-field="years_of_experience"
                                                    id="years_of_experience-header15-b"
                                                    value={
                                                        this.state.form
                                                            .years_of_experience
                                                    }
                                                    options={
                                                        this
                                                            .years_of_experience_options
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div data-for="qualifications">
                                            <div className="form-group">
                                                <Select
                                                    style={{
                                                        minHeight: "59px"
                                                    }}
                                                    onSelectChange={
                                                        this
                                                            .handeQualificationsSelectChange
                                                    }
                                                    className="form-control px-3"
                                                    placeholder={Translation.translateField(
                                                        "qualificationsPlaceholder",
                                                        "Qualifications"
                                                    )}
                                                    name="qualifications"
                                                    data-form-field="qualifications"
                                                    id="qualifications-header15-b"
                                                    value={
                                                        this.state.form
                                                            .qualification_id
                                                    }
                                                    options={this.qualifications_options.sort(
                                                        this.sortbyNumber
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div data-for="salary">
                                            <div className="form-group">
                                                <div
                                                    style={{
                                                        width: "33%",
                                                        float: "left"
                                                    }}
                                                >
                                                    <FloatingLabelInput
                                                        style={{
                                                            minHeight: "59px"
                                                        }}
                                                        onKeyPress={
                                                            this.handleKeyPress
                                                        }
                                                        onChange={this.handeChange(
                                                            "salary_from"
                                                        )}
                                                        type="text"
                                                        pattern="[0-9]*"
                                                        className="form-control px-3"
                                                        name="salary_from"
                                                        data-form-field="salary_from"
                                                        placeholder={Translation.translateField(
                                                            "salaryFromPlaceholder",
                                                            "Salary From"
                                                        )}
                                                        required=""
                                                        id="salary_from-header15-b"
                                                        value={
                                                            this.state.form
                                                                .salary_from
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        width: "33%",
                                                        float: "left"
                                                    }}
                                                >
                                                    <FloatingLabelInput
                                                        style={{
                                                            minHeight: "59px"
                                                        }}
                                                        onKeyPress={
                                                            this.handleKeyPress
                                                        }
                                                        onChange={this.handeChange(
                                                            "salary_to"
                                                        )}
                                                        type="text"
                                                        pattern="[0-9]*"
                                                        className="form-control px-3"
                                                        name="salary_to"
                                                        data-form-field="salary_to"
                                                        placeholder={Translation.translateField(
                                                            "salaryToPlaceholder",
                                                            "Salary To"
                                                        )}
                                                        required=""
                                                        id="salary_to-header15-b"
                                                        value={
                                                            this.state.form
                                                                .salary_to
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        width: "33%",
                                                        float: "right"
                                                    }}
                                                >
                                                    <Select
                                                        style={{
                                                            minHeight: "59px"
                                                        }}
                                                        onSelectChange={
                                                            this
                                                                .handeCurrencySelectChange
                                                        }
                                                        className="form-control px-3"
                                                        placeholder={Translation.translateField(
                                                            "currencyPlaceholder",
                                                            "Currency"
                                                        )}
                                                        name="currency_id"
                                                        data-form-field="currency_id"
                                                        id="currency_id-header15-b"
                                                        value={
                                                            this.state.form
                                                                .currency_id
                                                        }
                                                        options={
                                                            this
                                                                .currencies_options
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    style={{ clear: "both" }}
                                                />
                                            </div>
                                        </div>

                                        <div data-for="locations">
                                            <div className="form-group">
                                                <Locations
                                                    selectLocations={{
                                                        countries: this
                                                            .countries,
                                                        cities: this.cities,
                                                        locations: this
                                                            .locations
                                                    }}
                                                    values={
                                                        this.state.form
                                                            .locations
                                                    }
                                                    max={5}
                                                    onChange={locations => {
                                                        this.onChangeLocations(
                                                            locations
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div data-for="valid">
                                            <div className="form-group">
                                                <div style={{ width: "100%" }}>
                                                    <div
                                                        className="form-control"
                                                        style={{
                                                            width: "49%",
                                                            float: "left",
                                                            padding: "3px"
                                                        }}
                                                    >
                                                        <TextField
                                                            id="valid_from"
                                                            onChange={this.handeChange(
                                                                "valid_from"
                                                            )}
                                                            label={Translation.translateField(
                                                                "validFromPlaceholder",
                                                                "Valid From"
                                                            )}
                                                            type="date"
                                                            style={{
                                                                width: "98%"
                                                            }}
                                                            defaultValue={
                                                                this.state.form
                                                                    .valid_from
                                                            }
                                                            value={
                                                                this.state.form
                                                                    .valid_from
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className="form-control"
                                                        style={{
                                                            width: "49%",
                                                            float: "right",
                                                            padding: "3px"
                                                        }}
                                                    >
                                                        <TextField
                                                            id="valid_to"
                                                            onChange={this.handeChange(
                                                                "valid_to"
                                                            )}
                                                            label={Translation.translateField(
                                                                "validToPlaceholder",
                                                                "Valid To"
                                                            )}
                                                            type="date"
                                                            defaultValue={
                                                                this.state.form
                                                                    .valid_to
                                                            }
                                                            value={
                                                                this.state.form
                                                                    .valid_to
                                                            }
                                                            style={{
                                                                width: "98%"
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    style={{ clear: "both" }}
                                                />
                                            </div>
                                        </div>

                                        <div data-for="employment_time">
                                            <div className="form-group">
                                                <Select
                                                    id="employment_time"
                                                    name="employment_time"
                                                    onSelectChange={value => {
                                                        this.getOnSelectChangeEmploymentTime(
                                                            value
                                                        );
                                                    }}
                                                    value={
                                                        this.state.form
                                                            .employment_time
                                                    }
                                                    options={[
                                                        {
                                                            text: Translation.translateValue(
                                                                "full_time"
                                                            ),
                                                            value: "full_time"
                                                        },
                                                        {
                                                            text: Translation.translateValue(
                                                                "part_time"
                                                            ),
                                                            value: "part_time"
                                                        }
                                                    ]}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>

                                        <div data-for="reference_url">
                                            <div className="form-group">
                                                <FloatingLabelInput
                                                    style={{
                                                        minHeight: "59px"
                                                    }}
                                                    onKeyPress={
                                                        this.handleKeyPress
                                                    }
                                                    onChange={this.handeChange(
                                                        "reference_url"
                                                    )}
                                                    type="text"
                                                    className="form-control px-3"
                                                    name="reference_url"
                                                    data-form-field="reference_url"
                                                    placeholder={Translation.translateField(
                                                        "referenceUrlPlaceholder",
                                                        "Reference URL for the position (from another site)"
                                                    )}
                                                    required=""
                                                    id="reference_url-header15-b"
                                                    value={
                                                        this.state.form
                                                            .reference_url
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div data-for="freelance-and-remote-work">
                                            <div className="form-group">
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        textAlign: "left",
                                                        paddingLeft: "30px"
                                                    }}
                                                >
                                                    <Checkbox
                                                        onChange={() => {
                                                            this.getToggleCheckBox()(
                                                                "freelance"
                                                            );
                                                        }}
                                                        type="checkbox"
                                                        name="freelance"
                                                        data-form-field="freelance"
                                                        placeholder={Translation.translateField(
                                                            "acceptingFreelancePlaceholder",
                                                            "Accepting Freelance"
                                                        )}
                                                        id="freelance-header15-b"
                                                        checked={
                                                            this.state.form
                                                                .freelance ===
                                                            "yes"
                                                        }
                                                    />
                                                    {Translation.translateField(
                                                        "acceptingFreelancePlaceholder",
                                                        "Accepting Freelance"
                                                    )}
                                                    <br />
                                                    <Checkbox
                                                        onChange={() => {
                                                            this.getToggleCheckBox()(
                                                                "remote_work"
                                                            );
                                                        }}
                                                        type="checkbox"
                                                        name="remote_work"
                                                        data-form-field="remote_work"
                                                        placeholder={Translation.translateField(
                                                            "ableToWorkRemotelyPlaceholder",
                                                            "Able to work remotely"
                                                        )}
                                                        id="remote_work-header15-b"
                                                        checked={
                                                            this.state.form
                                                                .remote_work ===
                                                            "yes"
                                                        }
                                                    />
                                                    {Translation.translateField(
                                                        "ableToWorkRemotelyPlaceholder",
                                                        "Able to work remotely"
                                                    )}
                                                </div>
                                                <div
                                                    style={{ clear: "both" }}
                                                />
                                            </div>
                                        </div>

                                        <span className="input-group-btn">
                                            <Button
                                                onClick={() => {
                                                    this.onSubmit()();
                                                }}
                                                className={
                                                    "btn btn-" +
                                                    (this.state.form.submited
                                                        ? "primary"
                                                        : "secondary") +
                                                    " btn-form display-4"
                                                }
                                                disabled={
                                                    this.state.form.submited
                                                }
                                            >
                                                {saveButtonText}
                                            </Button>
                                        </span>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Save;
