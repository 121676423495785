import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import HelpOutlinedIcon from "@material-ui/icons/HelpOutlined";
import FloatingLabelInput from "../form_helpers/FloatingLabelInput";
import ToastHandler from "../../utils/ToastHandler";
import manager from "../../Manager";
import Translation from "../../utils/Translation";
import HelperTooltip from "../form_helpers/HelperTooltip";

class EmployeeProfileData extends Component {
  static contextType = manager;

  state = {
    loadedData: false,
    form: {
      employee_name: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
      currentPassword: "",
      submited: false
    }
  };

  rememberData(dataReturn) {
    let form = {
      employee_name: "",
      phone: "",
      email: "",
      password: ""
    };
    if (dataReturn.employee_name === null) {
      form.employee_name = "";
    } else {
      form.employee_name = dataReturn.employee_name;
    }
    if (dataReturn.phone === null) {
      form.phone = "";
    } else {
      form.phone = dataReturn.phone;
    }
    if (dataReturn.email === null) {
      form.email = "";
    } else {
      form.email = dataReturn.email;
    }
    this.setState({
      ...this.state,
      form: { ...this.state.form, ...form },
      loadedData: true
    });
  }

  componentWillMount() {
    this.context.getAuthManager().pageRestriction({ logged: true });
    let that = this;
    this.context.getCompanyManager().getLoggedEmployeeData(this, {
      onSuccess: {
        toast: false,
        callback: dataReturn => {
          that.rememberData(dataReturn);
        }
      }
    });
  }

  handeChange = name => ({ target: { value } }) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [name]: value,
        submited: false
      }
    });
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.onSubmit();
    }
  };

  getRequestForm() {
    return {
      employee_name: this.state.form.employee_name,
      phone: this.state.form.phone,
      email: this.state.form.email,
      password: this.state.form.password,
      password_confirmation: this.state.form.confirmPassword,
      current_password: this.state.form.currentPassword
    };
  }

  onSubmit() {
    const { password, confirmPassword } = this.state.form;
    if (password !== confirmPassword) {
      ToastHandler.showError(
        Translation.translateMessage(
          "FailedPasswordConfirmation",
          "Password does not match"
        )
      );
      return;
    }
    const component = this;
    this.setState({
      ...this.state,
      form: { ...this.state.form, submited: true }
    });
    this.context
      .getCompanyManager()
      .setLoggedEmployeeData(component, component.getRequestForm(), {
        onSuccess: {
          message: Translation.translateMessage("Saved", "Saved")
        },
        onFinish: {
          callback: () => {
            component.setState({
              ...component.state,
              form: { ...component.state.form, submited: false }
            });
          }
        }
      });
  }

  render() {
    if (!this.state.loadedData) return <br />;
    const {
      form: {
        employee_name,
        phone,
        email,
        password,
        confirmPassword,
        currentPassword
      }
    } = this.state;
    return (
      <div className="row">
        <div className="container align-center">
          <p className="mbr-text pb-3 mbr-fonts-style display-5" />
          <form className="mbr-form">
            {/*Disables autocomplete*/}
            <input type="text" style={{display: "none"}}/>
            <input
              style={{ display: "none" }}
              type="password"
              name="foilautofill"
            />
            <div data-for="employee_name">
              <div className="form-group">
                <FloatingLabelInput
                  onKeyPress={this.handleKeyPress}
                  onChange={this.handeChange("employee_name")}
                  type="text"
                  className="form-control px-3"
                  name="employee_name"
                  data-form-field="employee_name"
                  placeholder={Translation.translateField(
                    "employeeNamePlaceholder",
                    "Name"
                  )}
                  id="employee_name-header15-3"
                  value={employee_name}
                />
              </div>
            </div>
            <div data-for="phone">
              <div className="form-group">
                <FloatingLabelInput
                  onKeyPress={this.handleKeyPress}
                  onChange={this.handeChange("phone")}
                  type="text"
                  className="form-control px-3"
                  name="phone"
                  data-form-field="phone"
                  placeholder={Translation.translateField("phone", "Phone")}
                  id="phone-header15-3"
                  value={phone}
                />
              </div>
            </div>
            <div data-for="email">
              <div className="form-group">
                <FloatingLabelInput
                  onKeyPress={this.handleKeyPress}
                  onChange={this.handeChange("email")}
                  type="text"
                  className="form-control px-3"
                  name="email"
                  data-form-field="email"
                  placeholder={Translation.translateField("email", "Email")}
                  id="email-header15-3"
                  value={email}
                />
              </div>
            </div>
            <div className="align-left mbr-white">
              <h3 className="d-block">
                {Translation.translateStaticText(
                  "changePasswordHeading",
                  "Change your password"
                )}{" "}
                <HelperTooltip
                  title={Translation.translateStaticText(
                    "changePasswordHelperTooltip",
                    "Be careful, by changing your password you won't be able to login with your previous password. The new password must match and have 1 Capital Letter, 1 Small Case Letter, 1 Special Symbol and 1 Number"
                  )}
                  mobileClassName="display-7"
                >
                  <HelpOutlinedIcon
                    classes={{
                      root: "header-tooltip-icon"
                    }}
                  />
                </HelperTooltip>
              </h3>
              <hr className="mbr-bg-white" />
            </div>
            <Box
              border={1}
              borderColor="red"
              classes={{ root: "border-box dashed" }}
            >
              <div data-for="current_password">
                <div className="form-group">
                  <FloatingLabelInput
                    onKeyPress={this.handleKeyPress}
                    onChange={this.handeChange("currentPassword")}
                    type="password"
                    className="form-control px-3"
                    name="abc_current_password"
                    data-form-field="current_password"
                    placeholder={Translation.translateField(
                      "currentPassword",
                      "Current Password"
                    )}
                    id="password-header15-3"
                    value={currentPassword}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div data-for="password">
                <div className="form-group">
                  <FloatingLabelInput
                    onKeyPress={this.handleKeyPress}
                    onChange={this.handeChange("password")}
                    type="password"
                    className="form-control px-3"
                    name="abc_password"
                    data-form-field="password"
                    placeholder={Translation.translateField(
                      "newPassword",
                      "New password"
                    )}
                    id="password-header15-3"
                    value={password}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div data-for="confirm_password">
                <div className="form-group">
                  <FloatingLabelInput
                    onKeyPress={this.handleKeyPress}
                    onChange={this.handeChange("confirmPassword")}
                    type="password"
                    className="form-control px-3"
                    name="abc_confirm_password"
                    data-form-field="confirm_password"
                    placeholder={Translation.translateField(
                      "newPasswordConfirmation",
                      "New password confirmation"
                    )}
                    id="confirm_password-header15-3"
                    value={confirmPassword}
                    autoComplete="off"
                  />
                </div>
              </div>
            </Box>
            <div className="container align-center">
              <span className="input-group-btn">
                <Button
                  onClick={() => {
                    this.onSubmit();
                  }}
                  className={
                    "btn btn-" +
                    (this.state.form.submited ? "primary" : "secondary") +
                    " btn-form display-4"
                  }
                  disabled={this.state.form.submited}
                >
                  {Translation.translateField("saveButton", "Save")}
                </Button>
              </span>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default EmployeeProfileData;
