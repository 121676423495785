import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { styles } from "../../../defaultProps";
import { fileURLFactory } from "../../../utils/Images";
import SkillListing from "./SkillListing";
import EmploymentListing from "./EmploymentListing";
import Translation from "../../../utils/Translation";

class FullProfile extends Component {
	static defaultProps = {
		cv: {},
		skills: {},
		jobseeker: {},
		education: [],
		employments: [],
		children: [] //If some additional UI for higher level compoenents needs to be passed (return button when previewing profile as jobseeker)
	};
	render() {
		const {
			jobseeker: { jobseeker_name, phone, email, location },
			skills,
			cv,
			classes,
			education,
			employments,
			children
		} = this.props;
		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h5" className={classes.heading}>
						{Translation.translateStaticText(
							"jobseekerPublicProfileInformationHeading",
							"Personal Information"
						)}
					</Typography>
					<Divider className={classes.divider} />
				</Grid>
				<Grid item xs={6}>
					<Paper className={classes.paper}>
						<Typography variant="caption">
							{Translation.translateField(
								"fullName",
								"Full Name"
							)}
							:
						</Typography>
						<Typography component="p">{jobseeker_name}</Typography>
					</Paper>
				</Grid>
				<Grid item xs={6}>
					<Paper className={classes.paper}>
						<Typography variant="caption">
							{Translation.translateField("email", "Email")}:
						</Typography>
						<Typography component="p">{email}</Typography>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Paper className={classes.paper}>
						<Typography variant="caption">
							{Translation.translateField("phone", "Phone")}:
						</Typography>
						<Typography component="p">{phone}</Typography>
					</Paper>
				</Grid>
				{location && (
					<Grid key={location} item xs={6}>
						<Paper className={classes.paper}>
							<Typography variant="caption">
								{Translation.translateStaticText(
									"jobseekerLocationPlaceholder",
									"Location"
								)}
								:
							</Typography>
							<Typography component="p">
								{Translation.translateStaticText(
									"jobseekerCountry",
									"Country"
								)}
								:{" "}
								{location.country
									? ` ${Translation.translateValue(
											location.country,
											location.country
									  )} `
									: " - "}
							</Typography>
							<Typography component="p">
								{Translation.translateStaticText(
									"jobseekerCity",
									"City"
								)}
								:{" "}
								{location.city
									? ` ${Translation.translateValue(
											location.city,
											location.city
									  )} `
									: " - "}
							</Typography>
						</Paper>
					</Grid>
				)}
				<Grid item xs={6}>
					<Paper className={classes.cv}>
						<Typography variant="caption">
							{Translation.translateStaticText(
								"jobseekerCVPlaceholder",
								"CV"
							)}
							:
						</Typography>
						<Typography component="p">
							{cv && cv.file ? (
								<a
									href={fileURLFactory(cv.file)}
									target="_blank"
									rel="noopener noreferrer"
									download={cv.name}
								>
									{Translation.translateField(
										"downloadButton",
										"Download"
									)}
								</a>
							) : (
								Translation.translateStaticText(
									"jobseekerNoCV",
									"No CV uploaded"
								)
							)}
						</Typography>
					</Paper>
				</Grid>
				<SkillListing skills={skills} />
				{education.length > 0 && (
					<Grid item xs={12}>
						<Typography variant="h5" className={classes.heading}>
							{Translation.translateStaticText(
								"jobseekerPublicProfileEducationHeading",
								"Education"
							)}
						</Typography>
						<Divider className={classes.divider} />
					</Grid>
				)}
				{education.map(edu => (
					<Fragment key={edu.education_id}>
						<Grid item xs={12}>
							<Paper className={classes.paper}>
								<Typography variant="caption">
									{Translation.translateField(
										"jobseekerEducationLevel",
										"Education Level"
									)}
									:
								</Typography>
								<Typography component="p">
									{
										Translation.getEducationLevelLabelBasedOnLanguage(
											{
												label: edu.level,
												value: edu.level
											}
										).label
									}
								</Typography>
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper className={classes.paper}>
								<Typography variant="caption">
									{Translation.translateField(
										"jobseekerEducationSchool",
										"School"
									)}
									:
								</Typography>
								<Typography component="p">
									{Translation.translateValue(
										edu.school,
										edu.school
									)}
								</Typography>
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Paper className={classes.paper}>
								<Typography variant="caption">
									{Translation.translateField(
										"jobseekerEducationSpeciality",
										"Speciality"
									)}
									:
								</Typography>
								<Typography component="p">
									{Translation.translateValue(
										edu.speciality,
										edu.speciality
									)}
								</Typography>
							</Paper>
						</Grid>
						<Grid item xs={12}>
							<Paper className={classes.paper}>
								<Typography variant="caption">
									{Translation.translateField(
										"summary",
										"Summary"
									)}
									:
								</Typography>
								<Typography component="p">
									{Translation.translateValue(
										edu.summary,
										edu.summary
									)}
								</Typography>
							</Paper>
						</Grid>
					</Fragment>
				))}
				<Grid item xs={12}>
					<Typography variant="h5" className={classes.heading}>
						{Translation.translateStaticText(
							"employmentHistoryHeading",
							"Employment History"
						)}
					</Typography>
					<Divider className={classes.divider} />
				</Grid>
				<EmploymentListing employments={employments} />
				{children}
			</Grid>
		);
	}
}

export default withStyles(styles)(FullProfile);
