export default {
	UIError: "Грешка в потребителския интерфейс, моля, свържете се с поддръжка",
	serverError: "Грешка в сървъра, моля, свържете се с поддръжка",
	UserNotLoggedIn: "Потребителят не е влязъл в профила си",
	CompanyResponseError: "Грешка в отговора на компанията",
	ErrorPageDefaultMessage: "Страницата не съществува или нямате достъп до нея.",
	noAccessToUser: "Потребителят е деактивирал публичния достъп",
	passwordDoesNotMatch: "Паролите не съвпадат",
	searchStringRestriction:
		"Информацията зададена в търсачката трябва да съдържа повече от един знак",
	searchNotLoaded: "Вашето търсене не може да се зареди. Моля, опитайте отново",
	cannotSelfCorrespondance: "Не можете да поискате кореспонденция със себе си",
	cannotCorrespondWithAnotherCompany:
		"Не може да поискате кореспонденция с друга фирма.",
	correspondenceAlreadyAvailable:
		"Заявката за контакт вече е изпратена или потребителят я е блокирал",
	onlyCompaniesRequestCorrespondance:
		"Само компании имат възможност да поискат кореспонденция с кандидати",
	onlyCandidateJobseekerRequestCorrespondance:
		"Можете да изпращате заявка за кореспонденция само на кандидати, които са кандидатствали по Ваша обява",

	//Automatic form field validation - Jobseeker -> Employment History
	"employer-mandatory": "Работодателят е задължителен",
	"role-mandatory": "Ролята е задължителна",
	"initialDate-mandatory": "Началната дата е задължителна",
	"initialDate-<= endDate": "Началната дата трябва да бъде по-ниска или равна на Крайна дата",
	"endDate-oneOf": "Трябва да изберете един от (Крайна дата, до момента)"
};
