import React, { Component } from "react";
import Translation from "../../../utils/Translation";

class ContactList extends Component {
    static defaultProps = {
        contacts: [],
        onContactClick: contact => {},
        onContactCloseClick: contact => {},
        selectedContact: undefined,
        onContactProfileClick: contact => {}
    };

    render() {
        return (
            <div
                className="container"
                style={{
                    overflowY: "auto",
                    height: "100%",
                    padding: "0",
                    paddingLeft: "2px",
                    paddingRight: "2px"
                }}
            >
                <div
                    className="panel panel-default"
                    style={
                        !this.props.selectedContact
                            ? {
                                  width: "100%",
                                  minHeight: "30px",
                                  fontWeight: "bold"
                              }
                            : { width: "100%", minHeight: "30px" }
                    }
                >
                    <div
                        className="panel-body"
                        style={{ borderBottom: "1px solid black" }}
                    >
                        <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                this.props.onContactClick();
                            }}
                        >
                            {Translation.translateStaticText(
                                "systemUser",
                                "System"
                            )}
                        </span>
                    </div>
                </div>
                {this.props.contacts.map(contact => {
                    return (
                        <div
                            className="panel panel-default"
                            style={
                                this.props.selectedContact &&
                                this.props.selectedContact.contact_id ===
                                    contact.contact_id
                                    ? {
                                          width: "100%",
                                          minHeight: "30px",
                                          fontWeight: "bold"
                                      }
                                    : { width: "100%", minHeight: "30px" }
                            }
                            id={contact.contact_id}
                            key={contact.contact_id}
                        >
                            <div
                                className="panel-body"
                                style={{
                                    borderBottom: "1px solid black",
                                    paddingRight: "2px"
                                }}
                            >
                                <div
                                    style={{ cursor: "pointer", float: "left" }}
                                    onClick={() => {
                                        this.props.onContactClick(contact);
                                    }}
                                >
                                    {contact.unread && contact.unread > 0 ? (
                                        <span style={{ paddingRight: "3px" }}>
                                            {"\u0028"}
                                            <span style={{ color: "red" }}>
                                                {contact.unread}
                                            </span>
                                            {"\u0029"}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {contact.company_name
                                        ? contact.company_name
                                        : contact.name}
                                </div>
                                <div style={{ float: "right" }}>
                                    <span
                                        style={{
                                            cursor: "pointer",
                                            width: "2px"
                                        }}
                                        className="mbri-user"
                                        onClick={() => {
                                            this.props.onContactProfileClick(
                                                contact
                                            );
                                        }}
                                    />
                                    {"\u00A0"}
                                    <span
                                        style={{
                                            cursor: "pointer",
                                            width: "2px"
                                        }}
                                        className="mbri-close"
                                        onClick={() => {
                                            this.props.onContactCloseClick(
                                                contact
                                            );
                                        }}
                                    />
                                </div>
                                <div style={{ clear: "both" }} />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default ContactList;
