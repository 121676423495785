import React from "react";
import { languages, frontend } from "../../config";
import Translation from "../../utils/Translation";

export default function FreeCompanyError(props) {
	const englishTemplate = (
		<div>
			Your company is registered on a free account, as such can only have
			1 position.{" "}
			<a href={frontend.route.pricing}>Click here to update</a>
		</div>
	);

	const bulgarianTemplate = (
		<div>
			грешка при опит да се създаде повече от 1 позиция при free account
			на компанията: Вашата компания е регистрирана с безплатен акаунт,
			като такава може да има само 1 публикувана обява за работа.{" "}
			<a href={frontend.route.pricing}>
				Кликнете тук, за да актуализирате
			</a>
		</div>
	);

	const getTemplate = language => {
		switch (language) {
			case languages.english:
				return englishTemplate;
			case languages.bulgarian:
				return bulgarianTemplate;
			case languages.german:
				return englishTemplate;
			default:
				return englishTemplate;
		}
	};

	return getTemplate(Translation.getLanguage());
}
