import React from "react";
import Button from "@material-ui/core/Button";

import Select from "./Select";
import Translation from "../../utils/Translation";

class Locations extends React.Component {
    static defaultProps = {
        max: 1,
        // param return example [{location_id:"asdsa", country:"..", city: "..."}]
        onChange: locations => {},
        // example {countries:[{country:".."}], cities:[{country:"..", cities:[{city:"..."}]}], locations:[{id:"....", country:"..", city:"..."}]}
        selectLocations: {},
        // default values example: [{location_id:"asdsa", country:"..", city:"..."}]
        values: [],
        // default value example: {location_id:"asdsa", country:"..", city: ".."}
        value: {}
    };

    state = {
        count: 0
    };
    locations = [];
    locationsRender = [];
    countriesOptions = [];
    citiesOptions = [];
    controlRender = null;

    componentWillMount() {
        let that = this;
        if (
            this.props.selectLocations &&
            "countries" in this.props.selectLocations &&
            this.props.selectLocations.countries.length > 0
        ) {
            this.countriesOptions.push({
                value: "",
                text: Translation.translateField(
                    "jobseekerProfileLocationCountry",
                    "--- Select a Country ---"
                )
            });
            this.props.selectLocations.countries.forEach(function(country) {
                that.countriesOptions.push({
                    value:  country.country,
                    text: Translation.translateValue(country.country, country.country)
                });
            });
        }
        if (
            this.props.selectLocations &&
            "cities" in this.props.selectLocations &&
            this.props.selectLocations.cities.length > 0
        ) {
            this.props.selectLocations.cities.forEach(function(country) {
                country.cities.map(city => {
                    if (!that.citiesOptions.hasOwnProperty(country.country)) {
                        that.citiesOptions[country.country] = [];
                        that.citiesOptions[country.country].push({
                            value: "",
                            text: Translation.translateField(
                                "JobseekerProfileLocationCity",
                                "--- Select a City ---"
                            )
                        });
                        that.citiesOptions[country.country].push({
                            value: city,
                            text: Translation.translateValue(city, city)
                        });
                    } else {
                        that.citiesOptions[country.country].push({
                            value: city,
                            text: Translation.translateValue(city, city)
                        });
                    }
                    return that.citiesOptions;
                });
            });
        }
        if (this.props.values && this.props.values.length > 0) {
            let count = 0;
            this.props.values.forEach(function(defaultLocation) {
                if ("location_id" in defaultLocation) {
                    count++;
                    that.locations.push(defaultLocation);
                    that.locationsRender.push(
                        that.getALocationRender(count - 1)
                    );
                }
            });
            if (count === 0) {
                count++;
                this.locations.push({ country: "" });
                this.locationsRender.push(that.getALocationRender(count - 1));
            }
            this.setState({ count: count });
        } else if (this.props.value && "location_id" in this.props.value) {
            this.locations.push(this.props.value);
            this.locationsRender.push(this.getALocationRender(0));
            this.setState({ count: 1 });
        } else {
            this.locations.push({ country: "" });
            this.locationsRender.push(that.getALocationRender(0));
            this.setState({ count: 1 });
        }
    }

    getOnSelectChange(number, countryOrCity) {
        let that = this;
        return value => {
            if (countryOrCity === "country") {
                that.locations[number].country = value;
            } else {
                that.locations[number].city = value;
            }
            if (
                that.props.selectLocations &&
                "locations" in that.props.selectLocations &&
                that.props.selectLocations.locations.length > 0
            ) {
                that.props.selectLocations.locations.forEach(function(
                    location
                ) {
                    if (
                        location.country === that.locations[number].country &&
                        (("city" in that.locations[number] &&
                            (location.country + "_" + location.city ===
                                that.locations[number].city ||
                                location.city ===
                                    that.locations[number].city)) ||
                            location.city === "")
                    ) {
                        that.locations[number].location_id =
                            location.location_id;
                    }
                });
            }
            if (countryOrCity === "country") {
                that.locationsRender[number] = that.getALocationRender(number);
            }
            that.setState({ ...that.state });
            that.props.onChange(that.getFilledLocations());
        };
    }

    getFilledLocations() {
        let filledLocations = [];
        this.locations.forEach(function(location) {
            if ("country" in location && location.country) {
                filledLocations.push(location);
            }
        });
        return filledLocations;
    }

    removeALocationRender(number) {
        if (this.state.count <= 1) {
            if ("country" in this.locations[number]) {
                delete this.locations[number].country;
            }
            if ("location_id" in this.locations[number]) {
                delete this.locations[number].location_id;
            }
            if ("city" in this.locations[number]) {
                delete this.locations[number].city;
            }
            this.locationsRender[0] = this.getALocationRender(0);
            this.setState({ ...this.state });
        } else {
            this.locations.splice(number, 1);
            this.locationsRender.splice(number, 1);
            this.setState({ ...this.state, count: this.state.count - 1 });
        }
        this.props.onChange(this.getFilledLocations());
    }

    getALocationRender(number) {
        let citySelect = null;
        let defCountryVal = "";
        let countryStyle = {};
        let cityStyle = {};
        let removeButon = null;
        if (this.locations[number] && "country" in this.locations[number]) {
            defCountryVal = this.locations[number].country;
            if (this.locations[number].country !== "") {
                let that = this;
                if (this.props.max > 1) {
                    removeButon = (
                        <Button
                            style={{
                                float: "right",
                                marginTop: "1%",
                                paddingLeft: "0",
                                paddingRight: "0",
                                minWidth: "40px",
                                maxWidth: "40px"
                            }}
                            onClick={() => {
                                that.removeALocationRender(number);
                            }}
                        >
                            X
                        </Button>
                    );
                    cityStyle = {
                        width: "47%",
                        marginLeft: "10px",
                        float: "left"
                    };
                    countryStyle = { width: "47%", float: "left" };
                } else {
                    cityStyle = { width: "49%", float: "right" };
                    countryStyle = { width: "49%", float: "left" };
                }
                let defCityVal =
                    "city" in this.locations[number]
                        ? this.locations[number].city
                        : "";
                citySelect = (
                    <Select
                        id={"select_city_location_" + number}
                        name={"select_city_location_" + number}
                        onSelectChange={this.getOnSelectChange(number, "city")}
                        style={cityStyle}
                        value={defCityVal}
                        options={this.citiesOptions[defCountryVal]}
                        className="form-control"
                    />
                );
            }
        }
        return (
            <div
                id={"location_" + number}
                key={"location_" + number}
                style={{
                    clear: "both",
                    minHeight: "56px",
                    marginTop: "10px",
                    marginBottom: "10px"
                }}
            >
                <Select
                    id={"select_country_location_" + number}
                    name={"select_country_location_" + number}
                    onSelectChange={this.getOnSelectChange(number, "country")}
                    style={countryStyle}
                    value={defCountryVal}
                    options={this.countriesOptions}
                    className="form-control"
                />
                {citySelect}
                {removeButon}
            </div>
        );
    }

    render() {
        if (this.props.max > 1 && this.state.count < this.props.max) {
            let that = this;
            this.controlRender = (
                <div id="locations_controls">
                    <Button
                        onClick={() => {
                            that.locations.push({ country: "" });
                            that.locationsRender.push(
                                that.getALocationRender(that.state.count)
                            );
                            that.setState({ count: that.state.count + 1 });
                        }}
                    >
                        {Translation.translateField("addNewButton", "Add new")}
                    </Button>
                </div>
            );
        } else {
            this.controlRender = null;
        }
        return (
            <div id={"locations " + this.locationsRender.length}>
                {this.locationsRender.map(function(element) {
                    return element;
                })}
                {this.controlRender}
            </div>
        );
    }
}

export default Locations;
