
import React, { Component } from 'react';
import swal from 'sweetalert';

import manager from '../../Manager';
import { frontend, usertype } from '../../config';
import ContactList from './inSystemMessaging/ContactList';
import MessageBoard from './inSystemMessaging/MessageBoard';
import SystemMessageBoard from './inSystemMessaging/SystemMessageBoard';
import {isArray} from '../../utils/arrayUtils';
import Translation from '../../utils/Translation';

class InSystemMessaging extends Component {
    static contextType = manager;

    state = {
        contacts: [],
        selectedContact: undefined,
        showRemoveAlert: false,
        loaded: false
    }
    user_id = undefined;
    usertype = undefined;
    withV = undefined;

    subChannels = false;

    onBackButtonEvent(that) {
        return (e) =>{
            if("state" in e && e.state!=null && "chat" in e.state && "with" in e.state) {
                e.preventDefault();
                e.stopPropagation(); 
                window.addEventListener("popstate", that.onBackButtonEvent(that));
                let selectedContact = undefined;
                if(e.state.with!=="System") {
                    let found = false;
                    for(let index=0; index<that.state.contacts && !found; index++) {
                        if(that.state.contacts[index].contact_id===e.state.with) {
                            found = true;
                            selectedContact = that.state.contacts[index];
                            that.withV = selectedContact.contact_id;
                        }
                    }
                }
                that.setState({...that.state, selectedContact: selectedContact});
            }
        }
    }
      
    componentDidMount() {
        if(this.subChannels) {
            return;
        }
        this.subChannels = true;
        const component = this;
        window.addEventListener("popstate", component.onBackButtonEvent(component));
        component.context.getAuthManager().getLoggedUser(component,{
            onSuccess: {toast: false},
            onFinish: {
                toast: false,
                callback: (user)=>{
                    component.user_id = user.user_id;
                    component.usertype = user.user_type;
                    component.withV = component.state.selectedContact?component.state.selectedContact.contact_id:"System";
                    if(component.props.location.pathname.indexOf(frontend.route.inSystemMessaging+"/")>-1) {
                        component.withV = component.props.location.pathname.split(frontend.route.inSystemMessaging+"/")[1];
                    }
                    if (window.history.state === null) {
                        window.history.replaceState({
                            chat: true, 
                            with: component.withV, 
                            url: frontend.route.inSystemMessaging+"/"+component.withV
                        }, (component.state.selectedContact?component.state.selectedContact.name:"System")+" messages", frontend.route.inSystemMessaging+"/"+component.withV);
                    }
                    component.context.getWSManager().getWS().subscribe('getContacts',(message)=>{
                        let selectedContact = undefined;
                        if(component.withV!=="System") {
                            let found = false;
                            for(let index=0; index<message.length && !found; index++) {
                                if(message[index].contact_id===component.withV) {
                                    found = true;
                                    selectedContact = message[index];
                                }
                            }
                            if(selectedContact && selectedContact.unread>0) {
                                component.context.getWSManager().getWS().readMessages(selectedContact.user_id);
                                selectedContact.unread=0;
                            }
                        } else {
                            //read System messages
                            component.context.getWSManager().getWS().readMessages();
                        }
                        for(let index=0; index<message.length; index++) {
                            if(message[index].me_status==="not_accepted") {
                                message[index].unread = message[index].unread+1;
                            }
                        }
                        component.setState({...component.state, loaded: true, contacts: message, selectedContact: selectedContact});
                    });
                    this.context.getWSManager().getWS().subscribe("contactAccepted", (user_id)=>{
                        let contacts = component.state.contacts;
                        if(user_id && isArray(contacts)) {
                            let found = false;
                            for(let i=0; i<contacts.length && !found; i++) {
                                if('user_id' in contacts[i] && contacts[i].user_id === user_id && (!component.state.selectedContact || component.state.selectedContact.contact_id!==contacts[i].contact_id)) {
                                    window.location.reload();
                                } else if ('user_id' in contacts[i] && contacts[i].user_id === user_id) {
                                    contacts[i].you_status="accepted";
                                    contacts[i].me_status="accepted";
                                    contacts[i].unread = contacts[i].unread-1;
                                    found=true;
                                }
                            }
                            component.setState({contacts:contacts});
                        }
                    });
                    component.context.getWSManager().getWS().getContacts();
                }
            }
        });
        
        this.context.getWSManager().getWS().subscribe("newMessage", (user_id)=>{
            if(user_id) {
                let contacts = component.state.contacts;
                if(isArray(contacts)) {
                    let found = false;
                    for(let i=0; i<contacts.length && !found; i++) {
                        if('user_id' in contacts[i] && contacts[i].user_id===user_id && (!component.state.selectedContact || component.state.selectedContact.contact_id!==contacts[i].contact_id)) {
                            contacts[i].unread = contacts[i].unread+1;
                            found=true;
                        } else if(component.state.selectedContact && component.state.selectedContact.contact_id===contacts[i].contact_id) {
                            component.context.getWSManager().getWS().readMessages(component.state.selectedContact.user_id);
                        }
                    }
                    component.setState({contacts:contacts});
                }
            }
        });
    }

    componentWillUnmount() {
        this.subChannels = false;
        this.context.getWSManager().getWS().unsubscribe("contactAccepted");
        this.context.getWSManager().getWS().unsubscribe("newMessage");
        this.context.getWSManager().getWS().unsubscribe('getContacts');
    }

    selectContact = (contact, incontacts=undefined)=> {
        let contacts = incontacts!==undefined?incontacts:this.state.contacts;
        if(contact) {
            if(contact.unread>0) {
                this.context.getWSManager().getWS().readMessages(contact.user_id);
                contact.unread=0;
                let changed = false;
                for(let i=0; i<contacts.length && !changed; i++) {
                    if(contacts[i].user_id === contact.user_id) {
                        changed = true;
                        contacts[i].unread=0;
                    }
                }
            }
            this.props.location.pathname = frontend.route.insystemMessaging+"/"+contact.contact_id;
            this.withV = contact.user_id;
            window.history.pushState({chat: true, with:contact.contact_id, url: frontend.route.inSystemMessaging+"/"+contact.contact_id}, contact.name+" messages", frontend.route.inSystemMessaging+"/"+contact.contact_id);
        } else {
            this.props.location.pathname = frontend.route.insystemMessaging+"/System";
            this.withV = "System";
            window.history.pushState({chat: true, with:"System", url: frontend.route.inSystemMessaging+"/System"}, "System messages", frontend.route.inSystemMessaging+"/System");
        }
        this.setState({
            ...this.state,
            selectedContact: contact,
            contacts: contacts
        }); 
    }

    removeContact(contact) {
        const component = this;
        if(!contact) {
            console.error("no contact provided to remove");
            return;
        }
        let contacts = component.state.contacts;
        let removed = false;
        for(var index=0; index<contacts.length && !removed; index++) {
            if(contacts[index].contact_id===contact.contact_id) {
                removed = true;
                contacts.splice(index,1);
            }
        }
        if(!removed) {
            console.error("contact not found in contact list");
            return;
        }
        component.context.getWSManager().getWS().request("revokeContact", {you: contact.user_id});
        if(component.selectedContact===undefined || component.selectedContact.contact_id===contact.contact_id) {
            component.selectContact(undefined, contacts);
        } else {
            component.selectContact(contact, contacts);
        }
    }

    unblockContact() {
        const component = this;
        return (contact)=>{
            component.context.getWSManager().unblockContact(component, contact.user_id, {
                onSuccess: {
                    callback: returnData => {
                        let selectedContact = component.state.selectedContact;
                        if(selectedContact) {
                            selectedContact.me_status = returnData;
                            let changed = false;
                            let contacts = component.state.contacts;
                            for(let i=0; i<contacts.length && !changed; i++) {
                                if(contacts[i].contact_id === selectedContact.contact_id) {
                                    changed = true;
                                    contacts[i].me_status = returnData;
                                }
                            }
                            component.setState({...component.state, contacts: contacts, selectedContact: selectedContact});
                        } else {
                            window.location.reload(); 
                        }
                    }
                }
            });
        }
    }

    requestCorrespondence() {
        const component = this;
        return (contact) => {
            this.context.getWSManager().requestContact(component, {user: contact.user_id, isCompany: false}, {
                onSuccess: {
                    message: Translation.translateMessage("contactRequestSent", "Request sent"),
                    callback: ()=>{
                        let selectedContact = component.state.selectedContact;
                        selectedContact.me_status = "accepted";
                        selectedContact.you_status = "not_accepted";
                        let changed = false;
                        let contacts = component.state.contacts;
                        for(let i=0; i<contacts.length && !changed; i++) {
                            if(contacts[i].contact_id === selectedContact.contact_id) {
                                changed = true;
                                contacts[i].me_status = "accepted";
                                contacts[i].you_status = "not_accepted";
                            }
                        }
                        component.setState({...component.state, contacts: contacts, selectedContact: selectedContact});
                    }
                }
            });
        }
    }

    acceptContact() {
        const component = this;
        return (contact) => {
            component.context.getWSManager().acceptContact(component, contact.user_id, {
                onSuccess: {
                    callback: () => {
                        window.location.reload(); 
                    }
                }
            });
        }
    }

    rejectContact() {
        const component = this;
        return (contact) => {
            component.context.getWSManager().rejectContact(component, {user: contact.user_id}, {
                onSuccess: {
                    callback: returnData => {
                        let changed = false;
                        let contacts = component.state.contacts;
                        for(let i=0; i<contacts.length && !changed; i++) {
                            if(contacts[i].contact_id === component.state.selectedContact.contact_id) {
                                changed = true;
                                delete contacts[i];
                            }
                        }
                        component.setState({...component.state, contacts: contacts, selectedContact: undefined});
                    }
                }
            });
        }
    }
    hideAlert() {
        this.setState({...this.state, showRemoveAlert: false});
    }

    showAlert() {
        const component = this;
        return (contact) => {
            swal({
                icon: "warning",
                title: Translation.translateStaticText("warningHeader", "Warning"),
                text: Translation.translateStaticText("removeContactWarning","Are you sure you want to remove {contact} from contacts? This can not be easily reverted.").replace("{contact}", contact.name),
                buttons: {
                    cancel: Translation.translateField("cancelButton", "Cancel"),
                    block: {
                        text: Translation.translateField("blockButton", "Block")
                    },
                    remove: {
                        text: Translation.translateField("removeOnlyButton", "Remove Only")
                    }
                },
            }).then((value) => {
                switch (value) {
                    case "block":
                        component.context.getWSManager().blockContact(component, contact.user_id, {
                            onSuccess: {
                                callback: returnData => {
                                    if(returnData==="has_blocked") {
                                        let selectedContact = component.state.selectedContact;
                                        if(selectedContact && contact && contact.contact_id===selectedContact.contact_id) {
                                            selectedContact=undefined;
                                        }
                                        let changed = false;
                                        let contacts = component.state.contacts;
                                        for(let i=0; i<contacts.length && !changed; i++) {
                                            if(contacts[i].contact_id === contact.contact_id) {
                                                changed = true;
                                                delete contacts[i];
                                            }
                                        }
                                        component.setState({...component.state, contacts: contacts, selectedContact: selectedContact});
                                    }
                                }
                            }
                        });
                        break;
                
                    case "remove":
                        component.removeContact(contact);
                        break;
                
                    default:
                        break;
                }
            });
        }
    }

    onContactProfileClick() {
        return (contact) => {
            if(contact.user_type===usertype.company) {
                window.location = frontend.DOMAIN + frontend.route.company.public_profile + "/" + contact.company_name
            } else if(contact.user_type===usertype.jobseeker) {
                window.location = frontend.DOMAIN + frontend.route.jobseeker.public_profile + "/" + contact.user_id
            }
        }
    }


    render() {
        if(!this.state.loaded) return <br/> 
        return (
            <section className="counters6 counters cid-rg5irxrHy2" style={{paddingBottom: "0", display: "flex", justifyContent: "stretch", minHeight: "66.7vh", paddingTop: "77px"}}>
                <div className="row" style={{width: "100%", margin: "2px"}}>
                    <div className="col-lg-2 col-md-1" style={{padding: "0"}}>
                        <ContactList contacts={this.state.contacts} onContactClick={this.selectContact} selectedContact={this.state.selectedContact} onContactCloseClick={this.showAlert()} onContactProfileClick={this.onContactProfileClick()}/>
                    </div>
                    <div className="col-lg-10 col-md-11" style={{borderLeft: "1px solid black", paddingLeft: "1px", paddingRight: "1px"}}>
                        {
                            this.state.selectedContact
                            ?<MessageBoard disableInput={false} messages={this.state.messages} loggedUserId={this.user_id} contact={this.state.selectedContact} channel="getMessages" acceptContact={this.acceptContact()} rejectContact={this.showAlert()} unblockContact={this.unblockContact()} requestCorrespondence={this.requestCorrespondence()} />
                            :<SystemMessageBoard loggedUserId={this.user_id} channel="getSystemMessages" unblockContact={this.unblockContact()}/>
                        }
                    </div>
                </div>
            </section>
        );
    }
}

export default InSystemMessaging;