import React, { Component } from "react";
import HelpOutlinedIcon from "@material-ui/icons/HelpOutlined";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import SweetAlert from "react-bootstrap-sweetalert";

import LinkButton from "../../navigation/LinkButton";
import { jobseekerProfileProps } from "../../../defaultProps";
import { frontend, usertype, file } from "../../../config";
import FloatingLabelInput from "../../form_helpers/FloatingLabelInput";
import manager from "../../../Manager";
import Skills from "../Skills";
import EducationForm from "./EducationForm";
import HelperTooltip from "../../form_helpers/HelperTooltip";
import Locations from "../../form_helpers/Locations";
import Select from "../../form_helpers/Select";
import Translation from "../../../utils/Translation";
import ToastHandler from "../../../utils/ToastHandler";
import ProfileCompletion from "./ProfileCompletion";

class Edit extends Component {
	static contextType = manager;

	constructor(props) {
		super(props);
		this.viewRoute = frontend.route.jobseeker.jobseeker_profile.view;
		this.initialEducation = [];
	}

	componentWillMount() {
		this.context.getAuthManager().pageRestriction({
			logged: true,
			forLoggedUsertype: [usertype.jobseeker]
		});
		this.setState({
			modifications: false,
			preview: false,
			...jobseekerProfileProps,
			submited: false,
			...this.props.data,
			jobseeker: {
				...jobseekerProfileProps.jobseeker,
				...this.props.data.jobseeker
			}
		});
		const component = this;
		this.context.getJobseekerManager().getProfileCompletion(component, {
			onSuccess: {
				callback: profileCompletion => {
					component.setState({
						...component.state,
						profileCompletion: profileCompletion
					});
				}
			}
		});
		this.initialEducation = this.props.data.jobseeker.education;
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			...this.state,
			...nextProps.data,
			jobseeker: {
				...this.state.jobseeker,
				...nextProps.data.jobseeker
			},
			preview: false,
			modifications: false,
			submited: false
		});
	}

	handleChange = name => ({ target: { value } }) => {
		this.setState({
			...this.state,
			modifications: true,
			preview: false,
			jobseeker: {
				...this.state.jobseeker,
				[name]: value
			},
			submited: false
		});
	};

	handeFileChange = e => {
		const component = this;
		this.context.getFileManager().uploadFile(
			component,
			{ file: e.target.files[0], type: file.cv },
			{
				onSuccess: {
					message: Translation.translateMessage(
						"File Uploaded",
						"File Saved"
					),
					callback: data => {
						component.props.updateCv(data);
					}
				}
			}
		);
	};

	changeLocation = location => {
		this.setState({
			...this.state,
			modifications: true,
			preview: false,
			jobseeker: {
				...this.state.jobseeker,
				location:
					location.length > 0
						? { ...location[0] }
						: { location_id: "", country: "", city: "" }
			},
			submited: false
		});
	};

	addEducation = newEducation => {
		this.setState({
			...this.state,
			jobseeker: {
				...this.state.jobseeker,
				education: [...this.state.jobseeker.education, newEducation]
			},
			modifications: true
		});
	};

	removeEducation = educationId => {
		let educations = this.state.jobseeker.education.filter(
			edu => edu.education_id !== educationId
		);
		this.setState({
			...this.state,
			jobseeker: {
				...this.state.jobseeker,
				education: educations
			},
			modifications:
				JSON.stringify(this.initialEducation) !==
				JSON.stringify(educations)
		});
	};

	handleEducationInputFields = (name, key) => ({ target: { value } }) => {
		this.handleEducationChange(name, key, value);
	};

	handleEducationAutocompleteChange = (name, key, newValue) => {
		this.handleEducationChange(name, key, newValue);
	};

	handleEducationChange = (name, key, value) => {
		let educations = this.state.jobseeker.education.slice();
		const index = educations.indexOf(
			educations.find(edu => edu.education_id === key)
		);
		if (index < 0) {
			return;
		}
		educations[index] = {
			...educations[index],
			[name]: value
		};
		this.setState({
			...this.state,
			jobseeker: {
				...this.state.jobseeker,
				education: educations
			},
			modifications:
				JSON.stringify(this.initialEducation) !==
				JSON.stringify(educations)
		});
	};

	getJobseekerLocation(id, locations) {
		return locations.filter(
			location => id && id === location.location_id
		)[0];
	}

	getRequestForm() {
		let form = {
			jobseeker_name: this.state.jobseeker.jobseeker_name,
			phone: this.state.jobseeker.phone,
			email: this.state.jobseeker.email,
			location: this.state.jobseeker.location.location_id,
			password: this.state.jobseeker.password,
			password_confirmation: this.state.jobseeker.confirmPassword,
			current_password: this.state.jobseeker.currentPassword,
			skills: JSON.stringify(
				this.state.skills.map(skill => {
					return {
						name: skill.value
					};
				})
			),
			permission_type: this.state.jobseeker.permissionType
		};
		if (this.state.jobseeker.education.length > 0) {
			form.education = JSON.stringify(
				this.state.jobseeker.education.map(edu => {
					return {
						school: edu.school ? edu.school.value : "",
						level: edu.level ? edu.level.value : "",
						speciality: edu.speciality,
						summary: edu.summary
					};
				})
			);
		}
		return form;
	}

	onSubmit() {
		const {
			password,
			confirmPassword,
			currentPassword
		} = this.state.jobseeker;
		if (currentPassword || password || confirmPassword) {
			if (!currentPassword) {
				ToastHandler.showError(
					Translation.translateMessage(
						"noCurrentPasswordProvided",
						"You have to provide current password"
					)
				);
				return;
			} else if (password !== confirmPassword) {
				ToastHandler.showError(
					Translation.translateMessage(
						"FailedPasswordConfirmation",
						"Password does not match"
					)
				);
				return;
			}
		}
		const component = this;
		this.setState({
			...this.state,
			submited: true
		});
		this.context
			.getJobseekerManager()
			.setLoggedJobseekerData(component, component.getRequestForm(), {
				onSuccess: {
					message: Translation.translateMessage("Saved", "Saved"),
					callback: data => {
						this.context
							.getJobseekerManager()
							.getProfileCompletion(component, {
								onSuccess: {
									callback: profileCompletion => {
										data.profileCompletion = profileCompletion;
										component.props.rememberData(data);
									}
								}
							});
					}
				},
				onError: {
					state: {
						...component.state
					}
				},
				onFinish: {
					state: {
						...component.state,
						preview: false,
						modifications: false,
						submited: false
					}
				}
			});
	}

	onPreview = () => {
		if (this.state.modifications) {
			this.setState({
				...this.state,
				preview: true
			});
		} else {
			this.props.redirect(this.viewRoute);
		}
	};

	cancelPreview = () => {
		this.setState({ ...this.state, preview: false });
	};

	addSkill = newValue => {
		const skills = newValue;
		this.setState({
			...this.state,
			skills: skills,
			modifications: true,
			preview: false,
			submited: false
		});
	};

	changePermission = permissionType => {
		this.setState({
			...this.state,
			modifications: true,
			preview: false,
			jobseeker: {
				...this.state.jobseeker,
				permissionType: permissionType,
				visibility: this.state.accessLevels.filter(
					level => level.value === permissionType
				)[0].text
			},
			submited: false
		});
	};

	render() {
		const {
			approvedSkills,
			countries,
			cities,
			locations,
			accessLevels,
			jobseeker: {
				jobseeker_name,
				phone,
				email,
				location,
				password,
				confirmPassword,
				currentPassword,
				permissionType,
				education
			},
			skills,
			profileCompletion,
			modifications,
			preview,
			submited
		} = this.state;
		const {
			jobseeker: {
				jobseeker_name: savedName,
				phone: savedPhone,
				email: savedEmail,
				location: savedLocation,
				permissionType: savedPermission
			}
		} = this.props.data;
		return (
			<div className="row">
				<div className="container align-center">
					<HelperTooltip
						title={Translation.translateStaticText(
							"jobseekerCompanyAccessHelperText",
							"Modify or remove specific visibility of companies for your profile"
						)}
					>
						<span>
							<LinkButton
								className="btn btn-primary display-4"
								to={
									frontend.route.jobseeker.jobseeker_profile
										.company_access
								}
							>
								{Translation.translateStaticText(
									"JobseekerCompanyAccessHeading",
									"Company Access"
								)}
							</LinkButton>
						</span>
					</HelperTooltip>
					<HelperTooltip
						title={Translation.translateStaticText(
							"jobseekerEmploymentHistoryHelperText",
							"Add, Edit or Remove employments to your profile"
						)}
					>
						<span>
							<LinkButton
								className="btn btn-primary display-4 mx-3"
								to={
									frontend.route.jobseeker.jobseeker_profile
										.employment
								}
							>
								{Translation.translateStaticText(
									"employmentHistoryHeading",
									"Employment History"
								)}
							</LinkButton>
						</span>
					</HelperTooltip>
					<ProfileCompletion
						percentage={profileCompletion}
						jobseeker={{
							jobseeker_name: savedName,
							email: savedEmail,
							phone: savedPhone,
							location: savedLocation,
							permissionType: savedPermission
						}}
						skills={this.props.data.skills}
						education={this.props.data.jobseeker.education}
						employment={this.props.data.employments}
						modifications={modifications}
					/>
					<p className="mbr-text pb-3 mbr-fonts-style display-5" />
					<div className="align-left mbr-white">
						<h3>
							{Translation.translateStaticText(
								"jobseekerProfileInformationHeading",
								"Profile Information"
							)}
						</h3>
						<hr className="mbr-bg-white" />
					</div>
					<form className="mbr-form" autoComplete="off">
						<div data-for="jobseeker_name">
							<div className="form-group">
								{/*Stops autocomplete*/}
								<input type="text" style={{display: "none"}}/>
								<input
									style={{ display: "none" }}
									type="password"
									name="disableautofill"
									autoComplete="off"
								/>
								<FloatingLabelInput
									onChange={this.handleChange(
										"jobseeker_name"
									)}
									type="text"
									className="form-control px-3"
									name="jobseeker_name"
									data-form-field="jobseeker_name"
									placeholder={Translation.translateField(
										"fullName",
										"Full Name"
									)}
									id="jobseeker_name"
									value={jobseeker_name}
								/>
							</div>
						</div>
						<div data-for="phone">
							<div className="form-group">
								<FloatingLabelInput
									onChange={this.handleChange("phone")}
									type="text"
									className="form-control px-3"
									name="phone"
									data-form-field="phone"
									placeholder={Translation.translateField(
										"phone",
										"Phone"
									)}
									id="phone"
									value={phone}
								/>
							</div>
						</div>
						<div data-for="email">
							<div className="form-group">
								<FloatingLabelInput
									onChange={this.handleChange("email")}
									type="text"
									className="form-control px-3"
									name="email"
									data-form-field="email"
									placeholder={Translation.translateField(
										"email",
										"Emai;"
									)}
									id="email"
									value={email}
								/>
							</div>
						</div>
						<div className="form-group">
							<Locations
								selectLocations={{
									countries: countries,
									cities: cities,
									locations: locations
								}}
								value={location}
								onChange={this.changeLocation}
							/>
						</div>
						<div data-for="file">
							<div className="form-group">
								<FloatingLabelInput
									onChange={this.handeFileChange}
									placeholder={Translation.translateField(
										"jobseekerProfileCV",
										"CV (pdf only)"
									)}
									alwaysShowPlaceholderAsLabel="true"
									type="file"
									className="form-control px-3"
									name="file"
									data-form-field="file"
									id="file"
								/>
							</div>
						</div>
						<div data-for="visibility">
							<div className="form-group">
								<Select
									name="visibility"
									placeholder={Translation.translateField(
										"jobseekerProfileVisibility",
										"Profile visibility"
									)}
									className="form-control px-3"
									onSelectChange={this.changePermission}
									options={accessLevels}
									value={permissionType}
								/>
							</div>
						</div>
						<div className="help-menu">
							<ul className="help-toolbar">
								<li>
									<strong>
										{Translation.translateStaticText(
											"helperToolbarRestrictionFull",
											"Full"
										)}
									</strong>{" "}
									-{" "}
									{Translation.translateStaticText(
										"helperToolbarRestrictionFullDetails",
										"Companies that you have not configured access can see all your profile info"
									)}
								</li>
								<li>
									<strong>
										{Translation.translateStaticText(
											"helperToolbarRestrictionLimited",
											"Limited"
										)}
									</strong>{" "}
									-{" "}
									{Translation.translateStaticText(
										"helperToolbarRestrictionLimitedDetails",
										"Companies that you have not configured access can see only your skills"
									)}
								</li>
								<li>
									<strong>
										{Translation.translateStaticText(
											"helperToolbarRestrictionNone",
											"None"
										)}
									</strong>{" "}
									-{" "}
									{Translation.translateStaticText(
										"helperToolbarRestrictionNoneDetails",
										"Companies that you have not configured access cannot see your profile"
									)}
								</li>
							</ul>
						</div>
						<div className="align-left mbr-white">
							<h3>
								{Translation.translateStaticText(
									"jobseekerProfileSkillsHeading",
									"Skills & Education"
								)}
							</h3>
							<hr className="mbr-bg-white" />
						</div>
						<div className="help-menu">
							<ul className="help-toolbar">
								<li
									dangerouslySetInnerHTML={{
										__html: Translation.translateStaticText(
											"jobseekerProfileSkillsHelper",
											`You can skip the autocomplete feature and <strong>Create</strong> (add) your own Skill or School / University by typing the name and selecting <strong>Create "name"</strong>. <strong>It is recommended to choose from the autocomplete names whenever possible.</strong>`
										)
									}}
								/>
							</ul>
						</div>
						<div data-for="skills">
							<div className="form-group">
								<Skills
									mode="edit"
									addSkill={this.addSkill}
									approvedSkills={approvedSkills}
									value={skills}
									placeholder={Translation.translateField(
										"jobseekerProfileSkillsPLaceholder",
										"Your Skills..."
									)}
								/>
							</div>
						</div>
						<EducationForm
							education={education}
							addEducation={this.addEducation}
							removeEducation={this.removeEducation}
							handleChange={this.handleEducationInputFields}
							handleAutocompleteChange={
								this.handleEducationAutocompleteChange
							}
						/>
						<div className="align-left mbr-white">
							<h3 className="d-block">
								{Translation.translateStaticText(
									"changePasswordHeading",
									"Change Your Password"
								)}{" "}
								<HelperTooltip
									title={Translation.translateStaticText(
										"changePasswordHelperTooltip",
										"Be careful, by changing your password you won't be able to login with your previous password. The new password must match and have 1 Capital Letter, 1 Small Case Letter, 1 Special Symbol and 1 Number"
									)}
									mobileClassName="display-7"
								>
									<HelpOutlinedIcon
										classes={{
											root: "header-tooltip-icon"
										}}
									/>
								</HelperTooltip>
							</h3>
							<hr className="mbr-bg-white" />
						</div>
						<Box
							border={1}
							borderColor="red"
							classes={{ root: "border-box dashed" }}
						>
							<div data-for="current_password">
								<div className="form-group">
									<FloatingLabelInput
										onChange={this.handleChange(
											"currentPassword"
										)}
										type="password"
										className="form-control px-3"
										name="abc_current_password"
										data-form-field="current_password"
										placeholder={Translation.translateField(
											"currentPassword",
											"Current Password"
										)}
										id="current_password"
										value={currentPassword}
										autoComplete="off"
									/>
								</div>
							</div>
							<div>
								<div className="form-group">
									<FloatingLabelInput
										onChange={this.handleChange("password")}
										type="password"
										className="form-control px-3"
										name="abc_password"
										data-form-field="password"
										placeholder={Translation.translateField(
											"newPassword",
											"New Password"
										)}
										id="password"
										value={password}
										autoComplete="new-password"
									/>
								</div>
							</div>
							<div data-for="confirm_password">
								<div className="form-group">
									<FloatingLabelInput
										onChange={this.handleChange(
											"confirmPassword"
										)}
										type="password"
										className="form-control px-3"
										name="abc_confirm_password"
										data-form-field="confirm_password"
										placeholder={Translation.translateField(
											"newPasswordConfirmation",
											"Confirm Password"
										)}
										id="confirm_password"
										value={confirmPassword}
									/>
								</div>
							</div>
						</Box>
						<div className="container">
							<div className="row">
								<div className="col-6 align-right">
									<span className="input-group-btn">
										<Button
											onClick={() => {
												this.onSubmit();
											}}
											className={
												"btn btn-" +
												(submited
													? "primary"
													: "secondary") +
												" btn-form display-4"
											}
											disabled={submited}
										>
											{Translation.translateField(
												"saveButton",
												"Save"
											)}
										</Button>
									</span>
								</div>
								<div className="col-6 align-left">
									<span className="input-group-btn">
										<Button
											onClick={() => {
												this.onPreview();
											}}
											className={
												"btn btn-secondary btn-form display-4"
											}
										>
											{Translation.translateField(
												"profilePreviewButton",
												"Profile Preview"
											)}
										</Button>
									</span>
								</div>
							</div>
						</div>
					</form>
				</div>
				<SweetAlert
					warning
					show={preview && modifications}
					showCancel
					confirmBtnText={Translation.translateField(
						"continueButton",
						"Continue"
					)}
					confirmBtnBsStyle="primary"
					cancelBtnBsStyle="default"
					cancelBtnText={Translation.translateField(
						"cancelButton",
						"Cancel"
					)}
					title={Translation.translateStaticText(
						"jobseekerSaveWarningTitle",
						"You may lose your changes!"
					)}
					onConfirm={() => this.props.redirect(this.viewRoute)}
					onCancel={this.cancelPreview}
					onClose={this.cancelPreview}
				>
					{Translation.translateStaticText(
						"jobseekerSaveWarning",
						"Please save before continuing!"
					)}
				</SweetAlert>
			</div>
		);
	}
}

export default Edit;
