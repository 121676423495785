import React, { Component } from "react";
import AboutUs from "../sections/AboutUs";
import Page from "../Page";
import { setTitle } from "../../pageOptions";
import Translation from '../../utils/Translation';
class AboutUsPage extends Component {
  componentWillMount() {
    setTitle(Translation.translateStaticText("aboutUsTitle", "About us"));
  }

  render() {
    return (
      <Page location={this.props.location}>
        <AboutUs />
      </Page>
    );
  }
}

export default AboutUsPage;
