import React from "react";
import Translation from "../../../utils/Translation";
import { languages } from "../../../config";
import ENTermsAndConditionsJobseekersPage from './ENTermsAndConditionsJobseekersPage';
import BGTermsAndConditionsJobseekersPage from './BGTermsAndConditionsJobseekersPage';

export default function JobseekerTermsAndConditions(props) {
	const getTemplate = lang => {
		switch (lang) {
			case languages.english:
				return <ENTermsAndConditionsJobseekersPage />;
			case languages.bulgarian:
				return <BGTermsAndConditionsJobseekersPage />;
			case languages.german:
				//TODO implement german langauge ToS
				return <ENTermsAndConditionsJobseekersPage />;
			default:
				return <ENTermsAndConditionsJobseekersPage />;
		}
	};

	return getTemplate(Translation.getLanguage());
}
