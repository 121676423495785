export default {
	en: {
		EUR: "EUR",
		BGN: "BGN",
		USD: "USD",
	},
	bg: {
		EUR: "Евро",
		BGN: "Лева",
		USD: "Долари"
	},
	de: {}
};
