function capitalize(string) {
	if (!isString(string)) return string;
	return string.charAt(0).toUpperCase() + string.slice(1);
}

function capitalizeAllWords(string) {
	return string
		.toLowerCase()
		.split(" ")
		.map(s => s.charAt(0).toUpperCase() + s.substring(1))
		.join(" ");
}

function splitCamelCase(camelCaseString) {
	return camelCaseString.replace(/([a-z])([A-Z])/g, "$1 $2");
}

function isString(string) {
	return (
		string !== undefined &&
		string !== null &&
		typeof string === "string" &&
		string !== ""
	);
}

export { capitalize, isString, capitalizeAllWords, splitCamelCase };
