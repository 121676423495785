import React from "react";
import Button from "@material-ui/core/Button";

import FloatingLabelInput from "../form_helpers/FloatingLabelInput";
import Validator from "../../utils/Validator";
import manager from "../../Manager";
import { usertype, frontend } from "../../config";
import Translation from "../../utils/Translation";
import PasswordTooltip from "../form_helpers/PasswordTooltip";
import TermsOfServiceAcceptance from "./TermsOfServiceAcceptance";
import GDPRAcceptance from "./GDPRAcceptance";

class Signup extends React.Component {
    static contextType = manager;

    state = {
        type: usertype.company,
        gdpr_agree: 0,
        terms_agree: 0,
        done: false,
        form: {
            email: "",
            company_name: "",
            website: "",
            phone: "",
            contact_person: "",
            gdpr: "",
            password: "",
            password_confirmation: "",
            name: "",
            submited: false
        }
    };

    componentWillMount() {
        this.context.getAuthManager().pageRestriction({ logged: false });
    }

    toggleGDPRAgreeCheckBox = () => {
        this.setState({
            ...this.state,
            gdpr_agree: this.state.gdpr_agree === 0 ? 1 : 0,
            submited: false
        });
    };

    toggleTermsAgreeCheckBox = () => {
        this.setState({
            ...this.state,
            terms_agree: this.state.terms_agree === 0 ? 1 : 0,
            submited: false
        });
    };

    handeChange = name => ({ target: { value } }) => {
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                [name]: value,
                submited: false
            }
        });
    };

    handleKeyPress = event => {
        if (event.key === "Enter") {
            this.onSubmit(this.props.type);
        }
    };

    getRequestForm(type) {
        if (type === usertype.company) {
            return {
                employee_name: this.state.form.contact_person,
                email: this.state.form.email,
                phone: this.state.form.phone,
                password: this.state.form.password,
                password_confirmation: this.state.form.password_confirmation,
                company_name: this.state.form.company_name,
                company_email: this.state.form.email,
                website: this.state.form.website,
                gdpr: this.state.form.gdpr
            };
        } else if (type === usertype.jobseeker) {
            return {
                jobseeker_name: this.state.form.name,
                email: this.state.form.email,
                phone: this.state.form.phone,
                password: this.state.form.password,
                password_confirmation: this.state.form.password_confirmation
            };
        }
    }

    onSubmit = type => {
        const component = this;
        component.setState({
            ...component.state,
            form: { ...component.state.form, submited: true }
        });
        Validator.validate(
            component,
            [
                {
                    name: "equal",
                    value: [
                        this.state.form.password,
                        this.state.form.password_confirmation
                    ],
                    errorCode: 2007
                },
                {
                    name: "checkboxChecked",
                    value: this.state.terms_agree,
                    errorCode: 2010
                },
                {
                    name: "checkboxChecked",
                    value: this.state.gdpr_agree,
                    errorCode: 2011
                }
            ],
            valid => {
                if (!valid) {
                    component.setState({
                        ...component.state,
                        form: { ...component.state.form, submited: false }
                    });
                    return;
                }
                component.context
                    .getAuthManager()
                    .register(component, type, component.getRequestForm(type), {
                        onSuccess: {
                            toast: false,
                            state: {
                                ...component.state,
                                done: true,
                                agree: 0,
                                form: {
                                    email: "",
                                    company_name: "",
                                    website: "",
                                    phone: "",
                                    contact_person: "",
                                    gdpr: "",
                                    password: "",
                                    password_confirmation: "",
                                    name: "",
                                    submited: false
                                },
                                gdpr_agree: 0,
                                terms_agree: 0
                            }
                        },
                        onError: {
                            state: {
                                ...component.state,
                                gdpr_agree: 0,
                                terms_agree: 0,
                                form: {
                                    ...component.state.form,
                                    submited: false
                                }
                            }
                        }
                    });
            }
        );
    };

    render() {
        if (this.state.done) {
            return (
                <section
                    className={
                        "cid-rgbYhVM41a mbr-fullscreen " +
                        this.props.type +
                        "Background mbr-parallax-background "
                    }
                    id="header15-b"
                >
                    <div
                        className="mbr-overlay"
                        style={{
                            opacity: 0.5,
                            backgroundColor: "rgb(7, 59, 76)"
                        }}
                    />
                    <div
                        className="container align-right"
                        style={{ marginTop: "50px" }}
                    >
                        <div className="row">
                            <div className="mbr-white col-lg-8 col-md-7 content-container">
                                <h1 className="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-2">
                                    {Translation.translateStaticText(
                                        "createNewAccount",
                                        "Create New Account"
                                    )}
                                </h1>
                                <p className="mbr-text pb-3 mbr-fonts-style display-5">
                                    {Translation.translateStaticText(
                                        "checkEmail",
                                        "Check your email."
                                    )}
                                </p>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <div className="form-container">
                                    <div
                                        className="media-container-column"
                                        data-form-type="formoid"
                                        style={{ color: "gold" }}
                                    >
                                        {Translation.translateMessage(
                                            "Check for email",
                                            "Registration successful. An email validation link has been sent to the provided email address."
                                        )}
                                        <br />
                                        <br />
                                        <span className="input-group-btn">
                                            <Button
                                                onClick={() => {
                                                    this.setState({
                                                        ...this.state,
                                                        done: false
                                                    });
                                                }}
                                                className="btn btn-secondary btn-form display-4"
                                            >
                                                {Translation.translateField(
                                                    "backButton",
                                                    "Back"
                                                )}
                                            </Button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
        }
        if (this.props.type === usertype.company) {
            const {
                form: {
                    email,
                    company_name,
                    website,
                    phone,
                    contact_person,
                    gdpr,
                    password,
                    password_confirmation
                },
                gdpr_agree,
                terms_agree
            } = this.state;
            return (
                <section
                    className="cid-rgbYhVM41a mbr-fullscreen companyBackground mbr-parallax-background"
                    id="header15-b"
                >
                    <div
                        className="mbr-overlay"
                        style={{
                            opacity: 0.5,
                            backgroundColor: "rgb(7, 59, 76)"
                        }}
                    />
                    <div
                        className="container align-right"
                        style={{ marginTop: "50px" }}
                    >
                        <div className="row">
                            <div className="mbr-white col-lg-8 col-md-7 content-container">
                                <h2 className="login-sign-up-employers">
                                    {Translation.translateStaticText(
                                        "companyRegistrationHeading",
                                        "EMPLOYERS"
                                    )}
                                </h2>
                                <h1 className="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-2">
                                    {Translation.translateStaticText(
                                        "createNewAccount",
                                        "Create New Account"
                                    )}
                                </h1>
                                <p className="mbr-text pb-3 mbr-fonts-style display-5">
                                    {" "}
                                    {Translation.translateStaticText(
                                        "companyRegistrationHeadlight",
                                        "Spend a Мinute to Register so You Can Be Approached by Professionals"
                                    )}
                                </p>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <div className="form-container">
                                    <div
                                        className="media-container-column"
                                        data-form-type="formoid"
                                    >
                                        <form className="mbr-form">
                                            <input
                                                type="hidden"
                                                name="email"
                                                data-form-email="true"
                                                value="F69B3NcZe0l3wRLV5UF5Og6P1j1i3UcH9UDLW3cDpMcZoe8HbiWdkLSGqC6PhwHMrVoN5MwD7MMLXUO8aYnCwxS96h63iWLWDbrb3wllJpcPzN87pJFAVT6lEwlTnpqt"
                                            />

                                            <div data-for="company_name">
                                                <div className="form-group">
                                                    <FloatingLabelInput
                                                        onKeyPress={
                                                            this.handleKeyPress
                                                        }
                                                        onChange={this.handeChange(
                                                            "company_name"
                                                        )}
                                                        type="text"
                                                        className="form-control px-3"
                                                        name="company_name"
                                                        data-form-field="Company Name"
                                                        placeholder={Translation.translateField(
                                                            "companyName",
                                                            "Company Name"
                                                        )}
                                                        required=""
                                                        id="company_name-header15-b"
                                                        value={company_name}
                                                    />
                                                </div>
                                            </div>
                                            <div data-for="email">
                                                <div className="form-group">
                                                    <FloatingLabelInput
                                                        onKeyPress={
                                                            this.handleKeyPress
                                                        }
                                                        onChange={this.handeChange(
                                                            "email"
                                                        )}
                                                        type="text"
                                                        className="form-control px-3"
                                                        name="email"
                                                        data-form-field="Email"
                                                        placeholder={Translation.translateField(
                                                            "email",
                                                            "Email"
                                                        )}
                                                        required=""
                                                        id="email-header15-b"
                                                        value={email}
                                                    />
                                                </div>
                                            </div>
                                            <div data-for="website">
                                                <div className="form-group">
                                                    <FloatingLabelInput
                                                        onKeyPress={
                                                            this.handleKeyPress
                                                        }
                                                        onChange={this.handeChange(
                                                            "website"
                                                        )}
                                                        type="text"
                                                        className="form-control px-3"
                                                        name="website"
                                                        data-form-field="Website"
                                                        placeholder={Translation.translateField(
                                                            "website",
                                                            "Website"
                                                        )}
                                                        required=""
                                                        id="website-header15-b"
                                                        value={website}
                                                    />
                                                </div>
                                            </div>
                                            <div data-for="phone">
                                                <div className="form-group">
                                                    <FloatingLabelInput
                                                        onKeyPress={
                                                            this.handleKeyPress
                                                        }
                                                        onChange={this.handeChange(
                                                            "phone"
                                                        )}
                                                        type="text"
                                                        className="form-control px-3"
                                                        name="phone"
                                                        data-form-field="Phone"
                                                        placeholder={Translation.translateField(
                                                            "phone",
                                                            "Phone"
                                                        )}
                                                        id="phone-header15-b"
                                                        value={phone}
                                                    />
                                                </div>
                                            </div>
                                            <div data-for="contact_person">
                                                <div className="form-group">
                                                    <FloatingLabelInput
                                                        onKeyPress={
                                                            this.handleKeyPress
                                                        }
                                                        onChange={this.handeChange(
                                                            "contact_person"
                                                        )}
                                                        type="text"
                                                        className="form-control px-3"
                                                        name="contact_person"
                                                        data-form-field="Contact person name"
                                                        placeholder={Translation.translateField(
                                                            "contactPerson",
                                                            "Contact Person"
                                                        )}
                                                        id="contact_person-header15-b"
                                                        value={contact_person}
                                                    />
                                                </div>
                                            </div>
                                            <div data-for="compay_gdpr">
                                                <div className="form-group">
                                                    <FloatingLabelInput
                                                        onKeyPress={
                                                            this.handleKeyPress
                                                        }
                                                        onChange={this.handeChange(
                                                            "gdpr"
                                                        )}
                                                        type="text"
                                                        className="form-control px-3"
                                                        name="compay_gdpr"
                                                        data-form-field="Compay_gdpr"
                                                        placeholder={Translation.translateField(
                                                            "companyGdpr",
                                                            "GDPR (provide a link to your GDPR)"
                                                        )}
                                                        required=""
                                                        id="Compay_gdpr-header15-b"
                                                        value={gdpr}
                                                    />
                                                </div>
                                            </div>
                                            <div data-for="password">
                                                <div className="form-group">
                                                    <FloatingLabelInput
                                                        onKeyPress={
                                                            this.handleKeyPress
                                                        }
                                                        onChange={this.handeChange(
                                                            "password"
                                                        )}
                                                        type="password"
                                                        className="form-control px-3"
                                                        name="password"
                                                        data-form-field="Password"
                                                        placeholder={Translation.translateField(
                                                            "password",
                                                            "Password"
                                                        )}
                                                        required=""
                                                        id="password-header15-b"
                                                        value={password}
                                                        autoComplete="new-password"
                                                        focusInfo={
                                                            <PasswordTooltip />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div data-for="password_confirmation">
                                                <div className="form-group">
                                                    <FloatingLabelInput
                                                        onKeyPress={
                                                            this.handleKeyPress
                                                        }
                                                        onChange={this.handeChange(
                                                            "password_confirmation"
                                                        )}
                                                        type="password"
                                                        className="form-control px-3"
                                                        name="password_confirmation"
                                                        data-form-field="password_confirmation"
                                                        placeholder={Translation.translateField(
                                                            "passwordConfirmation",
                                                            "Confirm Password"
                                                        )}
                                                        required=""
                                                        id="password_confirmation-header15-b"
                                                        value={
                                                            password_confirmation
                                                        }
                                                        autoComplete="new-password"
                                                    />
                                                </div>
                                            </div>

                                            <div data-for="terms_agree">
                                                <div
                                                    className="form-group"
                                                    style={{
                                                        color: "white",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    <input
                                                        onChange={() => {
                                                            this.toggleTermsAgreeCheckBox();
                                                        }}
                                                        type="checkbox"
                                                        name="terms_agree"
                                                        data-form-field="terms_agree"
                                                        id="terms_agree"
                                                        checked={Boolean(
                                                            terms_agree
                                                        )}
                                                    />{" "}
                                                    <TermsOfServiceAcceptance
                                                        location={
                                                            frontend.route
                                                                .terms_and_conditions
                                                                .employers
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div data-for="gdpr_agree">
                                                <div
                                                    className="form-group"
                                                    style={{
                                                        color: "white",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    <input
                                                        onChange={() => {
                                                            this.toggleGDPRAgreeCheckBox();
                                                        }}
                                                        type="checkbox"
                                                        name="gdpr_agree"
                                                        data-form-field="gdpr_agree"
                                                        id="gdpr_agree"
                                                        checked={gdpr_agree}
                                                    />{" "}
                                                    <GDPRAcceptance
                                                        location={
                                                            frontend.route
                                                                .gdpr_employers
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <span className="input-group-btn">
                                                <Button
                                                    onClick={() => {
                                                        this.onSubmit(
                                                            this.props.type
                                                        );
                                                    }}
                                                    className="btn btn-secondary btn-form display-4"
                                                >
                                                    {Translation.translateField(
                                                        "signUpButton",
                                                        "Sign Up"
                                                    )}
                                                </Button>
                                            </span>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="mbr-arrow hidden-sm-down"
                        aria-hidden="true"
                    >
                        <a href="#next">
                            <i className="mbri-down mbr-iconfont" />
                        </a>
                    </div>
                </section>
            );
        } else if (this.props.type === usertype.jobseeker) {
            const {
                form: { email, phone, name, password, password_confirmation },
                gdpr_agree,
                terms_agree
            } = this.state;
            return (
                <section
                    className="cid-rg5fGgmhmj mbr-fullscreen jobseekerBackground mbr-parallax-background"
                    id="header15-3"
                >
                    <div
                        className="mbr-overlay"
                        style={{
                            opacity: 0.5,
                            backgroundColor: "rgb(7, 59, 76)"
                        }}
                    />
                    <div
                        className="container align-right"
                        style={{ marginTop: "50px" }}
                    >
                        <div className="row">
                            <div className="mbr-white col-lg-8 col-md-7 content-container">
                                <h2 className="login-sign-up-jobseekers">
                                    {Translation.translateStaticText(
                                        "jobseekerRegistrationHeading",
                                        "JOBSEEKERS"
                                    )}
                                </h2>
                                <h1 className="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-2">
                                    {Translation.translateStaticText(
                                        "createNewAccount",
                                        "Create New Account"
                                    )}
                                </h1>
                                <p className="mbr-text pb-3 mbr-fonts-style display-5">
                                    {Translation.translateStaticText(
                                        "jobseekerRegistrationHeadlight",
                                        "Allow a Minute to Register and Explore Careers Boost Opportunities"
                                    )}
                                </p>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <div className="form-container">
                                    <div
                                        className="media-container-column"
                                        data-form-type="formoid"
                                    >
                                        <form className="mbr-form">
                                            <input
                                                type="hidden"
                                                name="email"
                                                data-form-email="true"
                                                value="t00ENcxonzmNmuh5hi4SyezQcHgIWZxKl2x9gI+yWi4fufnGk3XVHz/R5ZeppcxVk/AfiNB6pnXuDM9Zra5tEyaZlr0tnIsSKiLtSiWIOf03f3ZUPDbzMou4BBx2I5ln"
                                            />

                                            <div data-for="email">
                                                <div className="form-group">
                                                    <FloatingLabelInput
                                                        onKeyPress={
                                                            this.handleKeyPress
                                                        }
                                                        onChange={this.handeChange(
                                                            "email"
                                                        )}
                                                        type="text"
                                                        className="form-control px-3"
                                                        name="email"
                                                        data-form-field="email"
                                                        placeholder={Translation.translateField(
                                                            "email",
                                                            "Email"
                                                        )}
                                                        required=""
                                                        id="email-header15-3"
                                                        value={email}
                                                    />
                                                </div>
                                            </div>
                                            <div data-for="Name">
                                                <div className="form-group">
                                                    <FloatingLabelInput
                                                        onKeyPress={
                                                            this.handleKeyPress
                                                        }
                                                        onChange={this.handeChange(
                                                            "name"
                                                        )}
                                                        type="text"
                                                        className="form-control px-3"
                                                        name="name"
                                                        data-form-field="name"
                                                        placeholder={Translation.translateField(
                                                            "fullName",
                                                            "Full Name"
                                                        )}
                                                        id="name-header15-3"
                                                        value={name}
                                                    />
                                                </div>
                                            </div>
                                            <div data-for="phone">
                                                <div className="form-group">
                                                    <FloatingLabelInput
                                                        onKeyPress={
                                                            this.handleKeyPress
                                                        }
                                                        onChange={this.handeChange(
                                                            "phone"
                                                        )}
                                                        type="text"
                                                        className="form-control px-3"
                                                        name="phone"
                                                        data-form-field="Phone"
                                                        placeholder={Translation.translateField(
                                                            "phone",
                                                            "Phone"
                                                        )}
                                                        id="phone-header15-3"
                                                        value={phone}
                                                    />
                                                </div>
                                            </div>
                                            <div data-for="password">
                                                <div className="form-group">
                                                    <FloatingLabelInput
                                                        onKeyPress={
                                                            this.handleKeyPress
                                                        }
                                                        onChange={this.handeChange(
                                                            "password"
                                                        )}
                                                        type="password"
                                                        className="form-control px-3"
                                                        name="password"
                                                        data-form-field="Password"
                                                        placeholder={Translation.translateField(
                                                            "password",
                                                            "Password"
                                                        )}
                                                        id="password-header15-3"
                                                        value={password}
                                                        focusInfo={
                                                            <PasswordTooltip />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div data-for="password_confirmation">
                                                <div className="form-group">
                                                    <FloatingLabelInput
                                                        onKeyPress={
                                                            this.handleKeyPress
                                                        }
                                                        onChange={this.handeChange(
                                                            "password_confirmation"
                                                        )}
                                                        type="password"
                                                        className="form-control px-3"
                                                        name="password_confirmation"
                                                        data-form-field="password_confirmation"
                                                        placeholder={Translation.translateField(
                                                            "passwordConfirmation",
                                                            "Confirm Password"
                                                        )}
                                                        id="password_confirmation-header15-3"
                                                        value={
                                                            password_confirmation
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div data-for="terms_agree">
                                                <div
                                                    className="form-group"
                                                    style={{
                                                        color: "white",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    <input
                                                        onChange={() => {
                                                            this.toggleTermsAgreeCheckBox();
                                                        }}
                                                        type="checkbox"
                                                        name="terms_agree"
                                                        data-form-field="terms_agree"
                                                        id="terms_agree"
                                                        checked={terms_agree}
                                                    />{" "}
                                                    <TermsOfServiceAcceptance
                                                        location={
                                                            frontend.route
                                                                .terms_and_conditions
                                                                .jobseekers
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div data-for="gdpr_agree">
                                                <div
                                                    className="form-group"
                                                    style={{
                                                        color: "white",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    <input
                                                        onChange={() => {
                                                            this.toggleGDPRAgreeCheckBox();
                                                        }}
                                                        type="checkbox"
                                                        name="gdpr_agree"
                                                        data-form-field="gdpr_agree"
                                                        id="gdpr_agree"
                                                        checked={gdpr_agree}
                                                    />{" "}
                                                    <GDPRAcceptance
                                                        location={
                                                            frontend.route
                                                                .gdpr_candidates
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <span className="input-group-btn">
                                                <Button
                                                    onClick={() => {
                                                        this.onSubmit(
                                                            this.props.type
                                                        );
                                                    }}
                                                    className={
                                                        "btn btn-" +
                                                        (this.state.form
                                                            .submited
                                                            ? "primary"
                                                            : "secondary") +
                                                        " btn-form display-4"
                                                    }
                                                    disabled={
                                                        this.state.form.submited
                                                    }
                                                >
                                                    {Translation.translateField(
                                                        "signUpButton",
                                                        "Sign Up"
                                                    )}
                                                </Button>
                                            </span>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="mbr-arrow hidden-sm-down"
                        aria-hidden="true"
                    >
                        <a href="#next">
                            <i className="mbri-down mbr-iconfont" />
                        </a>
                    </div>
                </section>
            );
        }
    }
}

export default Signup;
