import locations from "../vars/locations";
import universities from "../vars/universities";
import visibilityLevels from "../vars/visibility";
import {
	positionAvailabilityTypes,
	positionCategories,
	educationLevels,
	positionQualifications
} from "../vars/positions";
import booleanValues from "../vars/bool";
import currency from '../vars/currency';
export default {
	...universities["bg"],
	...locations["bg"],
	...visibilityLevels["bg"],
	...educationLevels["bg"],
	...positionCategories["bg"],
	...positionQualifications["bg"],
	...positionAvailabilityTypes["bg"],
	...booleanValues["bg"],
	...currency["bg"]
};
