import locations from "../vars/locations";
import universities from "../vars/universities";
import visibilityLevels from "../vars/visibility";
import {
	positionAvailabilityTypes,
	positionCategories,
	educationLevels,
	positionQualifications
} from "../vars/positions";
import booleanValues from "../vars/bool";
import currency from '../vars/currency';
export default {
	...universities["de"],
	...locations["de"],
	...visibilityLevels["de"],
	...educationLevels["de"],
	...positionCategories["de"],
	...positionQualifications["de"],
	...positionAvailabilityTypes["de"],
	...booleanValues["de"],
	...currency["de"]
};
