import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import manager from "../../../Manager";
import { usertype, frontend, companytype } from "../../../config";
import ApplicationList from "./ApplicationList";
import Translation from "../../../utils/Translation";
import { isBoolean } from "../../../utils/booleanUtils";
import ToastHandler from "../../../utils/ToastHandler";
import CompanyGDPRAcceptance from "./CompanyGDPRAcceptance";

class View extends Component {
  static contextType = manager;

  static defaultProps = {
    back: false,
    title: undefined,
    company: undefined
  };

  checkedCompany = false;
  checkedUser = false;
  loggedCompany = false;
  loggedJobseeker = false;
  hasApplied = false;
  hasContactEntity = false;

  state = {
    valid: false,
    gdpr_agree: 0,
    position: {},
    company: {},
    currency: {},
    position_category: {},
    position_locations: [],
    salary: {},
    loaded: false,
    applied: {
      subbmited: false
    }
  };

  componentWillMount() {
    window.scrollTo(0, 0);
    this.context.getAuthManager().pageRestriction({ logged: true });
    const component = this;
    if (component.props.title && component.props.company) {
      component.context
        .getPositionManager()
        .getPosition(
          component,
          component.props.title,
          component.props.company,
          {
            onSuccess: {
              callback: positionData => {
                component.rememberData(positionData);
                component.context.getAuthManager().getLoggedUser(component, {
                  onFinish: {
                    callback: user => {
                      if (user && user.user_type === usertype.company) {
                        component.context
                          .getCompanyManager()
                          .getLoggedCompany(component, {
                            onFinish: {
                              callback: company => {
                                component.loggedCompany =
                                  company &&
                                  company.company_id ===
                                    component.state.position.company_id
                                    ? company
                                    : false;
                                component.checkedCompany = true;
                                component.setState({ ...component });
                              }
                            }
                          });
                      } else {
                        component.checkedCompany = true;
                        this.context
                          .getWSManager()
                          .hasContactEntity(
                            component,
                            component.state.position.company_id,
                            {
                              onError: {
                                toast: false
                              },
                              onSuccess: {
                                toast: false,
                                callback: dataReturn => {
                                  component.hasContactEntity =
                                    dataReturn.successful &&
                                    /true/i.test(dataReturn.message);
                                }
                              }
                            }
                          );
                      }
                      component.loggedJobseeker =
                        user && user.user_type === usertype.jobseeker
                          ? user
                          : false;
                      if (component.loggedJobseeker) {
                        component.context
                          .getPositionManager()
                          .hasApplied(
                            component,
                            component.state.position.position_id,
                            {
                              onFinish: {
                                callback: hasApplied => {
                                  component.hasApplied =
                                    isBoolean(hasApplied) && hasApplied
                                      ? true
                                      : false;
                                  component.checkedUser = true;
                                  component.setState({ ...component });
                                }
                              }
                            }
                          );
                      } else {
                        component.checkedUser = true;
                        component.setState({ ...component });
                      }
                    }
                  }
                });
              }
            },
            onError: {
              state: { ...component.state, loaded: true },
              callback: (data, code) => {
                if (code === "11102") {
                  window.location = frontend.route.error;
                }
                return { toast: false };
              }
            }
          }
        );
    }
  }

  rememberData(data) {
    this.setState({
      ...this.state,
      valid: true,
      position: {
        ...data.position,
        education_level: data.position.education_level_id
          ? data.education_level.level
          : ""
      },
      company: data.company,
      currency:
        data.currency && data.currency.code && data.currency.code !== null
          ? data.currency.code
          : undefined,
      position_locations: data.position_locations,
      salary: data.salary,
      position_category: data.position_category,
      locations: data.locations,
      loaded: true
    });
  }

  onApply() {
    const component = this;
    if (this.state.company.gdpr && this.state.gdpr_agree !== 1) {
      ToastHandler.showError(
        Translation.translateMessage("MustAgreeWithCompanyGDPR")
      );
      return;
    }
    component.setState({
      ...component.state,
      applied: {
        ...component.state.applied,
        submited: true
      }
    });
    component.context
      .getPositionManager()
      .apply(component, component.state.position.position_id, {
        onSuccess: { message: Translation.translateMessage("Applied") },
        onFinish: {
          callback: hasApplied => {
            component.hasApplied =
              isBoolean(hasApplied) && hasApplied ? true : false;
            component.setState({
              ...component.state,
              applied: {
                ...component.state.applied,
                submited: false
              }
            });
          }
        }
      });
  }

  requestCorrespondence() {
    if (this.loggedCompany) {
      ToastHandler.showInfo(
        Translation.translateErrorMessage(
          "cannotSelfCorrespondance",
          "Can not request correspondence with yourself."
        )
      );
      return;
    } else if (!this.loggedJobseeker) {
      ToastHandler.showInfo(
        Translation.translateErrorMessage(
          "cannotCorrespondWithAnotherCompany",
          "Can not request correspondence with another company."
        )
      );
      return;
    } else if (this.hasContactEntity) {
      ToastHandler.showInfo(
        Translation.translateErrorMessage(
          "correspondenceAlreadyAvailable",
          "Contact request already sent or user has blocked it."
        )
      );
      return;
    }
    //TODO: Modal with choices for employes to make contact with
    //for now there is only one, so no problem
    const component = this;
    this.context.getWSManager().requestContact(
      component,
      { user: component.state.position.company_id, isCompany: true },
      {
        onSuccess: {
          message: Translation.translateMessage(
            "contactRequestSent",
            "Request sent"
          )
        }
      }
    );
  }

  toggleGDPRAgreeCheckBox() {
    this.setState({
      ...this.state,
      gdpr_agree: this.state.gdpr_agree === 0 ? 1 : 0
    });
  }

  render() {
    if (!this.state.loaded || !this.checkedCompany || !this.checkedUser) {
      //console.log(`Loading... positions: ${this.state.loaded?"Y":"N"}, checkedCompany: ${this.state.checkedCompany?"Y":"N"}, checkedUser: ${this.state.checkedUser?"Y":"N"}`);
      return null;
    } else {
      //console.log(`Loading... positions: ${this.state.loaded?"Y":"N"}, checkedCompany: ${this.state.checkedCompany?"Y":"N"}, checkedUser: ${this.state.checkedUser?"Y":"N"}`);
    }
    let back = "";
    if (this.props.back) {
      back = (
        <span className="input-group-btn">
          <Button
            onClick={() => {
              this.props.back();
            }}
            className="btn btn-secondary btn-form display-4 page-menu"
          >
            {Translation.translateField("backButton", "Back")}
          </Button>
        </span>
      );
    }
    if (!this.state.valid) {
      return (
        <section
          className="counters6 counters cid-rg5irxrHy2 visible full-visible"
          id="counters6-6"
        >
          <div className="container pt-4 mt-2">
            <div className="row">
              <div className="container align-center">
                {back}
                <p className="mbr-text pb-3 mbr-fonts-style display-5" />
                {Translation.translateStaticText(
                  "invalidPosition",
                  "Invalid position"
                )}
              </div>
            </div>
          </div>
        </section>
      );
    }
    const {
      company: { website, description, gdpr, company_name },
      position: {
        title,
        responsabilities,
        requirements,
        offer,
        education_level,
        speciality,
        valid_from,
        valid_to,
        years_of_experience,
        salary_from,
        salary_to,
        employment_time,
        remote_work,
        freelance
      },
      currency,
      position_category: { name },
      position_locations: { locations }
    } = this.state;

    let valid_from_value = undefined;
    let valid_to_value = undefined;
    if (valid_from && valid_from !== "0000-00-00 00:00:00") {
      valid_from_value = new Date(valid_from).toLocaleDateString("en-DE");
    }
    if (valid_to && valid_to !== "0000-00-00 00:00:00") {
      valid_to_value = new Date(valid_to).toLocaleDateString("en-DE");
    }
    let applicationButton = null;
    if (this.loggedJobseeker) {
      applicationButton = (
        <div className="container align-center">
          {!gdpr ? null : (
            <div>
              <input
                onChange={() => {
                  this.toggleGDPRAgreeCheckBox();
                }}
                type="checkbox"
                name="gdpr_agree"
                data-form-field="gdpr_agree"
                id="gdpr_agree"
              />{" "}
              <CompanyGDPRAcceptance
                location={
                  gdpr.startsWith("http://") || gdpr.startsWith("https://")
                    ? gdpr
                    : "http://" + gdpr
                }
              />
            </div>
          )}
          <span className="input-group-btn">
            <Button
              onClick={() => {
                this.onApply();
              }}
              className={
                "btn btn-" +
                (this.state.applied.submited || this.hasApplied
                  ? "primary"
                  : "secondary") +
                " btn-form display-4"
              }
              disabled={this.state.applied.submited || this.hasApplied}
            >
              {this.hasApplied
                ? Translation.translateField("appliedButton", "Applied")
                : Translation.translateField("applyButton", "Apply")}
            </Button>
          </span>
        </div>
      );
    }
    let applicationList = null;
    if (this.loggedCompany) {
      applicationList = (
        <Fragment>
          <Grid item xs={12} className="mb-3">
            <Typography variant="h5">
              {Translation.translateField("candidatesHeading", "Candidates")}
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ApplicationList
              freeCompany={this.loggedCompany.company_type === companytype.free}
              position_id={this.state.position.position_id}
            />
          </Grid>
        </Fragment>
      );
    }
    let requestcorispondanceButton = undefined;
    if (!this.loggedCompany) {
      requestcorispondanceButton = (
        <Button
          onClick={() => {
            this.requestCorrespondence();
          }}
          className={"btn btn-secondary btn-form display-4"}
        >
          {Translation.translateField(
            "requestCorrespondenceButton",
            "Request correspondence"
          )}
        </Button>
      );
    }
    return (
      <div>
        <section
          className="counters6 counters cid-rg5irxrHy2 visible full-visible"
          id="counters6-6"
        >
          <div className="container pt-4 mt-2">
            <div className="row">
              <div className="container align-center">{back}</div>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {Translation.translateField(
                      "companyDetailsHeading",
                      "Company Details"
                    )}
                  </Typography>
                  <Divider />
                  {requestcorispondanceButton}
                </Grid>
                <Grid item xs={6}>
                  <Paper>
                    <Typography variant="caption">
                      {Translation.translateField(
                        "companyName",
                        "Company name"
                      )}
                      :
                    </Typography>
                    <Typography component="p">
                      <Link
                        to={`${
                          frontend.route.company.public_profile
                        }/${company_name}`}
                      >
                        {company_name}
                      </Link>
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper>
                    <Typography variant="caption">
                      {Translation.translateField("website", "Website")}
                    </Typography>
                    <Typography component="p">{website}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper>
                    <Typography variant="caption">
                      {Translation.translateField(
                        "companyInfoPlaceholder",
                        "Company info"
                      )}
                      :
                    </Typography>
                    <Typography component="p">{description}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {Translation.translateField(
                      "positionDetailsHeading",
                      "Position Details"
                    )}
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" align="center">
                    {title}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Paper>
                    <Typography variant="caption">
                      {Translation.translateField(
                        "responsibilitiesPlaceholder",
                        "Responsibilities"
                      )}
                      :
                    </Typography>
                    <Typography
                      component="pre"
                      classes={{ root: "position-info" }}
                    >
                      {responsabilities}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper>
                    <Typography variant="caption">
                      {Translation.translateField(
                        "requirementsPlaceholder",
                        "Requirements"
                      )}
                      :
                    </Typography>
                    <Typography
                      component="pre"
                      classes={{ root: "position-info" }}
                    >
                      {requirements}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper>
                    <Typography variant="caption">
                      {Translation.translateField("offerPlaceholder", "Offer")}:
                    </Typography>
                    <Typography
                      component="pre"
                      classes={{ root: "position-info" }}
                    >
                      {offer}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper>
                    <Typography variant="caption">
                      {Translation.translateField(
                        "jobseekerEducationLevel",
                        "Education Level"
                      )}
                      :
                    </Typography>
                    <Typography component="p">
                      {
                        Translation.getEducationLevelLabelBasedOnLanguage({
                          label: education_level,
                          value: education_level
                        }).label
                      }
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper>
                    <Typography variant="caption">
                      {Translation.translateField(
                        "jobseekerEducationSpeciality",
                        "Speciality"
                      )}
                      :
                    </Typography>
                    <Typography
                      component="pre"
                      classes={{ root: "position-info" }}
                    >
                      {speciality}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper>
                    <Typography variant="caption">
                      {Translation.translateField(
                        "validFromPlaceholder",
                        "Valid From"
                      )}
                      :
                    </Typography>
                    <Typography component="p">{valid_from_value}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper>
                    <Typography variant="caption">
                      {Translation.translateField(
                        "validToPlaceholder",
                        "Valid To"
                      )}
                      :
                    </Typography>
                    <Typography component="p">{valid_to_value}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper>
                    <Typography variant="caption">
                      {Translation.translateField(
                        "yearsOfExperiencePlaceholder",
                        "Years of Experience"
                      )}
                      :
                    </Typography>
                    <Typography component="p">{years_of_experience}</Typography>
                  </Paper>
                </Grid>
                {salary_from && (
                  <Grid item xs={6}>
                    <Paper>
                      <Typography variant="caption">
                        {Translation.translateField(
                          "salaryFromPlaceholder",
                          "Salary From"
                        )}
                        :
                      </Typography>
                      <Typography component="p">
                        {salary_from}
                        {currency
                          ? " " + Translation.translateValue(currency, currency)
                          : null}
                      </Typography>
                    </Paper>
                  </Grid>
                )}
                {salary_to && (
                  <Grid item xs={6}>
                    <Paper>
                      <Typography variant="caption">
                        {Translation.translateField(
                          "salaryToPlaceholder",
                          "Salary To"
                        )}
                        :
                      </Typography>
                      <Typography component="p">
                        {salary_to}
                        {currency
                          ? " " + Translation.translateValue(currency, currency)
                          : null}
                      </Typography>
                    </Paper>
                  </Grid>
                )}
                <Grid item xs={4}>
                  <Paper>
                    <Typography variant="caption">
                      {Translation.translateField(
                        "categoryPlaceholder",
                        "Category"
                      )}
                      :
                    </Typography>
                    <Typography component="p">{name}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper>
                    <Typography variant="caption">
                      {Translation.translateField(
                        "remoteWorkPlaceholder",
                        "Remote Work"
                      )}
                      :
                    </Typography>
                    <Typography component="p">
                      {Translation.translateValue(remote_work)}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper>
                    <Typography variant="caption">
                      {Translation.translateField(
                        "freelancePlaceholder",
                        "Freelance"
                      )}
                      :
                    </Typography>
                    <Typography component="p">
                      {Translation.translateValue(freelance)}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper>
                    <Typography variant="caption">
                      {Translation.translateField(
                        "employmentPlaceholder",
                        "Employment"
                      )}
                      :
                    </Typography>
                    <Typography component="p">
                      {Translation.translateValue(
                        employment_time,
                        (
                          employment_time.charAt(0).toUpperCase() +
                          employment_time.slice(1)
                        ).replace("_", " ")
                      )}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {Translation.translateField(
                      "locationsHeading",
                      "Locations"
                    )}
                  </Typography>
                  <Divider />
                </Grid>
                {locations.map(location => {
                  let details = this.state.locations.reduce(
                    (acumulator, loc) => {
                      if (loc.location_id === location.location_id) {
                        return {
                          city: Translation.translateValue(loc.city, loc.city),
                          country: Translation.translateValue(
                            loc.country,
                            loc.country
                          )
                        };
                      }
                      return acumulator;
                    },
                    {}
                  );
                  return (
                    <Grid key={location.location_id} item xs={4}>
                      <Paper>
                        <Typography variant="caption" display="block">
                          {Translation.translateStaticText(
                            "jobseekerCountry",
                            "Country"
                          )}
                          :{" "}
                          <Typography component="p" display="inline">
                            {details.country ? details.country : " - "}
                          </Typography>
                        </Typography>

                        <Typography variant="caption" display="block">
                          {Translation.translateStaticText(
                            "jobseekerCity",
                            "City"
                          )}
                          :{" "}
                          <Typography component="p" display="inline">
                            {details.city ? details.city : " - "}
                          </Typography>
                        </Typography>
                      </Paper>
                    </Grid>
                  );
                })}
                <Grid item xs={12}>
                  {applicationButton}
                </Grid>
              </Grid>
              {applicationList}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default View;
