import locations from "../vars/locations";
import universities from "../vars/universities";
import visibilityLevels from "../vars/visibility";
import {
	positionAvailabilityTypes,
	positionCategories,
	educationLevels,
	positionQualifications
} from "../vars/positions";
import booleanValues from "../vars/bool";
import currency from '../vars/currency';
export default {
	...universities["en"],
	...locations["en"],
	...visibilityLevels["en"],
	...educationLevels["en"],
	...positionCategories["en"],
	...positionQualifications["en"],
	...positionAvailabilityTypes["en"],
	...booleanValues["en"],
	...currency["en"],
};
