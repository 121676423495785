import React, { Component } from "react";

import Signup from "../../sections/Signup";
import CompanyHowItWorks from "../../sections/CompanyHowItWorks";
import Page from "../../Page";
import { setTitle } from "../../../pageOptions";
import manager from "../../../Manager";
import Translation from "../../../utils/Translation";

class SignupPage extends Component {
  static contextType = manager;

  componentWillMount() {
    this.context.getAuthManager().pageRestriction({ logged: false });
    setTitle(Translation.translateStaticText("signupTitle", "Signup"));
  }

  render() {
    return (
      <Page location={this.props.location}>
        <Signup type="company" />
        <CompanyHowItWorks />
      </Page>
    );
  }
}

export default SignupPage;
