import React, { Component } from "react";
import Page from "../Page";
import Translation from "../../utils/Translation";
import { frontend } from "../../config";
import { setTitle } from "../../pageOptions";

class ErrorPage extends Component {
  state = {
    message: Translation.translateErrorMessage(
      "ErrorPageDefaultMessage",
      "Page does not exist or you have no access to it."
    ),
    locationStateSet: false,
    messageSet: false
  };

  componentWillMount() {
    if (
      !this.state.locationStateSet &&
      this.props &&
      "location" in this.props &&
      "state" in this.props.location
    ) {
      this.setState({ ...this.props.location.state, locationStateSet: true });
    }
    if (
      !this.state.messageSet &&
      this.props &&
      "match" in this.props &&
      "params" in this.props.match &&
      "errorCode" in this.props.match.params
    ) {
      this.setState({
        ...this.state,
        message: Translation.translateErrorMessage(
          this.props.match.params.errorCode,
          this.props.match.params.errorCode
        ),
        messageSet: true
      });
    }
    this.props.history.replace(frontend.route.error);
    setTitle(Translation.translateStaticText("errorPageTitle", "Error"));
  }

  render() {
    return (
      <Page location={this.props.location}>
        <section
          className="mbr-fullscreen mbr-parallax-background"
          id="header2-1"
        >
          <div className="container align-center">
            <div className="row justify-content-md-center">
              <div className="col-md-10">
                <h2 className="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-1">
                  {this.state.message}
                </h2>
              </div>
            </div>
          </div>
        </section>
      </Page>
    );
  }
}

export default ErrorPage;
