import { capitalize } from "./stringUtils";
import translations from "../translations/translations";
import { languages } from "../config";
import { educationLevels } from "../translations/vars/positions";

class Translation {
    static language = undefined;
    static defaultDictionary = languages.english;
    /**
     * Get Translation string based on code
     * @param {mixed} code either string ex: "2992" or number ex: 2992 from translations file
     * @param {*} defaultValue if translations does not contain code, show this value
     */
    get(code, type, defaultValue = undefined, capitalizeFirstLetter = true) {
        // ensure code is string
        if (typeof code !== "string") {
            code = String(code);
        }
        const language = Translation.language;
        // default value
        const defaultDictionary = Translation.defaultDictionary;

        let message =
            defaultValue !== undefined
                ? defaultValue
                : `Translation -> ${language}; type -> ${type}; Code -> ${code} not found`;
        if (
            language in translations &&
            type in translations[language] &&
            code in translations[language][type]
        ) {
            message = translations[language][type][code];
        } else if (
            defaultDictionary in translations &&
            type in translations[defaultDictionary] &&
            code in translations[defaultDictionary][type]
        ) {
            message = translations[defaultDictionary][type][code];
        }
        return capitalizeFirstLetter ? capitalize(message) : message;
    }

    static setLanguage(language) {
        if (typeof language !== "string") {
            throw new Error("laguange prop must be a string");
        }
        switch (language) {
            case "en":
                Translation.language = languages.english;
                break;
            case "english":
                Translation.language = languages.english;
                break;
            case "bg":
                Translation.language = languages.bulgarian;
                break;
            case "bulgarian":
                Translation.language = languages.bulgarian;
                break;
            case "de":
                Translation.language = languages.german;
                break;
            case "deutsch":
                Translation.language = languages.german;
                break;
            default:
                throw new Error("language not supported");
        }
    }

    static getLanguage() {
        return Translation.language;
    }

    static instance = undefined;
    static translateField(
        field,
        defaultValue = undefined,
        capitalizeFirstLetter = true
    ) {
        if (Translation.instance === undefined) {
            Translation.instance = new Translation();
        }
        return Translation.instance.get(
            field,
            "fields",
            defaultValue,
            capitalizeFirstLetter
        );
    }

    static translateValue(
        value,
        defaultValue = undefined,
        capitalizeFirstLetter = true
    ) {
        if (Translation.instance === undefined) {
            Translation.instance = new Translation();
        }
        return Translation.instance.get(
            value,
            "values",
            defaultValue,
            capitalizeFirstLetter
        );
    }
    /**
     * Value exists in dictionaries other than the default
     * @param  {String} value The code of the dictionary word
     * @param  {String} type  Which dictionary to look into
     * @return {[Boolean]} True|False if the value exists in the currently selected langauge
     */
    static valueHasTranslation(value, type = "values") {
        const language = Translation.language;
        return (
            language in translations &&
            type in translations[language] &&
            value in translations[language][type]
        );
    }

    static translateErrorMessage(
        error,
        defaultValue = undefined,
        capitalizeFirstLetter = true
    ) {
        if (Translation.instance === undefined) {
            Translation.instance = new Translation();
        }
        return Translation.instance.get(
            error,
            "errors",
            defaultValue,
            capitalizeFirstLetter
        );
    }

    static translateMessage(
        message,
        defaultValue = undefined,
        capitalizeFirstLetter = true
    ) {
        if (Translation.instance === undefined) {
            Translation.instance = new Translation();
        }
        return Translation.instance.get(
            message,
            "messages",
            defaultValue,
            capitalizeFirstLetter
        );
    }

    static translateResponse(
        code,
        defaultValue = undefined,
        capitalizeFirstLetter = true
    ) {
        if (Translation.instance === undefined) {
            Translation.instance = new Translation();
        }
        return Translation.instance.get(
            code,
            "responses",
            defaultValue,
            capitalizeFirstLetter
        );
    }

    static translateStaticText(
        text,
        defaultValue = undefined,
        capitalizeFirstLetter = true
    ) {
        if (Translation.instance === undefined) {
            Translation.instance = new Translation();
        }
        return Translation.instance.get(
            text,
            "staticText",
            defaultValue,
            capitalizeFirstLetter
        );
    }

    static translateFAQ(
        faqText,
        defaultValue = undefined,
        capitalizeFirstLetter = true
    ) {
        if (Translation.instance === undefined) {
            Translation.instance = new Translation();
        }
        return Translation.instance.get(
            faqText,
            "faq",
            defaultValue,
            capitalizeFirstLetter
        );
    }

    static translateNavigation(
        navigation,
        defaultValue = undefined,
        capitalizeFirstLetter = true
    ) {
        if (Translation.instance === undefined) {
            Translation.instance = new Translation();
        }
        return Translation.instance.get(
            navigation,
            "navigation",
            defaultValue,
            capitalizeFirstLetter
        );
    }

    static translateBool(
        bool,
        defaultValue = undefined,
        capitalizeFirstLetter = true
    ) {
        if (Translation.instance === undefined) {
            Translation.instance = new Translation();
        }
        return Translation.instance.get(
            bool,
            "bool",
            defaultValue,
            capitalizeFirstLetter
        );
    }

    /**
     * Since we may have different educaiton level based on country/language
     * we filter and define a logic by which we can show some of them
     * @param  {Object: {label: {String}, value: {String}}} level Object with label/value structure
     * @return {Object: {label: {String}, value: {String}}}
     */
    static getEducationLevelLabelBasedOnLanguage(level) {
        if (Translation.valueHasTranslation(level.value)) {
            return {
                ...level,
                label: Translation.translateValue(level.value)
            };
        }
        switch (Translation.getLanguage()) {
            case languages.bulgarian:
                return {
                    ...level,
                    label: educationLevels.bg["Primary Education"]
                };
            default:
                return level;
        }
    }
}

export default Translation;
