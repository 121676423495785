
import React from 'react';

import ToastHandler from "./ToastHandler";
import Translation from "./Translation";
import {hasProperty, ensureObjectHas, isObject} from "./ObjectUtils";
import { isString } from './stringUtils';
import { isFunction } from './FunctionUtils';
import { isBoolean } from './booleanUtils';

/**
 * Author Simeon-Banov
 * 
 * Util for processing response from server.
 * You have the options to show toast or change component state.
 */
class ResponseHandler {

    /**
     * Process server response
     * @param {React.component} component component recieving the response
     * @param {Object} serverResponse unedited server response
     * @param {Object} options do something with response
       {
        onError: {toast: true, state: undefined, callback: undefined, message: undefined},
        onSuccess: {toast: true, state: undefined, callback: undefined, message: undefined},
        onFinish: {toast: undefined, state: undefined, callback: undefined, message: undefined}
       }
       callbacks have 2 params (message, code) and an optional param if used @see ResponseHandler.replaceOptionsCallback
     */
    parse(
            component, 
            serverResponse, 
            options={
                onError: {toast: true},
                onSuccess: {toast: true}}
        ) {

        // ensure the component is from react (has state)
        if(!(component instanceof React.Component)) {
            console.error("ResponseHandler.parse ERROR: Component is not React.Component");
            return;
        }

        // ensure serverResponse is in correct format
        if(!hasProperty(serverResponse, "successful") || !hasProperty(serverResponse, "code") || !hasProperty(serverResponse, "message")) {
            console.error("ResponseHandler.parse ERROR: recieved response in incorrect format");
            console.error(serverResponse);
            return;
        }

        // ensure successful is boolean
        if(typeof serverResponse.successful === "string") { 
            serverResponse.successful = (/true/i).test(serverResponse.successful);
        }

        // parse input options and set default values
        if(!isObject(options)) {
            options = {};
        }
        ensureObjectHas(
            options, 
            {
                onError: {toast: true},
                onSuccess: {toast: true},
                onFinish: {}
            },
        );

        // message holder
        let message = Translation.translateResponse(serverResponse.code, serverResponse.message);

        let status;
        // parse response
        status = serverResponse.successful 
                ? String("onSuccess")
                : String("onError");

        // debug logs
        // console.log(component.constructor.name);
        // console.log(JSON.stringify(options));
        // console.log(serverResponse);
        // console.log(status);
        // console.log(message);

        // process options
        // first based on status of response, then onFinish
        let callbackOptions = undefined;
        if(hasProperty(options[status], "callback") && isFunction(options[status].callback)) {
            callbackOptions = options[status].callback(serverResponse.message, serverResponse.code);
        }
        if(hasProperty(callbackOptions, "toast") && isBoolean(callbackOptions.toast)) {
            options[status].toast = callbackOptions.toast
        }
        if(hasProperty(options[status], "toast") && isBoolean(options[status].toast) && options[status].toast) {
            if(hasProperty(callbackOptions, "message") && isBoolean(callbackOptions.message)) {
                options[status].message = callbackOptions.message
            }
            if(hasProperty(options[status], "message") && isString(options[status].message)) {
                ToastHandler["show"+status.substring(2)](options[status].message);
            } else if(message!==null) {
                ToastHandler["show"+status.substring(2)](message);
            } else if(message===null) {
                if(status==="OnError") {
                    console.error("ResponseHandler.parse ERROR: can not show message(null)!");
                    console.error(serverResponse);
                } else {
                    if(hasProperty(serverResponse, "name") && serverResponse.name.indexOf("set")!==-1) {
                        // API name includes set, so default message is "Saved"
                        ToastHandler["show"+status.substring(2)](Translation.translateMessage("Saved", "Saved"));
                    }
                }
            }
        }
        if(hasProperty(options[status], "state") && isObject(options[status].state)) {
            component.setState(options[status].state);
        }

        // onFinish
        if(hasProperty(options.onFinish, "callback") && isFunction(options.onFinish.callback)) {
            callbackOptions = options.onFinish.callback(serverResponse.message, serverResponse.code);
        }
        if(hasProperty(callbackOptions, "toast") && isBoolean(callbackOptions.toast)) {
            options.onFinish.toast = callbackOptions.toast
        }
        if(hasProperty(options.onFinish, "toast") && isBoolean(options.onFinish.toast) && options.onFinish.toast) {
            if(hasProperty(callbackOptions, "message") && isBoolean(callbackOptions.message)) {
                options.onFinish.message = callbackOptions.message
            }
            if(hasProperty(options.onFinish, "message") && isString(options.onFinish.message)) {
                message = options.onFinish.message;
            }
            ToastHandler.showInfo(message);
        }
        if(hasProperty(options.onFinish, "state") && isObject(options.onFinish.state)) {
            component.setState(options.onFinish.state);
        }
    }

    /**
     * Same as parse, simulates message with given code
     * @param {React.component} component 
     * @param {mixed} code either string ex: "UIError" or number ex: 2992 from translations file
     * @param {Object} options @see ResponseHandler.parse documentation
     */
    set(component, code, options={}) {
        return this.parse(component, {successful: false, code: code, message: "ResponseHandler.set - message simulation"}, options);
    }

    /**
     * @param {Object} options @see ResponseHandler.parse
     * @param {String} status @see ResponseHandler.parse main level options keys
     * @param {Function} newCallback
     */
    addOptionsCallback(options, status, newCallback) {
        if(!hasProperty(options, status)) {
            options[status] = {};
        }
        if(hasProperty(options[status], "callback")) {
            const oldCallback = options[status].callback;
            options[status]['callback'] = (data, code)=>{ newCallback(data, code); oldCallback(data, code); };
        } else {
            options[status]['callback'] = newCallback;
        }
    }

    /**
     * @param {Object} options @see ResponseHandler.parse
     * @param {String} key @see ResponseHandler.parse keys
     * @param {Function} value
     */
    setOption(options, key, value) {
        if(!hasProperty(options, key)) {
            options = {};
        }
        options[key] = value;
    }

    /**
     * @param {Object} options @see ResponseHandler.parse
     * @param {String} status @see ResponseHandler.parse main level options keys
     * @param {Function} newCallback
     */
    replaceOptionsCallback(options, status, newCallback) {
        if(!hasProperty(options, status)) {
            options[status] = {};
        }
        const oldCallback = options[status].callback;
        options[status]['callback'] = (data, code)=>{ newCallback(data, code, oldCallback); };
    }

}

export default new ResponseHandler();