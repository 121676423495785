
import React  from 'react';

import manager from '../Manager';
import { frontend, companytype } from '../config';

class MenuItem extends React.Component {
    static contextType = manager;

    static defaultProps = {
        renderOnLogged: true,
        renderOnUnlogged: true,
        renderOnUserType: undefined,
        name: '',
        children: [],
        href: "/",
        className: "",
        debug: false,
        onClick: undefined,
        isChat: false,
        location: {pathname:""}
    }

    subUnread = false;

    state = {
        render: false,
        indicator: undefined
    }

    componentDidMount() {
        const component = this;
        component.context.getAuthManager().getLoggedUser(component, {
            onSuccess: {toast: false},
            onFinish: {
                callback: (user)=>{
                    if(component.context.getAuthManager().isLogged()) {
                        if(!component.props.renderOnLogged || (component.props.renderOnUserType!==undefined && user.user_type!==component.props.renderOnUserType)) {
                            return;
                        }
                    } else {
                        if(!component.props.renderOnUnlogged) {
                            return;
                        }
                    }
                    if(component.props.isChat && user.company_type === companytype.free ) {
                        return;
                    }
                    component.setState({render: true});
                }
            }
        });
        if(!this.subUnread && this.props.isChat && this.props.location.pathname.indexOf(frontend.route.inSystemMessaging) <= -1 && this.context.getWSManager().isActive()) {
            this.subUnread = true;
            this.context.getWSManager().getWS().subscribe("getMyUnreadMessagesNumber", (message)=>{
                this.updateChatIndicator(message);
            });
            this.context.getWSManager().updateChatIndicator();
        }
    }

    componentWillUnmount() {
        if(this.subUnread && this.props.isChat) {
            this.subUnread = false;
            this.context.getWSManager().getWS().unsubscribe("getMyUnreadMessagesNumber");
        }
    }

    updateChatIndicator(indicator) {
        this.setState({
            ...this.state,
            indicator: indicator===0?undefined:(indicator>99?"99+":indicator)
        });
    }

    onClick = e => {
        if(this.props.onClick!==undefined) {
            this.props.onClick(e);
        }
    }

    render() {
        if(!this.state.render) return null;
        let item = "";
        if(this.props.children.length===0) {
            item = (
                <li className="nav-item">
                    <a className="nav-link link text-white display-7" onClick={this.onClick} href={this.props.href}>
                        <span className={this.props.className}></span>{this.props.name}{this.props.isChat && this.state.indicator?(<span style={{color:"red", paddingLeft:"3px"}}>{"("+this.state.indicator+")"}</span>):""} &nbsp; &nbsp; &nbsp;
                    </a>
                </li>
            )
        } else {
            item = (
                <li className="nav-item dropdown">
                    <a className="nav-link link text-white dropdown-toggle display-7" href="/" data-toggle="dropdown-submenu" aria-expanded="false">
                        <span className={this.props.className}></span>{this.props.name} &nbsp;
                    </a>
                    <div className="dropdown-menu">
                        {this.props.children}
                    </div>
                </li>
            )
        }
        if(this.props.debug) {
            console.log(this.props);
        }
        return item;
    }
}

export default MenuItem;