

import 'react-toastify/dist/ReactToastify.css';

import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';

import NavBar from './NavBar';
import Footer from './sections/Footer';

class Page extends Component {

  componentWillMount() {
    if(window.location.pathname.indexOf("login")<=-1) {
      localStorage.removeItem('redirect');
    }
  }

  render() {
    return (
      <div style={{minHeight: "100vh"}}>
        {/* https://stackoverflow.com/questions/2530/how-do-you-disable-browser-autocomplete-on-web-form-field-input-tag?page=2&tab=votes */}
        <input type="text" name="username" style={{display: "none"}}/>
        <input type="password" name="password" style={{display: "none"}}/>
        <ToastContainer />
        <NavBar location={this.props.location}/>
          <div style={{minHeight: "66.7vh"}}>
            {this.props.children}
          </div>
        <Footer />
      </div>
    );
  }
}

export default Page;
