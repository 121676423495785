import React from "react";

import manager from "../Manager";

class LanguageMenu extends React.Component {
    static contextType = manager;

    static defaultProps = {
        currentLanguage: "en",
        changeLanguage: lang => {}
    };

    getSubmenuExcludingSelectedLanguage() {
        let languagesSubMenu = [];
        if (this.props.currentLanguage !== "en") {
            languagesSubMenu.push(
                <a
                    className="text-white dropdown-item display-7"
                    href="/en"
                    onClick={this.langageChange("en")}
                    aria-expanded="false"
                    id="english-submenu"
                    key="english-submenu"
                >
                    <span
                        className={
                            "custom-language-icon english-language-icon mbr-iconfont mbr-iconfont-btn"
                        }
                    ></span>
                    <span>EN</span>
                </a>
            );
        }
        if (this.props.currentLanguage !== "bg") {
            languagesSubMenu.push(
                <a
                    className="text-white dropdown-item display-7"
                    href="/bg"
                    onClick={this.langageChange("bg")}
                    aria-expanded="false"
                    id="bulgarian-submenu"
                    key="bulgarian-submenu"
                >
                    <span
                        className={
                            "custom-language-icon bulgarian-language-icon mbr-iconfont mbr-iconfont-btn"
                        }
                    ></span>
                    <span>BG</span>
                </a>
            );
        }

        return languagesSubMenu;
    }

    langageChange(lang) {
        let component = this;
        return e => {
            e.preventDefault();
            component.context.getLanguageManger().setActiveLanugage(lang);
            return;
        };
    }

    render() {
        let languagesSubMenu = this.getSubmenuExcludingSelectedLanguage();
        return (
            <li className="nav-item dropdown">
                <a
                    className="nav-link link text-white dropdown-toggle display-7"
                    href="/"
                    data-toggle="dropdown-submenu"
                    aria-expanded="false"
                >
                    <span
                        className={
                            "custom-language-icon " +
                            this.context
                                .getLanguageManger()
                                .getLanguageFullName(
                                    this.props.currentLanguage
                                ) +
                            "-language-icon mbr-iconfont mbr-iconfont-btn"
                        }
                    ></span>
                    <span>{this.props.currentLanguage.toUpperCase()}</span>
                </a>
                <div className="dropdown-menu">{languagesSubMenu}</div>
            </li>
        );
    }
}

export default LanguageMenu;
