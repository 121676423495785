const locations = {
    en: {
        Austria: "Austria",
        Vienna: "Vienna",
        Graz: "Graz",
        Linz: "Linz",
        Salzburg: "Salzburg",
        Innsbruck: "Innsbruck",
        Azerbaijan: "Azerbaijan",
        Baku: "Baku",
        Ganja: "Ganja",
        Sumgait: "Sumgait",
        Mingechaur: "Mingechaur",
        Karachuhur: "Karachuhur",
        Albania: "Albania",
        Tirana: "Tirana",
        Drach: "Drach",
        Vlora: "Vlora",
        Elbasan: "Elbasan",
        Shkodra: "Shkodra",
        Andorra: "Andorra",
        "Andorra la Vella": "Andorra la Vella",
        "Escaldes-Engordane": "Escaldes-Engordane",
        Encam: "Encam",
        "Sant Julia de Loria": "Sant Julia de Loria",
        "La Massana": "La Massana",
        Armenia: "Armenia",
        Yerevan: "Yerevan",
        Gyumri: "Gyumri",
        Vanadzor: "Vanadzor",
        Echmiadzin: "Echmiadzin",
        Yazdan: "Yazdan",
        Belarus: "Belarus",
        Minsk: "Minsk",
        Gomel: "Gomel",
        Mogilev: "Mogilev",
        Vitebsk: "Vitebsk",
        Grodno: "Grodno",
        Belgium: "Belgium",
        Brussels: "Brussels",
        Antwerp: "Antwerp",
        Ghent: "Ghent",
        Charleroi: "Charleroi",
        Liege: "Liege",
        "Bosnia and Herzegovina": "Bosnia and Herzegovina",
        Sarajevo: "Sarajevo",
        "Banja Luka": "Banja Luka",
        Tuzla: "Tuzla",
        Zenica: "Zenica",
        Mostar: "Mostar",
        Bulgaria: "Bulgaria",
        Sofia: "Sofia",
        Plovdiv: "Plovdiv",
        Varna: "Varna",
        Burgas: "Burgas",
        Ruse: "Ruse",
        "Vatican City": "Vatican City",
        "Great Britain": "Great Britain",
        London: "London",
        Birningham: "Birningham",
        Liverpool: "Liverpool",
        Leeds: "Leeds",
        Manchester: "Manchester",
        Germany: "Germany",
        Berlin: "Berlin",
        Hamburg: "Hamburg",
        Munich: "Munich",
        Cologne: "Cologne",
        Frankfurt: "Frankfurt",
        Georgia: "Georgia",
        Tbilisi: "Tbilisi",
        Kutaisi: "Kutaisi",
        Batumi: "Batumi",
        Rustavi: "Rustavi",
        Zugdidi: "Zugdidi",
        Greece: "Greece",
        Athens: "Athens",
        Thessaloniki: "Thessaloniki",
        Piraeus: "Piraeus",
        Patras: "Patras",
        Heraklio: "Heraklio",
        Denmark: "Denmark",
        Estonia: "Estonia",
        Ireland: "Ireland",
        Iceland: "Iceland",
        Spain: "Spain",
        Italy: "Italy",
        Cyprus: "Cyprus",
        Latvia: "Latvia",
        Lithuania: "Lithuania",
        Liechtenstein: "Liechtenstein",
        Luxembourg: "Luxembourg",
        Macedonia: "Macedonia",
        Malta: "Malta",
        Moldova: "Moldova",
        Monaco: "Monaco",
        "The Netherlands": "The Netherlands",
        Norway: "Norway",
        Poland: "Poland",
        Portugal: "Portugal",
        Romania: "Romania",
        Russia: "Russia",
        "San Marino": "San Marino",
        Slovakia: "Slovakia",
        Slovenia: "Slovenia",
        Serbia: "Serbia",
        Turkey: "Turkey",
        Ukraine: "Ukraine",
        Hungary: "Hungary",
        "Faroe Islands": "Faroe Islands",
        Finland: "Finland",
        France: "France",
        Croatia: "Croatia",
        Montenegro: "Montenegro",
        "Czech Republic": "Czech Republic",
        Switzerland: "Switzerland",
        Sweden: "Sweden",
        Canada: "Canada",
        USA: "USA",
        Mexico: "Mexico",
        Brazil: "Brazil",
        Argentina: "Argentina",
        Egypt: "Egypt",
        Morocco: "Morocco",
        "South Africa": "South Africa",
        India: "India",
        China: "China",
        Vietnam: "Vietnam",
        Australia: "Australia",
        Japan: "Japan",
        country_other: "Other",
        city_other: "Other"
    },
    bg: {
        Austria: "Австрия",
        Vienna: "Виена",
        Graz: "Грац",
        Linz: "Линц",
        Salzburg: "Залцбург",
        Innsbruck: "Инсбрук",
        Azerbaijan: "Азербейджан",
        Baku: "Баку",
        Ganja: "Ганджа",
        Sumgait: "Сумгаит",
        Mingechaur: "Мингечаур",
        Karachuhur: "Карачухур",
        Albania: "Албания",
        Tirana: "Тирана",
        Drach: "Драч",
        Vlora: "Вльора",
        Elbasan: "Елбасан",
        Shkodra: "Шкодра",
        Andorra: "Андора",
        "Andorra la Vella": "Андора ла Веля",
        "Escaldes-Engordane": "Ескалдес-Енгордан",
        Encam: "Енкам",
        "Sant Julia de Loria": "Сант Жулия де Лория",
        "La Massana": "Ла Масана ",
        Armenia: "Армения",
        Yerevan: "Ереван",
        Gyumri: "Гюмри",
        Vanadzor: "Ванадзор",
        Echmiadzin: "Ечмиадзин",
        Yazdan: "Раздан",
        Belarus: "Беларус",
        Minsk: "Минск",
        Gomel: "Гомел",
        Mogilev: "Могильов",
        Vitebsk: "Витебск",
        Grodno: "Гродно",
        Belgium: "Белгия",
        Brussels: "Брюксел",
        Antwerp: "Антверпен",
        Ghent: "Гент",
        Charleroi: "Шарлероа",
        Liege: "Лиеж",
        "Bosnia and Herzegovina": "Босна и Херцеговина",
        Sarajevo: "Сараево",
        "Banja Luka": "Баня Лука",
        Tuzla: "Тузла",
        Zenica: "Зеница",
        Mostar: "Мостар",
        Bulgaria: "България",
        Sofia: "София",
        Plovdiv: "Пловдив",
        Varna: "Варна",
        Burgas: "Бургас",
        Ruse: "Русе",
        "Vatican City": "Ватикана",
        "Great Britain": "Великобритания",
        London: "Лондон",
        Birningham: "Бирнингам",
        Liverpool: "Ливърпул",
        Leeds: "Лийдс",
        Manchester: "Манчестър",
        Germany: "Германия",
        Berlin: "Берлин",
        Hamburg: "Хамбург",
        Munich: "Мюнхен",
        Cologne: "Кьолн",
        Frankfurt: "Франфуркт",
        Georgia: "Грузия",
        Tbilisi: "Тбилиси",
        Kutaisi: "Кутаиси",
        Batumi: "Батуми",
        Rustavi: "Рустави",
        Zugdidi: "Зугдиди",
        Greece: "Гърция",
        Athens: "Атина",
        Thessaloniki: "Солун",
        Piraeus: "Пиреа",
        Patras: "Патра",
        Heraklio: "Ираклио",
        Denmark: "Дания",
        Estonia: "Естония",
        Ireland: "Ирландия",
        Iceland: "Исландия",
        Spain: "Испания",
        Italy: "Италия",
        Cyprus: "Кипър",
        Latvia: "Латвия",
        Lithuania: "Литва",
        Liechtenstein: "Лихтенщайн",
        Luxembourg: "Люксембург",
        Macedonia: "Македония",
        Malta: "Малта",
        Moldova: "Молдова",
        Monaco: "Монако",
        "The Netherlands": "Нидерландия",
        Norway: "Норвегия",
        Poland: "Полша",
        Portugal: "Португалия",
        Romania: "Румъния",
        Russia: "Русия",
        "San Marino": "Сан Марино",
        Slovakia: "Словакия",
        Slovenia: "Словения",
        Serbia: "Сърбия",
        Turkey: "Турция",
        Ukraine: "Украйна",
        Hungary: "Унгария",
        "Faroe Islands": "Фарьорски острови",
        Finland: "Финландия",
        France: "Франция",
        Croatia: "Хърватска",
        Montenegro: "Черна Гора",
        "Czech Republic": "Чехия",
        Switzerland: "Швейцария",
        Sweden: "Швеция",
        Canada: "Канада",
        USA: "САЩ",
        Mexico: "Мексико",
        Brazil: "Бразилия",
        Argentina: "Аржентина",
        Egypt: "Египет",
        Morocco: "Мароко",
        "South Africa": "ЮАР",
        India: "Индия",
        China: "Китай",
        Vietnam: "Виетнам",
        Australia: "Австралия",
        Japan: "Япония",
        country_other: "Друга",
        city_other: "Друг"
    },
    de: {}
};

export default locations;
