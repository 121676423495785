import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";

import WhiteFilledTextField from "../../form_helpers/WhiteFilledTextField";
import Autocomplete from "../../form_helpers/Autocomplete";
import Checkbox from "../../form_helpers/Checkbox";
import { dateToYMDString } from "../../../utils/dateUtils";
import Translation from "../../../utils/Translation";

class EmploymentHistoryForm extends Component {
	state = {
		employerLoading: false,
		technicalStackLoading: false
	};
	static defaultProps = {
		className: "",
		employments: [],
		employers: [],
		skills: [],
		errors: {},
		submitted: false,
		handleAutocompleteChange: () => {},
		handleTextChange: () => {},
		handleCheckboxChange: () => {},
		deleteEmployment: () => {},
		addSkill: () => {},
		addEmployer: () => {}
	};

	autocompleteChange = (name, employmentId) => newValue => {
		this.props.handleAutocompleteChange(name, employmentId, newValue);
	};

	createEmployer = employmentId => newEmployer => {
		this.setState({ employerLoading: true });
		this.props.addEmployer(newEmployer, () => {
			const index = this.props.employers.indexOf(
				this.props.employers.find(
					employer => employer.label === newEmployer
				)
			);
			this.props.handleAutocompleteChange(
				"employer",
				employmentId,
				this.props.employers[index]
			);
			this.setState({ employerLoading: false });
		});
	};

	createSkill = employmentId => newSkill => {
		this.setState({ technicalStackLoading: true });
		this.props.addSkill(newSkill, () => {
			const index = this.props.skills.indexOf(
				this.props.skills.find(skill => skill.value === newSkill)
			);
			this.props.handleAutocompleteChange(
				"technicalStack",
				employmentId,
				this.props.skills[index],
				true
			);
			this.setState({ technicalStackLoading: false });
		});
	};

	render() {
		const {
			className,
			employments,
			employers,
			skills,
			handleTextChange,
			handleCheckboxChange,
			deleteEmployment,
			errors,
			submitted
		} = this.props;
		const { employerLoading, technicalStackLoading } = this.state;
		return employments.map(employment => {
			const { employmentId } = employment;
			return (
				<Grid
					key={employmentId}
					container
					spacing={2}
					className={className}
				>
					<Grid item xs={6}>
						<Autocomplete
							creatable
							isClearable
							className="form-control"
							placeholder={Translation.translateField(
								"jobseekerEmploymentHistoryEmployer",
								"Employer"
							)}
							value={employment.employer}
							options={employers}
							error={
								errors.hasOwnProperty(employmentId) &&
								errors[employmentId].hasOwnProperty("employer")
							}
							helperText={
								errors.hasOwnProperty(employmentId) &&
								errors[employmentId].hasOwnProperty("employer")
									? Translation.translateErrorMessage(
											`employer-${
												errors[employmentId].employer
													.reason
											}`,
											errors[employmentId].employer
												.message
									  )
									: ""
							}
							onChange={this.autocompleteChange(
								"employer",
								employmentId
							)}
							onCreateOption={this.createEmployer(employmentId)}
							isLoading={employerLoading}
							isDisabled={employerLoading}
						/>
					</Grid>
					<Grid item xs={6}>
						<WhiteFilledTextField
							className="form-control"
							label={Translation.translateField("jobseekerEmploymentHistoryRole", "Role")}
							onChange={handleTextChange("role", employmentId)}
							error={
								errors.hasOwnProperty(employmentId) &&
								errors[employmentId].hasOwnProperty("role")
							}
							helperText={
								errors.hasOwnProperty(employmentId) &&
								errors[employmentId].hasOwnProperty("role")
									? Translation.translateErrorMessage(
											`role-${
												errors[employmentId].role.reason
											}`,
											errors[employmentId].role.message
									  )
									: ""
							}
							value={employment.role}
						/>
					</Grid>
					<Grid item xs={6} sm={4}>
						<WhiteFilledTextField
							className="form-control"
							label={Translation.translateField("jobseekerEmploymentHistoryRole", "Initial Date")}
							type="date"
							inputProps={{
								max: dateToYMDString(new Date())
							}}
							InputLabelProps={{
								shrink: true
							}}
							value={employment.initialDate}
							onChange={handleTextChange(
								"initialDate",
								employmentId
							)}
							error={
								errors.hasOwnProperty(employmentId) &&
								errors[employmentId].hasOwnProperty(
									"initialDate"
								)
							}
							helperText={
								errors.hasOwnProperty(employmentId) &&
								errors[employmentId].hasOwnProperty(
									"initialDate"
								)
									? Translation.translateErrorMessage(
											`initialDate-${
												errors[employmentId].initialDate
													.reason
											}`,
											errors[employmentId].initialDate
												.message
									  )
									: ""
							}
						/>
					</Grid>
					<Hidden xsDown>
						<Grid item sm={2} />
					</Hidden>
					<Grid item xs={6} sm={4}>
						<WhiteFilledTextField
							className="form-control"
							label={Translation.translateField("jobseekerEmploymentHistoryEndDate", "End Date")}
							type="date"
							inputProps={{
								max: dateToYMDString(new Date())
							}}
							InputLabelProps={{
								shrink: true
							}}
							value={employment.endDate}
							onChange={handleTextChange("endDate", employmentId)}
							disabled={employment.present}
							error={
								errors.hasOwnProperty(employmentId) &&
								errors[employmentId].hasOwnProperty("endDate")
							}
							helperText={
								errors.hasOwnProperty(employmentId) &&
								errors[employmentId].hasOwnProperty("endDate")
									? Translation.translateErrorMessage(
											`endDate-${
												errors[employmentId].endDate
													.reason
											}`,
											errors[employmentId].endDate.message
									  )
									: ""
							}
						/>
					</Grid>
					<Hidden mdUp>
						<Grid item xs={6} />
					</Hidden>
					<Grid item xs={6} sm={4} md={2}>
						<Checkbox
							checked={employment.present}
							label={Translation.translateField("jobseekerEmploymentHistoryPresent", "Present")}
							color="primary"
							onChange={handleCheckboxChange(
								"present",
								employmentId
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Autocomplete
							creatable
							isMulti
							className="form-control"
							placeholder={Translation.translateField("jobseekerEmploymentHistoryTechnicalStack", "Technical Stack")}
							value={employment.technicalStack}
							onChange={this.autocompleteChange(
								"technicalStack",
								employmentId
							)}
							onCreateOption={this.createSkill(employmentId)}
							isLoading={technicalStackLoading}
							isDisabled={technicalStackLoading}
							options={skills}
						/>
					</Grid>
					<Grid item xs={12}>
						<WhiteFilledTextField
							className="form-control"
							multiline
							rows={8}
							label={Translation.translateField("summary", "Summary")}
							onChange={handleTextChange("summary", employmentId)}
							value={employment.summary}
							error={
								errors.hasOwnProperty(employmentId) &&
								errors[employmentId].hasOwnProperty("summary")
							}
							helperText={
								errors.hasOwnProperty(employmentId) &&
								errors[employmentId].hasOwnProperty("summary")
									? Translation.translateErrorMessage(
											`summary-${
												errors[employmentId].summary
													.reason
											}`,
											errors[employmentId].summary.message
									  )
									: ""
							}
						/>
					</Grid>
					<Grid item>
						<Button
							onClick={() => deleteEmployment(employmentId)}
							className={
								"btn btn-" +
								(submitted ? "primary" : "secondary") +
								" btn-form display-4 mr-2"
							}
							disabled={submitted}
						>
							{Translation.translateField(
								"deleteButton",
								"Delete"
							)}
						</Button>
					</Grid>
				</Grid>
			);
		});
	}
}

export default EmploymentHistoryForm;
