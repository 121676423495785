export default {
    "-9999": "TODO",
    "-9900": "Предоставени са грешни данни",
    "-9000": "Твърде много заявки, моля, опитайте отново по-късно",
    "0000": null,
    "0001": "Несъответствие в потребителско име и парола",
    "0012": "Неоторизирана заявка за файл",
    "0013": "Непознат файл формат",
    "0015": "Моля, предоставете един от следните формати(PDF, PNG, SVG)",
    "0016":
        "Размерът на файла е по-голям от очакваното, моля, предоставете по-компактен файл. (До 5MB)",
    "0017": "Файлът вече съществува",
    "0900":
        "Имаме проблеми с обработката на вашата заявка. Моля, свържете се с поддръжката. Извиняваме се за създаденото неудобство!",
    "0996": "Имейл адресът е вече регистриран",
    "0997": "Не може да се запази предоставеният служител",
    "0998": "Подобна компания вече съществува",
    "0999":
        "Имаме проблем със запазването на паролата ви, моля, свържете се с поддръжката.",
    "1000": "Грешка в базата данни. Моля, свържете се с поддръжката.",
    "2001": "Името на фирмата е необходимо.",
    "2002": "Имейлът не е валиден.",
    "2003": "Невалидно име на контактното лице, необходима дължина 4-80.",
    "2004": "Невалидно име, необходима дължина 4-80.",
    "2005": "Телефонът трябва да е валиден номер.",
    "2006":
        "Паролата не е валидна, дължина 6-80, поне 1 главна буква, 1 малка буква, 1 номер и 1 специален символ.",
    "2007": "Паролите не съвпадат",
    "2008": "Ключът за възстановяване не е предоставен.",
    "2009":
        "Вашата текуща парола не трябва да съвпада с новата парола и трябва да има дължина 6-80, най-малко 1 главна буква, 1 малка буква, 1 номер и 1 специален символ.",
    "2010": "Трябва да се съгласите с общите условия.",
    "2011": "Трябва да се съгласите с общия регламент за защита на данните.",
    "2012": "Данните за местоположението не са правилни.",
    "2013": "Предоставените умения са образувани неправило.",
    "2014": "Неизвестно ниво на видимост.",
    "2015":
        "Данните за образователното ниво са неправилни. Моля, премахнете и опитайте да въведете отново образованието си.",
    "2016": "SEO не е валидна стойност - моля, посочете низ.",
    "2017": "Предоставеният yебсайт трябва да е валиден URL адрес.",
    "2018": "Описание невалидно - моля, въведете изречение.",
    "2019":
        "Общият регламент за защита на данните трябва да бъде валиден линк.",
    "2020": "Не може да се определи вида на акаунта за регистриране.",
    "2021": "Изтекла възможност за възстановяване на профила, моля направете нова заявка за възстановяване.",
    "9000":
        "Трябва да влезете в своя акаунт, за да получите достъп до тази функция.",
    "9001": "Вашата сесия е изтекла, моля, презаредете страницата и влезте.",
    "9002": "Вашата сесия е изтекла, моля, презаредете страницата и влезте.",
    "9003": "Вашата сесия е изтекла, моля, презаредете страницата и влезте.",
    "9004": "Вашата сесия е изтекла, моля, презаредете страницата и влезте.",
    "9005": "Неоторизиран ресурс.",
    "9006": "Вече сте влезли.",
    "9804": "Профилът не е намерен",
    "9802": "Профилът, който в момента търсите, не е наличен.",
    "9801": "Нямате разрешение за достъп до тази функция",
    "9902": "Името на служителя е задължително.",
    "9903": "Телефонът на служителя трябва да е валиден номер.",
    "9904": "Изисква се имейл на служителя. Имейлът е задължителен",
    "9905":
        "Паролата е невалидна, моля, изпълнете изискванията - дължина 6-80, най-малко 1 главна буква, 1 малка буква, 1 номер и 1 специален символ.",
    "9906": "Паролите не съвпадат.",
    "9907": "Грешна текуща парола.",
    "10000": "Заглавието на длъжността е задължително.",
    "11001": "Името на фирмата е задължително.",
    "11002":
        "Минимумът/Максимумът на заплащане трябва да бъде или низ (с цифри), или числова.",
    "11003":
        "Минималната заплата трябва да бъде по-ниска или равна на максималната заплата",
    "11005": "Началната дата на валидност трябва да е по-ниска от крайната",
    "11004": "Валидността трябва да е в рамките на една година в бъдеще",
    "11006": "Заглавието на позицията трябва да бъде уникално и попълнено",
    "11007": "Не можем да намерим компанията на тази позиция",
    "11008": "Невъзможност за промяна на съществуваща позиция",
    "11009": "Безплатен акаунт има право само на една публикувана позиция",
    "11010": "Първоначалната дата за валидност не трябва да е в миналото",
    "11011": "Опцията за фрийлансър не е правилна",
    "11012": "Опцията за отдалечена работа не е правилна",
    "11013": "Годините опит трябва да бъдат числени",
    "11014": "Минималната заплата трябва да бъде положително число",
    "11015": "Максималната заплата трябва да бъде положително число",
    "11016": "Години опит трябва да бъдат положително число",
    "11017": "Референтният URL адрес не е валиден уеб адрес",
    "11400": "Не сте влезли в акаунта си",
    "11401": "Неизвестна позиция",
    "11402": "Вече сте кандидатствал по дадената позиция",
    "11403": "Позицията не съществува",
    "11404":
        "Кандидатът не може да бъде намерен, моля, свържете се с поддръжка",
    "11500": "Не сте влезли в акаунта си",
    "11501": "Нямате достъп до списъка с кандидати",
    "11502": "Неизвестна позиция",
    "11503": "ID (индентифициране) на позиция не е предоставена",
    "11504": "Позицията не е намерена",
    "11505": "Няма кандидати",
    "11506": "Няма позиции за показване на кандидати",
    "14000": "Не може да актуализирате несъществуващ достъп",
    "14001": "Предоставените данни са грешни",
    "14002": "Предоставените данни са грешни",
    "14003": "Предоставените данни са грешни",
    "14004": "Този тип разрешение не съществува",
    "15000": "Потребителят не е в списъка ви с контакти",
    "15001": "Контактът не е предоставен",
    "15002": "Служител не е намерен",
    "15003": "Потребителят е в списъка ви с контакти",
    "15004": "Потребителят ви е блокирал",
    "15005": "Невалиден потребител",
    "15006": "Не можете да поискате контакт със себе си",
    "15007":
        "Компанията е изтекла и не може да използва вътрешната система за комуникация",
    "15008": "Настройката не е приета.",
    "16000": "Трябва да предоставите низ за търсене",
    "16001": "Търсещият низ трябва да съдържа поне две букви.",
    "16002": "Безплатните фирмени профили не могат да използват търсачката",
    "20101": "Работодателят е задължително поле",
    "20102": "Ролята е задължително поле",
    "20103": "Началната дата е неправилна",
    "20104": "Крайната дата е неправилна",
    "20105": "Нещо объркващо се случва с подаването на формуляра Ви",
    "20106": "Нещо объркващо се случва с подаването на формуляра Ви",
    "20107": "Нещо объркващо се случва с подаването на формуляра Ви",
    "20111": "Нещо се обърка в намирането на предоставените умения",
    "21111": "Нещо се обърка с намирането на предоставения работодател",
    "20011":
        "Имаме проблеми с изчисляването на завършването на потребителския ви профил, моля, свържете се с поддръжката или опитайте отново да запазите профила си",
    "20012": "За това действие е нужно да попълните профила си на 100%"
};
