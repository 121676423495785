import React, { Component, Fragment } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import uuidv1 from "uuid/v1";

import EmploymentHistoryForm from "./EmploymentHistoryForm";
import { styles } from "../../../defaultProps";
import { applyRules } from "../../../utils/ValidatorUtils";
import { usertype } from "../../../config";
import manager from "../../../Manager";
import Translation from "../../../utils/Translation";

class EmploymentHistory extends Component {
	static contextType = manager;
	state = {
		loadedData: false,
		submitted: false,
		employers: [],
		formErrors: {}
	};
	static defaultProps: {
		approvedSkills: [],
		employments: [],
		addSkill: () => {},
		setEmployments: () => {}
	};

	validationRules = {
		employer: "mandatory",
		role: "mandatory",
		initialDate: "mandatory",
		endDate: { oneOf: ["endDate", "present"] }
	};

	//TODO load data from backend
	componentDidMount() {
		let component = this;
		this.context.getAuthManager().pageRestriction({
			logged: true,
			forLoggedUsertype: [usertype.jobseeker]
		});

		this.context.getEmployerManager().getApprovedEmployers(component, {
			onSuccess: {
				callback: approvedEmployers => {
					this.setState({
						...this.state,
						employers: approvedEmployers,
						loadedData: true
					});
				}
			}
		});
	}

	addEmployment = () => {
		let employments = this.props.employments.slice();
		employments.push({
			employmentId: uuidv1(),
			technicalStack: [],
			employer: null,
			role: "",
			initialDate: "",
			endDate: "",
			present: false,
			summary: ""
		});

		this.props.setEmployments(employments);
	};

	handleTextChange = (name, employmentId) => ({ target: { value } }) => {
		this.handleChange(name, employmentId, value);
	};
	handleCheckboxChange = (name, employmentId) => ({
		target: { checked }
	}) => {
		this.handleChange(name, employmentId, checked);
	};
	handleAutocompleteChange = (name, employmentId, value, append = false) => {
		this.handleChange(name, employmentId, value, append);
	};

	handleChange(
		name,
		employmentId,
		value,
		append = false,
		additionalState = {}
	) {
		let employments = this.props.employments.slice();
		let errors = { ...this.state.formErrors };

		const index = employments.indexOf(
			employments.find(
				employment => employment.employmentId === employmentId
			)
		);
		if (index < 0) {
			return;
		}
		if (append) {
			value = [...employments[index][name], value];
		}
		employments[index] = {
			...employments[index],
			[name]: value
		};
		if (name === "present") {
			employments[index].endDate = "";
		}

		//unset errors for the field
		if (errors.hasOwnProperty(employments[index].employmentId)) {
			let chainRemovedErrors = ["initialDate", "endDate", "present"];
			if (chainRemovedErrors.includes(name)) {
				chainRemovedErrors.forEach(el => {
					delete errors[employments[index].employmentId][el];
				});
			}
			delete errors[employments[index].employmentId][name];
			if (
				Object.keys(errors[employments[index].employmentId]).length ===
				0
			) {
				delete errors[employments[index].employmentId];
			}
		}
		this.props.setEmployments(employments);
		this.setState({
			...this.state,
			formErrors: errors
		});
	}

	saveData = () => {
		const employments = this.props.employments;
		const errors = this.checkValidationRules(
			employments,
			this.validationRules
		);
		if (this.hasErrors(errors)) {
			this.setState({ ...this.state, formErrors: errors });
			return;
		}
		let formattedData = employments.map(employment => {
			const {
				employer,
				technicalStack,
				employmentId,
				...rest
			} = employment;
			return {
				employer: employer.label,
				technicalStack: technicalStack.map(skill => skill.label),
				...rest
			};
		});
		this.context
			.getEmployerManager()
			.storeEmployments(this, formattedData, {
				onSuccess: {
					message: "Employments Saved!",
					callback: result => {
						this.setState({
							...this.state,
							formErrors: {}
						});
					}
				}
			});
	};

	hasErrors = errors => {
		return Object.keys(errors).length > 0;
	};

	checkValidationRules = (data, rules) => {
		let errors = {};
		if (data.constructor.name === "Array") {
			if (data.length === 0) {
				return errors;
			}
			data.forEach(employment => {
				let _rules = { ...rules };
				if (!employment.present) {
					_rules.initialDate = ["mandatory", "<= endDate"];
				}
				const ruleResult = applyRules(employment, _rules);
				if (Object.keys(ruleResult).length > 0) {
					errors[employment.employmentId] = ruleResult;
				}
			});
		}
		return errors;
	};

	deleteEmployment = employmentId => {
		let errors = { ...this.state.formErrors };
		delete errors[employmentId];

		this.props.setEmployments(
			this.props.employments.filter(
				employment => employment.employmentId !== employmentId
			)
		);
		this.setState({ errors: errors });
	};

	addEmployer = (employerName, callback = () => {}) => {
		let component = this;
		this.context.getEmployerManager().addEmployer(component, employerName, {
			onSuccess: {
				callback: data => {
					component.setState(
						{
							...component.state,
							employers: [...component.state.employers, data]
						},
						callback
					);
				}
			}
		});
	};

	renderer = state => {
		const { loadedData, employers, submitted, formErrors } = this.state;
		const { classes, approvedSkills, addSkill, employments } = this.props;
		switch (loadedData) {
			case true:
				return (
					<Fragment>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<Typography
									variant="h5"
									className={classes.heading}
								>
									{Translation.translateStaticText(
										"employmentHistoryHeading",
										"Employment History"
									)}
								</Typography>
								<Divider className={classes.divider} />
							</Grid>
						</Grid>
						<form className={"mbr-form my-2 mb-2"}>
							<EmploymentHistoryForm
								employments={employments}
								employers={employers}
								skills={approvedSkills}
								handleCheckboxChange={this.handleCheckboxChange}
								handleTextChange={this.handleTextChange}
								handleAutocompleteChange={
									this.handleAutocompleteChange
								}
								deleteEmployment={this.deleteEmployment}
								errors={formErrors}
								className="mb-3"
								submitted={submitted}
								addSkill={addSkill}
								addEmployer={this.addEmployer}
							/>
						</form>
						<Grid container spacing={1} justify="space-between">
							<Grid item>
								<Button
									onClick={this.addEmployment}
									className="btn btn-primary"
									disabled={submitted}
								>
									{Translation.translateField(
										"addEmploymentButton",
										"Add Employment"
									)}
								</Button>
							</Grid>
							<Grid item>
								<Button
									onClick={this.saveData}
									className={
										"btn btn-" +
										(submitted ? "primary" : "secondary") +
										" btn-form display-4"
									}
									disabled={
										submitted || this.hasErrors(formErrors)
									}
								>
									{Translation.translateField(
										"saveButton",
										"Save"
									)}
								</Button>
							</Grid>
						</Grid>
						{this.props.children}
					</Fragment>
				);
			default:
				return "";
		}
	};
	render() {
		return this.renderer();
	}
}

export default withStyles(styles)(EmploymentHistory);
