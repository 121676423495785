import React, { Fragment } from "react";
import { capitalize } from "../../utils/stringUtils";
import Translation from "../../utils/Translation";
import { languages } from "../../config";

function PurchaseInfo(props) {
	const purchaseInfoTemplate = language => {
		const englishTemplate = (
			<Fragment>
				After payment is confirmed, please allow the team some time to
				set up your company status. Keep in mind, that due to the bank
				transfer limits the whole process may take up to one week. If
				you have any questions or concerns please do not hesitate to
				contact us via{" "}
				<a
					href={`mailto:office@match.careers?subject=${capitalize(
						props.type
					)} Purchase`}
				>
					office@match.careers
				</a>
			</Fragment>
		);

		const bulgarianTemplate = (
			<Fragment>
				След потвърждаване на плащането, моля, дайте на екипа известно
				време да установи статуса на Вашата компания. Имайте предвид, че
				поради ограниченията на банковите преводи целият процес може да
				отнеме до една седмица. Ако имате някакви въпроси или
				притеснения, не се колебайте да се свържете с нас чрез{" "}
				<a
					href={`mailto:office@match.careers?subject=${capitalize(
						props.type
					)} Purchase`}
				>
					office@match.careers
				</a>
			</Fragment>
		);

		const germanTemplate = englishTemplate;

		switch (language) {
			case languages.english:
				return englishTemplate;
			case languages.bulgarian:
				return bulgarianTemplate;
			case languages.german:
				return germanTemplate;
			default:
				return englishTemplate;
		}
	};

	const bankDetailsTemplate = language => {
		const bankName = "Eurobank EFG";
		const IBAN = "BG56BPBI79421019620301";
		const receiver = "InspireSoft";

		const englishTemplate = (
			<Fragment>
				<div>Bank name: {bankName}</div>
				<div>Bank IBAN: {IBAN}</div>
				<div>Reciever Name: {receiver}</div>
			</Fragment>
		);

		const bulgarianTemplate = (
			<Fragment>
				<div>Име на банката: {bankName}</div>
				<div>IBAN: {IBAN}</div>
				<div>Име на получател: {receiver}</div>
			</Fragment>
		);

		const germanTemplate = englishTemplate;

		switch (language) {
			case languages.english:
				return englishTemplate;
			case languages.bulgarian:
				return bulgarianTemplate;
			case languages.german:
				return germanTemplate;
			default:
				return englishTemplate;
		}
	};

	return (
		<Fragment>
			<div className="purchase-info">
				{purchaseInfoTemplate(Translation.getLanguage())}
			</div>
			<hr />
			<div className="bank-details">
				{bankDetailsTemplate(Translation.getLanguage())}
			</div>
		</Fragment>
	);
}

export default PurchaseInfo;
