import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";

import { styles } from "../../../defaultProps";
import Translation from '../../../utils/Translation';

class SkillListing extends Component {
	static defaultProps = {
		skills: [],
		removeHeading: false,
		label: Translation.translateStaticText("jobseekerPublicProfileSkillsHeading","Personal Skills"),
		classes: {}
	};

	render() {
		const { classes, skills, label, removeHeading } = this.props;
		return (
			<Fragment>
				{!removeHeading && (
					<Grid item xs={12}>
						<Typography variant="h5" className={classes.heading}>
							{Translation.translateStaticText("jobseekerPublicProfileSkillsHeading", "Personal Skills")}
						</Typography>
						<Divider className={classes.divider} />
					</Grid>
				)}
				<Grid item xs={12}>
					<Paper className={classes.paper}>
						<Typography variant="caption">
							{label}
							{skills.length === 0 ? (
								<Typography component="p">
									{Translation.translateStaticText("jobseekerPublicProfileNoSkills", "No Available Skills")}
								</Typography>
							) : (
								skills.map(skill => (
									<Chip
										key={skill.value}
										label={skill.value}
										className={classes.chip}
										color="primary"
									/>
								))
							)}
						</Typography>
					</Paper>
				</Grid>
			</Fragment>
		);
	}
}

export default withStyles(styles)(SkillListing);
