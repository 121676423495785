import React, { Component } from "react";

import AccountRecovery from "../sections/AccountRecovery";
import Page from "../Page";
import { setTitle } from "../../pageOptions";
import manager from "../../Manager";
import Translation from "../../utils/Translation";

class AccountRecoveryPage extends Component {
  static contextType = manager;

  componentWillMount() {
    this.context.getAuthManager().pageRestriction({ logged: false });
    setTitle(
      Translation.translateStaticText("recoveryPageTitle", "Account Recovery")
    );
  }

  render() {
    return (
      <Page location={this.props.location}>
        <AccountRecovery location={this.props.location} />
      </Page>
    );
  }
}

export default AccountRecoveryPage;
