import React from "react";
import Translation from "./Translation";

/**
 * Pagination controller
 * Author: Simeon-Banov
 */
class Pagination {
    /**
     * automatic (internal) params
     */
    totalCount = -1;
    size = -1;
    page = -1;
    from = 1;
    onPageChange = (pageNumber) => {};

    /**
     * set render options once and use them for all pages
     */
    renderOptions = undefined;

    /**
     * @param {number} startPage start page number, default 1
     * @param {number} pageSize item count on each page, default 20
     */
    constructor(
        startPage = 1,
        pageSize = 20,
        title,
        onPageChange = (pageNumber) => (e) => {}
    ) {
        if (typeof startPage !== "number") {
            console.error(
                "Pagination constructor ERROR: startPage must be a number!"
            );
            console.error(startPage);
        } else if (typeof pageSize !== "number") {
            console.error(
                "Pagination constructor ERROR: pageSize must be a number!"
            );
            console.error(pageSize);
        } else if (startPage <= 0) {
            console.error(
                "Pagination constructor ERROR: startPage must be more than 0!"
            );
            console.error(startPage);
        } else if (pageSize <= 0) {
            console.error(
                "Pagination constructor ERROR: pageSize must be more than 0!"
            );
            console.error(pageSize);
        } else {
            this.page = startPage;
            this.size = pageSize;
            this.onPageChange = onPageChange;
            this.title = title;
        }
    }

    init() {
        this.goToPage(this.page);
    }

    goToPage(page = 1) {
        if (this.onPageChange) {
            this.page = page;
            this.from = page * this.size - this.size + 1;
            this.onPageChange(page)();
        }
    }

    /**
     * Render menu for pagination.
     * @param {object} renderOptions Set render options for menus. Tip: you can use the global options with setRenderOptions method.
     * Params:
     *  buttonClassName default undefined (string)
     *  returnButtonArray default false (boolean)
     *  returnButtonMenu default false (boolean)
     */
    render(renderOptions = undefined) {
        if (this.size === -1) {
            console.error("Pagination render ERROR: see constructor error!");
        } else if (this.size >= this.totalCount) {
            console.info(
                "Pagination render info: not enough items for pagination."
            );
            return null;
        } else {
            if (this.renderOptions && !renderOptions) {
                renderOptions = this.renderOptions;
            }
            let buttonClassName = "";
            if (
                renderOptions &&
                typeof renderOptions === "object" &&
                "buttonClassName" in renderOptions &&
                typeof renderOptions.buttonClassName === "string"
            ) {
                buttonClassName = renderOptions.buttonClassName;
            }
            let buttonArray = [];
            const buttonMenu = Array.from(
                { length: Math.ceil(this.totalCount / this.size) },
                (v, k) => k + 1
            ).map((pageNumber) => {
                const isCurrentPage = pageNumber === this.page;
                buttonArray.push(
                    <span
                        onClick={(e) => {
                            isCurrentPage
                                ? e.preventDefault()
                                : this.goToPage(pageNumber);
                        }}
                        className={`${buttonClassName} ${
                            isCurrentPage ? "active" : ""
                        }`}
                        id={"page-button-" + pageNumber}
                        key={"page-button-" + pageNumber}
                    >
                        {pageNumber}
                    </span>
                );
                return buttonArray[buttonArray.length - 1];
            });
            if (
                renderOptions &&
                typeof renderOptions === "object" &&
                "returnButtonArray" in renderOptions &&
                renderOptions.returnButtonArray
            ) {
                return buttonArray;
            }
            if (
                renderOptions &&
                typeof renderOptions === "object" &&
                "returnButtonMenu" in renderOptions &&
                renderOptions.returnButtonMenu
            ) {
                return buttonMenu;
            }
            let to = this.from + this.size - 1;
            if (to > this.totalCount) {
                to = this.totalCount;
            }
            return (
                <div className="row pagination-wrap">
                    <div className="col-md-6 text-center text-md-left mb-4 mb-md-0">
                        <span>
                            {Translation.translateStaticText(
                                "paginationShowing",
                                "Showing {from} - {to} Of {total} {title}"
                            )
                                .replace("{from}", this.from)
                                .replace("{to}", to)
                                .replace("{total}", this.totalCount)
                                .replace("{title}", this.title)}
                        </span>
                    </div>
                    <div className="col-md-6 text-center text-md-right">
                        <div className="custom-pagination ml-auto">
                            {this.page > 1 && (
                                <span
                                    onClick={(e) =>
                                        this.goToPage(this.page - 1)
                                    }
                                    className="prev"
                                >
                                    {Translation.translateField(
                                        "previousPaginationButton",
                                        "Prev"
                                    )}
                                </span>
                            )}
                            <div className="d-inline-block">{buttonMenu}</div>
                            {this.totalCount > this.page * this.size && (
                                <span
                                    className="next"
                                    onClick={(e) =>
                                        this.goToPage(this.page + 1)
                                    }
                                >
                                    {Translation.translateStaticText(
                                        "nextPaginationButton",
                                        "Next"
                                    )}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
    }

    /**
     * Set render options for menus.
     * @param {object} renderOptions
     * Params:
     *  buttonClassName (string)
     *  returnButtonArray (boolean)
     *  returnButtonMenu (boolean)
     */
    setRenderOptions(renderOptions) {
        this.renderOptions = renderOptions;
        return this;
    }
}

export default Pagination;
