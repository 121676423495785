import React, { Component } from 'react';

class FloatingLabelTextArea extends Component {

  static defaultProps = {
      value: '',
      disabled: false,
      placeholder: ""
  }

  state = {
    isFocused: false,
  };

  handleFocus = () => this.setState({ isFocused: true });
  handleBlur = () => this.setState({ isFocused: false });

  render() {
    const { ...props } = this.props;
    const { isFocused } = this.state;
    let label = undefined;
    const labelStyle = {
      position: 'absolute',
      left: 2,
      top: 2,
      fontSize: 14,
      color: '#8c9298',
    };

    if(!props.value && props.disabled) {
      return null;
    }
    
    if((isFocused || props.value) && props.placeholder) {
      label = props.placeholder;
      if(!props.disabled) {
        label+=":";
      }
      props.placeholder = undefined;
    }

    return (
      <div style={{position: "relative"}}>
        <div style={labelStyle}>
          {label}
        </div>
        <textarea 
          {...props}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        />
      </div>
    );
  }
}


export default FloatingLabelTextArea;