import {locale} from '../config';


function formatDateToLocale(date) {
	if(!isDateObject(date)) {
		throw new Error("invalid prop, must be instance of 'Date'");
	}
    return date.toLocaleDateString(locale); 
}

function isDateObject(object) {
	return object.constructor.name === "Date"
}

function dateToYMDString(date){
	if(!isDateObject(date)){
		throw new Error ("invalid prop, must be instance of 'Date'");
	}
	const d = date.getDate();
    const m = date.getMonth() + 1; //Month from 0 to 11
    const y = date.getFullYear();
    return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
}


export {
    formatDateToLocale,
    dateToYMDString
};