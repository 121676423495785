import React, {Component} from 'react';
import Edit from './skills/Edit'

class Skills extends Component {
  modes = {
    edit: 'edit',
  }

  render(){
    const {mode} = this.props
    switch (mode) {
      default:
      case this.modes.edit: 
      const {value, addSkill, approvedSkills, placeholder} = this.props;
      return (
        <Edit 
          selectedSkills={value} 
          addSkill={addSkill} 
          approvedSkills={approvedSkills} 
          placeholder={placeholder} 
          />
      )
    }
  }
}

export default Skills;