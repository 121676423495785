import React, { Component } from "react";
import { Redirect } from "react-router";

import { setTitle } from "../../../pageOptions";
import Page from "../../Page";
import manager from "../../../Manager";
import { usertype, accessibility, frontend } from "../../../config";
import ProfileView from "../../sections/jobseeker/ProfileView";
import Translation from "../../../utils/Translation";

class PublicJobseekerProfilePage extends Component {
	static contextType = manager;
	state = {
		jobseeker: {},
		skills: [],
		cv: {},
		visibility: "",
		loadedData: false,
		error: false
	};

	/**
	 * Structure of response is based on ResponseFilter
	 * see ResponseFilter.getpublicJobseekerData for reference.
	 */
	rememberData(dataReturn, jobseeker_id) {
		if (dataReturn.constructor.name !== "Object") {
			return this.setState({ error: true });
		}

		const { jobseeker } = dataReturn;
		const visibility = jobseeker.accessLevel;
		let jobseekerData = { jobseeker_id: jobseeker_id };
		let cvData = {};
		let education = [];
		if (visibility === accessibility.full) {
			jobseekerData = {
				jobseeker_name:
					jobseeker && jobseeker.jobseeker_name
						? jobseeker.jobseeker_name
						: "",
				phone: jobseeker && jobseeker.phone ? jobseeker.phone : "",
				email: jobseeker && jobseeker.email ? jobseeker.email : "",
				location:
					jobseeker && jobseeker.location ? jobseeker.location : {},
				jobseeker_id: jobseeker_id
			};
			cvData = jobseeker && jobseeker.cv ? jobseeker.cv : {};
			education =
				jobseeker && jobseeker.education ? jobseeker.education : [];
		}
		const skillsData =
			jobseeker.skills instanceof Array
				? jobseeker.skills.map(skill => {
						return { label: skill, value: skill };
				  })
				: [];

		const employments =
			jobseeker.employments instanceof Array
				? jobseeker.employments.map(employment => {
						const {
							employment_id,
							initial_date,
							end_date,
							technical_stack,
							...rest
						} = employment;
						return {
							...rest,
							employmentId: employment_id,
							technicalStack: technical_stack.map(skill => {
								return {
									value: skill
								};
							}),
							initialDate: initial_date,
							endDate: end_date
						};
				  })
				: [];

		this.setState({
			...this.state,
			jobseeker: jobseekerData,
			cv: cvData,
			skills: skillsData,
			visibility: visibility,
			education: education,
			employments: employments,
			loadedData: true
		});
	}

	componentWillMount() {
		this.context.getAuthManager().pageRestriction({
			logged: true,
			forLoggedUsertype: [usertype.company]
		});
		let that = this;
		this.context
			.getJobseekerManager()
			.getPublicJobseekerData(this, this.props.match.params.id, {
				onSuccess: { toast: false },
				onFinish: {
					callback: dataReturn => {
						that.rememberData(
							dataReturn,
							this.props.match.params.id
						);
					}
				}
			});
		setTitle(
			Translation.translateStaticText(
				"candidateProfileTitle",
				"Candidate Profile"
			)
		);
	}

	render() {
		const {
			jobseeker,
			cv,
			skills,
			visibility,
			education,
			employments,
			loadedData,
			error
		} = this.state;
		let profileView;
		if (loadedData) {
			profileView = (
				<ProfileView
					jobseeker={jobseeker}
					cv={cv}
					visibility={visibility}
					skills={skills}
					education={education}
					employments={employments}
				/>
			);
		}
		if (error === true) {
			return <Redirect to={frontend.route.error + "/noAccessToUser"} />;
		}
		return (
			<Page location={this.props.location}>
				<section
					className="cid-rgbYhVM41a mbr-fullscreen mbr-parallax-background flex-start"
					id="header15-b"
				>
					<br />
					<br />
					<br />
					<div
						className="mbr-overlay"
						style={{
							opacity: 0.5,
							backgroundColor: "rgb(7, 59, 76)"
						}}
					/>
					<div className="container" style={{ marginTop: "50px" }}>
						{profileView}
					</div>
				</section>
			</Page>
		);
	}
}

export default PublicJobseekerProfilePage;
