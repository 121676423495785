export default {
	// UIError: "UI error, please contact support.",
	// serverError: "Server error, please contact support.",
	// UserNotLoggedIn: "User not logged in",
	// CompanyResponseError: "Company response error",
	// ErrorPageDefaultMessage: "Page does not exist or you have no access to it.",
	// noAccessToUser: "User has disabled public access.",
	// passwordDoesNotMatch: "Password does not match.",
	// searchStringRestriction:
	// 	"Search string cannot be empty or 1 character long",
	// searchNotLoaded: "Unable to load your search. Please try again",
	// cannotSelfCorrespondance: "Can not request correspondence with yourself.",
	// cannotCorrespondWithAnotherCompany:
	// 	"Can not request correspondence with another company.",
	// correspondenceAlreadyAvailable:
	// 	"Contact request already sent or user has blocked it.",

	// //Automatic form field validation - Jobseeker -> Employment History
	// "employer-mandatory": "Employer is mandatory",
	// "role-mandatory": "Role is mandatory",
	// "initialDate-mandatory": "Initial Date is mandatory",
	// "initialDate-<= endDate": "Initial Date must be lower or equal to End Date",
	// "endDate-oneOf": "You must choose one of (End Date, Present)"
};
