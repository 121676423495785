import React, { Component } from "react";

class Question extends Component {

    static defaultProps = {
        isOpen: false,
        id: -1,
        children: null
    }

    render() {
        return (
            <dd>
                <p style={{display:this.props.isOpen?"block":"none"}} id={this.props.id}>
                    <br/>
                    {this.props.children}
                    <br/>
                </p>
            </dd>
        );
    }
}

export default Question;