
import React  from 'react';

class Select extends React.Component {

    static defaultProps = {
        name: '',
        className: "",
        onSelectChange: ()=>{console.log("no on chenge provided to select")},
        options: [],
        value: "",
        id: "select",
        style: {},
        placeholder: ""
    }

    render() {
        // if(this.props.name === "select_country_location_0") {
        //     console.log(this.props.name + " default value '"+this.props.value+"'");
        //     console.log(this.props.options);
        // }
        let that = this;
        let options = this.props.options.map(function(option, index) {
            // if(option.value===that.props.value) {
            //     options = [...options, <option key={option.value} value={option.value} selected="selected">{option.text}</option>]
            // } else {
                return <option key={option.value} value={option.value}>{option.text}</option>
            // }
        });
        let select = <select id={this.props.id} style={this.props.style} name={this.props.name} onChange={(e)=>{this.props.onSelectChange(e.target.value)}} className={this.props.className} defaultValue={that.props.value}>{options}</select>
        let label = undefined;
        if(this.props.placeholder ) {
            label = this.props.placeholder+":";
        }
        return (
            <div style={{position: "relative"}}>
                <div style={{position: 'absolute', left: 2, top: 2, fontSize: 14, color: '#949da7',}}>
                    {label}
                </div>
                {select}
            </div>
        );
    }
}

export default Select;