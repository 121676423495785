import React from "react";
import Button from "@material-ui/core/Button";

import FloatingLabelInput from "../form_helpers/FloatingLabelInput";
import ToastHandler from "../../utils/ToastHandler";
import manager from "../../Manager";
import { frontend } from "../../config";
import Translation from "../../utils/Translation";

class AccountRecovery extends React.Component {
    static contextType = manager;

    state = {
        recoveryKey: "",
        type: "send email",
        form: {
            email: "",
            password: "",
            password_confirmation: "",
            submited: false
        }
    };

    componentWillMount() {
        this.context.getAuthManager().pageRestriction({ logged: false });
    }

    handeChange = name => ({ target: { value } }) => {
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                [name]: value,
                submited: false
            }
        });
    };

    onRecoveryEmailSubmit = () => {
        const component = this;
        this.setState({
            ...this.state,
            form: { ...this.state.form, submited: true }
        });
        this.context.getAuthManager().accountRecoverySendEmail(
            component,
            { email: this.state.form.email },
            {
                onSuccess: {
                    message: Translation.translateMessage(
                        "recoveryEmailSubmitted",
                        "We have submitted recovery instructions to your email."
                    ),
                    state: {
                        ...component.state,
                        form: { password: "", email: "", submited: false }
                    }
                },
                onError: {
                    callback: () => {
                        this.setState({
                            ...this.state,
                            form: { ...this.state.form, submited: false }
                        });
                    }
                }
            }
        );
    };

    onPasswordResetSubmit = () => {
        if (
            this.state.form.password !== this.state.form.password_confirmation
        ) {
            ToastHandler.showError(
                Translation.translateErrorMessage(
                    "passwordDoesNotMatch",
                    "Password does not match."
                )
            );
            return;
        }
        const component = this;
        this.setState({
            ...this.state,
            form: { ...this.state.form, submited: true }
        });
        component.context.getAuthManager().accountRecoveryResetPassword(
            component,
            {
                recoveryKey: component.state.recoveryKey,
                password: component.state.form.password,
                password_confirmation:
                    component.state.form.password_confirmation
            },
            {
                onSuccess: {
                    message: Translation.translateMessage(
                        "passwordChange",
                        "Recovery finished, you can login with your new password"
                    ),
                    state: {
                        ...component.state,
                        form: { password: "", email: "", submited: false }
                    }
                },
                onError: {
                    state: { ...component.state, form: { submited: false } }
                }
            }
        );
    };

    handleKeyPressEmail = event => {
        if (event.key === "Enter") {
            event.preventDefault();
            this.onRecoveryEmailSubmit();
        }
    };

    handleKeyPressReset = event => {
        if (event.key === "Enter") {
            event.preventDefault();
            this.onPasswordResetSubmit();
        }
    };

    render() {
        if (
            this.state.recoveryKey === "" &&
            this.props.location &&
            this.props.location.search &&
            this.props.location.search.substring(0, 15) === "?recovery_code="
        ) {
            this.setState({
                ...this.state,
                recoveryKey: this.props.location.search.split("=")[1],
                type: "password reset"
            });
        }
        if (this.state.type === "send email") {
            const {
                form: { email }
            } = this.state;
            return (
                <section
                    className="cid-rgbYhVM41a mbr-fullscreen mbr-parallax-background"
                    id="header15-b"
                >
                    <br />
                    <br />
                    <br />
                    <div
                        className="mbr-overlay"
                        style={{
                            opacity: 0.5,
                            backgroundColor: "rgb(7, 59, 76)"
                        }}
                    />
                    <div
                        className="container align-right"
                        style={{ marginTop: "50px" }}
                    >
                        <div className="row">
                            <div className="mbr-white col-lg-8 col-md-7 content-container">
                                <h1 className="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-2">
                                    {Translation.translateStaticText(
                                        "accountRecovery",
                                        "Account recovery"
                                    )}
                                </h1>
                                <p className="mbr-text pb-3 mbr-fonts-style display-5" />
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <div className="form-container">
                                    <div
                                        className="media-container-column"
                                        data-form-type="formoid"
                                    >
                                        <form className="mbr-form">
                                            <input
                                                type="hidden"
                                                name="email"
                                                data-form-email="true"
                                                value="F69B3NcZe0l3wRLV5UF5Og6P1j1i3UcH9UDLW3cDpMcZoe8HbiWdkLSGqC6PhwHMrVoN5MwD7MMLXUO8aYnCwxS96h63iWLWDbrb3wllJpcPzN87pJFAVT6lEwlTnpqt"
                                            />
                                            <div data-for="username">
                                                <div className="form-group">
                                                    <FloatingLabelInput
                                                        onKeyPress={
                                                            this
                                                                .handleKeyPressEmail
                                                        }
                                                        onChange={this.handeChange(
                                                            "email"
                                                        )}
                                                        type="text"
                                                        className="form-control px-3"
                                                        name="username"
                                                        data-form-field="username"
                                                        placeholder={Translation.translateField(
                                                            "email",
                                                            "Email"
                                                        )}
                                                        required=""
                                                        id="username-header15-b"
                                                        value={email}
                                                    />
                                                </div>
                                            </div>
                                            <span className="input-group-btn">
                                                <Button
                                                    onClick={
                                                        this
                                                            .onRecoveryEmailSubmit
                                                    }
                                                    className={
                                                        "btn btn-" +
                                                        (this.state.form
                                                            .submited
                                                            ? "primary"
                                                            : "secondary") +
                                                        " btn-form display-4"
                                                    }
                                                    disabled={
                                                        this.state.form.submited
                                                    }
                                                >
                                                    {Translation.translateField(
                                                        "sendRecoveryEmailButton",
                                                        "Send recovery email"
                                                    )}
                                                </Button>
                                            </span>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
        } else if (this.state.type === "password reset") {
            const {
                form: { password, password_confirmation }
            } = this.state;
            return (
                <section
                    className="cid-rg5fGgmhmj mbr-fullscreen mbr-parallax-background"
                    id="header15-3"
                >
                    <div
                        className="mbr-overlay"
                        style={{
                            opacity: 0.5,
                            backgroundColor: "rgb(7, 59, 76)"
                        }}
                    />
                    <div
                        className="container align-right"
                        style={{ marginTop: "50px" }}
                    >
                        <div className="row">
                            <div className="mbr-white col-lg-8 col-md-7 content-container">
                                <h1 className="mbr-section-title mbr-bold pb-3 mbr-fonts-style display-2">
                                    {Translation.translateNavigation(
                                        "login",
                                        "Login"
                                    )}
                                </h1>
                                <p className="mbr-text pb-3 mbr-fonts-style display-5" />
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <div className="form-container">
                                    <div
                                        className="media-container-column"
                                        data-form-type="formoid"
                                    >
                                        <form className="mbr-form">
                                            <div data-for="password">
                                                <div className="form-group">
                                                    <FloatingLabelInput
                                                        onKeyPress={
                                                            this
                                                                .handleKeyPressReset
                                                        }
                                                        onChange={this.handeChange(
                                                            "password"
                                                        )}
                                                        type="password"
                                                        className="form-control px-3"
                                                        name="password"
                                                        data-form-field="Password"
                                                        placeholder={Translation.translateField(
                                                            "password",
                                                            "Password"
                                                        )}
                                                        id="password-header15-3"
                                                        value={password}
                                                    />
                                                </div>
                                            </div>
                                            <div data-for="password">
                                                <div className="form-group">
                                                    <FloatingLabelInput
                                                        onKeyPress={
                                                            this
                                                                .handleKeyPressReset
                                                        }
                                                        onChange={this.handeChange(
                                                            "password_confirmation"
                                                        )}
                                                        type="password"
                                                        className="form-control px-3"
                                                        name="password"
                                                        data-form-field="Password confirmation"
                                                        placeholder={Translation.translateField(
                                                            "passwordConfirmation",
                                                            "Password Confirmation"
                                                        )}
                                                        id="password_confirmation-header15-3"
                                                        value={
                                                            password_confirmation
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <span className="input-group-btn">
                                                <Button
                                                    onClick={
                                                        this
                                                            .onPasswordResetSubmit
                                                    }
                                                    className={
                                                        "btn btn-" +
                                                        (this.state.form
                                                            .submited
                                                            ? "primary"
                                                            : "secondary") +
                                                        " btn-form display-4"
                                                    }
                                                    disabled={
                                                        this.state.form.submited
                                                    }
                                                >
                                                    {Translation.translateField(
                                                        "setPasswordButton",
                                                        "Set password"
                                                    )}
                                                </Button>
                                            </span>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
        } else {
            window.location.pathname = frontend.route.error;
        }
    }
}

export default AccountRecovery;
