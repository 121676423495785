import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import manager from "../../../Manager";
import ListOwn from "./ListOwn";
import ApplicationList from "./ApplicationList";
import { usertype, companytype } from "../../../config";
import Translation from '../../../utils/Translation';

class CompanyPositions extends Component {
	static contextType = manager;
	state = {
		companyInfo: {}
	}

	componentDidMount() {
		this.context.getAuthManager().pageRestriction({
			logged: true,
			forLoggedUsertype: [usertype.company]
		});
		let component = this;
		this.context.getCompanyManager().getLoggedCompany(component, {
			onSuccess: {
				callback: companyInfo => {
					component.setState({
						companyInfo: companyInfo,
					})
				}
			}
		});
	}

	render() {
		return (
			<section
				className="counters6 counters cid-rg5irxrHy2 visible full-visible"
				id="counters6-6"
			>
				<div className="container">
					<div className="row">
						<div className="container align-center">
							<span className="input-group-btn">
								<Button
									onClick={() => {
										this.props.goToAdd();
									}}
									className="btn btn-secondary btn-form display-4 page-menu"
								>
									{Translation.translateField("addNewButton", "Add new")}
								</Button>
							</span>
							<br />
							<br />
							<ListOwn
								goToView={this.props.goToView}
								goToEdit={this.props.goToEdit}
								deletePosition={this.props.deletePosition}
								companyInfo={this.state.companyInfo}
							/>
							<br />
							<br />
							<h2>{Translation.translateField("candidatesHeading", "Candidates")}:</h2>
							<ApplicationList
								freeCompany={
									this.state.companyInfo.company_type ===
									companytype.free
								}
								goToView={position_id => {
									this.props.goToView(
										this.state.companyInfo.company_name,
										position_id
									);
								}}
							/>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default CompanyPositions;
