import { getCookie } from "./cookies";
import { cookies, backend } from "../config";

function fileURLFactory(location, publicFile = false) {
	if (location === "") {
		return "";
	}
	if (
		getCookie(cookies.cookieConcent) === "" &&
		(getCookie(cookies.localSessionCookie) === "" || publicFile)
	) {
		return `data:${location}`;
	}
	return `${backend.DOMAIN}${
		publicFile ? backend.api.getPublicFile : backend.api.getAuthorizedFile
	}${location}`;
}

export { fileURLFactory };
