import React from "react";
import Translation from "../../../utils/Translation";
import { languages } from "../../../config";
import ENTermsAndConditionsEmployersPage from './ENTermsAndConditionsEmployersPage';
import BGTermsAndConditionsEmployersPage from './BGTermsAndConditionsEmployersPage';

export default function EmploerTermsAndConditions(props) {
	const getTemplate = lang => {
		switch (lang) {
			case languages.english:
				return <ENTermsAndConditionsEmployersPage />;
			case languages.bulgarian:
				return <BGTermsAndConditionsEmployersPage />;
			case languages.german:
				//TODO implement german langauge ToS
				return <ENTermsAndConditionsEmployersPage />;
			default:
				return <ENTermsAndConditionsEmployersPage />;
		}
	};

	return getTemplate(Translation.getLanguage());
}
