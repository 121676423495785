import React, { Component } from "react";

import CompanyFAQ from "../sections/CompanyFAQ";
import JobseekerFAQ from "../sections/JobseekerFAQ";
import Page from "../Page";
import { setTitle } from "../../pageOptions";
import manager from "../../Manager";
import { usertype } from "../../config";
import Translation from "../../utils/Translation";

class FAQPage extends Component {
  static contextType = manager;

  state = {
    logged: false,
    user: {}
  };

  componentWillMount() {
    this.context.getAuthManager().pageRestriction({ logged: true });
    const component = this;
    component.context.getAuthManager().getLoggedUser(component, {
      onSuccess: {
        toast: false
      },
      onFinish: {
        callback: data => {
          if (data) {
            component.setState({
              ...component.state,
              user: data,
              logged: data !== "User is not logged."
            });
          }
          setTitle(Translation.translateStaticText("FAQPageTitle", "FAQ"));
        }
      }
    });
  }

  render() {
    let page = undefined;
    if (this.state.user.user_type === usertype.company) {
      page = (
        <Page location={this.props.location}>
          <CompanyFAQ />
        </Page>
      );
    } else if (this.state.user.user_type === usertype.jobseeker) {
      page = (
        <Page location={this.props.location}>
          <JobseekerFAQ />
        </Page>
      );
    }
    return page;
  }
}

export default FAQPage;
