export default {
	heading: "Често задавани въпроси",
	guidelines:
		"Натиснете върху въпроса, който ви интересува за да прочетете отговора му.",
	footer:
		"Все още имате неотговорени въпроси? Можете да използвате нашия онлайн чат или да ни изпратите имейл, ако сме офлайн",
	//questions & answers -> Jobseeker
	jobseekerFirstQuestion: "За кого е предназначена тази платформа?",
	jobseekerFirstAnswer:
		"Всеки, който търси нови възможности за да развие своите умения с помощта на разпознаваеми компании на пазара и всяка компания, която търси ново попълнение.",
	jobseekerSecondQuestion: "Кой може да види личната ми информация?",
	jobseekerSecondAnswer:
		"Напълно зависи от вас - можете да отключите изцяло своя профил или да дадете достъп на определени компании, които харесвате.",
	jobseekerThirdQuestion:
		"Мога ли да държа профила си отворен на няколко устройства?",
	jobseekerThirdAnswer:
		"Разбира се, че можете. Само имайте предвид, че съобщенията и известията няма да се отразяват на всеки екран, докато не презаредите страницата на даденото устройство.",
	jobseekerFourthQuestion: "Мога ли да се свържа директно с фирмите?",
	jobseekerFourthAnswer:
		"Разбира се, стига да им изпратите покана и те да я приемат. ",
	jobseekerFifthQuestion:
		"Мога ли да деактивирам профила си или да го направя невидим, когато не търся активно работа?",
	jobseekerFifthAnswer:
		"Да, това е една от добрите функции, които внедрихме. Вие контролирате профила си по всяко време.",
	jobseekerSixthQuestion: "Каква е цената за присъединяване към платформата?",
	jobseekerSixthAnswer: "Абсолютно безплатно.",
	jobseekerSeventhQuestion: "Тогава вие какво печелите?",
	jobseekerSeventhAnswer:
		"Фирмите, които искат да имат достъп до регистрирани профили на кандидатии да публикуват обяви за работа, трябва да се споразумеят с определена сума.",
	jobseekerEighthQuestion: "Каква е целта на платформата?",
	jobseekerEighthAnswer:
		"Предоставяне на инструмент, лесен за използване, свързване и чрез който можете да комуникирате директно.",
	jobseekerNinthQuestion: "Как мога да скрия профила си от компаниите?",
	jobseekerNinthAnswer:
		"Отидете в профила си и кликнете върху настройките - просто следвайте инструкциите. (това не е така реално)",
	jobseekerTenthQuestion:
		"Има ли начин да препоръчам колега за открита позиция?",
	jobseekerTenthAnswer:
		"Най-добрият начин е да го поканите да се присъедини към платформата. По този начин ще получи достъп до всички възможности, които имаме.",
	jobseekerEleventhQuestion:
		"Има ли начин да докладвам компания, ако възникне проблем?",
	jobseekerEleventhAnswer:
		"Ако има някакви проблеми, не се колебайте да се свържете с нас чрез онлайн чат поддръжка.",
	jobseekerTwelfthQuestion: "Защо профилът ми не е видим за някои компании?",
	jobseekerTwelfthAnswer:
		"Възможно е да има няколко фактора. Моля, проверете дали не сте поставили специални ограничения в потребителския си профил. Също така се уверете, че сте посочили всички умения, които притежавате - в повечето случаи компаниите търсят по определени критерии.",
	jobseekerThirtheenthQuestion:
		"Само български компании ли са в платформата?",
	jobseekerThirtheenthAnswer:
		"Платформата е свободна за използване в световен мащаб. Можете лесно да се свържете с регистрирани компании по целия свят, които могат да предоставят нов мащаб от възможности.",
	jobseekerFourteenthQuestion:
		"Необходимо ли е да кача моето CV, за да се присъединя към платформата?",
	jobseekerFourteenthAnswer:
		"Това не е задължително, но силно препоръчително - по този начин ще уточните подробно какво ви отделя от тълпата.",
	jobseekerFifteenQuestion:
		"Мога ли да разкрия и съответно да скрия части от моя профил?",
	jobseekerFifteenAnswer:
		"Абсолютно, въз основа на конфигурацията на профила за достъп.",
	jobseekerSixteenthQuestion:
		"Мога ли да се присъединя към платформата извън ЕС?",
	jobseekerSixteenthAnswer:
		"Да, няма ограничения от гледна точка местоположение откъдето можете да се присъедините или използвате платформата.",
	jobseekerSeventeenthQuestion:
		"Коя част от личната ми информация е видима за компаниите?",
	jobseekerSeventeenthAnswer:
		"Въз основа на конфигурацията на вашия профил можете да покажете цялата си лична информация, която сте въвели, само вашите умения или да не покажете нищо.",
	//Questions & Answers -> Company
	companyFirstQuestion:
		"С какво се отличава Match Careers? Има много други платформи, защо трябва да избера точно вас?",
	companyFirstAnswer:
		"Ние се стремим да осигурим лесни за използване инструменти. Също така се борим всекидневно да разширяваме разнообразието от възможности в платформата както за кандидати, така и за компании. Най-добрият начин да си отговорите на този въпрос е да пробвате платформата :-)",
	companySecondQuestion: "В какви позиции сте специализирани?",
	companySecondAnswer:
		"Не се ограничавамес позиции на високо ниво или само с позиции, които не изискват много опит. Ние сме покрили и двете, така че можем да помогнем на колкото се може повече хора да намерят желаната за тях позиция.",
	companyThirdQuestion: "Как мога да се регистрирам като компания?",
	companyThirdAnswer:
		"Просто попълнете необходимите данни и следвайте инструкциите.",
	companyFourthQuestion:
		"Мога ли да държа профила си отворен на няколко устройства?",
	companyFourthAnswer:
		"Разбира се, че можете. Само имайте предвид, че съобщенията и известията няма да се отразяват на всеки екран, докато не презаредите страницата на даденото устройство.",
	companyFifthQuestion:
		"Колко бързо ще получа кандидатури по обявите си за работа?",
	companyFifthAnswer:
		"Ще бъдете заляти с кандидатури, но трябва да се уверите, че обявата и изискванията са примамливи и самата обява е подробна,така че да няма несъответствия.",
	companySixthQuestion:
		"Верифицирали ли сте ли набора от умения на кандидатите, които вече са се регистрирали във вашата платформа?",
	companySixthAnswer:
		"Match Careers е само платформа - ние предоставяме терена и инструментите за свързване. Ние не сме HR агенция.",
	companySeventhQuestion: "Кога мога да се свържа директно с кандидат?",
	companySeventhAnswer:
		"Веднага щом получите техния формуляр за кандидатстване  или ако кандидатът вече е добавил вашата компания към списъка си за наблюдение - в такъв случай можете да използвате нашия авангарден инструмент за писмена комуникация.",
	companyEighthQuestion: "Кои кандидати са видими за нас като компания?",
	companyEighthAnswer:
		"Всеки, който отговаря на вашите изисквания ине е поставил ограничения в своя профил.",
	companyNinthQuestion:
		"Тъй като ще имате моя имейл адрес това означава ли, че ще получавам многобройни спам съобщения?",
	companyNinthAnswer: "Това ще бъде наистина лошо, така че няма."
};
