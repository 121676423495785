export default {
	// heading: "Frequently asked questions",
	// guidelines: "Click on a question to read the answer.",
	// footer:
	// 	"Still have unanswered Questions? You can use our online chat to either send us a message or an email, if we are offline.",

	// //questions & answers -> Jobseeker
	// jobseekerFirstQuestion: "Who is this platform for?",
	// jobseekerFirstAnswer:
	// 	"Anyone seeking new opportunities to develop his/her skills or extend even further with the help of recognizable companies in the market, and any company looking for a new rockstar, so you can kick off the new project.",
	// jobseekerSecondQuestion: "Who can see my personal info?",
	// jobseekerSecondAnswer:
	// 	"Totally depends on you - whether you decide to make your profile public or give access to certain companies you’ve liked.",
	// jobseekerThirdQuestion: "Can I keep my account open on several devices?",
	// jobseekerThirdAnswer:
	// 	"Sure you can. Just keep in mind that messages and notifications won't reflect on each of them until you refresh the page on the current device.",
	// jobseekerFourthQuestion: "Can I reach out to companies directly?",
	// jobseekerFourthAnswer:
	// 	"Sure, as long as you wave to them by sending an invitation and they accept it. Matching begins.",
	// jobseekerFifthQuestion:
	// 	"Can I deactivate my profile and make it invisible while not actively searching for a new position?",
	// jobseekerFifthAnswer:
	// 	"Yes, it is one of the good features we’ve implemented. You are in control of your profile at all time.",
	// jobseekerSixthQuestion: "What is the price for joining the platform?",
	// jobseekerSixthAnswer: "Absolutely free.",
	// jobseekerSeventhQuestion: "Then what is in it for you?",
	// jobseekerSeventhAnswer:
	// 	"The companies that want to have access to registered profiles or to post job ads have to settle up with certain amount.",
	// jobseekerEighthQuestion: "What is the purpose of the platform?",
	// jobseekerEighthAnswer:
	// 	"Matching the talents with the companies who are looking for their skills. Providing a tool, that is easy to use, connect and via which you can communicate directly.",
	// jobseekerNinthQuestion: "How can I hide my profile from the companies?",
	// jobseekerNinthAnswer:
	// 	"Go to your profile and click on settings - simply follow the instructions.",
	// jobseekerTenthQuestion:
	// 	"Is there a way to recommend a colleague for an open position?",
	// jobseekerTenthAnswer:
	// 	"The best way is to invite him/her to join the platform. That way will be given access to all the opportunities you have.",
	// jobseekerEleventhQuestion:
	// 	"Is there a way to report a company when an issue arises?",
	// jobseekerEleventhAnswer:
	// 	"If you have any discrepancies please feel free to contact us via online chat support.",
	// jobseekerTwelfthQuestion: "Why am I not visible to some companies?",
	// jobseekerTwelfthAnswer:
	// 	"There might be several factors. Please check if you haven’t put special restrictions on your profile. Also makes sure you’ve listed all of the skills you have  - most of the times companies search by certain criterias.",
	// jobseekerThirtheenthQuestion:
	// 	"Are there only Bulgarian companies in the platform?",
	// jobseekerThirtheenthAnswer:
	// 	"The platform is free to use globally. You can easily connect with registered companies all over the world that can provide a new scale of opportunities.",
	// jobseekerFourteenthQuestion:
	// 	"Is it necessary to upload my CV in order to join the platform?",
	// jobseekerFourteenthAnswer:
	// 	"It is not mandatory but highly recommendable - that way you will specify in detail what separates you from the crowd.",
	// jobseekerFifteenQuestion:
	// 	"Can I reveal and respectively hide portions of my profile?",
	// jobseekerFifteenAnswer:
	// 	"Absolutely, based on the access profile configuration.",
	// jobseekerSixteenthQuestion:
	// 	"Can I join the platform from outside of the EU?",
	// jobseekerSixteenthAnswer:
	// 	"Yes, there are no restrictions from where you can join or use the platform.",
	// jobseekerSeventeenthQuestion:
	// 	"Which part of my personal info is visible to the companies?",
	// jobseekerSeventeenthAnswer:
	// 	"Based on your profile configuration you could show all your personal information that you have introduced, only your skills or not show anything.",

	// 	//Questions & Answers -> Company
	// companyFirstQuestion: "What makes Match Careers different? There are many other platforms out there, why should i choose you?",
	// companyFirstAnswer: "We strive to provide simplicity and easy to use tools. Also we struggle on a dalily basis to expand the variety of options in the platform for both candidates and companies. The best way to answer this question is to take a peek and enjoy :-)",
	// companySecondQuestion: "Which positions do you specialize in?",
	// companySecondAnswer: "We do not limit ourselves to only high-level or too unexperienced positions. We have it all covered, so we can match as many people with their desirable career as possible.",
	// companyThirdQuestion: "How can I register as a company?",
	// companyThirdAnswer: "Simply fill out the requested data and follow the instructions.",
	// companyFourthQuestion: "Can I keep my account open on several devices?",
	// companyFourthAnswer: "Sure you can. Just keep in mind that messages and notifications won't reflect on each of them until you refresh the page on the current device.",
	// companyFifthQuestion: "How soon will I receive applications from my job postings?",
	// companyFifthAnswer: "You will be blown with candidates, but you need to make sure, that the ad and requirements are tempting and the opportunity itself is detailed, so there are no discrepancies.",
	// companySixthQuestion: "Have you verified the skill set of the candidates that are already in your platform?",
	// companySixthAnswer: "Match Careers is only a platform - we provide the ground and the tools to connect. We are not an HR agency.",
	// companySeventhQuestion: "When can I reach out directly to a candidate?",
	// companySeventhAnswer: "As soon as you receive is/her application or if the candidate already added your company to his/her watchlist - in that case you can use our cutting edge insystem messaging tool.",
	// companyEighthQuestion: "Which candidates are visible to us as a company?",
	// companyEighthAnswer: "Anyone, matching based on your preferences, who has not put restrictions on his profile.",
	// companyNinthQuestion: "Since you will have my email address does that mean that I will receive numerous spam messages?",
	// companyNinthAnswer: "That will be really bad, so no way." 
};
