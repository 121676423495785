import {
	capitalizeAllWords as capitalize,
	splitCamelCase
} from "./stringUtils";

function mandatoryRule(data, rule, field, action, message = "") {
	if (rule[field] === "mandatory") {
		if (!data.hasOwnProperty(field) || !Boolean(data[field])) {
			return {
				message: message
					? message
					: `${capitalize(splitCamelCase(field))} is mandatory`
			};
		}
	}
	return {};
}

function comparisonRule(data, rule, field, action, message = "") {
	const comparisonStrings = ["<=", ">=", "<", ">", "="];
	let result = {};
	if (RegExp(`/${comparisonStrings.join("|")}/`).test(rule[field])) {
		const comparison = rule[field]
			.match(RegExp(comparisonStrings.join("|")))
			.toString();
		const secondaryField = rule[field].replace(comparison, "").trim();
		switch (comparison) {
			case "<=":
				data[field] <= data[secondaryField]
					? (result = {})
					: (result = {
							message: message
								? message
								: `${capitalize(
										splitCamelCase(field)
								  )} must be lower or equal to ${capitalize(
										splitCamelCase(secondaryField)
								  )}`
					  });
				break;
			case ">=":
				data[field] >= data[secondaryField]
					? (result = {})
					: (result = {
							message: message
								? message
								: `${capitalize(
										splitCamelCase(field)
								  )} must be higher or equal to ${capitalize(
										splitCamelCase(secondaryField)
								  )}`
					  });
				break;
			case "<":
				data[field] < data[secondaryField]
					? (result = {})
					: (result = {
							message: message
								? message
								: `${capitalize(
										splitCamelCase(field)
								  )} must be lower than ${capitalize(
										splitCamelCase(secondaryField)
								  )}`
					  });
				break;
			case ">":
				data[field] > data[secondaryField]
					? (result = {})
					: (result = {
							message: message
								? message
								: `${capitalize(
										splitCamelCase(field)
								  )} must be higher than ${capitalize(
										splitCamelCase(secondaryField)
								  )}`
					  });
				break;
			case "=":
				data[field] === data[secondaryField]
					? (result = {})
					: (result = {
							message: message
								? message
								: `${capitalize(
										splitCamelCase(field)
								  )} must be same as ${capitalize(
										splitCamelCase(secondaryField)
								  )}`
					  });
				break;
			default:
				result = {
					message: "Unsupported rule"
				};
				break;
		}
		return result;
	}
}

function oneOfRule(data, rule, field, action, message = []) {
	let result = {};
	if (action === "oneOf") {
		if (rule[field].constructor.name !== "Array") {
			throw new Error("oneOf rule must be of type Array");
		}
		let anyFieldHasData = false;
		rule[field].forEach(_field => {
			if (data.hasOwnProperty(_field) && Boolean(data[_field])) {
				anyFieldHasData = true;
			}
		});
		if (!anyFieldHasData) {
			const errorFields = rule[field].map(_field =>
				_field.replace(/([a-z])([A-Z])/g, "$1 $2")
			);
			result = {
				message: `You must choose one of (${errorFields
					.map(s => s.charAt(0).toUpperCase() + s.substring(1))
					.toString()})`
			};
		}
	}
	return result;
}

export { mandatoryRule, comparisonRule, oneOfRule };
