import React, { Component, Fragment } from "react";
import swal from "sweetalert";
import { frontend, positionType, remoteWork } from "../../../config";
import EditIcon from "@material-ui/icons/EditTwoTone";
import DeleteIcon from "@material-ui/icons/DeleteTwoTone";
import Translation from "../../../utils/Translation";

class Table extends Component {
  static defaultProps = {
    loggedCompanyId: undefined, // example
    positions: [],
    companyNames: {}, // example {"id1":"name1","id2":"name2"}
    goToEdit: position_id => {},
    goToView: (position_id, title, own) => {},
    deletePosition: position_id => {},
    pagination: { render() {} }
  };

  openView = (companyId, title, own) => {
    return e => {
      e.preventDefault();
      this.props.goToView(this.props.companyNames[companyId], title, own);
    };
  };

  isExpired(date_to) {
    let pdate = new Date(date_to);
    let ndate = new Date();
    if (pdate < ndate) {
      return true;
    }
    return false;
  }

  showAlert = position_id => {
    return e => {
      swal({
        icon: "warning",
        title: Translation.translateStaticText(
          "deletePositionTitle",
          "Deleting Position"
        ),
        text: Translation.translateStaticText(
          "deletePositionWarning",
          "This action is irreversible, are you sure you want to proceed?"
        ),
        buttons: {
          cancel: Translation.translateField("cancelButton", "Cancel"),
          remove: {
            text: Translation.translateField("deleteButton", "Delete")
          }
        }
      }).then(value => {
        switch (value) {
          case "remove":
            this.props.deletePosition(position_id);
            break;

          default:
            break;
        }
      });
    };
  };

  render() {
    if (!this.props.positions) return null;
    const conponent = this;
    let content = this.props.positions.map(position => {
      let ownControl = null;
      let own = false;
      let info = [];
      if (
        conponent.props.loggedCompanyId &&
        position.company_id === conponent.props.loggedCompanyId
      ) {
        ownControl = (
          <div className="job-listing-actions d-flex ml-2">
            <span
              onClick={() => {
                conponent.props.goToEdit(position.position_id);
              }}
              style={{ cursor: "pointer" }}
            >
              <EditIcon />
            </span>
            <span
              className="danger"
              onClick={this.showAlert(position.position_id)}
              style={{ cursor: "pointer" }}
            >
              <DeleteIcon />
            </span>
          </div>
        );
        own = true;
        if (position.active === "no") {
          info.push(
            <span
              key={`${position.position_id}-inactive`}
              className="mb-1 d-md-block d-xs-inline badge badge-warning"
            >
              {Translation.translateField("positionInactive", "Inactive")}
            </span>
          );
        }
        if (this.isExpired(position.valid_to)) {
          info.push(
            <span
              key={`${position.position_id}-expired`}
              className="mb-1 d-md-block d-xs-inline badge badge-danger"
            >
              {Translation.translateField("positionExpired", "Expired")}
            </span>
          );
        }
      }
      // default location when no remote_work or locations are set
      let locations = [
        Translation.translateStaticText(
          "positionLocationNotChoosen",
          "Not Choosen"
        )
      ];
      if (position.remote_work === remoteWork.yes) {
        locations = [remoteWork.location.yes];
      } else if (position.positionLocations.length > 0) {
        locations = position.positionLocations.slice(0, 2).map(location => {
          if (location.city) {
            return `${Translation.translateValue(
              location.country,
              location.country
            )}, ${Translation.translateValue(location.city, location.city)}`;
          }
          return location.country;
        }); // 2 locations
        if (position.positionLocations.length > 2) {
          locations.push(
            Translation.translateStaticText("morePositionLocations", "More...")
          );
        }
      }
      const badgeClass =
        positionType[position.employment_time] === positionType.full_time
          ? "badge-success"
          : "badge-info";

      return (
        <li
          className="job-listing d-block d-sm-flex pb-3 pb-sm-0 align-items-center"
          key={position.position_id}
        >
          <a
            href={`${frontend.route.position.main}/view/${
              conponent.props.companyNames[position.company_id]
            }/${position.title}`}
            onClick={this.openView(position.company_id, position.title, own)}
            aria-label={position.title}
          />
          <div className="job-listing-about d-sm-flex custom-width w-100 justify-content-between mx-4">
            <div className="job-listing-position custom-width w-50 mb-3 mb-sm-0">
              <h2>{position.title}</h2>
              <strong>
                {conponent.props.companyNames[position.company_id]}
              </strong>
            </div>
            <div className="job-listing-location mb-3 mb-sm-0 custom-width w-25 d-flex flex-column justify-content-between">
              {locations.map(location => {
                return (
                  <span key={location} className="icon-room">
                    {location}
                  </span>
                );
              })}
            </div>
            <div className="job-listing-meta">
              {info}
              <span className={`d-md-block d-xs-inline badge ${badgeClass}`}>
                {Translation.translateValue(
                  position.employment_time,
                  position.employment_time
                )}
              </span>
            </div>
            {ownControl}
          </div>
        </li>
      );
    });
    return (
      <Fragment>
        <ul className="job-listings mb-5">{content}</ul>
        {this.props.pagination ? this.props.pagination.render() : null}
      </Fragment>
    );
  }
}

export default Table;
