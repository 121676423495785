import React, { Component } from "react";
import Button from "@material-ui/core/Button";

import CompanyProfileData from "../../sections/CompanyProfileData";
import EmployeeProfileData from "../../sections/EmployeeProfileData";
import Page from "../../Page";
import manager from "../../../Manager";
import { setTitle } from "../../../pageOptions";
import Translation from "../../../utils/Translation";

class CompanyProfilePage extends Component {
  static contextType = manager;

  components = {
    CompanyProfileData: "CompanyProfileData",
    EmployeeProfileData: "EmployeeProfileData"
  };

  state = {
    active: this.components.CompanyProfileData
  };

  handeChange = name => {
    this.setState({
      active: name
    });
  };

  componentWillMount() {
    this.context.getAuthManager().pageRestriction({ logged: true });
    setTitle(
      Translation.translateStaticText("profileInformationTitle", "Profile")
    );
  }

  render() {
    let section = undefined;
    let pageMenu = undefined;
    switch (this.state.active) {
      case this.components.EmployeeProfileData:
        section = <EmployeeProfileData />;
        pageMenu = (
          <div className="container align-center">
            <span className="input-group-btn">
              <Button
                onClick={() => {
                  this.handeChange(this.components.CompanyProfileData);
                }}
                className="btn btn-secondary btn-form display-4 page-menu"
              >
                {Translation.translateField(
                  "companyProfileButton",
                  "Company Profile"
                )}
              </Button>
            </span>
            &nbsp;&nbsp;&nbsp;
            <span className="input-group-btn">
              <Button
                onClick={() => {}}
                className="btn btn-secondary btn-form display-4 page-menu"
              >
                {Translation.translateField(
                  "employeeProfileButton",
                  "Your Profile"
                )}
              </Button>
            </span>
          </div>
        );
        break;
      default:
        section = <CompanyProfileData />;
        pageMenu = (
          <div className="container align-center">
            <span className="input-group-btn">
              <Button
                onClick={() => {}}
                className="btn btn-secondary btn-form display-4 page-menu"
              >
                {Translation.translateField(
                  "companyProfileButton",
                  "Company Profile"
                )}
              </Button>
            </span>
            &nbsp;&nbsp;&nbsp;
            <span className="input-group-btn">
              <Button
                onClick={() => {
                  this.handeChange(this.components.EmployeeProfileData);
                }}
                className="btn btn-secondary btn-form display-4 page-menu"
              >
                {Translation.translateField(
                  "employeeProfileButton",
                  "Your Profile"
                )}
              </Button>
            </span>
          </div>
        );
        break;
    }
    return (
      <Page location={this.props.location}>
        <section
          className="cid-rgbYhVM41a mbr-fullscreen mbr-parallax-background"
          id="header15-b"
        >
          <br />
          <br />
          <br />
          <div
            className="mbr-overlay"
            style={{ opacity: 0.5, backgroundColor: "rgb(7, 59, 76)" }}
          />
          <div className="container" style={{ marginTop: "50px" }}>
            {pageMenu}
            <br />
            {section}
          </div>
        </section>
      </Page>
    );
  }
}

export default CompanyProfilePage;
