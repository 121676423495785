import React, { Fragment, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grow from "@material-ui/core/Grow";

const styles = makeStyles({
	tooltip: {
		fontSize: 14,
		backgroundColor: "#ffffff",
		padding: "1rem",
		color: "#c03"
	}
});

export default function HelperTooltip(props) {
	const {
		classes,
		mobileClassName,
		disableClickListener,
		children,
		...rest
	} = props;
	const [isFocused, focus] = useState(false);

	const tooltipClasses = styles(classes);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up("md"));

	let childEvents = {
		onFocus: _ => focus(true),
		onBlur: _ => focus(false),
		onMouseOver: _ => focus(true),
		onMouseOut: _ => focus(false),
		onClick: _ => focus(!isFocused)
	};
	if (props.disableFocusListener) {
		delete childEvents.onFocus;
		delete childEvents.onBlur;
	}
	if (props.disableHoverListener) {
		delete childEvents.onMouseOver;
		delete childEvents.onMouseOut;
	}
	//we disable the click handler for higher resolutions as is intended for mobile media.
	if (disableClickListener || matches) {
		delete childEvents.onClick;
	}

	if (matches) {
		return (
			<Tooltip classes={tooltipClasses} {...rest}>
				{children}
			</Tooltip>
		);
	}

	return (
		<Fragment>
			{React.cloneElement(children, childEvents)}
			{props.title && isFocused ? (
				<Grow in={isFocused}>
					<span
						className={`${mobileClassName} d-block mt-2 mb-2 text-left bg-white p-3 helper-heading-text rounded`}
					>
						{props.title}
					</span>
				</Grow>
			) : null}
		</Fragment>
	);
}

HelperTooltip.defaultProps = {
	classes: {},
	disableHoverListener: false,
	disableFocusListener: false,
	disableClickListener: false,
	title: "",
	mobileClassName: "",
	placement: undefined
};
