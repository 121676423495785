import React, { Component } from "react";
import manager from "../../../Manager";
import Table from "./Table";
import Pagination from "../../../utils/Pagination";
import { usertype } from "../../../config";
import Translation from "../../../utils/Translation";

class List extends Component {
  static contextType = manager;

  pagination = undefined;
  _ismounted = false;

  state = {
    positionsData: [],
    loadingPositions: true,
    loggedCompany: undefined,
    companyNames: {},
    loadingloggedCompany: true
  };

  componentDidMount() {
    this.context
      .getAuthManager()
      .pageRestriction({ logged: true, forLoggedUsertype: usertype.jobseeker });
    const component = this;
    this.context.getAuthManager().getLoggedUser(this, {
      onFinish: {
        callback: user => {
          if (user && user.user_type === usertype.company) {
            this.context.getCompanyManager().getLoggedCompany(component, {
              onError: {
                state: {
                  ...component.state,
                  loadingloggedCompany: false,
                  loadingPositions: false
                }
              },
              onSuccess: {
                callback: data => {
                  component.setState({
                    ...component.state,
                    loggedCompany: data,
                    loadingloggedCompany: false
                  });
                  component.initPagination();
                }
              }
            });
          } else {
            component.setState({
              ...component.state,
              loadingloggedCompany: false
            });
            component.initPagination();
          }
        }
      }
    });
  }

  initPagination() {
    this.pagination = new Pagination(
      1,
      20,
      Translation.translateStaticText("paginationJobsTitle", "Jobs"),
      this.onPageChange
    );
    this.pagination.init();
  }

  onPageChange = (page = 1) => {
    return (e) => {
      this.context
        .getPositionManager()
        .getPaginationAllPositionList(
          this,
          (page - 1) * this.pagination.size,
          this.pagination.size,
          {
            onSuccess: {
              toast: false,
              callback: (data) => {
                if (!("totalCount" in data) || !("items" in data)) {
                  console.error(
                    "Position List onPageChange ERROR: managerAPI response not containing pagination structure!"
                  );
                  return;
                }
                this.pagination.totalCount = data.totalCount;
                this.populateData(this, data.items, data);
                window.scrollTo(0, 0);
              },
            },
            onError: {
              toast: false,
              callback: (data) => {
                this.populateData(this, {}, data);
              },
            },
          }
        );
    };
  };

  populateData(that, data, debugApiReturnData) {
    let companiesDict = {};
    if ("companies" in data) {
      data.companies.map((company) => {
        companiesDict[company.company_id] = company.company_name;
        return null;
      });
    }
    that.setState({
      ...that.state,
      loadingPositions: false,
      positionsData: "positions" in data ? data.positions : [],
      companyNames: companiesDict,
    });
  }

  render() {
    // data loading, wait for it
    if (this.state.loadingloggedCompany || this.state.loadingPositions) {
      return null;
    }

    // prepare text/animation/list of positions
    let positions = "";
    if (this.state.positionsData.length > 0) {
      positions = (
        <Table
          ref={this.scrollRef}
          positions={this.state.positionsData}
          companyNames={this.state.companyNames}
          pagination={this.pagination}
          goToView={this.props.goToView}
        />
      );
    } else if (this.state.loadingPositions) {
      // TODO: make an animation
      positions = (
        <div>
          {Translation.translateStaticText(
            "loadingPositions",
            "Loading positions..."
          )}
        </div>
      );
    } else {
      positions = (
        <div>
          {Translation.translateStaticText(
            "noPunlishedPositions",
            "No published positions."
          )}
        </div>
      );
    }

    return (
      <section
        className="counters6 counters cid-rg5irxrHy2 visible full-visible"
        id="counters6-6"
      >
        <div className="container">
          <div className="row">
            <div className="container align-center">
              <h2 className="section-title mb-2">
                {Translation.translateStaticText(
                  "allPositionsHeading",
                  "All positions"
                )}
                :
              </h2>
              {positions}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default List;
