import React from "react";
import Translation from "../../../utils/Translation";
import { languages } from "../../../config";
import ENGdprCandidatesPage from './ENGdprCandidatesPage';
import BGGdprCandidatesPage from './BGGdprCandidatesPage';

export default function GdprJobseekersPage(props) {
	const getTemplate = lang => {
		switch (lang) {
			case languages.english:
				return <ENGdprCandidatesPage />;
			case languages.bulgarian:
				return <BGGdprCandidatesPage />;
			case languages.german:
				//TODO implement german langauge GDPR
				return <ENGdprCandidatesPage />;
			default:
				return <ENGdprCandidatesPage />;
		}
	};

	return getTemplate(Translation.getLanguage());
}
