import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";

import SkillListing from "./SkillListing";

class LimitedProfile extends Component {
	static defaultProps = {
		children: [], //If some additional UI for higher level compoenents needs to be passed (return button when previewing profile as jobseeker)
		skills: []
	}
	render() {
		return (
			<Grid container spacing={2}>
				<SkillListing skills={this.props.skills} />
				{this.props.children}
			</Grid>
		);
	}
}

export default LimitedProfile;
