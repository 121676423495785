import React from "react";
import Translation from "../../utils/Translation";

export default function CompanyHowItWorks(props) {
    return (
        <div id="next">
            <section className="timeline1 cid-rgxv6dNGOY" id="timeline1-g">
                <div className="container align-center">
                    <h2 className="mbr-section-title pb-3 mbr-fonts-style display-1">
                        {Translation.translateStaticText(
                            "howItWorksHeading",
                            "How it Works"
                        )}
                        <br />
                    </h2>
                    <div
                        className="container timelines-container"
                        mbri-timelines=""
                    >
                        <div className="row timeline-element reverse separline">
                            <div className="timeline-date-panel col-xs-12 col-md-6  align-left">
                                <div className="time-line-date-content">
                                    <p className="mbr-timeline-date mbr-fonts-style display-5">
                                        {Translation.translateStaticText(
                                            "step1",
                                            "STEP 1"
                                        )}
                                    </p>
                                </div>
                            </div>
                            <span className="iconBackground" />
                            <div className="col-xs-12 col-md-6 align-right">
                                <div className="timeline-text-content">
                                    <h4 className="mbr-timeline-title pb-3 mbr-fonts-style display-5">
                                        <p>
                                            {Translation.translateStaticText(
                                                "companyStep1Heading",
                                                "Get registered"
                                            )}
                                        </p>
                                    </h4>
                                    <p className="mbr-timeline-text mbr-fonts-style display-7">
                                        {Translation.translateStaticText(
                                            "companyStep1Description",
                                            "Just a few details and you are in - you will receive a confirmation email once everything is done."
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row timeline-element  separline">
                            <div className="timeline-date-panel col-xs-12 col-md-6 align-right">
                                <div className="time-line-date-content">
                                    <p className="mbr-timeline-date mbr-fonts-style display-5">
                                        {Translation.translateStaticText(
                                            "step2",
                                            "STEP 2"
                                        )}
                                    </p>
                                </div>
                            </div>
                            <span className="iconBackground" />
                            <div className="col-xs-12 col-md-6 align-left ">
                                <div className="timeline-text-content">
                                    <h4 className="mbr-timeline-title pb-3 mbr-fonts-style display-5">
                                        {Translation.translateStaticText(
                                            "companyStep2Heading",
                                            "Tell Us a Story About You"
                                        )}
                                    </h4>
                                    <p className="mbr-timeline-text mbr-fonts-style display-7">
                                        {Translation.translateStaticText(
                                            "companyStep2Description",
                                            "Spend some time to fill as much info as possible about your enterprise - the more the better. This will increase the interest in candidates about your work and projects."
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row timeline-element reverse separline">
                            <div className="timeline-date-panel col-xs-12 col-md-6  align-left">
                                <div className="time-line-date-content">
                                    <p className="mbr-timeline-date mbr-fonts-style display-5">
                                        {Translation.translateStaticText(
                                            "step3",
                                            "STEP 3"
                                        )}
                                    </p>
                                </div>
                            </div>
                            <span className="iconBackground" />
                            <div className="col-xs-12 col-md-6 align-right">
                                <div className="timeline-text-content">
                                    <h4 className="mbr-timeline-title pb-3 mbr-fonts-style display-5">
                                        {Translation.translateStaticText(
                                            "companyStep3Heading",
                                            "Choose your pricing plan"
                                        )}
                                    </h4>
                                    <p className="mbr-timeline-text mbr-fonts-style display-7">
                                        {Translation.translateStaticText(
                                            "companyStep3Description",
                                            "Based on your needs - get the plan that matches it and start your journey through our platform."
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row timeline-element ">
                            <div className="timeline-date-panel col-xs-12 col-md-6 align-right">
                                <div className="time-line-date-content">
                                    <p className="mbr-timeline-date mbr-fonts-style display-5">
                                        {Translation.translateStaticText(
                                            "finalStep",
                                            "FINAL STEP"
                                        )}
                                    </p>
                                </div>
                            </div>
                            <span className="iconBackground" />
                            <div className="col-xs-12 col-md-6 align-left ">
                                <div className="timeline-text-content">
                                    <h4 className="mbr-timeline-title pb-3 mbr-fonts-style display-5">
                                        {Translation.translateStaticText(
                                            "companyFinalStepHeading",
                                            "Spread The Word"
                                        )}
                                    </h4>
                                    <p className="mbr-timeline-text mbr-fonts-style display-7">
                                        {Translation.translateStaticText(
                                            "companyFinalStepDescription",
                                            "Put your job ad on the market and start hiring through Match.Careers."
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
